import { TextField, Button, Box, Grid, Typography, Autocomplete, Stack, Modal } from "@mui/material";
import * as React from "react";
import DocumentBrowser from "./DocumentBrowser";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import getCSRFToken from "../../stores/CSRFStore";
import SemanticSearch from "./SemanticSearch";
import { Helmet } from "react-helmet";
import { MDBIcon } from "mdb-react-ui-kit";
import useUserData from "../../hooks/useUserData";
import { Link } from "react-router-dom";

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type AddNewKnowledgeBaseModalProps = {
  handleClose: () => void;
};

const AddNewKnowledgeBaseModal = ({ handleClose }: AddNewKnowledgeBaseModalProps) => {
  const [newKnowledgeBaseName, setNewKnowledgeBaseName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();

  async function addNewKnowledgeBase() {
    setLoading(true);
    await getCSRFToken();
    axios.post(`api/v1/knowledge-bases`,
      JSON.stringify({
        path: newKnowledgeBaseName
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then(response => {
      return response.data;
    })
      .then(data => {
        console.log(data);
        setLoading(false);
        snackbar.setMessage('Baza wiedzy została dodana');
        snackbar.setSeverity('success');
        snackbar.setOpen(true);
        handleClose();
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        snackbar.setMessage('Wystąpił błąd podczas dodawania bazy wiedzy');
        snackbar.setSeverity('error');
        snackbar.setOpen(true);
      });
  }

  return (
    <Box display="flex" alignContent="center">
      <TextField
        id="outlined-basic"
        label="Nazwa bazy wiedzy"
        variant="outlined"
        value={newKnowledgeBaseName}
        onChange={(event) => { setNewKnowledgeBaseName(event.target.value); }}
      />
      <Button
        variant="contained"
        onClick={addNewKnowledgeBase}
        sx={{
          alignSelf: 'center',
          marginLeft: '16px'
        }}
      >Dodaj</Button>
    </Box>
  );
}


export default function BrowseDocuments({ minimal = false, callback }: { minimal?: boolean, callback?: any }) {
  const [collection, setCollection] = React.useState('test-collection');
  const [knowledgeBases, setKnowledgeBases] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);
  const { userData, setUserData } = useUserData();

  const axios = useAxiosPrivate();

  async function refreshKnowledgeBases() {
    await getCSRFToken();
    axios.get(`api/v1/knowledge-bases`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        setKnowledgeBases(data.paths);
      })
      .catch(error => {
        console.log(error);
        snackbar.setMessage('Wystąpił błąd podczas pobierania listy baz wiedzy');
        snackbar.setSeverity('error');
        snackbar.setOpen(true);
      });
  }

  function deleteCurrentKnowledgeBase() {
    getCSRFToken();
    axios.delete(`api/v1/knowledge-bases?path=${collection}`)
      .then(response => {
        console.log(response.data);
        snackbar.setMessage('Baza wiedzy została usunięta');
        snackbar.setSeverity('success');
        snackbar.setOpen(true);
      })
      .catch(error => {
        console.log(error);
        snackbar.setMessage('Wystąpił błąd podczas usuwania bazy wiedzy');
        snackbar.setSeverity('error');
        snackbar.setOpen(true);
      });
  }

  // save collection name to local storage
  React.useEffect(() => {
    localStorage.setItem('collection', collection);
    refreshKnowledgeBases();
  }, [collection]);

  console.log(knowledgeBases);

  return (
    <>
      <Helmet>
        <title>Własna baza | Gaius Lex</title>
        <meta
          name="description"
          content="Gaius Lex - asystent prawnika. Własna baza wiedzy. Przeszukaj swoje dokumenty jak w wyszukiwarce orzeczeń!"
        />
      </Helmet>
      <div id={"browse-documents"}>
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            refreshKnowledgeBases();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={MODAL_STYLE}>
            <AddNewKnowledgeBaseModal
              handleClose={() => {
                setModalOpen(false);
                refreshKnowledgeBases();
              }}
            />
          </Box>
        </Modal>
        <Modal
          open={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
            refreshKnowledgeBases();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={MODAL_STYLE}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Usuń bazę wiedzy
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Czy na pewno chcesz usunąć bazę wiedzy "{collection}"?
            </Typography>
            <Stack direction={"row"} justifyContent={"end"}>
              <Button
                color="error"
                onClick={() => {
                  deleteCurrentKnowledgeBase();
                  setDeleteModalOpen(false);
                  refreshKnowledgeBases();
                }}
                sx={{
                  alignSelf: "center",
                  marginLeft: "16px",
                }}
              >
                Usuń
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setDeleteModalOpen(false);
                }}
                sx={{
                  alignSelf: "center",
                  marginLeft: "16px",
                }}
              >
                Anuluj
              </Button>
            </Stack>
          </Box>
        </Modal>
        <div id={"document-browser"}>
          <Box
            className="mb-3 d-flex"
            sx={{
              justifyContent: "end",
            }}
          >
            <Grid container spacing={2} justifyContent={"center"}>
              {!minimal && (
                <Grid item xs={12} sm={12} md={12}>
                  <h1 className="mb-3">Przeglądaj dokumenty</h1>
                  <Typography variant="h3" sx={{ fontSize: "0.9rem" }}>
                    Po wrzuceniu plików będziesz w stanie przeszukiwać ich
                    treści jak w wyszukiwarce!
                  </Typography>
                </Grid>
              )}
              {/* <Grid item xs={12} sm={12} md={4}>
            <Stack direction={"row"}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={knowledgeBases}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Kolekcja" />}
                onChange={(event, value) => {
                  if (value) {
                    setCollection(value);
                  }
                }}
                value={collection}
              />
              <Button
                variant="contained"
                onClick={() => { setModalOpen(true); }}
                sx={{
                  alignSelf: 'center',
                  marginLeft: '16px'
                }}
              >Dodaj nową kolekcję</Button>
            </Stack>
          </Grid> */}
            </Grid>
          </Box>
          {/* <Box className="mb-3 d-flex" sx={{
        justifyContent: 'end',
      }}>
        <Button
          color="error"
          onClick={() => { setDeleteModalOpen(true); }}
        >
          Usuń obecną bazę wiedzy
        </Button>
      </Box> */}
          <Typography sx={{ display: "inline-flex" }}>
            {userData.isSocialLogin && userData.hasSecretAnswers && (
              <>
                <MDBIcon
                  fas
                  icon="triangle-exclamation"
                  color="danger"
                  style={{ fontSize: 20, marginTop: 5 }}
                />
                <p className="encryption-info">
                  Twoje pliki NIE są szyfrowane, ponieważ jesteś zalogowany(-a)
                  przy użyciu konta społecznościowego (google lub facebook). Aby
                  aktywować możliwość szyfrowania plików{" "}
                  <Link to="/login?socialLogin=true">
                    zaloguj się przy pomocy hasła i adresu email
                  </Link>
                </p>
              </>
            )}

            {userData.isSocialLogin && !userData.hasSecretAnswers && (
              <>
                <MDBIcon
                  fas
                  icon="triangle-exclamation"
                  color="danger"
                  style={{ fontSize: 20, marginTop: 5 }}
                />
                <p className="encryption-info">
                  Twoje pliki NIE są szyfrowane, ponieważ jesteś zalogowany(-a)
                  przy użyciu konta społecznościowego (google lub facebook). Aby
                  aktywować możliwość szyfrowania plików{" "}
                  <Link to="/password-reset">
                    zresetuj hasło oraz podaj odpowiedzi na pytania
                    bezpieczeństwa
                  </Link>
                  , następnie zaloguj się przy pomocy hasła i adresu email
                </p>
              </>
            )}

            {!userData.isSocialLogin && !userData.hasSecretAnswers && (
              <>
                <MDBIcon
                  fas
                  icon="triangle-exclamation"
                  color="danger"
                  style={{ fontSize: 20, marginTop: 5 }}
                />
                <p className="encryption-info">
                  Twoje pliki NIE są szyfrowane, ponieważ pominąłeś(-ęłaś)
                  odpowiedzi na pytania bezpieczeństwa. Aby aktywować możliwość
                  szyfrowania plików{" "}
                  <Link to="/password-reset">zresetuj hasło</Link> oraz podaj
                  odpowiedzi na pytania bezpieczeństwa
                </p>
              </>
            )}
          </Typography>
          <DocumentBrowser
            collection={collection}
            minimal={minimal}
            callback={callback}
          />
        </div>
        {!minimal && <SemanticSearch />}
      </div>
    </>
  );
}