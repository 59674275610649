import React, { createContext, useState } from 'react';
import { TextField, Paper, List, ListItem, ListItemIcon, ListItemText, IconButton, InputAdornment, Collapse, Stack, ListItemButton, Checkbox, Divider, Typography, Box, Tabs, Tab, ClickAwayListener, Select, MenuItem, InputLabel, FormControl, Tooltip, Button, Grid } from '@mui/material';
import { default as GenericButton } from '../../components/generic/Button';
import { History, Search, ExpandMore, ArrowForward, Description, FormatListBulleted, QuestionAnswer, QuestionAnswerTwoTone, QuestionMark, Filter, FilterList, PriorityHigh, CopyAll, DoDisturb } from '@mui/icons-material';
import useGaiusWebSocket from '../../hooks/useGaiusWebSocket';
import { scrollBarStyleSXX, scrollBarStyleSXY } from '../../components/styles';
import { formatData } from '../interactiveQuery/InteractiveQuery';
import { CircularProgress } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import getCSRFToken from '../../stores/CSRFStore';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import "./interactiveSearch.scss";
import { useSearchParams } from 'react-router-dom';
import InteractiveSearchHistory from './InteractiveSearchHistory';
import LinearDeterminate from '../../components/LinearBuffer';
import OnboardingModal from "../../components/onboardingModal";
import Joyride from 'react-joyride';
import { JoyRideLocale, JoyRideSteps, JoyRideStepsPhaseOne } from './joyRideData';

type TabMetadata = {
  label: string,
  index: number
}

const NAMES_MAPPING: { [key: string]: TabMetadata } = {
  "own_database": {
    "label": "Własne dane",
    "index": 0,
  },
  "judiciary": {
    "label": "Orzecznictwo",
    "index": 1,
  },
  "Eureka": {
    "label": "Interpretacje podatkowe",
    "index": 2,
  },
  "Kodeksy": {
    "label": "Kodeksy",
    "index": 3,
  },
  "Acts": {
    "label": "Ustawy",
    "index": 4,
  },
  "EurLex": {
    "label": "EurLex",
    "index": 5,
  },
}


type TMessage = {
  notebook: string;
  command: string;
  results: {}[];
  source: string;
  documents: {}[];
  document: string;
  summary: string;
  message: string;
  query: string;
  answer: string;
  displayId: string;
};

export const InteractiveSearchContext = createContext<{
  disabled: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  itemMapping: {
    [key: string]: {
      displayId: string;
      id: string;
      internalUrl: string;
    };
  };
}>({
  disabled: false,
  loading: false,
  setLoading: (loading: boolean) => { },
  itemMapping: {
    "init": {
      displayId: "",
      id: "",
      internalUrl: ""
    }
  },
});

const NotebookInterface = () => {
  const axios = useAxiosPrivate();
  const [title, setTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [firstPhaseDiabled, setFirstPhaseDisabled] = useState(false);
  const [secondPhaseDisabled, setSecondPhaseDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [expandedSection, setExpandedSection] = useState('factual');

  const [searchResults, setSearchResults] = useState({});
  const [itemMapping, setItemMapping] = useState<any>({});
  const [searchInit, setSearchInit] = useState(false);
  const [selected, setSelected] = useState<any>([]);

  const [summaries, setSummaries] = useState<{ [key: string]: { [key: string]: { summary: string, displayId: string } } }>({});
  const [questionAnswers, setQuestionAnswers] = useState<{ [key: string]: { [key: string]: [{ query: string, answer: string, displayId: string }] } }>({});
  const [finalSummary, setFinalSummary] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  // const [drawJoyride, setDrawJoyride] = useState(false);

  let { sendMessage, lastMessage, getWebSocket } = useGaiusWebSocket('/ws/v1/search-interactive');

  const handleSectionClick = (section: string) => {
    setExpandedSection(section);
  };

  const onFactualStateSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSearchResults({});
    setSelected([]);
    setSummaries({});
    setQuestionAnswers({});
    setFinalSummary('');
    sendMessage(JSON.stringify({ query: searchQuery, command: "search-sources" }));
  }

  const onSearchDocumentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendMessage(JSON.stringify({ query: searchQuery, command: "find-document" }));
    setLoading(true);
    setSearchResults({});
    setSelected([]);
    setSummaries({});
    setQuestionAnswers({});
    setFinalSummary('');
  }

  const handleHistoryResult = (key: string) => {
    setSearchInit(true);
    axios.get(`/api/v1/interactive-search-history?key=${key}`)
      .then((response) => {
        const data = response.data;

        setSearchQuery(data.query);
        data.results.forEach((result: TMessage) => parseResults(result))
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => setSearchInit(false));
  }

  const parseResults = (message: TMessage) => {
    if (message.command === 'notebook-name') {
      if (title === '') {
        setTitle(message.notebook);
      }
    }
    else if (message.command === 'search-sources-init') {
      setSearchInit(true);
    }
    else if (message.command === 'search-sources') {
      setSearchInit(false);
      setLoading(false);
      setSecondPhaseDisabled(false);
      setSearchResults((prev: any) => {
        const newResults = { ...prev };
        newResults[message.source] = message.results;
        setItemMapping((prev: any) => {
          const newMapping = { ...prev };
          message.results.forEach((item: any) => {
            const newItem = formatData([item])[0];
            newMapping[newItem.id] = newItem;
          });
          return newMapping;
        });

        return newResults;
      });
    }
    else if (message.command === 'search-sources-complete') {
      setFirstPhaseDisabled(false);
      setSearchInit(false);
      setSecondPhaseDisabled(false);
      setLoading(false);
    }
    else if (message.command === 'find-document') {
      setSearchResults((prev: any) => {
        const newResults = { ...prev };
        newResults[message.source] = message.documents;
        return newResults;
      });
    }
    else if (message.command === 'summarize-documents') {
      setSummaries((prev: any) => {
        const newSummaries = { ...prev };

        const item: { [key: string]: { summary: string } } = {};
        item[message.document] = {
          "summary": message.summary,
        };

        newSummaries[message.source] = { ...newSummaries[message.source], ...item };
        return newSummaries;
      });
    }
    else if (message.command === 'summarize-documents-complete') {
      setLoading(false);
    }
    else if (message.command === 'ask-question') {
      setQuestionAnswers((prev: any) => {
        const newSummaries = { ...prev };

        if (!newSummaries[message.source]) {
          newSummaries[message.source] = {};
        }
        if (!newSummaries[message.source][message.document]) {
          newSummaries[message.source][message.document] = [];
        }
        // filter out answers with the same query
        newSummaries[message.source][message.document] = newSummaries[message.source][message.document].filter((qa: any) => qa.query !== message.query);
        newSummaries[message.source][message.document].push({ query: message.query, answer: message.answer, displayId: message.displayId });

        return newSummaries;
      });
    }
    else if (message.command === 'ask-question-complete') {
      setLoading(false);
    }
    else if (message.command === 'final-summary') {
      setFinalSummary(message.message);
      setLoading(false);
    }
  }

  const handleInteractiveSearch = () => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data);
      parseResults(message);
    }
  }

  React.useEffect(() => {
    handleInteractiveSearch();
  }, [lastMessage]);

  React.useEffect(() => {
    const key = searchParams.get("key");
    // TODO FIX: we need to force joyride to render after all other components
    // it works but this is terrible solution - probably refactor for the
    // whole component is required
    // setDrawJoyride(true);

    if (key) {
      handleHistoryResult(key);
    }

  }, []);

  const startNewCase = () => {
    getWebSocket()?.close();
    setSearchQuery('');
    setSearchResults({});
    setSummaries({});
    setQuestionAnswers({});
    setFinalSummary('');
    setFirstPhaseDisabled(false);
    setSecondPhaseDisabled(true);
    setLoading(false);
    setTitle('');
    setSelected([]);
    setSearchInit(false);
  }


  return (
    <>

      {/* {drawJoyride && <Joyride steps={JoyRideSteps} continuous={true} locale={JoyRideLocale} />} */}

      {/* <TextField
        fullWidth
        variant="standard"
        value={title}
        placeholder="Nazwij wyszukiwanie"
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mb: 3, '& input': { fontSize: '1.5rem', fontWeight: 'light', color: '#555' } }}
      /> */}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ mb: 3 }}>Wyszukiwanie interaktywne</Typography>
        <Box sx={{display: 'flex', gap: 2}}>
          <OnboardingModal videos={["992154264", "992154202", "992154164"]}/>
          <Button
            variant="contained"
            onClick={startNewCase}
          >
            Zacznij nową sprawę
          </Button>
        </Box>

      </Box>

      <InteractiveSearchContext.Provider value={{
        disabled: !!firstPhaseDiabled,
        loading: loading,
        setLoading: setLoading,
        itemMapping: itemMapping,
      }}>

        <InitialTile
          handleSectionClick={handleSectionClick}
          expandedSection={expandedSection}
          onFactualStateSubmit={onFactualStateSubmit}
          onSearchDocumentSubmit={onSearchDocumentSubmit}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          disabled={firstPhaseDiabled}
        />
        {
          searchInit &&
          <Paper elevation={0} sx={{ p: 3, maxWidth: 800, margin: 'auto', mt: 3 }}>
            <List>
              <ListItem sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}>
                <LinearDeterminate quotes={true} maxTime={60} />
              </ListItem>
            </List>
          </Paper>
        }
        {
          !searchInit && Object.keys(searchResults).length > 0 &&
          <PhaseOneSearchResults
            searchResults={searchResults}
            selected={selected}
            onSelect={setSelected}
            sendMessage={sendMessage}
            query={searchQuery}
            setSummaries={setSummaries}
            setQuestionAnswers={setQuestionAnswers}
            disabled={false}
            searchInit={searchInit}
          />
        }
        <PhaseTwoResults
          summaries={summaries}
          questionAnswers={questionAnswers}
          query={searchQuery}
          sendMessage={sendMessage}
          lastMessage={lastMessage}
        />
        <PhaseThreeResults finalSummary={finalSummary} />
      </InteractiveSearchContext.Provider>

    </>
  );
};

const PhaseOneSearchResults = ({ searchResults, selected, onSelect, sendMessage, query, setQuestionAnswers, setSummaries, disabled, searchInit }:
  { searchResults: any, selected: any, onSelect: any, sendMessage: any, query: string, setQuestionAnswers: any, setSummaries: any, disabled: boolean, searchInit: boolean }) => {

  const [expandedSection, setExpandedSection] = useState<string>('judiciary');
  const { loading, setLoading } = React.useContext(InteractiveSearchContext);

  const onItemSelect = (item: any) => {
    if (selected.some((selectedItem: any) => (selectedItem.id === item.id))) {
      onSelect(selected.filter((selectedItem: any) => selectedItem.id !== item.id));
    } else {
      onSelect([...selected, item]);
    }
  }

  return (
    <>
      {!loading && <Joyride steps={JoyRideStepsPhaseOne} continuous={true} locale={JoyRideLocale} disableOverlayClose={true} disableOverlay={true} />}
      <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>Wybierz pasujące dokumenty</Typography>
      <Tabs
        id="search-results-tabs"
        onChange={
          (event, newValue) => {
            setExpandedSection(newValue);
          }
        } value={expandedSection}
      >
        {
          Object.keys(searchResults).sort((a, b) => NAMES_MAPPING[a].index - NAMES_MAPPING[b].index).map((source) => {
            return (
              <Tab key={NAMES_MAPPING[source]['label']} label={NAMES_MAPPING[source]['label']} value={source} />
            );
          })
        }
      </Tabs>
      <Box mb={2}>
        {
          searchResults[expandedSection] &&
          <SearchResults source={expandedSection} results={searchResults[expandedSection]} selected={selected} searchInit={searchInit}
            disabled={disabled}
            onSelect={onItemSelect} />
        }
      </Box>
      <NextPhaseTile selected={selected} sendMessage={sendMessage} query={query} callback={() => {
        setLoading(true);
        setSummaries({});
        setQuestionAnswers({});
      }}
        disabled={loading} />
    </>
  );
}

const PhaseTwoResults = ({ summaries, questionAnswers, query, sendMessage, lastMessage }: {
  summaries: { [key: string]: { [key: string]: { summary: string, displayId: string } } },
  questionAnswers: {
    [key: string]: { [key: string]: [{ query: string, answer: string, displayId: string }] }
  },
  query: string,
  sendMessage: (msg: string) => void,
  lastMessage: MessageEvent<any> | null
}) => {
  const [expandedSection, setExpandedSection] = useState<string | undefined>("judiciary");
  const [selected, setSelected] = useState<any>([]);

  const { itemMapping } = React.useContext(InteractiveSearchContext);

  React.useEffect(() => {
    if (Object.keys(summaries).length > 0 && !expandedSection) {
      setExpandedSection(Object.keys(summaries)[0]);
    }
  }, [summaries]);

  const tabs = Object.keys(summaries).concat(Object.keys(questionAnswers));
  // remove duplicates
  const uniqueTabs = Array.from(new Set(tabs));
  // sort by index
  uniqueTabs.sort((a, b) => NAMES_MAPPING[a].index - NAMES_MAPPING[b].index);

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }} justifyContent="space-between">
        <Tabs value={expandedSection} onChange={(event, newValue) => setExpandedSection(newValue)}>
          {
            uniqueTabs.map((source) => {
              return (
                <Tab label={NAMES_MAPPING[source]['label']} value={source} />
              );
            })
          }
        </Tabs>
        {
          (Object.keys(summaries).length > 0) && (
            <Button
              value="Zaznacz wszystko"
              variant="contained"
              className="shadow-0 m-2"
              onClick={() => {
                setSelected(Object.keys(summaries).flatMap((source) => {
                  return Object.keys(summaries[source]).map((doc) => {
                    return { id: doc, summary: summaries[source][doc].summary, displayId: itemMapping[doc].displayId, source: source };
                  });
                }));
              }}
              disabled={Object.keys(summaries).some((source) => Object.keys(summaries[source]).some((doc) => summaries[source][doc].summary === ""))}
            >Zaznacz wszystko</Button>
          )
        }
      </Stack>
      {
        Object.keys(questionAnswers).length > 0 && (
          Object.keys(questionAnswers).map((source) => {
            return (
              <Box sx={{ display: expandedSection === source ? 'block' : 'none' }} mb={2}>
                <QuestionAnswerTile source={source} documents={questionAnswers[source]} selected={selected} setSelected={setSelected} />
              </Box>
            );
          })
        )
      }
      {
        Object.keys(summaries).length > 0 && Object.keys(summaries).map((source) => {
          return (
            <Box sx={{ display: expandedSection === source ? 'block' : 'none' }} mb={2}>
              <DocumentSummaryTile source={source} documents={summaries[source]} selected={selected} setSelected={setSelected} />
            </Box>
          );
        })
      }
      {
        (Object.keys(summaries).length > 0) && (
          <PhaseTwoActionTile selected={selected} sendMessage={sendMessage} query={query} />
        )
      }
    </>
  );
}

const PhaseTwoActionTile = ({
  selected, sendMessage, query
}: { selected: any[], sendMessage: (msg: string) => void, query: string }) => {

  const [command, setCommand] = useState('');
  const [expanded, setExpanded] = useState(false);

  const { loading, setLoading } = React.useContext(InteractiveSearchContext);

  const submit = (e: any): void => {
    e.preventDefault();
    const toSend = selected.map((item: any) => {
      const iter = item;
      delete iter.quote;
      return iter;
    });

    sendMessage(JSON.stringify({
      command: "custom-command",
      customCommand: command,
      query: query,
      documents: toSend
    }));
    setLoading(true);
  };

  return (
    <Paper elevation={0} sx={{ p: 3, maxWidth: 800, margin: 'auto', marginBottom: "32px" }}>
      <List>
        <ListItem button onClick={() => {
          const toSend = selected.map((item: any) => {
            const iter = item;
            delete iter.quote;
            return iter;
          });

          sendMessage(JSON.stringify({
            command: "final-summary",
            query: query,
            documents: toSend
          }));
          setLoading(true);
        }}
          disabled={selected.length === 0 || loading}
        >
          <ListItemIcon>
            <FormatListBulleted />
          </ListItemIcon>
          <ListItemText primary={`Finalne podsumowanie`} />
        </ListItem>
        <ListItem button onClick={() => setExpanded(!expanded)}
          disabled={selected.length === 0 || loading}
        >
          <ListItemIcon>
            <PriorityHigh />
          </ListItemIcon>
          <ListItemText primary={`Własne polecenie`} />
        </ListItem>
        <Collapse in={expanded}>
          <form onSubmit={submit}>
            <TextField
              fullWidth
              placeholder="Napisz ekspertyzę na podstawie wybranych dokumentów"
              variant="outlined"
              multiline
              rows={3}
              value={command}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  submit(e);
                }
              }}
              onChange={(e) => setCommand(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <ArrowForward />
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
          </form>
        </Collapse>
      </List>
    </Paper>
  )
}

const PhaseThreeResults = ({
  finalSummary
}: { finalSummary: string }) => {

  const axios = useAxiosPrivate();
  const snackBarContext = React.useContext(SnackbarContext);

  const downloadAsDocx = async () => {
    const content = finalSummary;

    await getCSRFToken();
    axios.post("api/v1/download", JSON.stringify({
      "content": content,
    }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        const b64 = response.data;
        // Bytes to base64
        const link = document.createElement("a");
        link.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + b64;
        link.setAttribute("download", "answer.docx");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error(error);
        snackBarContext.setMessage("Wystąpił błąd podczas pobierania dokumentu");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  };

  if (finalSummary === '') {
    return null;
  }

  let msgFormatted = finalSummary.replace(/<[^>]*>?/gm, '');
  msgFormatted = msgFormatted.replace(/&nbsp;/g, ' ');
  msgFormatted = msgFormatted.replace(/\n/g, '<br>');
  msgFormatted = msgFormatted.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\*{2}((?=[^\s\*]).*?[^\s\*])\*{2}/g, "<strong>$1</strong>");

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
      <Paper elevation={0} sx={{ p: 3, minWidth: "900px", margin: 'auto' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Finalne podsumowanie</Typography>
        <Typography variant="body1">
          <div dangerouslySetInnerHTML={{ __html: msgFormatted }} />
        </Typography>
      </Paper>
      <Button
        onClick={downloadAsDocx}
        variant="outlined"
        color="primary"
        className="mb-5"
        sx={{
          alignSelf: "end",
          width: "fit-content",
        }}
      >Pobierz jako DOCX</Button>
    </Stack>
  );
}

const DocumentViewLink = ({
  primaryText,
  secondaryText,
  fullSecondaryText,
  data,
}: {
  primaryText: string;
  secondaryText: string;
  fullSecondaryText: string;
  data: { internalUrl: string };
}) => {

  return (
    <Tooltip title={fullSecondaryText} className="documentViewLink">
      <ListItemText
        primary={
          data.internalUrl ? (
            <GenericButton
              href={data.internalUrl}
              target="_blank"
              variant="outlined-light"
              width={"100%"}
            >
              {primaryText}
            </GenericButton>
          ) : (
            primaryText
          )
        }
        secondary={secondaryText}
        sx={{
          overflowWrap: "break-word",
          marginY: "auto",
          justifyContent: "center",
          alignContent: "start",
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
        }}
      />
    </Tooltip>
  );
};

const SearchResults = (
  { source, results, selected, onSelect, disabled, searchInit }: { source: string, results: any, selected: any, onSelect: any, disabled: boolean, searchInit: boolean }
) => {
  const formattedItems = formatData(results);

  const noResults = formattedItems.length === 0 && !searchInit;

  return (
    <Paper elevation={0} sx={{ p: 3, minWidth: "900px", height: "600px", margin: 'auto', overflow: 'hidden' }}>
      <List sx={{
        ...scrollBarStyleSXY,
        height: "100%",
        paddingBottom: "16px"
      }}>
        {noResults && <ListItem>Brak wyników pasujących do zapytania</ListItem>}
        {(formattedItems.length === 0 && searchInit) && <CircularProgress />}
        {formattedItems.map((formattedItem: any) => {
          let title = formattedItem.date;
          let fullTitle = formattedItem.date;

          if (formattedItem.title) {
            fullTitle = formattedItem.title;
            if (formattedItem.title.length > 100) {
              title = formattedItem.title.substring(0, 100) + "...";
            }
            else {
              title = formattedItem.title;
            }
          }

          const itemSelected = selected.some(
            (selectedItem: any) =>
              selectedItem.id == formattedItem.id
          )

          return (
            <Stack
              direction="row"
              spacing={2}
              justifyContent={"space-between"}
              width="100%"
              pr={1}
              py={2}
              alignItems={"center"}
            >
              <>
                {disabled ? (
                  <Checkbox
                    edge="start"
                    disableRipple
                    disabled
                    indeterminate
                    sx={{
                      color: "text.secondary",
                      "&.Mui-checked": {
                        color: "primary.secondary",
                      },
                    }}
                  />
                ) : (
                  <Checkbox
                    key={formattedItem.id + formattedItem.quote.substring(0, 10)}
                    edge="start"
                    disableRipple
                    checked={itemSelected}
                    onClick={() => onSelect(formattedItem)}
                    disabled={disabled}
                      className="factual-state-results-checkbox"
                  />
                )}
                <DocumentViewLink
                  primaryText={formattedItem.displayId}
                  secondaryText={title}
                  fullSecondaryText={fullTitle}
                  data={formattedItem}

                />
              </>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  maxWidth: "75%",
                  minWidth: "75%",
                  textAlign: "justify",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                (...) {formattedItem.quote} (...)
              </Typography>
            </Stack>
          );
        })}
      </List>
    </Paper>
  );
}

const NextPhaseTile = ({ selected, query, sendMessage, callback, disabled }: { selected: any[], query: string, sendMessage: (msg: string) => void, callback: () => void, disabled?: boolean }) => {
  const [expanded, setExpanded] = useState(false);
  const [citationExpanded, setCitationExpanded] = useState(false);
  const [question, setQuestion] = useState('');

  function prepareDocs() {
    return selected.map((item: any) => {
      const iter = item;
      delete iter.quote;
      return iter;
    });
  }

  const submit = (e: any): void => {
    e.preventDefault();
    const toSend = prepareDocs();

    console.log(toSend);

    sendMessage(JSON.stringify({
      command: "ask-question",
      query: question,
      documents: toSend,
    }));
    callback();
  };

  return (
    <Paper elevation={0} sx={{ p: 3, maxWidth: 800, margin: 'auto', marginBottom: "32px" }}>
      <List>
        <ListItem disabled={disabled || selected.length === 0} button onClick={() => {
          const toSend = prepareDocs();

          sendMessage(JSON.stringify({
            command: "summarize-documents",
            query: query,
            documents: toSend
          }));
          callback();
        }}>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText id="summarize-documents-button" primary={`Podsumuj dokumenty (${selected.length})`} />
        </ListItem>
        <Stack direction="column" className="w-100">
          <ListItem disabled={disabled || selected.length === 0} button onClick={() => setExpanded(!expanded)}>
            <Stack direction="row">
              <ListItemIcon className='my-auto'>
                <QuestionMark />
              </ListItemIcon>
              <ListItemText id="ask-question-to-documents-button" primary={`Zadaj pytanie do dokumentów`} />
            </Stack>
          </ListItem>
          <Collapse in={expanded}>
            <form onSubmit={submit}>
              <TextField
                fullWidth
                placeholder="O co chodziło w sprawie?"
                variant="outlined"
                multiline
                rows={3}
                disabled={disabled}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className="w-100"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submit(e);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={submit}>
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
            </form>
          </Collapse>
        </Stack>

        <Stack direction="column" className="w-100">
          <ListItem disabled={disabled || selected.length === 0} button onClick={() => { setCitationExpanded(!citationExpanded) }}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText id="export-signatures-button" primary={`Wyeksportuj cytowania`} />
          </ListItem>
          <Collapse in={citationExpanded}>
            <Stack direction="row" spacing={2} justifyContent={"space-between"} width="100%" pr={1} py={2}>
              <Box sx={{ p: 2, borderRadius: "8px" }} className="border border-1 w-100">
                <Typography variant="body2">
                  <div dangerouslySetInnerHTML={{ __html: selected.map((doc: any) => doc.citation).join('<br />') }} />
                </Typography>
              </Box>
              <IconButton onClick={() => {
                navigator.clipboard.writeText(selected.map((doc: any) => `- ${doc.citation}`).join('\n'));
              }}>
                <CopyAll />
              </IconButton>
            </Stack >
          </Collapse >
        </Stack >

      </List >
    </Paper >
  );
}

const DocumentSummaryTile = ({ source, documents, selected, setSelected }: { source: string, documents: { [key: string]: { summary: string, displayId: string } }, selected: any, setSelected: (x: any) => void }) => {
  const { itemMapping } = React.useContext(InteractiveSearchContext);
  return (
    <Paper elevation={0} sx={{ p: 3, minWidth: "900px", margin: 'auto', height: "fit-content" }}>
      <List>
        {
          Object.entries(documents).map(([doc, { displayId, summary }]) => {
            console.log(selected);
            return (
              <>
                <ListItem sx={{
                  marginLeft: "-16px",
                }}>
                  <Checkbox
                    disableRipple
                    indeterminate={summary === ""}
                    disabled={summary === ""}
                    checked={selected.some((selectedItem: any) => (selectedItem.id === doc))}
                    onClick={() => {
                      const selectedItemIndex = selected.findIndex((selectedItem: any) => selectedItem.id === doc);

                      if (selectedItemIndex >= 0) {
                        setSelected(selected.filter((_: any, index: number) => index !== selectedItemIndex));
                      } else {
                        setSelected([...selected, { id: doc, summary, source, displayId: itemMapping[doc].displayId }]);
                      }
                    }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {itemMapping[doc].displayId}
                  </Typography>
                  {
                    itemMapping[doc].internalUrl &&
                    <Button
                      href={itemMapping[doc].internalUrl}
                      target="_blank"
                      variant="outlined"
                      sx={{
                        marginLeft: "auto !important",
                        "borderRadius": ".25rem !important",
                        "&:hover": {
                          color: 'primary.main',
                        }
                      }}
                    >
                      Zobacz dokument
                    </Button>
                  }
                </ListItem>
                {
                  summary === "" && (
                    <ListItem className='d-flex justify-content-center'>
                      <CircularProgress />
                    </ListItem>
                  )
                }
                <ListItem sx={{ marginLeft: "32px" }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {summary}
                  </Typography>
                </ListItem>
              </>
            );
          })
        }
      </List>
    </Paper>
  );
}

const QuestionAnswerTile = ({
  source, documents, selected, setSelected
}:
  {
    source: string,
    documents: { [key: string]: [{ query: string, answer: string, displayId: string }] },
    selected: any,
    setSelected: (x: any) => void
  }) => {

  const { itemMapping } = React.useContext(InteractiveSearchContext);


  return (
    <Paper elevation={0} sx={{ p: 3, minWidth: "900px", margin: 'auto', height: "fit-content" }}>
      <List>
        {
          Object.entries(documents).map(([doc, questions]) => {
            return (
              <>
                <ListItem
                  sx={{
                    marginLeft: "-16px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {itemMapping[doc].displayId}
                  </Typography>
                </ListItem>
                <Box sx={{ width: '100%', marginLeft: "32px" }}>
                  {
                    questions.map((qa) => {
                      return (
                        <List>
                          <ListItem>
                            <ListItemIcon sx={{
                              width: "fit-content",
                              "&.MuiListItemIcon-root::hover": {
                                color: null,
                              }
                            }}>
                              <QuestionAnswerTwoTone />
                            </ListItemIcon>
                            <ListItemText primary={qa.query} />
                          </ListItem>
                          <ListItem>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {qa.answer}
                            </Typography>
                          </ListItem>
                          <Divider className='my-3' />
                        </List>
                      );
                    })
                  }
                </Box>
              </>
            );
          })
        }
      </List>
    </Paper>
  );
}

export default NotebookInterface;

function InitialTile(props: {
  handleSectionClick: (section: string) => void,
  expandedSection: string,
  onFactualStateSubmit: (e: React.FormEvent) => void,
  onSearchDocumentSubmit: (e: React.FormEvent) => void,
  searchQuery: string, setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
}) {
  const { handleSectionClick, expandedSection, onFactualStateSubmit, onSearchDocumentSubmit, searchQuery, setSearchQuery, disabled } = props;
  const { loading, setLoading } = React.useContext(InteractiveSearchContext);

  const resetUrl = () => {
    const newUrl = new URL(location.href);
    newUrl.searchParams.delete("key");
    window.history.replaceState({}, document.title, newUrl.href);
  }

  const submit = (e: React.FormEvent) => {
    onFactualStateSubmit(e);
    resetUrl();
    setLoading(true);
  }

  return (
    <Paper elevation={0} sx={{ p: 3, maxWidth: 800, margin: "auto" }}>
      <List id={"initial-tile-list"}>
        <ListItem
          button
          onClick={() => handleSectionClick("factual")}
          disabled={disabled || loading}
          selected={expandedSection === "factual"}
        >
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText primary="Opisz stan faktyczny i zadaj pytanie" />
        </ListItem>
        <Collapse in={expandedSection === "factual"}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <form onSubmit={submit} id="factual-state">
              <TextField
                fullWidth
                disabled={disabled || loading}
                placeholder="Mam dzikiego lokatora który mieszka nielegalnie w mojej nieruchomości. Nie jest u mnie zameldowany i nie chce dobrowolnie opuścić posiadłości. Jak mogę go usunąć?"
                variant="outlined"
                multiline
                minRows={3}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submit(e);
                  }
                }}
                value={searchQuery}
                onChange={(e) => {
                  resetUrl();
                  return setSearchQuery(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton id='factual-state-submit' onClick={submit} disabled={disabled || loading}>
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

            </form>
          </Paper>
        </Collapse>
        <ListItem
          id="history-section"
          button
          onClick={() => handleSectionClick("history")}
          disabled={disabled || loading}
          selected={expandedSection === "history"}
        >
          <ListItemIcon>
            <History />
          </ListItemIcon>
          <ListItemText primary="Wyświetl historię zapytań" />
        </ListItem>
        <Collapse in={expandedSection === "history"}>
          <Paper elevation={0} sx={{ p: 2 }}>

            <InteractiveSearchHistory />

          </Paper>
        </Collapse>
        {/* <ListItem button onClick={() => handleSectionClick('extract')} disabled={disabled}>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText primary="Wyszukaj dokument" />
      </ListItem>
      <Collapse in={expandedSection === 'extract'}>
        <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
          <form onSubmit={onSearchDocumentSubmit}>
            <TextField
              fullWidth
              placeholder="II OSK 1436/16"
              variant="outlined"
              disabled={disabled}
              value={searchQuery}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSearchDocumentSubmit(e);
                }
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSearchDocumentSubmit}>
                      <ArrowForward />
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
          </form>
        </Paper>
      </Collapse> */}
      </List>
    </Paper>
  );
}

