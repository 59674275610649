import React from "react";
import Button from "../generic/Button";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import getCSRFToken from "../../stores/CSRFStore";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";

const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: "0.75rem",
  borderColor: "#D9E1E7",
  boxShadow: 24,
  p: 4,
};

const ReportImprovementButton = () => {
  const axios = useAxiosPrivate();
  const snackBarContext = React.useContext(SnackbarContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [text, setText] = React.useState<string>();

  const handleSubmit = async () => {
    await getCSRFToken();
    axios
      .post(`api/v1/improvement-report`, {
        improvement: text,
      })
      .then((response) => {
        snackBarContext.setMessage("Dziękujemy za zgłoszenie pomysłu!");
        snackBarContext.setSeverity("success");
        snackBarContext.setOpen(true);
        setModalOpen(false);
      })
      .catch((error) => {
        snackBarContext.setMessage("Wystąpił błąd przy przesyłaniu pomysłu");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  };

  return (
    <>
      <Button
        id={"report-improvement-button"}
        variant={"contained-dark"}
        onClick={() => setModalOpen(true)}
      >
        Zgłoś pomysł
      </Button>
      <Modal open={modalOpen}>
        <Box sx={MODAL_STYLE}>
          <Typography id={"rib-modal-title"}>Zgłoś pomysł</Typography>
          <IconButton id={"rib-close-bttn"} onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </IconButton>

          <TextareaAutosize
            id="rib-textarea"
            aria-label="Opisz zgłaszany pomysł"
            minRows={10}
            placeholder="Opisz zgłaszany pomysł ..."
            style={{ width: "100%" }}
            name="text"
            onChange={(e) => setText(e.target.value)}
          />

          <Button
            variant={"contained-dark"}
            width={"100%"}
            onClick={() => {
              handleSubmit();
            }}
            disabled={!text}
          >
            Wyślij swój pomysł
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ReportImprovementButton;
