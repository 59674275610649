import * as React from 'react';

const PrivacyPolicy = () => {
    const [ data, setData ] = React.useState<string|undefined>()

    React.useEffect(() => {
        fetch("/policy.html").then((data) => data.text()).then((html: string) => setData(html))
    }, [])

    return (
        <>{data && <div id="privacy-policy" dangerouslySetInnerHTML={{ __html: data }} />}</>
    );
};

export default PrivacyPolicy;