import * as React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useContext, useState } from "react";
import axios from "../api/axios";
import TextStrings from "../pages/register/Strings";
import Button from './generic/Button';
import { SnackbarContext } from "../contexts/SnackbarContext";
import getCSRFToken from "../stores/CSRFStore";

const handleSocialLogin = async (authData: {code: string}, setError: (arg: string) => void, referral: string) => {
  try {
    const url = referral ? `/rest-auth/google/login/?referral=${referral}`: "/rest-auth/google/login/";

    const response = await axios.post(
      url,
      JSON.stringify({
        code: authData.code,
        provider: "google",
      }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.key) {
      console.log("Google Auth: Login successful:", response.data);
      window.location.href = "/";
    } else {
      console.error("Google Auth: Login failed:", response.data);
      setError(TextStrings.GOOGLE_AUTH_ERROR);
    }
  } catch (error) {
    console.error("Error during google login:", error);
    setError(TextStrings.GOOGLE_AUTH_ERROR);
  }
};

const GoogleButton = ({ referral, email }: { referral: string, email: string }) => {
  const [error, setError] = useState("");
  const snackbar = useContext(SnackbarContext);

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => handleSocialLogin(codeResponse, setError, referral),
    flow: 'auth-code',
  });

  const handleGoogleLogin = async () => {
    if (email) {
      try {
        const response = await axios.post(
          "core_api/user/exist",
          JSON.stringify({ "email": email }),
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.data.exists) {
          snackbar.setMessage("Nie istnieje konto z podanym adresem email połączone z kontem Google. Chcesz założyć konto? Zarejestruj się");
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
          return;
        }
      } catch (error) {
        console.error("Error during google login:", error);
        snackbar.setMessage("Wystąpił błąd podczas próby zalogowania przez Google. Spróbuj ponownie później");
        snackbar.setSeverity("error");
        snackbar.setOpen(true);
        return;
      }
    }

    googleLogin();
  };

  return (
    <>
      <div
        className="cx-error-message-email"
        style={{ color: "red", display: error ? "block" : "none" }}
      >
        {error}
      </div>
      <div>
        <div style={{ display: "inline-block", width: "50%", float: "left", paddingRight: 5 }} >
          <Button variant={"outlined-light"} width={"100%"} onClick={handleGoogleLogin} >
            <i className="fa-brands fa-google" style={{ marginRight: 10 }} />
            Zaloguj się z Google
          </Button>
        </div>
      </div>
    </>
  );
};

export default GoogleButton;