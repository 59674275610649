import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export default axios.create({
  baseURL: process.env.BACKEND_ENDPOINT,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.BACKEND_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
