import React from "react";
import "./Mission.scss";
import { Colors } from "../../components/generic/Colors";

const Mission = () => {
    const logo = require("~/public/static/img/idea1.jpeg");
    return (<>
        <div className="team-mission-section">
            <h1 style={{ fontSize: 24, color: Colors.DarkNavyBlue }} >Misja Gaius Lex</h1>
            <p>
                Misja naszej firmy Gaius-Lex opiera się na głębokim przekonaniu, że technologia, zwłaszcza narzędzia oparte na sztucznej inteligencji (AI), może być źródłem inspiracji i wsparcia dla ludzi w przekraczaniu własnych granic. Naszą misją jest nie tylko dostarczanie rozwiązań technologicznych, ale również tworzenie możliwości dla jednostek do osiągania nowych poziomów efektywności i kreatywności.
            </p>
            <p>
                W naszym podejściu do rewolucji branżowej skupiamy się na projektowaniu narzędzi, które są nie tylko zaawansowane technologicznie, ale również dobrze przemyślane i dostosowane do konkretnych potrzeb użytkowników. Tworzymy narzędzia, które są jakby szyte na miarę, odzwierciedlając specyfikę pracy danego sektora.
            </p>
            <p>
                W szczególności, dedykujemy nasze wysiłki transformacji branży prawnej. Rozumiemy, że prawnicy są konfrontowani z powtarzalną pracą, która absorbuje ich czas i energię. Nasze narzędzie zostało stworzone w celu ułatwienia im życia, umożliwiając delegowanie rutynowych zadań, co pozwala prawnikom skupić się na bardziej złożonych aspektach swojej pracy i budowaniu wartościowych relacji z klientami.
            </p>
            <p>
                Wierzymy, że technologia AI nie tylko ułatwia pracę, ale także inspiruje do kreatywności, rozwoju i wyznaczania nowych standardów w danym obszarze. Nasza firma dąży do tego, aby nasi klienci, w tym prawnicy, mogli doświadczyć potencjału, jaki drzemie w inteligentnych narzędziach, i przekraczać swoje dotychczasowe ograniczenia, kreując nowe, innowacyjne ścieżki w swoich dziedzinach zawodowych.</p>
            <p className="signature"><br /> Zespół Gaius Lex.</p>
        </div>
        <div className="logo-div">
            <img src={logo}></img>
        </div>
    </>);
}

export default Mission;