import React from "react";
import { Button, Box, Typography, Modal } from "@mui/material";
import Colors from "./colors";
import useUserData from "../hooks/useUserData";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getCSRFToken from "../stores/CSRFStore";

const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "440px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BUTTON_STYLE = {
  height: "30px",
  backgroundColor: Colors.DarkBlue,
  fontFamily: "sofia-pro, sans-serif",
  fontWeight: "semibold",
  fontSize: "1rem",
  color: "white",
  ":hover": {
    backgroundColor: Colors.LightBlue,
    textDecoration: "none",
  },
};

const StatueConsentModal = () => {
  const axios = useAxiosPrivate();
  const { userData, setUserData } = useUserData();
  const statueConsentDate: Date = new Date(userData.statueConsent as string);
  const statueUpdateDate: Date = new Date(process.env.STATUE_UPDATE as string);
  const [modalOpen, setModalOpen] = React.useState(statueConsentDate < statueUpdateDate || statueConsentDate == null);
  const [declineModalOpen, setDeclineModalOpen] = React.useState(false);

  const setStatueConsent = async () => {
    try {
      await getCSRFToken();
      await axios
        .post(
          `${process.env.BACKEND_ENDPOINT}/core_api/statue-consent`,
          JSON.stringify({ statue_consent: true })
        )
        .then((response: { status: number; data: { error: string, statue_consent: string } }) => {
          if (response.status == 200) {
            setUserData({
              ...userData,
              statueConsent: response.data.statue_consent,
            });
            setModalOpen(false);
          } else {
            console.error(response.data.error);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const StatueConsent = () => (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        color={Colors.DarkBlue}
      >
        Regulamin świadczenia usług za pośrednictwem Serwisu Internetowego
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }} color={"black"}>
        Oświadczam, że znam i akceptuję postanowienia{" "}
        <Link to="/statute-new">Regulaminu świadczenia usług za pośrednictwem Serwisu Internetowego</Link> Gaius Lex (wchodzi w życie 06.08.2024 r.)
      </Typography>
      <div style={{display: "flex", gap: 12, flexWrap: "wrap", justifyContent: "flex-end", marginTop: "12px"}}>
        <Button
          sx={{ ...BUTTON_STYLE, float: "right" }}
          onClick={() => {
            setModalOpen(false);
            setDeclineModalOpen(true);
          }}
        >
          Nie wyrażam zgody
        </Button>
        <Button
          sx={{ ...BUTTON_STYLE, float: "right" }}
          onClick={() => {
            setModalOpen(false);
            setStatueConsent();
          }}
        >
          Akceptuję
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Modal open={modalOpen}>
        <Box sx={MODAL_STYLE}>
          <StatueConsent />
        </Box>
      </Modal>
      <Modal open={declineModalOpen}>
        <Box sx={MODAL_STYLE}>
          <Typography>Jeśli nie wyrażasz zgody na treść nowego regulaminu, możesz skierować do nas swój sprzeciw na adres e-mail: <a
            href="mailto:kontakt@gaius-lex.pl">kontakt@gaius-lex.pl</a> W takim wypadku, stosownie do pkt. 13 obecnie obowiązującego Regulaminu świadczenia usług, który został zaakceptowany przy rejestracji konta w Serwisie, umowa ulegnie rozwiązaniu, a konto użytkownika usunięte w ostatnim dniu obowiązywania obecnego Regulaminu.</Typography>
          <Typography margin={2}>
            <Button
              sx={{ ...BUTTON_STYLE, float: "right" }}
              onClick={() => {
                setDeclineModalOpen(false);
              }}
            >
              Zamknij
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default StatueConsentModal;
