import { Form, Formik, ErrorMessage } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../api/axios";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import Button from "../../components/generic/Button";
import Background from "../../components/generic/Background";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { Colors } from "../../components/generic/Colors";
import CircularProgress from "@mui/material/CircularProgress";

const ErrorStyle = {
  color: "red",
  backgroundColor: Colors.LightRed,
  height: 50,
  display: "inline-block",
  margin: "5px 0",
  padding: "10px",
  borderRadius: "0.75rem",
  width: "100%",
};

const ResetPasswordRequestStore = async (email: String) => {
  return axios
    .post("rest-auth/password/reset/", JSON.stringify({ email }), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log("Error during sending request", error);
    });
};

const PasswordResetRequest = () => {
  const navigate = useNavigate();
  const snackbarContext = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (values: { email: string }, _actions: {}) => {
    const { email } = values;
    setLoading(true);
    if ((await ResetPasswordRequestStore(email)) === true) {
      snackbarContext.setSeverity("success");
      snackbarContext.setDuration(15000);
      snackbarContext.setMessage(
        "Na podany adres email wysłaliśmy maila z linkiem do resetowania hasła."
      );
      snackbarContext.setOpen(true);
      setLoading(false);
      navigate("/login");
    } else {
      snackbarContext.setSeverity("error");
      snackbarContext.setMessage(
        "Błąd zmiany hasła, skontaktuj się z administratorem lub spróbuj później."
      );
      snackbarContext.setOpen(true);
      setLoading(false);
    }
  };

  return (
    <Background>
      <MDBRow className="flex-grow-1 w-100 m-0">
        <MDBCol className="d-flex flex-column px-0">
          <MDBCard
            className="mx-auto rounded-6"
            style={{
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <MDBCardBody>
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Email musi być poprawny")
                    .required("Email jest wymagany"),
                })}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div>
                        <ErrorMessage
                          name="email"
                          component={(props: any) => (
                            <div style={ErrorStyle}>
                              <div style={{ float: "left" }}>
                                {props?.children}
                              </div>
                              <div style={{ float: "right" }}>X</div>
                            </div>
                          )}
                        />
                      </div>
                      <MDBInput
                        className="mt-2"
                        id="email-input"
                        name="email"
                        label="Podaj Email dla którego chcesz zresetować hasło"
                        type="email"
                        value={formik.values?.email}
                        onChange={formik.handleChange}
                      />
                      <br />
                      <Button
                        id="reset-password-button"
                        variant="contained-dark"
                        type="submit"
                        width={"100%"}
                        disabled={loading}
                      >
                        { loading ? <CircularProgress /> : "Wyślij email resetujący hasło" }
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Background>
  );
};

export default PasswordResetRequest;
