import { useEffect } from "react";

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
        textAreaRef.style.height = "auto";
      
        // Calculate the scroll height
        const scrollHeight = textAreaRef.scrollHeight;
  
        // Apply the new height, ensuring a minimum of 46px
        textAreaRef.style.height = `${Math.max(scrollHeight + 2, 48)}px`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
