import "./index.scss";
import { Box, Modal, Typography } from "@mui/material";
import * as React from "react";
import Button from "../generic/Button";
import getCSRFToken from "../../stores/CSRFStore";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useUserData from "../../hooks/useUserData";

const DataUsageNoticeModal = () => {
  const axios = useAxiosPrivate();
  const { userData, setUserData } = useUserData();
  const [open, setOpen] = React.useState(!!!userData.dataUsageNotice);
  const handleClose = () => setOpen(false);

  const setDataUsageNotice = async () => {
    try {
      await getCSRFToken();
      await axios
        .post(
          `${process.env.BACKEND_ENDPOINT}/core_api/data-usage-notice`,
          JSON.stringify({ data_usage_notice: true })
        )
        .then((response: { status: number; data: { error: string, data_usage_notice: string } }) => {
          if (response.status == 200) {
            setUserData({
              ...userData,
              dataUsageNotice: response.data.data_usage_notice,
            });
            handleClose();
          } else {
            console.error(response.data.error);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="dunm-modal"
    >
      <Box id="dunm-box">
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Informujemy, że korzystając z usług Administratora jest Pan/Pani
          uprawniona do przekazania Administratorowi wyłącznie danych osobowych,
          które Pana/Pani dotyczą. Przekazywanie Administratorowi informacji lub
          dokumentów zawierających jakiekolwiek dane osobowe innych osób jest
          dozwolone tylko wtedy, gdy jest to uzasadnione przepisami o ochronie
          danych i po zawarciu stosownej umowy z Administratorem w przedmiocie
          powierzenia przetwarzania danych osobowych (w razie woli jej zawarcia,
          prosimy o kontakt z Administratorem). W przypadku, gdy udostępniane
          przez Państwa treści lub dokument zawierają dane osobowe innych osób,
          do których udostępnienia Administratorowi nie są Państwo uprawnieni,
          należy je najpierw odpowiednio zanonimizować, przed ich udostępnieniem
          w Serwisie Internetowym.
        </Typography>
        <Button variant="contained-dark" width="100%" sx={{ mt: 3 }} onClick={() => {
            setDataUsageNotice();
            handleClose();
        }} >Rozumiem</Button>
      </Box>
    </Modal>
  );
};

export default DataUsageNoticeModal;
