import LocalizedStrings from "react-localization";

let lang = "pl";
if (document.location.pathname.includes("/en/")) lang = "en";

const TextStrings = new LocalizedStrings({
  pl: {
    secret_answer_form: {
        mandatory_secret_answer: "Odpowiedź jest wymagana",
        mandatory_question: "Pytanie jest wymagana",
        warning: "Zapamiętaj wybrane pytania, kolejność wybranych pytań oraz poszczególne odpowiedzi. W przypadku zapomnienia hasła odpowiedzi będą niezbędne przy resetowaniu hasła"
    }
  },
  en: {
    secret_answer_form: {
        mandatory_secret_answer: "The answer is required",
        mandatory_question: "The question is required",
        warning: "Note the selected questions, the order of the selected questions and the individual answers. In case you forget your password, the answers will be necessary when resetting your password"
    }
  },
});

TextStrings.setLanguage(lang);

export default TextStrings;