import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import { Box, TextField, Typography, Button, Stack } from "@mui/material";

const CreateDocument = () => {
    const formikSetup = {
        initialValues: {
            legalArguments: "",
            recipient: "",
            worth: "",
            evidence: "",
            request: "",
            circumstances: "",
        },
        validationSchema: yup.object({
            legalArguments: yup.string().required("Pole wymagane"),
            recipient: yup.string().required("Pole wymagane"),
            worth: yup.string().required("Pole wymagane"),
            evidence: yup.string().required("Pole wymagane"),
            request: yup.string().required("Pole wymagane"),
            circumstances: yup.string().required("Pole wymagane"),
        }),
        onSubmit: (values: any) => {
            console.log(values);
        }
    };

    const formik = useFormik(formikSetup);

    return (
        <>
            <Typography variant="h4" sx={{ fontSize: "2.5rem", textAlign: "center" }}>🏗 Work in progress</Typography>
            <Box className="border border-solid p-3" sx={{ borderRadius: "8px" }}>
                <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>Utwórz dokument</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            label="Podaj argumenty prawne"
                            margin="normal"
                            name="legalArguments"
                            onChange={formik.handleChange}
                            value={formik.values.legalArguments}
                            variant="outlined"
                            error={formik.touched.legalArguments && Boolean(formik.errors.legalArguments)}
                            helperText={formik.touched.legalArguments && formik.errors.legalArguments}
                            multiline
                            rows={4}
                        />
                        <Stack direction={"row"} spacing={2} >
                            <TextField
                                fullWidth
                                label="Podaj adresata"
                                margin="normal"
                                name="recipient"
                                onChange={formik.handleChange}
                                value={formik.values.recipient}
                                variant="outlined"
                                error={formik.touched.recipient && Boolean(formik.errors.recipient)}
                                helperText={formik.touched.recipient && formik.errors.recipient}
                                className="my-auto"
                            />
                            <TextField
                                fullWidth
                                label="Podaj wartość"
                                margin="normal"
                                name="worth"
                                onChange={formik.handleChange}
                                value={formik.values.worth}
                                variant="outlined"
                                error={formik.touched.worth && Boolean(formik.errors.worth)}
                                helperText={formik.touched.worth && formik.errors.worth}
                                className="my-auto"
                            />
                        </Stack>
                        <TextField
                            fullWidth
                            label="Podaj dowody"
                            margin="normal"
                            name="evidence"
                            onChange={formik.handleChange}
                            value={formik.values.evidence}
                            variant="outlined"
                            error={formik.touched.evidence && Boolean(formik.errors.evidence)}
                            helperText={formik.touched.evidence && formik.errors.evidence}
                        />
                        <TextField
                            fullWidth
                            label="Podaj żądanie"
                            margin="normal"
                            name="request"
                            onChange={formik.handleChange}
                            value={formik.values.request}
                            variant="outlined"
                            error={formik.touched.request && Boolean(formik.errors.request)}
                            helperText={formik.touched.request && formik.errors.request}
                            multiline
                            rows={4}
                        />
                        <TextField
                            fullWidth
                            label="Podaj okoliczności"
                            margin="normal"
                            name="circumstances"
                            onChange={formik.handleChange}
                            value={formik.values.circumstances}
                            variant="outlined"
                            error={formik.touched.circumstances && Boolean(formik.errors.circumstances)}
                            helperText={formik.touched.circumstances && formik.errors.circumstances}
                            multiline
                            rows={4}
                        />
                        <Button color="primary" fullWidth type="submit" variant="contained" disabled>Utwórz</Button>
                    </Stack>
                </form>
            </Box>
        </>
    );
}

export default CreateDocument;