import * as React from 'react';

const statute = `<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        .lst-kix_wbr2z7x73lve-3>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-3, decimal) ". "
        }

        .lst-kix_wbr2z7x73lve-5>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-5, lower-roman) ". "
        }

        ol.lst-kix_1w7g9wynhivh-6.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-6 0
        }

        .lst-kix_wae1ymlgm6l6-3>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-3
        }

        .lst-kix_wbr2z7x73lve-0>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-0, decimal) ". "
        }

        .lst-kix_wbr2z7x73lve-4>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-4, lower-latin) ". "
        }

        ol.lst-kix_w6rtoi8h18q4-1.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-1 0
        }

        ol.lst-kix_9otmb23h2e72-1.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-1 0
        }

        ol.lst-kix_dgucu56m2lmv-8.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-8 0
        }

        .lst-kix_wbr2z7x73lve-7>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-7, lower-latin) ". "
        }

        ol.lst-kix_uqbv7hiqqgcv-1.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-1 0
        }

        .lst-kix_pceibtxro1if-1>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-1
        }

        ol.lst-kix_5vj4vaup0lg-2.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-2 0
        }

        .lst-kix_g6wy5ndlov25-7>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-7
        }

        .lst-kix_wbr2z7x73lve-6>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-6, decimal) ". "
        }

        .lst-kix_uqbv7hiqqgcv-7>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-7
        }

        .lst-kix_av44a7gw93kh-3>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-3
        }

        .lst-kix_wbr2z7x73lve-8>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-8, lower-roman) ". "
        }

        .lst-kix_g9bf9rgk5fju-8>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-8
        }

        ol.lst-kix_xr9khdw9fe6q-5.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-5 0
        }

        .lst-kix_z6r466v9qacb-3>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-3
        }

        ol.lst-kix_wae1ymlgm6l6-1.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-1 0
        }

        .lst-kix_yl36npzuxxn-0>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-0
        }

        ol.lst-kix_shfel39lkr1e-7.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-7 0
        }

        .lst-kix_8lu05uv13twm-8>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-8
        }

        ol.lst-kix_k9i9cgazomij-8.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-8 0
        }

        ol.lst-kix_wbr2z7x73lve-7.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-7 0
        }

        ol.lst-kix_5vj4vaup0lg-7.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-7 0
        }

        ol.lst-kix_a38rqt8hbxzi-2.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-2 0
        }

        .lst-kix_wbr2z7x73lve-6>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-6
        }

        ol.lst-kix_easree9c2dho-7.start {
            counter-reset: lst-ctn-kix_easree9c2dho-7 0
        }

        ol.lst-kix_pceibtxro1if-8.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-8 0
        }

        .lst-kix_dgucu56m2lmv-7>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-7
        }

        ol.lst-kix_9otmb23h2e72-6.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-6 0
        }

        .lst-kix_xr9khdw9fe6q-7>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-7
        }

        .lst-kix_uqbv7hiqqgcv-5>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-5, lower-roman) ". "
        }

        .lst-kix_uqbv7hiqqgcv-7>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-7, lower-latin) ". "
        }

        .lst-kix_av44a7gw93kh-1>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-1, lower-latin) ". "
        }

        .lst-kix_av44a7gw93kh-0>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-0, decimal) ". "
        }

        .lst-kix_uqbv7hiqqgcv-4>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-4, lower-latin) ". "
        }

        .lst-kix_uqbv7hiqqgcv-8>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-8, lower-roman) ". "
        }

        ol.lst-kix_a38rqt8hbxzi-7.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-7 0
        }

        .lst-kix_av44a7gw93kh-3>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-3, decimal) ". "
        }

        .lst-kix_bs8h945xvt8p-6>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-6
        }

        ol.lst-kix_w6rtoi8h18q4-6.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-6 0
        }

        ol.lst-kix_uqbv7hiqqgcv-6.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-6 0
        }

        .lst-kix_av44a7gw93kh-2>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-2, lower-roman) ". "
        }

        .lst-kix_uqbv7hiqqgcv-6>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-6, decimal) ". "
        }

        .lst-kix_av44a7gw93kh-7>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-7, lower-latin) ". "
        }

        .lst-kix_8q238ecyvl96-7>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-7
        }

        .lst-kix_av44a7gw93kh-5>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-5, lower-roman) ". "
        }

        .lst-kix_av44a7gw93kh-4>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-4, lower-latin) ". "
        }

        .lst-kix_av44a7gw93kh-8>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-8, lower-roman) ". "
        }

        .lst-kix_j05skkyse9cl-5>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-5
        }

        .lst-kix_8lu05uv13twm-1>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-1
        }

        .lst-kix_av44a7gw93kh-6>li:before {
            content: "" counter(lst-ctn-kix_av44a7gw93kh-6, decimal) ". "
        }

        ol.lst-kix_yl36npzuxxn-8.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-8 0
        }

        .lst-kix_pceibtxro1if-8>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-8
        }

        ol.lst-kix_yl36npzuxxn-3.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-3 0
        }

        ol.lst-kix_xr9khdw9fe6q-0.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-0 0
        }

        ol.lst-kix_8lu05uv13twm-7.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-7 0
        }

        ol.lst-kix_easree9c2dho-2.start {
            counter-reset: lst-ctn-kix_easree9c2dho-2 0
        }

        .lst-kix_zgyf1q7d6njt-5>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-5
        }

        .lst-kix_2amedsbs4wpe-7>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-7
        }

        .lst-kix_g9bf9rgk5fju-1>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-1
        }

        .lst-kix_3lt2rtjf4bk3-1>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-1
        }

        .lst-kix_uqbv7hiqqgcv-0>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-0, decimal) ". "
        }

        .lst-kix_uqbv7hiqqgcv-1>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-1, lower-latin) ". "
        }

        .lst-kix_uqbv7hiqqgcv-3>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-3, decimal) ". "
        }

        .lst-kix_wbr2z7x73lve-1>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-1, lower-latin) ". "
        }

        .lst-kix_uqbv7hiqqgcv-2>li:before {
            content: "" counter(lst-ctn-kix_uqbv7hiqqgcv-2, lower-roman) ". "
        }

        .lst-kix_wbr2z7x73lve-2>li:before {
            content: "" counter(lst-ctn-kix_wbr2z7x73lve-2, lower-roman) ". "
        }

        .lst-kix_yl36npzuxxn-7>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-7
        }

        ol.lst-kix_av44a7gw93kh-2.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-2 0
        }

        .lst-kix_5cyrqd6ve42g-4>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-4
        }

        ol.lst-kix_2amedsbs4wpe-6.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-6 0
        }

        ol.lst-kix_lhfs68ixug90-7.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-7 0
        }

        .lst-kix_1w7g9wynhivh-3>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-3
        }

        .lst-kix_easree9c2dho-5>li {
            counter-increment: lst-ctn-kix_easree9c2dho-5
        }

        .lst-kix_pceibtxro1if-6>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-6, decimal) ". "
        }

        .lst-kix_k9i9cgazomij-7>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-7, lower-latin) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-1.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-1 0
        }

        .lst-kix_pceibtxro1if-4>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-4, lower-latin) ". "
        }

        .lst-kix_pceibtxro1if-8>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-8, lower-roman) ". "
        }

        .lst-kix_easree9c2dho-6>li {
            counter-increment: lst-ctn-kix_easree9c2dho-6
        }

        .lst-kix_1w7g9wynhivh-4>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-4
        }

        .lst-kix_5cyrqd6ve42g-5>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-5
        }

        .lst-kix_k9i9cgazomij-1>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-1, lower-latin) ". "
        }

        ol.lst-kix_8q238ecyvl96-1.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-1 0
        }

        .lst-kix_l2ui8c35mkpb-4>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-4
        }

        .lst-kix_nas4vziddqer-2>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-2
        }

        .lst-kix_xr9khdw9fe6q-0>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-0
        }

        ol.lst-kix_u6lsy85ayutq-6.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-6 0
        }

        ol.lst-kix_l2ui8c35mkpb-0.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-0 3
        }

        .lst-kix_k9i9cgazomij-5>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-5, lower-roman) ". "
        }

        .lst-kix_uqbv7hiqqgcv-0>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-0
        }

        .lst-kix_k9i9cgazomij-3>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-3, decimal) ". "
        }

        ol.lst-kix_nas4vziddqer-3.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-3 0
        }

        .lst-kix_w6rtoi8h18q4-3>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-3
        }

        .lst-kix_mjcqlmrhlk54-6>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-6
        }

        ol.lst-kix_g6wy5ndlov25-5.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-5 0
        }

        .lst-kix_1vy3yzwcks66-3>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-3
        }

        ol.lst-kix_mjcqlmrhlk54-4.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-4 0
        }

        .lst-kix_mjcqlmrhlk54-0>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-0
        }

        ol.lst-kix_1w19ehkp76hc-7.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-7 0
        }

        .lst-kix_1vy3yzwcks66-2>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-2
        }

        .lst-kix_mjcqlmrhlk54-7>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-7
        }

        .lst-kix_3lt2rtjf4bk3-8>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-8
        }

        ol.lst-kix_a38rqt8hbxzi-7 {
            list-style-type: none
        }

        .lst-kix_3lt2rtjf4bk3-2>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-2
        }

        .lst-kix_xr9khdw9fe6q-6>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-6
        }

        ol.lst-kix_a38rqt8hbxzi-8 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-7.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-7 0
        }

        ol.lst-kix_5cyrqd6ve42g-7.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-7 0
        }

        ol.lst-kix_a38rqt8hbxzi-3 {
            list-style-type: none
        }

        ol.lst-kix_a38rqt8hbxzi-4 {
            list-style-type: none
        }

        ol.lst-kix_a38rqt8hbxzi-5 {
            list-style-type: none
        }

        ol.lst-kix_a38rqt8hbxzi-6 {
            list-style-type: none
        }

        .lst-kix_1w19ehkp76hc-0>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-0, decimal) ". "
        }

        ol.lst-kix_a38rqt8hbxzi-0 {
            list-style-type: none
        }

        .lst-kix_mjcqlmrhlk54-4>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-4, lower-latin) ". "
        }

        ol.lst-kix_a38rqt8hbxzi-1 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-3.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-3 0
        }

        ol.lst-kix_a38rqt8hbxzi-2 {
            list-style-type: none
        }

        .lst-kix_pceibtxro1if-0>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-0, decimal) ". "
        }

        .lst-kix_1w19ehkp76hc-2>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-2, lower-roman) ". "
        }

        .lst-kix_pceibtxro1if-2>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-2, lower-roman) ". "
        }

        .lst-kix_1w19ehkp76hc-4>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-4, lower-latin) ". "
        }

        ol.lst-kix_g6wy5ndlov25-4.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-4 0
        }

        .lst-kix_mjcqlmrhlk54-6>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-6, decimal) ". "
        }

        .lst-kix_wbr2z7x73lve-7>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-7
        }

        .lst-kix_1w19ehkp76hc-8>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-8, lower-roman) ". "
        }

        ol.lst-kix_dgucu56m2lmv-0 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-2.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-2 0
        }

        ol.lst-kix_dgucu56m2lmv-1 {
            list-style-type: none
        }

        ol.lst-kix_dgucu56m2lmv-3.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-3 0
        }

        ol.lst-kix_dgucu56m2lmv-2 {
            list-style-type: none
        }

        ol.lst-kix_dgucu56m2lmv-3 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-6.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-6 0
        }

        .lst-kix_1w19ehkp76hc-6>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-6, decimal) ". "
        }

        .lst-kix_mjcqlmrhlk54-0>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-0, decimal) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-2.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-2 0
        }

        ol.lst-kix_dgucu56m2lmv-8 {
            list-style-type: none
        }

        .lst-kix_nas4vziddqer-1>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-1
        }

        .lst-kix_mjcqlmrhlk54-2>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-2, lower-roman) ". "
        }

        ol.lst-kix_dgucu56m2lmv-4 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-3.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-3 0
        }

        ol.lst-kix_dgucu56m2lmv-5 {
            list-style-type: none
        }

        .lst-kix_u6lsy85ayutq-4>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-4
        }

        ol.lst-kix_dgucu56m2lmv-6 {
            list-style-type: none
        }

        ol.lst-kix_dgucu56m2lmv-7 {
            list-style-type: none
        }

        .lst-kix_dgucu56m2lmv-6>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-6
        }

        .lst-kix_2amedsbs4wpe-8>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-8
        }

        .lst-kix_wae1ymlgm6l6-8>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-8, lower-roman) ". "
        }

        .lst-kix_wae1ymlgm6l6-5>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-5, lower-roman) ". "
        }

        ol.lst-kix_8lu05uv13twm-2 {
            list-style-type: none
        }

        ol.lst-kix_8lu05uv13twm-3 {
            list-style-type: none
        }

        .lst-kix_wae1ymlgm6l6-4>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-4, lower-latin) ". "
        }

        ol.lst-kix_8lu05uv13twm-4 {
            list-style-type: none
        }

        ol.lst-kix_8lu05uv13twm-5 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-0.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-0 0
        }

        ol.lst-kix_8lu05uv13twm-0 {
            list-style-type: none
        }

        ol.lst-kix_8lu05uv13twm-1 {
            list-style-type: none
        }

        .lst-kix_av44a7gw93kh-4>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-4
        }

        .lst-kix_g6wy5ndlov25-8>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-8
        }

        .lst-kix_3elc2ktxgkmv-0>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-0, decimal) ". "
        }

        ol.lst-kix_av44a7gw93kh-6.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-6 0
        }

        .lst-kix_u6lsy85ayutq-5>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-5
        }

        .lst-kix_pceibtxro1if-2>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-2
        }

        .lst-kix_3elc2ktxgkmv-1>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-1, lower-latin) ". "
        }

        .lst-kix_3elc2ktxgkmv-5>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-5, lower-roman) ". "
        }

        .lst-kix_u6lsy85ayutq-5>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-5, lower-roman) ". "
        }

        .lst-kix_u6lsy85ayutq-6>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-6, decimal) ". "
        }

        .lst-kix_nas4vziddqer-8>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-8
        }

        .lst-kix_9otmb23h2e72-6>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-6
        }

        .lst-kix_3elc2ktxgkmv-4>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-4, lower-latin) ". "
        }

        ol.lst-kix_5cyrqd6ve42g-1.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-1 0
        }

        ol.lst-kix_g9bf9rgk5fju-1.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-1 0
        }

        ol.lst-kix_g9bf9rgk5fju-7.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-7 0
        }

        .lst-kix_wae1ymlgm6l6-0>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-0, decimal) ". "
        }

        ol.lst-kix_2amedsbs4wpe-2.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-2 0
        }

        .lst-kix_wae1ymlgm6l6-1>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-1, lower-latin) ". "
        }

        ol.lst-kix_1vy3yzwcks66-6.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-6 0
        }

        .lst-kix_j05skkyse9cl-4>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-4
        }

        ol.lst-kix_8q238ecyvl96-7.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-7 0
        }

        ol.lst-kix_lhfs68ixug90-6.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-6 0
        }

        .lst-kix_3elc2ktxgkmv-8>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-8, lower-roman) ". "
        }

        .lst-kix_9otmb23h2e72-4>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-4
        }

        .lst-kix_g6wy5ndlov25-5>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-5, lower-roman) ". "
        }

        .lst-kix_g6wy5ndlov25-2>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-2, lower-roman) ". "
        }

        .lst-kix_g6wy5ndlov25-6>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-6, decimal) ". "
        }

        .lst-kix_g6wy5ndlov25-1>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-1, lower-latin) ". "
        }

        ol.lst-kix_z6r466v9qacb-3.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-3 0
        }

        .lst-kix_5vj4vaup0lg-0>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-0, decimal) ". "
        }

        ol.lst-kix_g9bf9rgk5fju-2.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-2 0
        }

        ol.lst-kix_1vy3yzwcks66-5.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-5 0
        }

        .lst-kix_5vj4vaup0lg-4>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-4, lower-latin) ". "
        }

        .lst-kix_bs8h945xvt8p-7>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-7
        }

        .lst-kix_5vj4vaup0lg-1>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-1, lower-latin) ". "
        }

        .lst-kix_5vj4vaup0lg-5>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-5, lower-roman) ". "
        }

        .lst-kix_8lu05uv13twm-7>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-7
        }

        ol.lst-kix_u6lsy85ayutq-5.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-5 0
        }

        ol.lst-kix_2amedsbs4wpe-1.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-1 0
        }

        ol.lst-kix_nas4vziddqer-7.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-7 0
        }

        .lst-kix_shfel39lkr1e-3>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-3
        }

        .lst-kix_bs8h945xvt8p-0>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-0
        }

        ol.lst-kix_l2ui8c35mkpb-1.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-1 0
        }

        ol.lst-kix_5cyrqd6ve42g-0 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-6.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-6 0
        }

        ol.lst-kix_easree9c2dho-0 {
            list-style-type: none
        }

        .lst-kix_k9i9cgazomij-8>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-8
        }

        ol.lst-kix_3lt2rtjf4bk3-6.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-6 0
        }

        .lst-kix_av44a7gw93kh-2>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-2
        }

        .lst-kix_mjcqlmrhlk54-8>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-8
        }

        ol.lst-kix_easree9c2dho-4 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-8 {
            list-style-type: none
        }

        .lst-kix_5vj4vaup0lg-8>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-8, lower-roman) ". "
        }

        ol.lst-kix_easree9c2dho-3 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-7 {
            list-style-type: none
        }

        ol.lst-kix_easree9c2dho-2 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-6 {
            list-style-type: none
        }

        ol.lst-kix_easree9c2dho-1 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-5 {
            list-style-type: none
        }

        .lst-kix_lhfs68ixug90-0>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-0, decimal) ". "
        }

        ol.lst-kix_easree9c2dho-8 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-4 {
            list-style-type: none
        }

        .lst-kix_1w19ehkp76hc-8>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-8
        }

        ol.lst-kix_easree9c2dho-7 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-3 {
            list-style-type: none
        }

        ol.lst-kix_easree9c2dho-6 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-2 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-4.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-4 0
        }

        ol.lst-kix_easree9c2dho-5 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-1 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-8 {
            list-style-type: none
        }

        ol.lst-kix_8lu05uv13twm-6 {
            list-style-type: none
        }

        ol.lst-kix_8lu05uv13twm-7 {
            list-style-type: none
        }

        .lst-kix_w6rtoi8h18q4-4>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-4
        }

        ol.lst-kix_8lu05uv13twm-8 {
            list-style-type: none
        }

        .lst-kix_2amedsbs4wpe-1>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-1
        }

        ol.lst-kix_l2ui8c35mkpb-3 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-2 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-1 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-0 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-7 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-6 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-5 {
            list-style-type: none
        }

        ol.lst-kix_l2ui8c35mkpb-4 {
            list-style-type: none
        }

        .lst-kix_g6wy5ndlov25-1>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-1
        }

        .lst-kix_1vy3yzwcks66-0>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-0, decimal) ". "
        }

        ol.lst-kix_nas4vziddqer-8.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-8 0
        }

        ol.lst-kix_z6r466v9qacb-8.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-8 0
        }

        ol.lst-kix_5cyrqd6ve42g-2.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-2 0
        }

        ol.lst-kix_g6wy5ndlov25-0.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-0 0
        }

        ol.lst-kix_5vj4vaup0lg-6.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-6 0
        }

        .lst-kix_lhfs68ixug90-8>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-8, lower-roman) ". "
        }

        .lst-kix_pceibtxro1if-7>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-7, lower-latin) ". "
        }

        .lst-kix_1vy3yzwcks66-8>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-8, lower-roman) ". "
        }

        ol.lst-kix_u6lsy85ayutq-0.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-0 11
        }

        ol.lst-kix_l2ui8c35mkpb-6.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-6 0
        }

        .lst-kix_k9i9cgazomij-8>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-8, lower-roman) ". "
        }

        .lst-kix_g9bf9rgk5fju-3>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-3, decimal) ". "
        }

        .lst-kix_1vy3yzwcks66-4>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-4, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-5>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-5, lower-roman) ". "
        }

        ol.lst-kix_8q238ecyvl96-6.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-6 0
        }

        .lst-kix_lhfs68ixug90-4>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-4, lower-latin) ". "
        }

        ol.lst-kix_dgucu56m2lmv-7.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-7 0
        }

        .lst-kix_k9i9cgazomij-1>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-1
        }

        ol.lst-kix_uqbv7hiqqgcv-5.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-5 0
        }

        .lst-kix_g9bf9rgk5fju-7>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-7, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-1>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-1, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-6>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-6
        }

        ol.lst-kix_mjcqlmrhlk54-8.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-8 0
        }

        .lst-kix_k9i9cgazomij-0>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-0, decimal) ". "
        }

        .lst-kix_5cyrqd6ve42g-3>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-3
        }

        .lst-kix_l2ui8c35mkpb-1>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-1, lower-latin) ". "
        }

        .lst-kix_xr9khdw9fe6q-8>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-8
        }

        ol.lst-kix_8lu05uv13twm-2.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-2 0
        }

        .lst-kix_lhfs68ixug90-3>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-3
        }

        .lst-kix_1w19ehkp76hc-1>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-1
        }

        ol.lst-kix_uqbv7hiqqgcv-2.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-2 0
        }

        .lst-kix_k9i9cgazomij-4>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-4, lower-latin) ". "
        }

        .lst-kix_shfel39lkr1e-5>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-5
        }

        ol.lst-kix_rb5mt5ymao2m-7.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-7 0
        }

        ol.lst-kix_1w7g9wynhivh-2.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-2 0
        }

        .lst-kix_l2ui8c35mkpb-5>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-5, lower-roman) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-7.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-7 0
        }

        ol.lst-kix_1w7g9wynhivh-1.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-1 0
        }

        .lst-kix_shfel39lkr1e-3>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-3, decimal) ". "
        }

        ol.lst-kix_lhfs68ixug90-2.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-2 0
        }

        ol.lst-kix_l2ui8c35mkpb-4.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-4 0
        }

        ol.lst-kix_av44a7gw93kh-7.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-7 0
        }

        .lst-kix_shfel39lkr1e-7>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-7, lower-latin) ". "
        }

        .lst-kix_8lu05uv13twm-0>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-0
        }

        ol.lst-kix_u6lsy85ayutq-2.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-2 0
        }

        ol.lst-kix_g9bf9rgk5fju-6.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-6 0
        }

        ol.lst-kix_wbr2z7x73lve-2.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-2 0
        }

        .lst-kix_j05skkyse9cl-6>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-6, decimal) ". "
        }

        .lst-kix_2amedsbs4wpe-6>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-6
        }

        .lst-kix_zgyf1q7d6njt-6>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-6
        }

        .lst-kix_mjcqlmrhlk54-3>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-3, decimal) ". "
        }

        .lst-kix_u6lsy85ayutq-2>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-2, lower-roman) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-8.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-8 0
        }

        .lst-kix_mjcqlmrhlk54-7>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-7, lower-latin) ". "
        }

        ol.lst-kix_av44a7gw93kh-0 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-3.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-3 0
        }

        .lst-kix_pceibtxro1if-3>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-3, decimal) ". "
        }

        .lst-kix_1w19ehkp76hc-3>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-3, decimal) ". "
        }

        ol.lst-kix_u6lsy85ayutq-1.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-1 0
        }

        ol.lst-kix_av44a7gw93kh-3 {
            list-style-type: none
        }

        ol.lst-kix_1w7g9wynhivh-0.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-0 12
        }

        ol.lst-kix_av44a7gw93kh-4 {
            list-style-type: none
        }

        ol.lst-kix_av44a7gw93kh-1 {
            list-style-type: none
        }

        .lst-kix_5vj4vaup0lg-4>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-4
        }

        .lst-kix_l2ui8c35mkpb-2>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-2
        }

        ol.lst-kix_av44a7gw93kh-2 {
            list-style-type: none
        }

        ol.lst-kix_av44a7gw93kh-7 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-1.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-1 0
        }

        .lst-kix_rb5mt5ymao2m-5>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-5
        }

        ol.lst-kix_av44a7gw93kh-8 {
            list-style-type: none
        }

        .lst-kix_1w19ehkp76hc-7>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-7, lower-latin) ". "
        }

        ol.lst-kix_av44a7gw93kh-5 {
            list-style-type: none
        }

        .lst-kix_j05skkyse9cl-2>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-2, lower-roman) ". "
        }

        ol.lst-kix_av44a7gw93kh-6 {
            list-style-type: none
        }

        .lst-kix_g6wy5ndlov25-6>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-6
        }

        ol.lst-kix_8lu05uv13twm-3.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-3 0
        }

        .lst-kix_8q238ecyvl96-0>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-0
        }

        ol.lst-kix_lhfs68ixug90-1.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-1 0
        }

        .lst-kix_3elc2ktxgkmv-3>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-3
        }

        ol.lst-kix_l2ui8c35mkpb-5.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-5 0
        }

        .lst-kix_bs8h945xvt8p-5>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-5
        }

        ol.lst-kix_8lu05uv13twm-6.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-6 0
        }

        .lst-kix_9otmb23h2e72-7>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-7
        }

        .lst-kix_wbr2z7x73lve-8>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-8
        }

        .lst-kix_k9i9cgazomij-7>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-7
        }

        ol.lst-kix_8q238ecyvl96-8 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-7 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-2.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-2 0
        }

        ol.lst-kix_rb5mt5ymao2m-0 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-0 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-0 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-2 {
            list-style-type: none
        }

        .lst-kix_xr9khdw9fe6q-5>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-5
        }

        ol.lst-kix_8q238ecyvl96-1 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-2 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-4 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-1 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-3 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-4 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-6 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-3 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-5 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-2.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-2 0
        }

        ol.lst-kix_nas4vziddqer-4.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-4 0
        }

        .lst-kix_a38rqt8hbxzi-0>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-0
        }

        .lst-kix_u6lsy85ayutq-2>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-2
        }

        ol.lst-kix_j05skkyse9cl-6.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-6 0
        }

        ol.lst-kix_9otmb23h2e72-6 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-5 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-8 {
            list-style-type: none
        }

        ol.lst-kix_9otmb23h2e72-7 {
            list-style-type: none
        }

        .lst-kix_easree9c2dho-0>li {
            counter-increment: lst-ctn-kix_easree9c2dho-0
        }

        .lst-kix_8q238ecyvl96-8>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-8, lower-roman) ". "
        }

        ol.lst-kix_rb5mt5ymao2m-5 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-7.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-7 0
        }

        ol.lst-kix_rb5mt5ymao2m-6 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-7 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-8 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-1 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-1 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-2 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-2 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-3.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-3 0
        }

        ol.lst-kix_rb5mt5ymao2m-3 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-4 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-2.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-2 0
        }

        ol.lst-kix_xr9khdw9fe6q-0 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-1.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-1 0
        }

        .lst-kix_3elc2ktxgkmv-2>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-2
        }

        .lst-kix_yl36npzuxxn-7>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-7, lower-latin) ". "
        }

        .lst-kix_5vj4vaup0lg-2>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-2
        }

        ol.lst-kix_xr9khdw9fe6q-5 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-6 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-5>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-5
        }

        ol.lst-kix_xr9khdw9fe6q-3 {
            list-style-type: none
        }

        .lst-kix_8q238ecyvl96-0>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-0, decimal) ". "
        }

        ol.lst-kix_xr9khdw9fe6q-4 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-7 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-8 {
            list-style-type: none
        }

        .lst-kix_nas4vziddqer-3>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-3
        }

        .lst-kix_8q238ecyvl96-3>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-3, decimal) ". "
        }

        .lst-kix_g6wy5ndlov25-5>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-5
        }

        .lst-kix_u6lsy85ayutq-6>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-6
        }

        ol.lst-kix_5vj4vaup0lg-8.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-8 0
        }

        .lst-kix_8q238ecyvl96-6>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-6, decimal) ". "
        }

        ol.lst-kix_pceibtxro1if-7.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-7 0
        }

        ol.lst-kix_mjcqlmrhlk54-7.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-7 0
        }

        .lst-kix_8q238ecyvl96-5>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-5, lower-roman) ". "
        }

        .lst-kix_1w7g9wynhivh-5>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-5
        }

        .lst-kix_av44a7gw93kh-5>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-5
        }

        ol.lst-kix_g9bf9rgk5fju-3.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-3 0
        }

        ol.lst-kix_bs8h945xvt8p-8.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-8 0
        }

        ol.lst-kix_mjcqlmrhlk54-0.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-0 0
        }

        .lst-kix_easree9c2dho-4>li {
            counter-increment: lst-ctn-kix_easree9c2dho-4
        }

        ol.lst-kix_k9i9cgazomij-7.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-7 0
        }

        ol.lst-kix_zgyf1q7d6njt-2.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-2 0
        }

        .lst-kix_yl36npzuxxn-2>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-2
        }

        .lst-kix_easree9c2dho-7>li {
            counter-increment: lst-ctn-kix_easree9c2dho-7
        }

        .lst-kix_3lt2rtjf4bk3-7>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-7
        }

        .lst-kix_8lu05uv13twm-3>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-3
        }

        .lst-kix_3elc2ktxgkmv-5>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-5
        }

        ol.lst-kix_mjcqlmrhlk54-2.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-2 0
        }

        .lst-kix_1vy3yzwcks66-3>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-3, decimal) ". "
        }

        ol.lst-kix_xr9khdw9fe6q-6.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-6 0
        }

        .lst-kix_1vy3yzwcks66-1>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-1, lower-latin) ". "
        }

        .lst-kix_lhfs68ixug90-7>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-7, lower-latin) ". "
        }

        .lst-kix_g9bf9rgk5fju-2>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-2
        }

        .lst-kix_lhfs68ixug90-5>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-5, lower-roman) ". "
        }

        .lst-kix_easree9c2dho-0>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-0, decimal) ". "
        }

        ol.lst-kix_uqbv7hiqqgcv-0.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-0 8
        }

        .lst-kix_rb5mt5ymao2m-3>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-3, decimal) ". "
        }

        ol.lst-kix_g9bf9rgk5fju-0.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-0 1
        }

        .lst-kix_rb5mt5ymao2m-5>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-5, lower-roman) ". "
        }

        .lst-kix_g9bf9rgk5fju-4>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-4, lower-latin) ". "
        }

        .lst-kix_g9bf9rgk5fju-6>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-6, decimal) ". "
        }

        .lst-kix_dgucu56m2lmv-3>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-3, decimal) ". "
        }

        .lst-kix_w6rtoi8h18q4-4>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-4, lower-latin) ". "
        }

        .lst-kix_l2ui8c35mkpb-0>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-0, decimal) ". "
        }

        .lst-kix_easree9c2dho-8>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-8, lower-roman) ". "
        }

        .lst-kix_a38rqt8hbxzi-4>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-4, lower-latin) ". "
        }

        .lst-kix_5cyrqd6ve42g-6>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-6
        }

        .lst-kix_8lu05uv13twm-2>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-2
        }

        ol.lst-kix_1w19ehkp76hc-6.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-6 0
        }

        .lst-kix_9otmb23h2e72-3>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-3
        }

        .lst-kix_dgucu56m2lmv-1>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-1, lower-latin) ". "
        }

        .lst-kix_g6wy5ndlov25-2>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-2
        }

        ol.lst-kix_mjcqlmrhlk54-5.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-5 0
        }

        .lst-kix_a38rqt8hbxzi-2>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-2, lower-roman) ". "
        }

        .lst-kix_yl36npzuxxn-1>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-1, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-4>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-4
        }

        ol.lst-kix_8lu05uv13twm-8.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-8 0
        }

        .lst-kix_l2ui8c35mkpb-8>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-8, lower-roman) ". "
        }

        .lst-kix_k9i9cgazomij-3>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-3
        }

        .lst-kix_l2ui8c35mkpb-6>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-6, decimal) ". "
        }

        .lst-kix_w6rtoi8h18q4-6>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-6, decimal) ". "
        }

        .lst-kix_wae1ymlgm6l6-8>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-8
        }

        .lst-kix_easree9c2dho-6>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-6, decimal) ". "
        }

        .lst-kix_3lt2rtjf4bk3-3>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-3
        }

        .lst-kix_k9i9cgazomij-4>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-4
        }

        ol.lst-kix_xr9khdw9fe6q-4.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-4 0
        }

        ol.lst-kix_pceibtxro1if-4.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-4 0
        }

        .lst-kix_shfel39lkr1e-2>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-2, lower-roman) ". "
        }

        .lst-kix_uqbv7hiqqgcv-5>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-5
        }

        .lst-kix_av44a7gw93kh-1>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-1
        }

        .lst-kix_shfel39lkr1e-0>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-0, decimal) ". "
        }

        .lst-kix_j05skkyse9cl-3>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-3, decimal) ". "
        }

        .lst-kix_shfel39lkr1e-8>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-8, lower-roman) ". "
        }

        .lst-kix_mjcqlmrhlk54-1>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-1
        }

        ol.lst-kix_5vj4vaup0lg-5 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-6 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-7 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-8 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-1 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-0.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-0 0
        }

        ol.lst-kix_5vj4vaup0lg-2 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-3 {
            list-style-type: none
        }

        ol.lst-kix_5vj4vaup0lg-4 {
            list-style-type: none
        }

        .lst-kix_j05skkyse9cl-5>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-5, lower-roman) ". "
        }

        ol.lst-kix_5vj4vaup0lg-0 {
            list-style-type: none
        }

        .lst-kix_lhfs68ixug90-5>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-5
        }

        .lst-kix_a38rqt8hbxzi-3>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-3
        }

        ol.lst-kix_1vy3yzwcks66-1 {
            list-style-type: none
        }

        .lst-kix_u6lsy85ayutq-1>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-1, lower-latin) ". "
        }

        ol.lst-kix_1vy3yzwcks66-2 {
            list-style-type: none
        }

        .lst-kix_wbr2z7x73lve-1>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-1
        }

        ol.lst-kix_1vy3yzwcks66-0 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-8 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-8 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-7 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-7 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-6 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-5 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-8.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-8 0
        }

        ol.lst-kix_uqbv7hiqqgcv-0 {
            list-style-type: none
        }

        .lst-kix_wae1ymlgm6l6-1>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-1
        }

        ol.lst-kix_1w19ehkp76hc-8.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-8 0
        }

        ol.lst-kix_uqbv7hiqqgcv-4 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-3 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-2 {
            list-style-type: none
        }

        ol.lst-kix_uqbv7hiqqgcv-1 {
            list-style-type: none
        }

        .lst-kix_3elc2ktxgkmv-6>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-6
        }

        ol.lst-kix_1vy3yzwcks66-5 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-6 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-3 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-4 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-7 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-8 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-8 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-7 {
            list-style-type: none
        }

        .lst-kix_wae1ymlgm6l6-7>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-7, lower-latin) ". "
        }

        .lst-kix_yl36npzuxxn-1>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-1
        }

        ol.lst-kix_k9i9cgazomij-6 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-5 {
            list-style-type: none
        }

        .lst-kix_5vj4vaup0lg-5>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-5
        }

        .lst-kix_w6rtoi8h18q4-0>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-0
        }

        ol.lst-kix_1w19ehkp76hc-5.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-5 0
        }

        .lst-kix_shfel39lkr1e-1>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-1
        }

        ol.lst-kix_k9i9cgazomij-0 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-2>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-2
        }

        ol.lst-kix_k9i9cgazomij-4 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-3 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-2 {
            list-style-type: none
        }

        ol.lst-kix_k9i9cgazomij-1 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-1.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-1 0
        }

        ol.lst-kix_mjcqlmrhlk54-0 {
            list-style-type: none
        }

        ol.lst-kix_xr9khdw9fe6q-2.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-2 0
        }

        ol.lst-kix_zgyf1q7d6njt-5 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-4 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-2 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-3 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-1 {
            list-style-type: none
        }

        .lst-kix_mjcqlmrhlk54-4>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-4
        }

        ol.lst-kix_zgyf1q7d6njt-2 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-4 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-1 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-3 {
            list-style-type: none
        }

        .lst-kix_u6lsy85ayutq-3>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-3
        }

        ol.lst-kix_zgyf1q7d6njt-0 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-6 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-5 {
            list-style-type: none
        }

        .lst-kix_uqbv7hiqqgcv-8>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-8
        }

        .lst-kix_xr9khdw9fe6q-6>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-6, decimal) ". "
        }

        .lst-kix_nas4vziddqer-6>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-6
        }

        ol.lst-kix_zgyf1q7d6njt-8 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-7 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-6 {
            list-style-type: none
        }

        .lst-kix_3elc2ktxgkmv-3>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-3, decimal) ". "
        }

        .lst-kix_u6lsy85ayutq-4>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-4, lower-latin) ". "
        }

        .lst-kix_u6lsy85ayutq-7>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-7, lower-latin) ". "
        }

        ol.lst-kix_bs8h945xvt8p-6.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-6 0
        }

        .lst-kix_2amedsbs4wpe-7>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-7, lower-latin) ". "
        }

        .lst-kix_wae1ymlgm6l6-2>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-2, lower-roman) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-0.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-0 0
        }

        ol.lst-kix_j05skkyse9cl-3.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-3 0
        }

        ol.lst-kix_1w19ehkp76hc-0.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-0 1
        }

        .lst-kix_2amedsbs4wpe-2>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-2, lower-roman) ". "
        }

        .lst-kix_3elc2ktxgkmv-6>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-6, decimal) ". "
        }

        ol.lst-kix_k9i9cgazomij-5.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-5 0
        }

        ol.lst-kix_bs8h945xvt8p-0.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-0 0
        }

        ol.lst-kix_bs8h945xvt8p-7.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-7 0
        }

        .lst-kix_wbr2z7x73lve-5>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-5
        }

        .lst-kix_g6wy5ndlov25-7>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-7, lower-latin) ". "
        }

        ol.lst-kix_xr9khdw9fe6q-1.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-1 0
        }

        .lst-kix_5vj4vaup0lg-2>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-2, lower-roman) ". "
        }

        .lst-kix_nas4vziddqer-6>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-6, decimal) ". "
        }

        .lst-kix_3lt2rtjf4bk3-5>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-5, lower-roman) ". "
        }

        ol.lst-kix_k9i9cgazomij-6.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-6 0
        }

        .lst-kix_l2ui8c35mkpb-6>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-6
        }

        ol.lst-kix_1w19ehkp76hc-4.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-4 0
        }

        .lst-kix_g6wy5ndlov25-4>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-4, lower-latin) ". "
        }

        .lst-kix_av44a7gw93kh-8>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-8
        }

        .lst-kix_dgucu56m2lmv-6>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-6, decimal) ". "
        }

        ol.lst-kix_nas4vziddqer-2.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-2 0
        }

        ol.lst-kix_bs8h945xvt8p-1.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-1 0
        }

        .lst-kix_1w19ehkp76hc-2>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-2
        }

        .lst-kix_yl36npzuxxn-8>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-8
        }

        .lst-kix_5vj4vaup0lg-7>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-7, lower-latin) ". "
        }

        .lst-kix_yl36npzuxxn-5>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-5
        }

        .lst-kix_xr9khdw9fe6q-3>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-3, decimal) ". "
        }

        .lst-kix_1w7g9wynhivh-8>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-8
        }

        .lst-kix_w6rtoi8h18q4-7>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-7
        }

        ol.lst-kix_j05skkyse9cl-4.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-4 0
        }

        .lst-kix_3lt2rtjf4bk3-2>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-2, lower-roman) ". "
        }

        ol.lst-kix_pceibtxro1if-0.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-0 0
        }

        ol.lst-kix_k9i9cgazomij-0.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-0 10
        }

        .lst-kix_nas4vziddqer-1>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-1, lower-latin) ". "
        }

        ol.lst-kix_k9i9cgazomij-4.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-4 0
        }

        .lst-kix_3lt2rtjf4bk3-0>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-0
        }

        .lst-kix_rb5mt5ymao2m-4>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-4
        }

        .lst-kix_dgucu56m2lmv-0>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-0
        }

        ol.lst-kix_bs8h945xvt8p-2.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-2 0
        }

        ol.lst-kix_yl36npzuxxn-6.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-6 0
        }

        .lst-kix_lhfs68ixug90-2>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-2, lower-roman) ". "
        }

        .lst-kix_rb5mt5ymao2m-8>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-8, lower-roman) ". "
        }

        .lst-kix_uqbv7hiqqgcv-1>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-1
        }

        .lst-kix_pceibtxro1if-5>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-5, lower-roman) ". "
        }

        .lst-kix_1vy3yzwcks66-6>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-6, decimal) ". "
        }

        .lst-kix_a38rqt8hbxzi-7>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-7, lower-latin) ". "
        }

        ol.lst-kix_1w19ehkp76hc-1.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-1 0
        }

        .lst-kix_g9bf9rgk5fju-1>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-1, lower-latin) ". "
        }

        .lst-kix_j05skkyse9cl-8>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-8
        }

        .lst-kix_lhfs68ixug90-2>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-2
        }

        .lst-kix_l2ui8c35mkpb-3>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-3
        }

        ol.lst-kix_j05skkyse9cl-2.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-2 0
        }

        ol.lst-kix_k9i9cgazomij-1.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-1 0
        }

        .lst-kix_rb5mt5ymao2m-0>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-0, decimal) ". "
        }

        .lst-kix_l2ui8c35mkpb-3>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-3, decimal) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-4.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-4 0
        }

        .lst-kix_w6rtoi8h18q4-1>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-1, lower-latin) ". "
        }

        .lst-kix_k9i9cgazomij-6>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-6, decimal) ". "
        }

        .lst-kix_bs8h945xvt8p-5>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-5, lower-roman) ". "
        }

        .lst-kix_easree9c2dho-3>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-3, decimal) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-7.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-7 0
        }

        .lst-kix_k9i9cgazomij-0>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-0
        }

        ol.lst-kix_nas4vziddqer-0.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-0 9
        }

        .lst-kix_yl36npzuxxn-4>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-4, lower-latin) ". "
        }

        ol.lst-kix_1w19ehkp76hc-3.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-3 0
        }

        .lst-kix_9otmb23h2e72-0>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-0
        }

        .lst-kix_8lu05uv13twm-6>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-6
        }

        ol.lst-kix_yl36npzuxxn-4.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-4 0
        }

        ol.lst-kix_j05skkyse9cl-0.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-0 2
        }

        .lst-kix_j05skkyse9cl-8>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-8, lower-roman) ". "
        }

        .lst-kix_shfel39lkr1e-4>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-4
        }

        .lst-kix_a38rqt8hbxzi-7>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-7
        }

        .lst-kix_xr9khdw9fe6q-2>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-2
        }

        ol.lst-kix_bs8h945xvt8p-4.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-4 0
        }

        .lst-kix_5cyrqd6ve42g-2>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-2
        }

        .lst-kix_2amedsbs4wpe-0>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-0
        }

        .lst-kix_1w7g9wynhivh-6>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-6, decimal) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-6.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-6 0
        }

        .lst-kix_1w7g9wynhivh-1>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-1
        }

        .lst-kix_g9bf9rgk5fju-5>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-5
        }

        ol.lst-kix_yl36npzuxxn-5.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-5 0
        }

        .lst-kix_1w19ehkp76hc-5>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-5, lower-roman) ". "
        }

        ol.lst-kix_yl36npzuxxn-8 {
            list-style-type: none
        }

        .lst-kix_wae1ymlgm6l6-4>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-4
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ol.lst-kix_1w19ehkp76hc-2.start {
            counter-reset: lst-ctn-kix_1w19ehkp76hc-2 0
        }

        ol.lst-kix_k9i9cgazomij-3.start {
            counter-reset: lst-ctn-kix_k9i9cgazomij-3 0
        }

        ol.lst-kix_yl36npzuxxn-3 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-2 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-1 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-8 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-0 {
            list-style-type: none
        }

        .lst-kix_j05skkyse9cl-0>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-0, decimal) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-7 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-7 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-6 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-6 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-5 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-5 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-4 {
            list-style-type: none
        }

        ol.lst-kix_yl36npzuxxn-4 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-3 {
            list-style-type: none
        }

        .lst-kix_8q238ecyvl96-6>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-6
        }

        ol.lst-kix_3lt2rtjf4bk3-2 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-1.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-1 0
        }

        .lst-kix_mjcqlmrhlk54-1>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-1, lower-latin) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-1 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-5.start {
            counter-reset: lst-ctn-kix_bs8h945xvt8p-5 0
        }

        .lst-kix_shfel39lkr1e-5>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-5, lower-roman) ". "
        }

        ol.lst-kix_3lt2rtjf4bk3-0 {
            list-style-type: none
        }

        ol.lst-kix_zgyf1q7d6njt-5.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-5 0
        }

        ol.lst-kix_g9bf9rgk5fju-4.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-4 0
        }

        ol.lst-kix_z6r466v9qacb-1.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-1 0
        }

        ol.lst-kix_bs8h945xvt8p-0 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-1 {
            list-style-type: none
        }

        .lst-kix_mjcqlmrhlk54-3>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-3
        }

        .lst-kix_8lu05uv13twm-7>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-7, lower-latin) ". "
        }

        ol.lst-kix_g6wy5ndlov25-7.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-7 0
        }

        .lst-kix_8lu05uv13twm-8>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-8, lower-roman) ". "
        }

        .lst-kix_zgyf1q7d6njt-6>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-6, decimal) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-3 {
            list-style-type: none
        }

        .lst-kix_zgyf1q7d6njt-5>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-5, lower-roman) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-8.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-8 0
        }

        ol.lst-kix_3elc2ktxgkmv-2 {
            list-style-type: none
        }

        ol.lst-kix_3elc2ktxgkmv-1 {
            list-style-type: none
        }

        ol.lst-kix_3elc2ktxgkmv-0 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-3>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-3, decimal) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-7 {
            list-style-type: none
        }

        .lst-kix_zgyf1q7d6njt-3>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-3, decimal) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-6 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-2>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-2, lower-roman) ". "
        }

        .lst-kix_8lu05uv13twm-4>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-4, lower-latin) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-5 {
            list-style-type: none
        }

        .lst-kix_zgyf1q7d6njt-2>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-2, lower-roman) ". "
        }

        .lst-kix_zgyf1q7d6njt-4>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-4, lower-latin) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-4 {
            list-style-type: none
        }

        .lst-kix_1vy3yzwcks66-6>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-6
        }

        ol.lst-kix_3elc2ktxgkmv-8 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-4 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-5 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-6>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-6, decimal) ". "
        }

        .lst-kix_zgyf1q7d6njt-0>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-0, decimal) ". "
        }

        ol.lst-kix_bs8h945xvt8p-2 {
            list-style-type: none
        }

        .lst-kix_3lt2rtjf4bk3-5>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-5
        }

        ol.lst-kix_bs8h945xvt8p-3 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-5>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-5, lower-roman) ". "
        }

        .lst-kix_zgyf1q7d6njt-1>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-1, lower-latin) ". "
        }

        ol.lst-kix_bs8h945xvt8p-8 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-6 {
            list-style-type: none
        }

        ol.lst-kix_bs8h945xvt8p-7 {
            list-style-type: none
        }

        .lst-kix_nas4vziddqer-5>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-5
        }

        .lst-kix_shfel39lkr1e-0>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-0
        }

        ol.lst-kix_3elc2ktxgkmv-1.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-1 0
        }

        ol.lst-kix_mjcqlmrhlk54-1.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-1 0
        }

        .lst-kix_xr9khdw9fe6q-3>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-3
        }

        .lst-kix_l2ui8c35mkpb-7>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-7
        }

        ol.lst-kix_u6lsy85ayutq-3 {
            list-style-type: none
        }

        .lst-kix_1w7g9wynhivh-1>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-1, lower-latin) ". "
        }

        .lst-kix_1w7g9wynhivh-2>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-2, lower-roman) ". "
        }

        ol.lst-kix_u6lsy85ayutq-4 {
            list-style-type: none
        }

        ol.lst-kix_u6lsy85ayutq-5 {
            list-style-type: none
        }

        ol.lst-kix_u6lsy85ayutq-6 {
            list-style-type: none
        }

        .lst-kix_j05skkyse9cl-1>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-1
        }

        ol.lst-kix_u6lsy85ayutq-7 {
            list-style-type: none
        }

        .lst-kix_1w7g9wynhivh-0>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-0, decimal) ". "
        }

        ol.lst-kix_u6lsy85ayutq-8 {
            list-style-type: none
        }

        .lst-kix_zgyf1q7d6njt-7>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-7, lower-latin) ". "
        }

        ol.lst-kix_z6r466v9qacb-6.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-6 0
        }

        .lst-kix_rb5mt5ymao2m-1>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-1
        }

        .lst-kix_zgyf1q7d6njt-8>li:before {
            content: "" counter(lst-ctn-kix_zgyf1q7d6njt-8, lower-roman) ". "
        }

        ol.lst-kix_1vy3yzwcks66-3.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-3 0
        }

        ol.lst-kix_u6lsy85ayutq-0 {
            list-style-type: none
        }

        ol.lst-kix_u6lsy85ayutq-1 {
            list-style-type: none
        }

        ol.lst-kix_u6lsy85ayutq-2 {
            list-style-type: none
        }

        .lst-kix_5cyrqd6ve42g-5>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-5, lower-roman) ". "
        }

        .lst-kix_dgucu56m2lmv-3>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-3
        }

        .lst-kix_9otmb23h2e72-8>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-8, lower-roman) ". "
        }

        .lst-kix_5cyrqd6ve42g-4>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-4, lower-latin) ". "
        }

        .lst-kix_5cyrqd6ve42g-6>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-6, decimal) ". "
        }

        .lst-kix_l2ui8c35mkpb-0>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-0
        }

        .lst-kix_5cyrqd6ve42g-1>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-1, lower-latin) ". "
        }

        .lst-kix_9otmb23h2e72-6>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-6, decimal) ". "
        }

        ol.lst-kix_zgyf1q7d6njt-3.start {
            counter-reset: lst-ctn-kix_zgyf1q7d6njt-3 0
        }

        .lst-kix_5cyrqd6ve42g-0>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-0, decimal) ". "
        }

        .lst-kix_5cyrqd6ve42g-8>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-8, lower-roman) ". "
        }

        .lst-kix_a38rqt8hbxzi-2>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-2
        }

        .lst-kix_9otmb23h2e72-7>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-7, lower-latin) ". "
        }

        ol.lst-kix_av44a7gw93kh-4.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-4 0
        }

        .lst-kix_5cyrqd6ve42g-7>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-7, lower-latin) ". "
        }

        .lst-kix_u6lsy85ayutq-0>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-0
        }

        ol.lst-kix_8q238ecyvl96-4.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-4 0
        }

        .lst-kix_5cyrqd6ve42g-1>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-1
        }

        .lst-kix_z6r466v9qacb-0>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-0, decimal) ". "
        }

        ol.lst-kix_shfel39lkr1e-1 {
            list-style-type: none
        }

        .lst-kix_9otmb23h2e72-1>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-1, lower-latin) ". "
        }

        ol.lst-kix_shfel39lkr1e-0 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-2.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-2 0
        }

        .lst-kix_bs8h945xvt8p-0>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-0, decimal) ". "
        }

        .lst-kix_9otmb23h2e72-0>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-0, decimal) ". "
        }

        .lst-kix_9otmb23h2e72-2>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-2, lower-roman) ". "
        }

        .lst-kix_z6r466v9qacb-1>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-1, lower-latin) ". "
        }

        ol.lst-kix_shfel39lkr1e-5 {
            list-style-type: none
        }

        ol.lst-kix_shfel39lkr1e-4 {
            list-style-type: none
        }

        ol.lst-kix_shfel39lkr1e-3 {
            list-style-type: none
        }

        .lst-kix_bs8h945xvt8p-3>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-3, decimal) ". "
        }

        ol.lst-kix_shfel39lkr1e-2 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-2>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-2, lower-roman) ". "
        }

        .lst-kix_9otmb23h2e72-5>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-5, lower-roman) ". "
        }

        ol.lst-kix_lhfs68ixug90-4 {
            list-style-type: none
        }

        ol.lst-kix_shfel39lkr1e-8 {
            list-style-type: none
        }

        ol.lst-kix_lhfs68ixug90-5 {
            list-style-type: none
        }

        ol.lst-kix_5cyrqd6ve42g-4.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-4 0
        }

        ol.lst-kix_shfel39lkr1e-7 {
            list-style-type: none
        }

        .lst-kix_9otmb23h2e72-4>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-4, lower-latin) ". "
        }

        ol.lst-kix_lhfs68ixug90-6 {
            list-style-type: none
        }

        .lst-kix_5cyrqd6ve42g-2>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-2, lower-roman) ". "
        }

        ol.lst-kix_shfel39lkr1e-6 {
            list-style-type: none
        }

        ol.lst-kix_lhfs68ixug90-7 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-3>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-3, decimal) ". "
        }

        .lst-kix_z6r466v9qacb-5>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-5, lower-roman) ". "
        }

        .lst-kix_pceibtxro1if-5>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-5
        }

        .lst-kix_9otmb23h2e72-3>li:before {
            content: "" counter(lst-ctn-kix_9otmb23h2e72-3, decimal) ". "
        }

        ol.lst-kix_lhfs68ixug90-8 {
            list-style-type: none
        }

        .lst-kix_bs8h945xvt8p-2>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-2, lower-roman) ". "
        }

        .lst-kix_5cyrqd6ve42g-3>li:before {
            content: "" counter(lst-ctn-kix_5cyrqd6ve42g-3, decimal) ". "
        }

        .lst-kix_5vj4vaup0lg-8>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-8
        }

        .lst-kix_bs8h945xvt8p-1>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-1, lower-latin) ". "
        }

        .lst-kix_z6r466v9qacb-4>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-4, lower-latin) ". "
        }

        .lst-kix_g9bf9rgk5fju-4>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-4
        }

        ol.lst-kix_nas4vziddqer-0 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-1 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-2 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-3 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-0>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-0, decimal) ". "
        }

        .lst-kix_1w19ehkp76hc-5>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-5
        }

        .lst-kix_zgyf1q7d6njt-2>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-2
        }

        ol.lst-kix_nas4vziddqer-4 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-5 {
            list-style-type: none
        }

        .lst-kix_8lu05uv13twm-1>li:before {
            content: "" counter(lst-ctn-kix_8lu05uv13twm-1, lower-latin) ". "
        }

        ol.lst-kix_nas4vziddqer-6 {
            list-style-type: none
        }

        ol.lst-kix_nas4vziddqer-7 {
            list-style-type: none
        }

        .lst-kix_k9i9cgazomij-5>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-5
        }

        ol.lst-kix_nas4vziddqer-8 {
            list-style-type: none
        }

        ol.lst-kix_mjcqlmrhlk54-6.start {
            counter-reset: lst-ctn-kix_mjcqlmrhlk54-6 0
        }

        .lst-kix_lhfs68ixug90-7>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-7
        }

        ol.lst-kix_8lu05uv13twm-0.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-0 0
        }

        .lst-kix_9otmb23h2e72-1>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-1
        }

        ol.lst-kix_j05skkyse9cl-5.start {
            counter-reset: lst-ctn-kix_j05skkyse9cl-5 0
        }

        ol.lst-kix_dgucu56m2lmv-1.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-1 0
        }

        ol.lst-kix_easree9c2dho-5.start {
            counter-reset: lst-ctn-kix_easree9c2dho-5 0
        }

        .lst-kix_wbr2z7x73lve-2>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-2
        }

        .lst-kix_shfel39lkr1e-7>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-7
        }

        .lst-kix_z6r466v9qacb-7>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-7
        }

        .lst-kix_bs8h945xvt8p-3>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-3
        }

        ol.lst-kix_u6lsy85ayutq-8.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-8 0
        }

        .lst-kix_9otmb23h2e72-2>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-2
        }

        .lst-kix_2amedsbs4wpe-3>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-3
        }

        .lst-kix_8lu05uv13twm-4>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-4
        }

        ol.lst-kix_uqbv7hiqqgcv-8.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-8 0
        }

        .lst-kix_bs8h945xvt8p-8>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-8, lower-roman) ". "
        }

        .lst-kix_z6r466v9qacb-6>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-6
        }

        ol.lst-kix_lhfs68ixug90-0 {
            list-style-type: none
        }

        ol.lst-kix_lhfs68ixug90-1 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-4.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-4 0
        }

        ol.lst-kix_lhfs68ixug90-2 {
            list-style-type: none
        }

        ol.lst-kix_lhfs68ixug90-3 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-6>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-6, decimal) ". "
        }

        .lst-kix_bs8h945xvt8p-4>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-4, lower-latin) ". "
        }

        .lst-kix_bs8h945xvt8p-6>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-6, decimal) ". "
        }

        .lst-kix_z6r466v9qacb-8>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-8, lower-roman) ". "
        }

        .lst-kix_3elc2ktxgkmv-7>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-7
        }

        ol.lst-kix_pceibtxro1if-1.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-1 0
        }

        .lst-kix_shfel39lkr1e-8>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-8
        }

        ol.lst-kix_3lt2rtjf4bk3-4.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-4 0
        }

        ol.lst-kix_yl36npzuxxn-1.start {
            counter-reset: lst-ctn-kix_yl36npzuxxn-1 0
        }

        .lst-kix_lhfs68ixug90-0>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-0
        }

        .lst-kix_5vj4vaup0lg-0>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-0
        }

        ol.lst-kix_1w7g9wynhivh-8.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-8 0
        }

        .lst-kix_av44a7gw93kh-7>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-7
        }

        .lst-kix_1w7g9wynhivh-3>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-3, decimal) ". "
        }

        .lst-kix_5vj4vaup0lg-1>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-1
        }

        .lst-kix_wae1ymlgm6l6-7>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-7
        }

        ol.lst-kix_w6rtoi8h18q4-3.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-3 0
        }

        .lst-kix_av44a7gw93kh-6>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-6
        }

        ol.lst-kix_2amedsbs4wpe-4.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-4 0
        }

        .lst-kix_1w7g9wynhivh-5>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-5, lower-roman) ". "
        }

        .lst-kix_1w7g9wynhivh-7>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-7, lower-latin) ". "
        }

        ol.lst-kix_5vj4vaup0lg-0.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-0 0
        }

        .lst-kix_av44a7gw93kh-0>li {
            counter-increment: lst-ctn-kix_av44a7gw93kh-0
        }

        .lst-kix_bs8h945xvt8p-2>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-2
        }

        .lst-kix_5vj4vaup0lg-7>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-7
        }

        ol.lst-kix_w6rtoi8h18q4-4.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-4 0
        }

        ol.lst-kix_nas4vziddqer-5.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-5 0
        }

        .lst-kix_rb5mt5ymao2m-8>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-8
        }

        ol.lst-kix_9otmb23h2e72-3.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-3 0
        }

        ol.lst-kix_1vy3yzwcks66-8.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-8 0
        }

        ol.lst-kix_a38rqt8hbxzi-4.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-4 0
        }

        .lst-kix_8q238ecyvl96-3>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-3
        }

        .lst-kix_3elc2ktxgkmv-0>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-0
        }

        ol.lst-kix_xr9khdw9fe6q-3.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-3 0
        }

        .lst-kix_g6wy5ndlov25-3>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-3
        }

        .lst-kix_1w19ehkp76hc-6>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-6
        }

        ol.lst-kix_l2ui8c35mkpb-2.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-2 0
        }

        ol.lst-kix_3lt2rtjf4bk3-5.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-5 0
        }

        .lst-kix_3lt2rtjf4bk3-6>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-6
        }

        .lst-kix_xr9khdw9fe6q-8>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-8, lower-roman) ". "
        }

        .lst-kix_1vy3yzwcks66-5>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-5
        }

        ol.lst-kix_lhfs68ixug90-0.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-0 1
        }

        ol.lst-kix_9otmb23h2e72-4.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-4 0
        }

        .lst-kix_xr9khdw9fe6q-4>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-4, lower-latin) ". "
        }

        .lst-kix_xr9khdw9fe6q-5>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-5, lower-roman) ". "
        }

        ol.lst-kix_1w7g9wynhivh-3.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-3 0
        }

        .lst-kix_j05skkyse9cl-2>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-2
        }

        ol.lst-kix_g6wy5ndlov25-8 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-7 {
            list-style-type: none
        }

        .lst-kix_lhfs68ixug90-8>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-8
        }

        ol.lst-kix_g6wy5ndlov25-4 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-3 {
            list-style-type: none
        }

        .lst-kix_2amedsbs4wpe-5>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-5, lower-roman) ". "
        }

        ol.lst-kix_g6wy5ndlov25-6 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-5 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-5.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-5 0
        }

        ol.lst-kix_g6wy5ndlov25-0 {
            list-style-type: none
        }

        .lst-kix_9otmb23h2e72-8>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-8
        }

        ol.lst-kix_dgucu56m2lmv-5.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-5 0
        }

        ol.lst-kix_g6wy5ndlov25-2 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-1 {
            list-style-type: none
        }

        .lst-kix_2amedsbs4wpe-0>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-0, decimal) ". "
        }

        .lst-kix_2amedsbs4wpe-8>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-8, lower-roman) ". "
        }

        .lst-kix_z6r466v9qacb-0>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-0
        }

        .lst-kix_lhfs68ixug90-6>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-6
        }

        .lst-kix_k9i9cgazomij-6>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-6
        }

        ol.lst-kix_xr9khdw9fe6q-8.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-8 0
        }

        ol.lst-kix_nas4vziddqer-6.start {
            counter-reset: lst-ctn-kix_nas4vziddqer-6 0
        }

        ol.lst-kix_l2ui8c35mkpb-7.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-7 0
        }

        .lst-kix_dgucu56m2lmv-4>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-4
        }

        .lst-kix_2amedsbs4wpe-1>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-1, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-1>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-1
        }

        .lst-kix_1w19ehkp76hc-4>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-4
        }

        .lst-kix_2amedsbs4wpe-4>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-4, lower-latin) ". "
        }

        ol.lst-kix_wbr2z7x73lve-4.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-4 0
        }

        ol.lst-kix_5vj4vaup0lg-4.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-4 0
        }

        .lst-kix_8q238ecyvl96-2>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-2
        }

        ol.lst-kix_dgucu56m2lmv-6.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-6 0
        }

        .lst-kix_g6wy5ndlov25-4>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-4
        }

        ol.lst-kix_a38rqt8hbxzi-0.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-0 0
        }

        .lst-kix_rb5mt5ymao2m-7>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-7
        }

        .lst-kix_pceibtxro1if-4>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-4
        }

        .lst-kix_nas4vziddqer-3>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-3, decimal) ". "
        }

        ol.lst-kix_rb5mt5ymao2m-5.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-5 0
        }

        .lst-kix_uqbv7hiqqgcv-4>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-4
        }

        ol.lst-kix_lhfs68ixug90-5.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-5 0
        }

        .lst-kix_nas4vziddqer-4>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-4, lower-latin) ". "
        }

        .lst-kix_3elc2ktxgkmv-1>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-1
        }

        .lst-kix_nas4vziddqer-8>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-8, lower-roman) ". "
        }

        ol.lst-kix_xr9khdw9fe6q-7.start {
            counter-reset: lst-ctn-kix_xr9khdw9fe6q-7 0
        }

        .lst-kix_3lt2rtjf4bk3-8>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-8, lower-roman) ". "
        }

        .lst-kix_3elc2ktxgkmv-8>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-8
        }

        .lst-kix_wae1ymlgm6l6-0>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-0
        }

        .lst-kix_nas4vziddqer-7>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-7, lower-latin) ". "
        }

        .lst-kix_3lt2rtjf4bk3-7>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-7, lower-latin) ". "
        }

        .lst-kix_xr9khdw9fe6q-0>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-0, decimal) ". "
        }

        .lst-kix_1vy3yzwcks66-7>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-7
        }

        .lst-kix_xr9khdw9fe6q-1>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-1, lower-latin) ". "
        }

        ol.lst-kix_wbr2z7x73lve-5.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-5 0
        }

        .lst-kix_rb5mt5ymao2m-0>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-0
        }

        .lst-kix_2amedsbs4wpe-4>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-4
        }

        .lst-kix_zgyf1q7d6njt-8>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-8
        }

        ol.lst-kix_l2ui8c35mkpb-8.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-8 0
        }

        .lst-kix_dgucu56m2lmv-7>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-7, lower-latin) ". "
        }

        .lst-kix_3lt2rtjf4bk3-4>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-4, lower-latin) ". "
        }

        .lst-kix_3lt2rtjf4bk3-3>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-3, decimal) ". "
        }

        .lst-kix_dgucu56m2lmv-8>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-8, lower-roman) ". "
        }

        ol.lst-kix_1w7g9wynhivh-4.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-4 0
        }

        .lst-kix_3lt2rtjf4bk3-4>li {
            counter-increment: lst-ctn-kix_3lt2rtjf4bk3-4
        }

        ol.lst-kix_pceibtxro1if-6.start {
            counter-reset: lst-ctn-kix_pceibtxro1if-6 0
        }

        .lst-kix_a38rqt8hbxzi-8>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-8
        }

        .lst-kix_nas4vziddqer-0>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-0, decimal) ". "
        }

        .lst-kix_3lt2rtjf4bk3-0>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-0, decimal) ". "
        }

        ol.lst-kix_u6lsy85ayutq-4.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-4 0
        }

        .lst-kix_easree9c2dho-1>li {
            counter-increment: lst-ctn-kix_easree9c2dho-1
        }

        .lst-kix_w6rtoi8h18q4-6>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-6
        }

        .lst-kix_1vy3yzwcks66-0>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-0
        }

        ol.lst-kix_lhfs68ixug90-4.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-4 0
        }

        .lst-kix_uqbv7hiqqgcv-2>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-2
        }

        .lst-kix_lhfs68ixug90-1>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-1
        }

        .lst-kix_easree9c2dho-1>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-1, lower-latin) ". "
        }

        .lst-kix_rb5mt5ymao2m-6>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-6, decimal) ". "
        }

        ol.lst-kix_z6r466v9qacb-5.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-5 0
        }

        ol.lst-kix_g9bf9rgk5fju-8.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-8 0
        }

        ol.lst-kix_wbr2z7x73lve-0.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-0 2
        }

        ol.lst-kix_av44a7gw93kh-8.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-8 0
        }

        .lst-kix_dgucu56m2lmv-4>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-4, lower-latin) ". "
        }

        ol.lst-kix_u6lsy85ayutq-3.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-3 0
        }

        .lst-kix_w6rtoi8h18q4-3>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-3, decimal) ". "
        }

        ol.lst-kix_l2ui8c35mkpb-3.start {
            counter-reset: lst-ctn-kix_l2ui8c35mkpb-3 0
        }

        ol.lst-kix_8lu05uv13twm-5.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-5 0
        }

        .lst-kix_z6r466v9qacb-7>li:before {
            content: "" counter(lst-ctn-kix_z6r466v9qacb-7, lower-latin) ". "
        }

        .lst-kix_rb5mt5ymao2m-2>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-2, lower-roman) ". "
        }

        .lst-kix_bs8h945xvt8p-7>li:before {
            content: "" counter(lst-ctn-kix_bs8h945xvt8p-7, lower-latin) ". "
        }

        .lst-kix_dgucu56m2lmv-0>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-0, decimal) ". "
        }

        .lst-kix_8lu05uv13twm-5>li {
            counter-increment: lst-ctn-kix_8lu05uv13twm-5
        }

        ol.lst-kix_av44a7gw93kh-5.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-5 0
        }

        ol.lst-kix_9otmb23h2e72-8.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-8 0
        }

        .lst-kix_easree9c2dho-5>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-5, lower-roman) ". "
        }

        .lst-kix_w6rtoi8h18q4-7>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-7, lower-latin) ". "
        }

        .lst-kix_yl36npzuxxn-2>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-2, lower-roman) ". "
        }

        .lst-kix_zgyf1q7d6njt-1>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-1
        }

        ol.lst-kix_j05skkyse9cl-7 {
            list-style-type: none
        }

        .lst-kix_yl36npzuxxn-4>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-4
        }

        ol.lst-kix_j05skkyse9cl-6 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-5 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-4 {
            list-style-type: none
        }

        .lst-kix_easree9c2dho-8>li {
            counter-increment: lst-ctn-kix_easree9c2dho-8
        }

        .lst-kix_1w7g9wynhivh-8>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-8, lower-roman) ". "
        }

        ol.lst-kix_j05skkyse9cl-8 {
            list-style-type: none
        }

        .lst-kix_g9bf9rgk5fju-6>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-6
        }

        ol.lst-kix_j05skkyse9cl-3 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-2 {
            list-style-type: none
        }

        ol.lst-kix_j05skkyse9cl-1 {
            list-style-type: none
        }

        .lst-kix_1w7g9wynhivh-0>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-0
        }

        ol.lst-kix_j05skkyse9cl-0 {
            list-style-type: none
        }

        ol.lst-kix_rb5mt5ymao2m-8.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-8 0
        }

        .lst-kix_1w7g9wynhivh-7>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-7
        }

        .lst-kix_5cyrqd6ve42g-8>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-8
        }

        .lst-kix_1w7g9wynhivh-4>li:before {
            content: "" counter(lst-ctn-kix_1w7g9wynhivh-4, lower-latin) ". "
        }

        .lst-kix_easree9c2dho-3>li {
            counter-increment: lst-ctn-kix_easree9c2dho-3
        }

        ol.lst-kix_8lu05uv13twm-4.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-4 0
        }

        ol.lst-kix_uqbv7hiqqgcv-3.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-3 0
        }

        ol.lst-kix_1vy3yzwcks66-2.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-2 0
        }

        ol.lst-kix_8q238ecyvl96-8.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-8 0
        }

        ol.lst-kix_5vj4vaup0lg-5.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-5 0
        }

        ol.lst-kix_z6r466v9qacb-7.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-7 0
        }

        ol.lst-kix_g9bf9rgk5fju-5.start {
            counter-reset: lst-ctn-kix_g9bf9rgk5fju-5 0
        }

        .lst-kix_wbr2z7x73lve-4>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-4
        }

        .lst-kix_u6lsy85ayutq-7>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-7
        }

        ol.lst-kix_5cyrqd6ve42g-0.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-0 0
        }

        ol.lst-kix_uqbv7hiqqgcv-4.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-4 0
        }

        ol.lst-kix_1w19ehkp76hc-0 {
            list-style-type: none
        }

        ol.lst-kix_1w19ehkp76hc-2 {
            list-style-type: none
        }

        .lst-kix_8q238ecyvl96-5>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-5
        }

        ol.lst-kix_1w19ehkp76hc-1 {
            list-style-type: none
        }

        ol.lst-kix_1w19ehkp76hc-4 {
            list-style-type: none
        }

        ol.lst-kix_wae1ymlgm6l6-7.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-7 0
        }

        ol.lst-kix_1w19ehkp76hc-3 {
            list-style-type: none
        }

        ol.lst-kix_shfel39lkr1e-1.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-1 0
        }

        ol.lst-kix_1w19ehkp76hc-6 {
            list-style-type: none
        }

        ol.lst-kix_1w19ehkp76hc-5 {
            list-style-type: none
        }

        .lst-kix_nas4vziddqer-7>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-7
        }

        .lst-kix_z6r466v9qacb-1>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-1
        }

        ol.lst-kix_1w19ehkp76hc-8 {
            list-style-type: none
        }

        ol.lst-kix_1w19ehkp76hc-7 {
            list-style-type: none
        }

        .lst-kix_dgucu56m2lmv-5>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-5
        }

        ol.lst-kix_rb5mt5ymao2m-3.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-3 0
        }

        ol.lst-kix_a38rqt8hbxzi-8.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-8 0
        }

        ol.lst-kix_g9bf9rgk5fju-7 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-6 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-5 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-4 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-3 {
            list-style-type: none
        }

        ol.lst-kix_lhfs68ixug90-3.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-3 0
        }

        ol.lst-kix_g9bf9rgk5fju-2 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-1 {
            list-style-type: none
        }

        .lst-kix_5vj4vaup0lg-6>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-6
        }

        ol.lst-kix_g9bf9rgk5fju-0 {
            list-style-type: none
        }

        .lst-kix_8q238ecyvl96-7>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-7, lower-latin) ". "
        }

        .lst-kix_shfel39lkr1e-2>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-2
        }

        ol.lst-kix_easree9c2dho-1.start {
            counter-reset: lst-ctn-kix_easree9c2dho-1 0
        }

        .lst-kix_1w19ehkp76hc-7>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-7
        }

        .lst-kix_wae1ymlgm6l6-5>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-5
        }

        ol.lst-kix_2amedsbs4wpe-8 {
            list-style-type: none
        }

        ol.lst-kix_g9bf9rgk5fju-8 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-5.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-5 0
        }

        .lst-kix_yl36npzuxxn-8>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-8, lower-roman) ". "
        }

        .lst-kix_yl36npzuxxn-5>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-5, lower-roman) ". "
        }

        ol.lst-kix_wbr2z7x73lve-6.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-6 0
        }

        .lst-kix_bs8h945xvt8p-1>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-1
        }

        ol.lst-kix_shfel39lkr1e-6.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-6 0
        }

        ol.lst-kix_8q238ecyvl96-0.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-0 5
        }

        .lst-kix_yl36npzuxxn-6>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-6, decimal) ". "
        }

        ol.lst-kix_wae1ymlgm6l6-2.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-2 0
        }

        .lst-kix_8q238ecyvl96-1>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-1, lower-latin) ". "
        }

        ol.lst-kix_1w7g9wynhivh-0 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-8.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-8 0
        }

        ol.lst-kix_1w7g9wynhivh-1 {
            list-style-type: none
        }

        ol.lst-kix_1w7g9wynhivh-2 {
            list-style-type: none
        }

        .lst-kix_8q238ecyvl96-2>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-2, lower-roman) ". "
        }

        .lst-kix_8q238ecyvl96-4>li:before {
            content: "" counter(lst-ctn-kix_8q238ecyvl96-4, lower-latin) ". "
        }

        ol.lst-kix_8lu05uv13twm-1.start {
            counter-reset: lst-ctn-kix_8lu05uv13twm-1 0
        }

        ol.lst-kix_1w7g9wynhivh-7 {
            list-style-type: none
        }

        .lst-kix_rb5mt5ymao2m-6>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-6
        }

        ol.lst-kix_1w7g9wynhivh-8 {
            list-style-type: none
        }

        .lst-kix_w6rtoi8h18q4-0>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-0, decimal) ". "
        }

        .lst-kix_8q238ecyvl96-1>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-1
        }

        ol.lst-kix_1w7g9wynhivh-3 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-0.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-0 0
        }

        ol.lst-kix_1w7g9wynhivh-4 {
            list-style-type: none
        }

        ol.lst-kix_1w7g9wynhivh-5 {
            list-style-type: none
        }

        ol.lst-kix_1w7g9wynhivh-6 {
            list-style-type: none
        }

        ol.lst-kix_1vy3yzwcks66-4.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-4 0
        }

        ol.lst-kix_w6rtoi8h18q4-7.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-7 0
        }

        ol.lst-kix_5cyrqd6ve42g-3.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-3 0
        }

        ol.lst-kix_2amedsbs4wpe-0.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-0 7
        }

        .lst-kix_rb5mt5ymao2m-3>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-3
        }

        .lst-kix_1w7g9wynhivh-2>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-2
        }

        ol.lst-kix_5vj4vaup0lg-3.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-3 0
        }

        ol.lst-kix_a38rqt8hbxzi-1.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-1 0
        }

        ol.lst-kix_av44a7gw93kh-3.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-3 0
        }

        ol.lst-kix_9otmb23h2e72-7.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-7 0
        }

        .lst-kix_g9bf9rgk5fju-0>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-0, decimal) ". "
        }

        .lst-kix_mjcqlmrhlk54-5>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-5
        }

        ol.lst-kix_8q238ecyvl96-5.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-5 0
        }

        .lst-kix_w6rtoi8h18q4-1>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-1
        }

        ol.lst-kix_uqbv7hiqqgcv-7.start {
            counter-reset: lst-ctn-kix_uqbv7hiqqgcv-7 0
        }

        .lst-kix_xr9khdw9fe6q-1>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-1
        }

        ol.lst-kix_9otmb23h2e72-0.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-0 0
        }

        .lst-kix_1vy3yzwcks66-4>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-4
        }

        ol.lst-kix_g6wy5ndlov25-1.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-1 0
        }

        ol.lst-kix_shfel39lkr1e-8.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-8 0
        }

        .lst-kix_zgyf1q7d6njt-4>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-4
        }

        ol.lst-kix_wae1ymlgm6l6-7 {
            list-style-type: none
        }

        ol.lst-kix_wae1ymlgm6l6-8 {
            list-style-type: none
        }

        .lst-kix_pceibtxro1if-7>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-7
        }

        ol.lst-kix_3elc2ktxgkmv-2.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-2 0
        }

        ol.lst-kix_wae1ymlgm6l6-5 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-7 {
            list-style-type: none
        }

        ol.lst-kix_wae1ymlgm6l6-6 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-8 {
            list-style-type: none
        }

        ol.lst-kix_wae1ymlgm6l6-3 {
            list-style-type: none
        }

        ol.lst-kix_wae1ymlgm6l6-4 {
            list-style-type: none
        }

        ol.lst-kix_dgucu56m2lmv-4.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-4 0
        }

        ol.lst-kix_wae1ymlgm6l6-1 {
            list-style-type: none
        }

        ol.lst-kix_g6wy5ndlov25-3.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-3 0
        }

        ol.lst-kix_wae1ymlgm6l6-2 {
            list-style-type: none
        }

        .lst-kix_z6r466v9qacb-8>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-8
        }

        .lst-kix_2amedsbs4wpe-2>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-2
        }

        .lst-kix_easree9c2dho-2>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-2, lower-roman) ". "
        }

        ol.lst-kix_wae1ymlgm6l6-0 {
            list-style-type: none
        }

        .lst-kix_1vy3yzwcks66-7>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-7, lower-latin) ". "
        }

        ol.lst-kix_1vy3yzwcks66-7.start {
            counter-reset: lst-ctn-kix_1vy3yzwcks66-7 0
        }

        .lst-kix_nas4vziddqer-0>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-0
        }

        .lst-kix_rb5mt5ymao2m-7>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-7, lower-latin) ". "
        }

        ol.lst-kix_wbr2z7x73lve-8.start {
            counter-reset: lst-ctn-kix_wbr2z7x73lve-8 0
        }

        ol.lst-kix_z6r466v9qacb-0 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-1 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-2 {
            list-style-type: none
        }

        .lst-kix_zgyf1q7d6njt-3>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-3
        }

        ol.lst-kix_z6r466v9qacb-3 {
            list-style-type: none
        }

        ol.lst-kix_z6r466v9qacb-4 {
            list-style-type: none
        }

        .lst-kix_lhfs68ixug90-1>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-1, lower-latin) ". "
        }

        ol.lst-kix_z6r466v9qacb-5 {
            list-style-type: none
        }

        .lst-kix_a38rqt8hbxzi-8>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-8, lower-roman) ". "
        }

        ol.lst-kix_z6r466v9qacb-6 {
            list-style-type: none
        }

        .lst-kix_g9bf9rgk5fju-2>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-2, lower-roman) ". "
        }

        ol.lst-kix_easree9c2dho-8.start {
            counter-reset: lst-ctn-kix_easree9c2dho-8 0
        }

        ol.lst-kix_w6rtoi8h18q4-5.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-5 0
        }

        .lst-kix_shfel39lkr1e-6>li {
            counter-increment: lst-ctn-kix_shfel39lkr1e-6
        }

        .lst-kix_1vy3yzwcks66-5>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-5, lower-roman) ". "
        }

        .lst-kix_lhfs68ixug90-3>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-3, decimal) ". "
        }

        .lst-kix_a38rqt8hbxzi-6>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-6, decimal) ". "
        }

        .lst-kix_l2ui8c35mkpb-2>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-2, lower-roman) ". "
        }

        .lst-kix_w6rtoi8h18q4-2>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-2, lower-roman) ". "
        }

        ol.lst-kix_9otmb23h2e72-5.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-5 0
        }

        ol.lst-kix_3lt2rtjf4bk3-1.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-1 0
        }

        ol.lst-kix_8q238ecyvl96-3.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-3 0
        }

        .lst-kix_l2ui8c35mkpb-4>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-4, lower-latin) ". "
        }

        .lst-kix_a38rqt8hbxzi-0>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-0, decimal) ". "
        }

        ol.lst-kix_rb5mt5ymao2m-1.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-1 0
        }

        ol.lst-kix_1w7g9wynhivh-5.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-5 0
        }

        ol.lst-kix_z6r466v9qacb-0.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-0 6
        }

        ol.lst-kix_5cyrqd6ve42g-5.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-5 0
        }

        .lst-kix_rb5mt5ymao2m-1>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-1, lower-latin) ". "
        }

        .lst-kix_g9bf9rgk5fju-8>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-8, lower-roman) ". "
        }

        .lst-kix_dgucu56m2lmv-1>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-1
        }

        ol.lst-kix_a38rqt8hbxzi-6.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-6 0
        }

        ol.lst-kix_w6rtoi8h18q4-2.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-2 0
        }

        ol.lst-kix_9otmb23h2e72-2.start {
            counter-reset: lst-ctn-kix_9otmb23h2e72-2 0
        }

        ol.lst-kix_5vj4vaup0lg-1.start {
            counter-reset: lst-ctn-kix_5vj4vaup0lg-1 0
        }

        .lst-kix_wbr2z7x73lve-0>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-0
        }

        .lst-kix_yl36npzuxxn-3>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-3, decimal) ". "
        }

        .lst-kix_w6rtoi8h18q4-8>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-8
        }

        .lst-kix_yl36npzuxxn-6>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-6
        }

        .lst-kix_easree9c2dho-4>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-4, lower-latin) ". "
        }

        .lst-kix_g9bf9rgk5fju-3>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-3
        }

        .lst-kix_w6rtoi8h18q4-8>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-8, lower-roman) ". "
        }

        ol.lst-kix_g6wy5ndlov25-6.start {
            counter-reset: lst-ctn-kix_g6wy5ndlov25-6 0
        }

        ol.lst-kix_5cyrqd6ve42g-8.start {
            counter-reset: lst-ctn-kix_5cyrqd6ve42g-8 0
        }

        .lst-kix_j05skkyse9cl-7>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-7
        }

        ol.lst-kix_2amedsbs4wpe-6 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-7 {
            list-style-type: none
        }

        .lst-kix_1w19ehkp76hc-3>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-3
        }

        ol.lst-kix_2amedsbs4wpe-4 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-5 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-2 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-3 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-0 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-1 {
            list-style-type: none
        }

        ol.lst-kix_av44a7gw93kh-0.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-0 0
        }

        .lst-kix_1vy3yzwcks66-8>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-8
        }

        .lst-kix_rb5mt5ymao2m-2>li {
            counter-increment: lst-ctn-kix_rb5mt5ymao2m-2
        }

        ol.lst-kix_a38rqt8hbxzi-3.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-3 0
        }

        ol.lst-kix_3lt2rtjf4bk3-3.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-3 0
        }

        .lst-kix_dgucu56m2lmv-8>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-8
        }

        .lst-kix_dgucu56m2lmv-2>li {
            counter-increment: lst-ctn-kix_dgucu56m2lmv-2
        }

        ol.lst-kix_av44a7gw93kh-1.start {
            counter-reset: lst-ctn-kix_av44a7gw93kh-1 0
        }

        .lst-kix_j05skkyse9cl-7>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-7, lower-latin) ". "
        }

        ol.lst-kix_z6r466v9qacb-2.start {
            counter-reset: lst-ctn-kix_z6r466v9qacb-2 0
        }

        ol.lst-kix_dgucu56m2lmv-2.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-2 0
        }

        ol.lst-kix_1w7g9wynhivh-7.start {
            counter-reset: lst-ctn-kix_1w7g9wynhivh-7 0
        }

        ol.lst-kix_wbr2z7x73lve-6 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-3 {
            list-style-type: none
        }

        .lst-kix_u6lsy85ayutq-3>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-3, decimal) ". "
        }

        ol.lst-kix_wbr2z7x73lve-7 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-2 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-4 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-5 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-5 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-4 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-2 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-7 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-3 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-6 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-0 {
            list-style-type: none
        }

        ol.lst-kix_wbr2z7x73lve-1 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-8 {
            list-style-type: none
        }

        .lst-kix_j05skkyse9cl-0>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-0
        }

        ol.lst-kix_2amedsbs4wpe-3.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-3 0
        }

        .lst-kix_j05skkyse9cl-6>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-6
        }

        ol.lst-kix_lhfs68ixug90-8.start {
            counter-reset: lst-ctn-kix_lhfs68ixug90-8 0
        }

        ol.lst-kix_easree9c2dho-6.start {
            counter-reset: lst-ctn-kix_easree9c2dho-6 0
        }

        .lst-kix_j05skkyse9cl-1>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-1, lower-latin) ". "
        }

        .lst-kix_pceibtxro1if-0>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-0
        }

        .lst-kix_l2ui8c35mkpb-5>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-5
        }

        .lst-kix_pceibtxro1if-6>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-6
        }

        ol.lst-kix_u6lsy85ayutq-7.start {
            counter-reset: lst-ctn-kix_u6lsy85ayutq-7 0
        }

        .lst-kix_shfel39lkr1e-6>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-6, decimal) ". "
        }

        ol.lst-kix_wae1ymlgm6l6-0.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-0 0
        }

        .lst-kix_bs8h945xvt8p-8>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-8
        }

        ol.lst-kix_3elc2ktxgkmv-0.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-0 0
        }

        ol.lst-kix_wbr2z7x73lve-8 {
            list-style-type: none
        }

        ol.lst-kix_w6rtoi8h18q4-1 {
            list-style-type: none
        }

        .lst-kix_shfel39lkr1e-4>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-4, lower-latin) ". "
        }

        ol.lst-kix_w6rtoi8h18q4-0 {
            list-style-type: none
        }

        ol.lst-kix_8q238ecyvl96-2.start {
            counter-reset: lst-ctn-kix_8q238ecyvl96-2 0
        }

        ol.lst-kix_a38rqt8hbxzi-5.start {
            counter-reset: lst-ctn-kix_a38rqt8hbxzi-5 0
        }

        .lst-kix_wae1ymlgm6l6-6>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-6, decimal) ". "
        }

        .lst-kix_wae1ymlgm6l6-3>li:before {
            content: "" counter(lst-ctn-kix_wae1ymlgm6l6-3, decimal) ". "
        }

        ol.lst-kix_rb5mt5ymao2m-0.start {
            counter-reset: lst-ctn-kix_rb5mt5ymao2m-0 0
        }

        .lst-kix_xr9khdw9fe6q-7>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-7, lower-latin) ". "
        }

        .lst-kix_8q238ecyvl96-4>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-4
        }

        .lst-kix_mjcqlmrhlk54-8>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-8, lower-roman) ". "
        }

        ol.lst-kix_wae1ymlgm6l6-4.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-4 0
        }

        ol.lst-kix_shfel39lkr1e-4.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-4 0
        }

        .lst-kix_2amedsbs4wpe-6>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-6, decimal) ". "
        }

        .lst-kix_3elc2ktxgkmv-2>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-2, lower-roman) ". "
        }

        .lst-kix_u6lsy85ayutq-8>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-8, lower-roman) ". "
        }

        .lst-kix_w6rtoi8h18q4-2>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-2
        }

        .lst-kix_yl36npzuxxn-3>li {
            counter-increment: lst-ctn-kix_yl36npzuxxn-3
        }

        ol.lst-kix_pceibtxro1if-7 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-8 {
            list-style-type: none
        }

        ol.lst-kix_2amedsbs4wpe-8.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-8 0
        }

        ol.lst-kix_pceibtxro1if-5 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-6 {
            list-style-type: none
        }

        .lst-kix_5vj4vaup0lg-3>li {
            counter-increment: lst-ctn-kix_5vj4vaup0lg-3
        }

        .lst-kix_wae1ymlgm6l6-2>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-2
        }

        .lst-kix_3elc2ktxgkmv-7>li:before {
            content: "" counter(lst-ctn-kix_3elc2ktxgkmv-7, lower-latin) ". "
        }

        .lst-kix_5cyrqd6ve42g-0>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-0
        }

        .lst-kix_uqbv7hiqqgcv-6>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-6
        }

        ol.lst-kix_easree9c2dho-4.start {
            counter-reset: lst-ctn-kix_easree9c2dho-4 0
        }

        .lst-kix_2amedsbs4wpe-3>li:before {
            content: "" counter(lst-ctn-kix_2amedsbs4wpe-3, decimal) ". "
        }

        .lst-kix_wae1ymlgm6l6-6>li {
            counter-increment: lst-ctn-kix_wae1ymlgm6l6-6
        }

        .lst-kix_g6wy5ndlov25-3>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-3, decimal) ". "
        }

        .lst-kix_lhfs68ixug90-4>li {
            counter-increment: lst-ctn-kix_lhfs68ixug90-4
        }

        ol.lst-kix_easree9c2dho-3.start {
            counter-reset: lst-ctn-kix_easree9c2dho-3 0
        }

        .lst-kix_8q238ecyvl96-8>li {
            counter-increment: lst-ctn-kix_8q238ecyvl96-8
        }

        .lst-kix_5cyrqd6ve42g-7>li {
            counter-increment: lst-ctn-kix_5cyrqd6ve42g-7
        }

        ol.lst-kix_pceibtxro1if-3 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-4 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-1 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-2 {
            list-style-type: none
        }

        ol.lst-kix_pceibtxro1if-0 {
            list-style-type: none
        }

        ol.lst-kix_3lt2rtjf4bk3-0.start {
            counter-reset: lst-ctn-kix_3lt2rtjf4bk3-0 4
        }

        .lst-kix_g6wy5ndlov25-0>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-0, decimal) ". "
        }

        .lst-kix_g6wy5ndlov25-8>li:before {
            content: "" counter(lst-ctn-kix_g6wy5ndlov25-8, lower-roman) ". "
        }

        .lst-kix_5vj4vaup0lg-3>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-3, decimal) ". "
        }

        .lst-kix_nas4vziddqer-5>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-5, lower-roman) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-4.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-4 0
        }

        .lst-kix_5vj4vaup0lg-6>li:before {
            content: "" counter(lst-ctn-kix_5vj4vaup0lg-6, decimal) ". "
        }

        .lst-kix_3lt2rtjf4bk3-6>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-6, decimal) ". "
        }

        .lst-kix_dgucu56m2lmv-5>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-5, lower-roman) ". "
        }

        .lst-kix_1vy3yzwcks66-1>li {
            counter-increment: lst-ctn-kix_1vy3yzwcks66-1
        }

        .lst-kix_g9bf9rgk5fju-7>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-7
        }

        ol.lst-kix_wae1ymlgm6l6-3.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-3 0
        }

        ol.lst-kix_2amedsbs4wpe-7.start {
            counter-reset: lst-ctn-kix_2amedsbs4wpe-7 0
        }

        .lst-kix_mjcqlmrhlk54-2>li {
            counter-increment: lst-ctn-kix_mjcqlmrhlk54-2
        }

        .lst-kix_k9i9cgazomij-2>li {
            counter-increment: lst-ctn-kix_k9i9cgazomij-2
        }

        .lst-kix_xr9khdw9fe6q-2>li:before {
            content: "" counter(lst-ctn-kix_xr9khdw9fe6q-2, lower-roman) ". "
        }

        ol.lst-kix_dgucu56m2lmv-0.start {
            counter-reset: lst-ctn-kix_dgucu56m2lmv-0 0
        }

        ol.lst-kix_shfel39lkr1e-5.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-5 0
        }

        ol.lst-kix_3elc2ktxgkmv-3.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-3 0
        }

        .lst-kix_nas4vziddqer-2>li:before {
            content: "" counter(lst-ctn-kix_nas4vziddqer-2, lower-roman) ". "
        }

        .lst-kix_3lt2rtjf4bk3-1>li:before {
            content: "" counter(lst-ctn-kix_3lt2rtjf4bk3-1, lower-latin) ". "
        }

        .lst-kix_xr9khdw9fe6q-4>li {
            counter-increment: lst-ctn-kix_xr9khdw9fe6q-4
        }

        .lst-kix_a38rqt8hbxzi-5>li {
            counter-increment: lst-ctn-kix_a38rqt8hbxzi-5
        }

        .lst-kix_lhfs68ixug90-6>li:before {
            content: "" counter(lst-ctn-kix_lhfs68ixug90-6, decimal) ". "
        }

        .lst-kix_1vy3yzwcks66-2>li:before {
            content: "" counter(lst-ctn-kix_1vy3yzwcks66-2, lower-roman) ". "
        }

        .lst-kix_1w19ehkp76hc-0>li {
            counter-increment: lst-ctn-kix_1w19ehkp76hc-0
        }

        .lst-kix_bs8h945xvt8p-4>li {
            counter-increment: lst-ctn-kix_bs8h945xvt8p-4
        }

        .lst-kix_rb5mt5ymao2m-4>li:before {
            content: "" counter(lst-ctn-kix_rb5mt5ymao2m-4, lower-latin) ". "
        }

        ol.lst-kix_w6rtoi8h18q4-8.start {
            counter-reset: lst-ctn-kix_w6rtoi8h18q4-8 0
        }

        .lst-kix_u6lsy85ayutq-8>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-8
        }

        ol.lst-kix_wae1ymlgm6l6-8.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-8 0
        }

        .lst-kix_dgucu56m2lmv-2>li:before {
            content: "" counter(lst-ctn-kix_dgucu56m2lmv-2, lower-roman) ". "
        }

        .lst-kix_w6rtoi8h18q4-5>li:before {
            content: "" counter(lst-ctn-kix_w6rtoi8h18q4-5, lower-roman) ". "
        }

        .lst-kix_g9bf9rgk5fju-5>li:before {
            content: "" counter(lst-ctn-kix_g9bf9rgk5fju-5, lower-roman) ". "
        }

        .lst-kix_a38rqt8hbxzi-3>li:before {
            content: "" counter(lst-ctn-kix_a38rqt8hbxzi-3, decimal) ". "
        }

        .lst-kix_3elc2ktxgkmv-4>li {
            counter-increment: lst-ctn-kix_3elc2ktxgkmv-4
        }

        ol.lst-kix_wae1ymlgm6l6-5.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-5 0
        }

        .lst-kix_yl36npzuxxn-0>li:before {
            content: "" counter(lst-ctn-kix_yl36npzuxxn-0, decimal) ". "
        }

        .lst-kix_w6rtoi8h18q4-5>li {
            counter-increment: lst-ctn-kix_w6rtoi8h18q4-5
        }

        .lst-kix_1w7g9wynhivh-6>li {
            counter-increment: lst-ctn-kix_1w7g9wynhivh-6
        }

        .lst-kix_g9bf9rgk5fju-0>li {
            counter-increment: lst-ctn-kix_g9bf9rgk5fju-0
        }

        .lst-kix_easree9c2dho-7>li:before {
            content: "" counter(lst-ctn-kix_easree9c2dho-7, lower-latin) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-5.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-5 0
        }

        ol.lst-kix_3elc2ktxgkmv-8.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-8 0
        }

        .lst-kix_l2ui8c35mkpb-1>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-1
        }

        .lst-kix_l2ui8c35mkpb-7>li:before {
            content: "" counter(lst-ctn-kix_l2ui8c35mkpb-7, lower-latin) ". "
        }

        .lst-kix_uqbv7hiqqgcv-3>li {
            counter-increment: lst-ctn-kix_uqbv7hiqqgcv-3
        }

        ol.lst-kix_shfel39lkr1e-0.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-0 0
        }

        .lst-kix_k9i9cgazomij-2>li:before {
            content: "" counter(lst-ctn-kix_k9i9cgazomij-2, lower-roman) ". "
        }

        .lst-kix_wbr2z7x73lve-3>li {
            counter-increment: lst-ctn-kix_wbr2z7x73lve-3
        }

        .lst-kix_2amedsbs4wpe-5>li {
            counter-increment: lst-ctn-kix_2amedsbs4wpe-5
        }

        .lst-kix_shfel39lkr1e-1>li:before {
            content: "" counter(lst-ctn-kix_shfel39lkr1e-1, lower-latin) ". "
        }

        .lst-kix_easree9c2dho-2>li {
            counter-increment: lst-ctn-kix_easree9c2dho-2
        }

        .lst-kix_zgyf1q7d6njt-7>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-7
        }

        ol.lst-kix_shfel39lkr1e-3.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-3 0
        }

        .lst-kix_j05skkyse9cl-4>li:before {
            content: "" counter(lst-ctn-kix_j05skkyse9cl-4, lower-latin) ". "
        }

        ol.lst-kix_3elc2ktxgkmv-7.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-7 0
        }

        ol.lst-kix_easree9c2dho-0.start {
            counter-reset: lst-ctn-kix_easree9c2dho-0 0
        }

        .lst-kix_9otmb23h2e72-5>li {
            counter-increment: lst-ctn-kix_9otmb23h2e72-5
        }

        .lst-kix_z6r466v9qacb-4>li {
            counter-increment: lst-ctn-kix_z6r466v9qacb-4
        }

        ol.lst-kix_shfel39lkr1e-2.start {
            counter-reset: lst-ctn-kix_shfel39lkr1e-2 0
        }

        .lst-kix_1w19ehkp76hc-1>li:before {
            content: "" counter(lst-ctn-kix_1w19ehkp76hc-1, lower-latin) ". "
        }

        .lst-kix_j05skkyse9cl-3>li {
            counter-increment: lst-ctn-kix_j05skkyse9cl-3
        }

        ol.lst-kix_3elc2ktxgkmv-6.start {
            counter-reset: lst-ctn-kix_3elc2ktxgkmv-6 0
        }

        .lst-kix_u6lsy85ayutq-0>li:before {
            content: "" counter(lst-ctn-kix_u6lsy85ayutq-0, decimal) ". "
        }

        .lst-kix_pceibtxro1if-1>li:before {
            content: "" counter(lst-ctn-kix_pceibtxro1if-1, lower-latin) ". "
        }

        ol.lst-kix_wae1ymlgm6l6-6.start {
            counter-reset: lst-ctn-kix_wae1ymlgm6l6-6 0
        }

        .lst-kix_zgyf1q7d6njt-0>li {
            counter-increment: lst-ctn-kix_zgyf1q7d6njt-0
        }

        .lst-kix_mjcqlmrhlk54-5>li:before {
            content: "" counter(lst-ctn-kix_mjcqlmrhlk54-5, lower-roman) ". "
        }

        .lst-kix_pceibtxro1if-3>li {
            counter-increment: lst-ctn-kix_pceibtxro1if-3
        }

        .lst-kix_l2ui8c35mkpb-8>li {
            counter-increment: lst-ctn-kix_l2ui8c35mkpb-8
        }

        .lst-kix_nas4vziddqer-4>li {
            counter-increment: lst-ctn-kix_nas4vziddqer-4
        }

        .lst-kix_u6lsy85ayutq-1>li {
            counter-increment: lst-ctn-kix_u6lsy85ayutq-1
        }

        .lst-kix_g6wy5ndlov25-0>li {
            counter-increment: lst-ctn-kix_g6wy5ndlov25-0
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c6 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c16 {
            margin-left: 18pt;
            padding-top: 1pt;
            padding-bottom: 5pt;
            line-height: 0.06818181818181818;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 11pt
        }

        .c10 {
            margin-left: 72pt;
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c8 {
            margin-left: 36pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c7 {
            margin-left: 144pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            margin-left: 72pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            margin-left: 108pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c17 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c22 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c14 {
            color: #00000a;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c21 {
            padding-top: 12pt;
            padding-bottom: 0pt;
            line-height: 0.06818181818181818;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c13 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 17pt;
            font-family: "Arial";
            font-style: normal
        }

        .c4 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c19 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c15 {
            background-color: #ffffff;
            max-width: 451.4pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c5 {
            padding: 0;
            margin: 0
        }

        .c9 {
            color: inherit;
            text-decoration: inherit
        }

        .c12 {
            font-style: italic
        }

        .c20 {
            vertical-align: super
        }

        .c18 {
            color: #ff0000
        }

        .c3 {
            font-weight: 700
        }

        .c11 {
            color: #374151
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c15 doc-content">
    <p class="c21"><span class="c4 c3">REGULAMIN</span></p>
    <ol class="c5 lst-kix_5vj4vaup0lg-0 start" start="1">
        <li class="c8 li-bullet-0"><span class="c4 c3">Postanowienia Og&oacute;lne</span></li>
    </ol>
    <p class="c16"><span class="c4 c3"><br></span></p>
    <ol class="c5 lst-kix_zgyf1q7d6njt-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Niniejszy regulamin okre&#347;la rodzaj, zakres i warunki
                &#347;wiadczenia Us&#322;ug drog&#261; elektroniczn&#261; za po&#347;rednictwem Serwisu, w tym zasady
                korzystania z Systemu.</span></li>
        <li class="c1 li-bullet-0"><span>W&#322;a&#347;cicielem Serwisu jest &bdquo;</span><span class="c3">Flathub
                sp&oacute;&#322;ka z ograniczon&#261; odpowiedzialno&#347;ci&#261;</span><span class="c0">&rdquo;, adres
                e-mail: kontakt@gaius-lex.pl</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Korzystanie z Serwisu i &#347;wiadczonych za jego po&#347;rednictwem
                Us&#322;ug wymaga uprzedniego zapoznania si&#281; z tre&#347;ci&#261; Regulaminu i jego akceptacji. Brak
                akceptacji Regulaminu uniemo&#380;liwia z&#322;o&#380;enie zam&oacute;wienia i korzystanie z
                Us&#322;ug.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Z uwagi na charakter Us&#322;ug Regulamin nie ma zastosowania do
                Konsument&oacute;w.</span></li>
    </ol>
    <ol class="c5 lst-kix_1w19ehkp76hc-0 start" start="2">
        <li class="c8 li-bullet-0"><span class="c3">Definicje</span><span class="c0">:<br><br>Poj&#281;ciom u&#380;ytym
                w niniejszym Regulaminie nadaje si&#281; nast&#281;puj&#261;ce znaczenia:<br></span></li>
    </ol>
    <ol class="c5 lst-kix_wae1ymlgm6l6-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c3">Aplikacja przegl&#261;darkowa</span><span class="c0">&nbsp;-
                oprogramowanie przeznaczone do u&#380;ytku w przegl&#261;darce internetowej na r&oacute;&#380;nych
                urz&#261;dzeniach, takich jak komputery osobiste, laptopy, tablety czy smartfony. Aplikacje te s&#261;
                dost&#281;pne i uruchamiane bezpo&#347;rednio z przegl&#261;darki internetowej;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Cennik </span><span class="c0">- zestawienie informacji o cenach
                Us&#322;ug &#347;wiadczonych przez Us&#322;ugodawc&#281; dost&#281;pne w Serwisie w zak&#322;adce
                &bdquo;P&#322;atno&#347;ci&rdquo;, stanowi&#261;ce integraln&#261; cz&#281;&#347;&#263;
                Regulaminu;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Konsument</span><span>&nbsp;- osoba fizyczna dokonuj&#261;ca z
                przedsi&#281;biorc&#261; czynno&#347;ci prawnej niezwi&#261;zanej bezpo&#347;rednio z jej
                dzia&#322;alno&#347;ci&#261; gospodarcz&#261; lub zawodow&#261; oraz przedsi&#281;biorca prowadz&#261;cy
                jednoosobow&#261; dzia&#322;alno&#347;&#263; gospodarcz&#261; zawieraj&#261;cy umowy niemaj&#261;ce dla
                niego charakteru zawodowego, kt&oacute;remu przepisy prawa (w tym ustawa o prawach konsumenta
                i</span><span class="c20">&nbsp;</span><span class="c0">kodeks cywilny) przyznaj&#261; uprawnienia o
                charakterze konsumenckim;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Konto Us&#322;ugobiorcy/Konto</span><span class="c0">&nbsp;-
                cz&#281;&#347;&#263; Serwisu stanowi&#261;ca zbi&oacute;r zasob&oacute;w i uprawnie&#324; przypisanych
                konkretnemu Us&#322;ugobiorcy, dost&#281;pna po zalogowaniu si&#281; przy u&#380;yciu loginu i
                has&#322;a, za po&#347;rednictwem kt&oacute;rej Us&#322;ugobiorca ma dost&#281;p do Systemu i mo&#380;e
                korzysta&#263; z Us&#322;ug;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Okres rozliczeniowy</span><span class="c0">&nbsp;- okres, za
                kt&oacute;ry dokonywane s&#261; rozliczenia zobowi&#261;za&#324; Us&#322;ugobiorcy<br>z tytu&#322;u
                Us&#322;ug &#347;wiadczonych w trybie abonamentowym rozpoczynaj&#261;cy si&#281; w dniu dokonania
                p&#322;atno&#347;ci a ko&#324;cz&#261;cy si&#281; miesi&#261;c p&oacute;&#378;niej;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Op&#322;ata abonamentowa</span><span class="c0">&nbsp;- op&#322;ata
                uiszczana przez Us&#322;ugobiorc&#281; w wysoko&#347;ci okre&#347;lonej w Cenniku tytu&#322;em
                korzystania z Us&#322;ug w danym Okresie rozliczeniowym;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Przedsi&#281;biorca</span><span class="c0">&nbsp;&ndash; osoba
                fizyczna nieb&#281;d&#261;ca Konsumentem, osoba prawna lub jednostka organizacyjna nieb&#281;d&#261;ca
                osob&#261; prawn&#261;, kt&oacute;rej odr&#281;bna ustawa przyznaje zdolno&#347;&#263; prawn&#261;,
                wykonuj&#261;ca dzia&#322;alno&#347;&#263; gospodarcz&#261;, w tym tak&#380;e wsp&oacute;lnicy
                sp&oacute;&#322;ki cywilnej w zakresie wykonywanej przez nich dzia&#322;alno&#347;ci
                gospodarczej;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Regulamin</span><span class="c0">&nbsp;- niniejszy regulamin
                &#347;wiadczenia us&#322;ug drog&#261; elektroniczn&#261; stanowi&#261;cy wzorzec umowy i
                b&#281;d&#261;cy jednocze&#347;nie wype&#322;nieniem obowi&#261;zku, o kt&oacute;rym mowa w art. 8 ust.
                1 pkt 1 Ustawy o &#347;wiadczeniu us&#322;ug drog&#261; elektroniczn&#261;;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">RODO</span><span class="c0">&nbsp;- rozporz&#261;dzenie Parlamentu
                Europejskiego i Rady (UE) 2016/679 z dnia<br>27 kwietnia 2016 r. w sprawie ochrony os&oacute;b
                fizycznych w zwi&#261;zku z przetwarzaniem danych osobowych i w sprawie swobodnego przep&#322;ywu takich
                danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporz&#261;dzenie o ochronie danych);</span>
        </li>
        <li class="c1 li-bullet-0"><span class="c3">Serwis</span><span>&nbsp;- zorganizowana platforma
                informatyczno-informacyjna umo&#380;liwiaj&#261;ca Us&#322;ugobiorcy korzystanie Us&#322;ug oraz
                dost&#281;p do tre&#347;ci cyfrowych opracowanych przez Us&#322;ugodawc&#281; dost&#281;pna pod adresem
                domeny</span><span class="c19"><a class="c9"
                    href="https://gaius-lex.pl">&nbsp;gaius-lex.pl</a></span><span
                class="c0">&nbsp;oraz adresami jej subdomen;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">System</span><span>&nbsp;- rozwi&#261;zanie informatyczne
                b&#281;d&#261;ce oprogramowaniem wraz elementami nieb&#281;d&#261;cymi programem komputerowym
            </span><span>dost&#281;pne w modelu SaaS (Software as a Service</span><span>) dostarczane przez
                Us&#322;ugodawc&#281; Us&#322;ugobiorcy za po&#347;rednictwem Serwisu na podstawie Umowy </span><span
                class="c11">&#347;wiadczenia us&#322;ug informatycznych</span><span class="c0">.;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">&#346;wiadczenie us&#322;ugi drog&#261;
                elektroniczn&#261;</span><span class="c0">&nbsp;- wykonanie Us&#322;ugi &#347;wiadczonej bez
                jednoczesnej obecno&#347;ci stron (na odleg&#322;o&#347;&#263;) na rzecz Us&#322;ugobiorcy, poprzez
                przekaz danych na indywidualne &#380;&#261;danie Us&#322;ugobiorcy, przesy&#322;anej i otrzymywanej za
                pomoc&#261; urz&#261;dze&#324; do elektronicznego przetwarzania, w&#322;&#261;cznie z kompresj&#261;
                cyfrow&#261;, i przechowywania danych, kt&oacute;ra jest w ca&#322;o&#347;ci nadawana, odbierana lub
                transmitowana za pomoc&#261; sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca 2004 r. Prawo
                telekomunikacyjne;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Umowa</span><span class="c0">&nbsp;- umowa o &#347;wiadczenie
                Us&#322;ug drog&#261; elektroniczn&#261; zawierana na odleg&#322;o&#347;&#263; pomi&#281;dzy
                Us&#322;ugodawc&#261; a Us&#322;ugobiorc&#261;, kt&oacute;rej przedmiot i tre&#347;&#263;
                okre&#347;laj&#261; postanowienia niniejszego Regulaminu, Cennik oraz informacje o dost&#281;pnych
                Us&#322;ugach dost&#281;pne w Serwisie;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Us&#322;ugi</span><span class="c0">&nbsp;- bezp&#322;atne i
                odp&#322;atne us&#322;ugi &#346;wiadczone drog&#261; elektroniczn&#261; za po&#347;rednictwem Serwisu
                przez Us&#322;ugodawc&#281; na rzecz Us&#322;ugobiorcy, polegaj&#261;ce na zapewnieniu przez
                Us&#322;ugodawc&#281; Us&#322;ugobiorcy dost&#281;pu do Systemu i jego funkcjonalno&#347;ci oraz jego
                prawid&#322;owego dzia&#322;ania;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Us&#322;ugobiorca</span><span class="c0">&nbsp;&ndash;
                Przedsi&#281;biorca, kt&oacute;ry korzysta z Us&#322;ug na podstawie Umowy w celach zwi&#261;zanych
                bezpo&#347;rednio z jego dzia&#322;alno&#347;ci&#261; gospodarcz&#261; lub zawodow&#261;;</span></li>
        <li class="c1 li-bullet-0"><span class="c3">Us&#322;ugodawca</span><span>&nbsp;&ndash; podmiot
                &#347;wiadcz&#261;cy Us&#322;ugi drog&#261; elektroniczn&#261; na podstawie Regulaminu, Cennika i
                informacji o Us&#322;ugach dost&#281;pnych w Serwisie, tj.</span><span class="c3">&nbsp;Flathub
                Sp&oacute;&#322;ka z ograniczon&#261; odpowiedzialno&#347;ci&#261;, Krak&oacute;w, ul. Bronowicka 38/5,
                30-091, Numer KRS: </span><span class="c3">0001009006, NIP: 6772488192, REGON: 52396307300000.</span>
        </li>
        <li class="c1 li-bullet-0"><span class="c3">Ustawa o &#347;wiadczeniu us&#322;ug drog&#261;
                elektroniczn&#261;</span><span>&nbsp;- ustawa z dnia 18 lipca 2002 r.<br>o &#347;wiadczeniu us&#322;ug
                drog&#261; elektroniczn&#261; (tj. z dnia 3 marca 2020 r.</span><span><a class="c9"
                    href="https://sip.legalis.pl/document-view.seam?documentId%3Dmfrxilrtg4ytgmrqhe3tg">&nbsp;</a></span><span
                class="c19"><a class="c9"
                    href="https://sip.legalis.pl/document-view.seam?documentId%3Dmfrxilrtg4ytgmrqhe3tg">(Dz.U.2020.344)</a></span>
        </li>
    </ol>
    <p class="c10"><span class="c6"></span></p>
    <ol class="c5 lst-kix_j05skkyse9cl-0 start" start="3">
        <li class="c8 li-bullet-0"><span class="c4 c3">Wymagania techniczne</span></li>
    </ol>
    <ol class="c5 lst-kix_8lu05uv13twm-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Korzystanie z Serwisu, w tym w szczeg&oacute;lno&#347;ci korzystanie
                z Us&#322;ug, wymaga spe&#322;nienia minimalnych wymaga&#324; technicznych niezb&#281;dnych do
                wsp&oacute;&#322;pracy z Systemem, tj.:</span></li>
    </ol>
    <ol class="c5 lst-kix_pceibtxro1if-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">dysponowanie sprawnym urz&#261;dzeniem posiadaj&#261;cym
                po&#322;&#261;czenie z sieci&#261; Internet (np. komputer, tablet, smartphone),</span></li>
        <li class="c1 li-bullet-0"><span>sta&#322;e &#322;&#261;cze internetowe o przepustowo&#347;ci minimum
            </span><span class="c3">[2Mb/s]</span><span class="c4 c3">,</span></li>
        <li class="c1 li-bullet-0"><span>aktualna i poprawnie skonfigurowana wersja przegl&#261;darek internetowych:
            </span><span class="c3">Google Chrome</span><span class="c0">,</span></li>
        <li class="c1 li-bullet-0"><span class="c0">oprogramowanie umo&#380;liwiaj&#261;ce prawid&#322;owe
                wy&#347;wietlanie dokument&oacute;w PDF,</span></li>
        <li class="c1 li-bullet-0"><span class="c0">posiadanie aktywnego adresu poczty elektronicznej,</span></li>
        <li class="c1 li-bullet-0"><span class="c0">posiadanie aktywnego numeru telefonu w celu weryfikacji
                klienta,</span></li>
        <li class="c1 li-bullet-0"><span>procesor </span><span class="c3">2 rdzeniowy</span><span class="c4 c3">,</span>
        </li>
        <li class="c1 li-bullet-0"><span>pami&#281;&#263; </span><span class="c3">8 GB</span><span
                class="c4 c3">,</span></li>
        <li class="c1 li-bullet-0"><span>w&#322;&#261;czona obs&#322;uga plik&oacute;w cookies i
            </span><span>Javascript</span><span class="c0">.</span></li>
    </ol>
    <ol class="c5 lst-kix_l2ui8c35mkpb-0 start" start="4">
        <li class="c8 li-bullet-0"><span class="c4 c3">Korzystanie z Serwisu</span></li>
    </ol>
    <ol class="c5 lst-kix_g6wy5ndlov25-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">W ramach Serwisu Us&#322;ugodawca umo&#380;liwia
                Us&#322;ugobiorcy:</span></li>
    </ol>
    <ol class="c5 lst-kix_mjcqlmrhlk54-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">zapoznanie si&#281; z informacjami dotycz&#261;cymi zakresu i
                warunk&oacute;w &#347;wiadczenia Us&#322;ug,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">za&#322;o&#380;enie i prowadzenie Konta Us&#322;ugobiorcy,</span>
        </li>
        <li class="c2 li-bullet-0"><span class="c0">sk&#322;adanie zam&oacute;wie&#324; na Pakiety Us&#322;ug i
                korzystanie z Us&#322;ug,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">przeprowadzenie rozmowy z Us&#322;ugodawc&#261; na chacie
                online.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">wyszukiwanie oraz zadawanie pyta&#324; do orzecze&#324;,
                kodeks&oacute;w i innych og&oacute;lnodost&#281;pnych dokument&oacute;w.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">wgrywanie i przeszukiwanie oraz zadawanie pyta&#324; do
                w&#322;asnych dokument&oacute;w we wspieranych formatach:</span></li>
    </ol>
    <ol class="c5 lst-kix_mjcqlmrhlk54-2 start" start="1">
        <li class="c7 li-bullet-0"><span class="c0">pdf</span></li>
        <li class="c7 li-bullet-0"><span class="c0">txt</span></li>
        <li class="c7 li-bullet-0"><span class="c0">jpg</span></li>
        <li class="c7 li-bullet-0"><span class="c0">png</span></li>
        <li class="c7 li-bullet-0"><span class="c0">jpeg</span></li>
        <li class="c7 li-bullet-0"><span class="c0">gif</span></li>
        <li class="c7 li-bullet-0"><span class="c0">bmp</span></li>
        <li class="c7 li-bullet-0"><span class="c0">mp3</span></li>
        <li class="c7 li-bullet-0"><span class="c0">wav</span></li>
        <li class="c7 li-bullet-0"><span class="c0">m4a</span></li>
        <li class="c7 li-bullet-0"><span class="c0">flac</span></li>
        <li class="c7 li-bullet-0"><span class="c0">ac3</span></li>
    </ol>
    <ol class="c5 lst-kix_mjcqlmrhlk54-0" start="7">
        <li class="c2 li-bullet-0"><span class="c0">Rozmowa z czatem prawnym</span></li>
    </ol>
    <ol class="c5 lst-kix_g9bf9rgk5fju-0 start" start="2">
        <li class="c1 li-bullet-0"><span class="c0">W ramach &#347;wiadczonych us&#322;ug, Us&#322;ugodawca mo&#380;e
                przesy&#322;a&#263; dane, w tym dane U&#380;ytkownik&oacute;w, do OpenAI Ltd. oraz OctoAI w celu ich
                przetwarzania. Dane te mog&#261; obejmowa&#263;, mi&#281;dzy innymi, tre&#347;ci generowane przez
                U&#380;ytkownik&oacute;w w ramach korzystania z us&#322;ug.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Akceptuj&#261;c niniejszy Regulamin, Us&#322;ugobiorca
                zobowi&#261;zuje si&#281; w szczeg&oacute;lno&#347;ci do:</span></li>
    </ol>
    <ol class="c5 lst-kix_dgucu56m2lmv-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">respektowania zakazu dostarczania tre&#347;ci o charakterze
                bezprawnym, przez co nale&#380;y rozumie&#263; m.in.: zakaz umieszczania tre&#347;ci obra&#378;liwych,
                nieprawdziwych, niemoralnych, naruszaj&#261;cych dobre obyczaje, propaguj&#261;cych przemoc i
                nienawi&#347;&#263;,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">niepodejmowania dzia&#322;a&#324; zmierzaj&#261;cych do
                zak&#322;&oacute;cenia funkcjonowania Serwisu i wej&#347;cia<br>w posiadanie informacji oraz danych
                nieprzeznaczonych dla Us&#322;ugobiorcy,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">niepodejmowania innych dzia&#322;a&#324;, kt&oacute;re by&#322;yby
                niezgodne z obowi&#261;zuj&#261;cymi przepisami prawem, dobrymi obyczajami lub narusza&#322;yby prawa i
                dobra osobiste Us&#322;ugodawcy oraz os&oacute;b trzecich.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">Us&#322;ugobiorca zobowi&#261;zuje si&#281; korzysta&#263; z
                tre&#347;ci zamieszczanych w Serwisie wy&#322;&#261;cznie na w&#322;asny u&#380;ytek i w celu realizacji
                zawartej Umowy. U&#380;ywanie zasob&oacute;w i funkcji Serwisu w innych celach wymaga odr&#281;bnej,
                uprzedniej i pisemnej zgody Us&#322;ugobiorcy.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">W przypadku, gdy Us&#322;ugobiorca korzystaj&#261;c z Serwisu
                dopu&#347;ci si&#281; narusze&#324; Regulaminu lub obowi&#261;zuj&#261;cych przepis&oacute;w prawa, a
                tak&#380;e gdy w inny spos&oacute;b dzia&#322;a na szkod&#281; Us&#322;ugodawcy<br>i/lub os&oacute;b
                trzecich, Us&#322;ugodawca mo&#380;e ograniczy&#263; jego dost&#281;p lub pozbawi&#263; go dost&#281;pu
                do Serwisu ze skutkiem natychmiastowym. O zastosowanych &#347;rodkach Us&#322;ugobiorca zostanie
                niezw&#322;ocznie poinformowany. Uzyskanie powt&oacute;rnego dost&#281;pu do Serwisu wymaga uprzedniej
                zgody Us&#322;ugodawcy.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">Us&#322;ugobiorca zobowi&#261;zuje si&#281; do nieudost&#281;pniania
                swojego konta osobom trzecim.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">W przypadku zaj&#347;cia zdarzenia, o kt&oacute;rym mowa w ust. 4
                powy&#380;ej Us&#322;ugobiorca zobowi&#261;zany jest podj&#261;&#263; niezw&#322;ocznie dzia&#322;ania w
                celu usuni&#281;cia przyczyny ograniczenia lub zablokowania dost&#281;pu do Konta Us&#322;ugobiorcy, w
                przeciwnym wypadku Us&#322;ugodawca jest uprawniony do rozwi&#261;zania Umowy ze skutkiem
                natychmiastowym bez wypowiedzenia.</span></li>
        <li class="c2 li-bullet-0"><span>Us&#322;ugodawca &#347;wiadczy Us&#322;ugi w spos&oacute;b nieprzerwany
            </span><span class="c3">7</span><span class="c0">&nbsp;dni w tygodniu z zastrze&#380;eniem postanowie&#324;
                ust. 9 poni&#380;ej.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">Us&#322;ugodawca zastrzega sobie prawo do czasowego zawieszenia
                &#347;wiadczenia Us&#322;ug w ramach Systemu w zwi&#261;zku z konieczno&#347;ci&#261; przeprowadzenia
                prac serwisowych lub naprawczych, jak r&oacute;wnie&#380; w zwi&#261;zku z modernizacj&#261; lub
                przebudow&#261; Systemu. Us&#322;ugodawca do&#322;o&#380;y stara&#324;, aby przerwy w &#347;wiadczeniu
                Us&#322;ug nie by&#322;y uci&#261;&#380;liwe dla Us&#322;ugobiorcom. Informacja o planowanym czasowym
                zawieszeniu pracy Systemu b&#281;dzie przekazywana Us&#322;ugobiorcom drog&#261; elektroniczn&#261; na
                podany przy rejestracji adres e-mail lub za po&#347;rednictwem Konta Us&#322;ugobiorcy.</span></li>
        <li class="c2 li-bullet-0"><span class="c0">Us&#322;ugodawca nie ponosi odpowiedzialno&#347;ci za przerwy w
                &#347;wiadczeniu Us&#322;ug lub zak&#322;&oacute;cenia w dost&#281;pie do Serwisu g wywo&#322;ane
                si&#322;&#261; wy&#380;sz&#261; (po&#380;ar, kl&#281;ski &#380;ywio&#322;owe, epidemia), awariami
                sprz&#281;tu Us&#322;ugobiorcy, przerwami w dostawie pr&#261;du czy &#322;&#261;czno&#347;ci z
                Internetem, nieuprawnion&#261; ingerencj&#261; Us&#322;ugobiorc&oacute;w lub os&oacute;b trzecich czy
                te&#380; b&#322;&#281;dnym dzia&#322;aniem system&oacute;w telekomunikacyjnych i oprogramowania
                zainstalowanego na sprz&#281;cie komputerowym Us&#322;ugobiorc&oacute;w.</span></li>
    </ol>
    <ol class="c5 lst-kix_3lt2rtjf4bk3-0 start" start="5">
        <li class="c8 li-bullet-0"><span class="c4 c3">Konto Us&#322;ugobiorcy</span></li>
    </ol>
    <ol class="c5 lst-kix_yl36npzuxxn-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Rejestracja i prowadzenie Konta Us&#322;ugobiorcy s&#261;
                bezp&#322;atne.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Zarejestrowanie Konta Us&#322;ugobiorcy jest mo&#380;liwe poprzez
                wype&#322;nienie dost&#281;pnego w Serwisie formularza rejestracji oraz podanie nast&#281;puj&#261;cych
                danych zgodnych ze stanem faktycznym:</span></li>
    </ol>
    <ol class="c5 lst-kix_yl36npzuxxn-1 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">email</span></li>
        <li class="c2 li-bullet-0"><span class="c0">numer telefonu</span></li>
    </ol>
    <ol class="c5 lst-kix_yl36npzuxxn-0" start="3">
        <li class="c1 li-bullet-0"><span class="c0">Do zawarcia umowy prowadzenia Konta pomi&#281;dzy
                Us&#322;ugodawc&#261; a Us&#322;ugobiorc&#261; dochodzi z chwil&#261; jego aktywacji przez
                Us&#322;ugobiorc&#281;, kt&oacute;ra nast&#281;puje poprzez klikni&#281;cie w przycisk zarejestruj
                podczas rejestracji konta.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Zalogowanie si&#281; do Serwisu wymaga podania loginu oraz
                has&#322;a. Loginem jest adres e-mail U&#380;ytkownika. Has&#322;o ustalane jest przez
                Us&#322;ugobiorc&#281; w procesie rejestracji Konta.</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugobiorca zobowi&#261;zuje si&#281; do utrzymania w poufno&#347;ci
                informacji umo&#380;liwiaj&#261;cych dost&#281;p do Konta Us&#322;ugobiorcy. W celu zachowania
                bezpiecze&#324;stwa logowania Us&#322;ugodawca zaleca zmian&#281; has&#322;a logowania co
            </span><span>30 dni.</span><span class="c0">&nbsp;Us&#322;ugodawca nie ponosi odpowiedzialno&#347;ci za
                skutki udost&#281;pnienia has&#322;a przez Us&#322;ugobiorc&#281; osobom trzecim.</span></li>
        <li class="c1 li-bullet-0"><span>Konto zak&#322;adane jest na czas nieokre&#347;lony, przy czym
                Us&#322;ugobiorca jest uprawniony do likwidacji Konta w ka&#380;dym czasie bez podania przyczyny. W tym
                celu nale&#380;y </span><span>skontaktowa&#263; si&#281; z obs&#322;ug&#261; klienta.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca jest uprawniony do zablokowania Konta w sytuacji, o
                kt&oacute;rej mowa w &sect; 4.3 ust. 4 Regulaminu oraz &sect; 4.3 ust. 6, lub usuni&#281;cia Konta w
                sytuacji, o kt&oacute;rej mowa w &sect; 4.3 ust. 5 Regulaminu.</span></li>
        <li class="c1 li-bullet-0"><span>Zmiana, b&#261;d&#378; odzyskiwanie has&#322;a mo&#380;liwa jest poprzez
                funkcje odzyskiwania, b&#261;d&#378; zmian&#281; has&#322;a dost&#281;pn&#261; w </span><span
                class="c0">serwisie lub za pomoc&#261; obs&#322;ugi klienta.</span></li>
    </ol>
    <ol class="c5 lst-kix_8q238ecyvl96-0 start" start="6">
        <li class="c8 li-bullet-0"><span class="c4 c3">Korzystanie z Us&#322;ug</span></li>
    </ol>
    <ol class="c5 lst-kix_rb5mt5ymao2m-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Korzystanie z Us&#322;ug jest mo&#380;liwie po uprzednim
                zarejestrowaniu w Serwisie Konta Us&#322;ugobiorcy, zaakceptowaniu Regulaminu i Polityki
                Prywatno&#347;ci, zapoznaniu si&#281; z Cennikiem i informacjami o dost&#281;pnych Us&#322;ugach
                zamieszczonymi w Serwisie oraz w przypadku us&#322;ug odp&#322;atnych &ndash; po uiszczeniu Op&#322;aty
                abonamentowej.</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugobiorcy mog&#261; korzysta&#263; z Us&#322;ug bezp&#322;atnie &ndash;
                w ramach darmowego dost&#281;pu do Us&#322;ug przez </span><span class="c3">7</span><span
                class="c3">&nbsp;</span><span class="c0">dni oraz odp&#322;atnie &ndash; w ramach wybranego wariantu
                Us&#322;ug odp&#322;atnych zgodnie z Cennikiem.</span></li>
        <li class="c1 li-bullet-0"><span>Okres abonamentowy &#347;wiadczenia Us&#322;ug odp&#322;atnych wynosi
                odpowiednio </span><span class="c3">1 miesi&#261;c</span><span class="c0">, a wyb&oacute;r wariantu
                odp&#322;atnych Us&#322;ug dokonywany jest przez Us&#322;ugobiorc&#281; w trakcie sk&#322;adania
                zam&oacute;wienia na Us&#322;ug&#281;. Czas &#347;wiadczenia Us&#322;ugi w ramach wykupionego abonamentu
                zaczyna biec od dnia aktywowania Us&#322;ugi.</span></li>
        <li class="c1 li-bullet-0"><span>Umowa o &#347;wiadczenie Us&#322;ug odp&#322;atnych zostaje zawarta z
                chwil&#261; zatwierdzenia przez Us&#322;ugobiorc&#281; z&#322;o&#380;onego zam&oacute;wienia na
                Us&#322;ug&#281; poprzez wybranie przycisku &bdquo;</span><span class="c0">wybieram&rdquo;.</span></li>
        <li class="c1 li-bullet-0"><span>Zam&oacute;wienia, kt&oacute;rych nie uda si&#281; potwierdzi&#263; w
                ci&#261;gu </span><span class="c3">3</span><span class="c3">&nbsp;</span><span class="c0">dni
                zostan&#261; anulowane.</span></li>
        <li class="c1 li-bullet-0"><span>Zam&oacute;wione Us&#322;ugi bezp&#322;atne zostan&#261; aktywowane w terminie
            </span><span class="c3">24</span><span>&nbsp;h od potwierdzenia ich zam&oacute;wienia przez
                Us&#322;ugobiorc&#281;, za&#347; zam&oacute;wione Us&#322;ugi odp&#322;atne w terminie </span><span
                class="c3">24</span><span class="c0">&nbsp;h<br>od zaksi&#281;gowania na koncie Us&#322;ugodawcy
                Op&#322;aty abonamentowej, kt&oacute;rej wysoko&#347;&#263; uzale&#380;niona jest od wybranego wariantu
                Us&#322;ug.</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugobiorca, zar&oacute;wno w trakcie trwania, jak i po zako&#324;czeniu
                Us&#322;ugi bezp&#322;atnej, ma mo&#380;liwo&#347;&#263; zawarcia Umowy odp&#322;atnej poprzez
            </span><span class="c3">dokonanie p&#322;atno&#347;ci w serwisie</span><span class="c3">. </span><span
                class="c0">O zako&#324;czeniu Us&#322;ugi bezp&#322;atnej i mo&#380;liwo&#347;ci zawarcia Umowy
                odp&#322;atnej Us&#322;ugodawca poinformuje Us&#322;ugobiorc&#281; w serwisie.</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugobiorca w dowolnym momencie, przed up&#322;ywem bie&#380;&#261;cego
                Okresu rozliczeniowego, ma prawo zmiany Op&#322;aty abonamentowej i wybranego wariantu Us&#322;ug. W tym
                celu Us&#322;ugobiorca powinien </span><span class="c3">wybra&#263; odpowiedni plan w zak&#322;adce
                p&#322;atno&#347;ci</span><span class="c0">. Nowy okres rozliczeniowy w ramach nowego wariantu
                Us&#322;ug zaczyna biec po zako&#324;czeniu bie&#380;&#261;cego Okresu rozliczeniowego.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Umowa o &#347;wiadczenie odp&#322;atnej Us&#322;ugi zawierana jest
                na czas jej trwania okre&#347;lony w wybranym wariancie Us&#322;ug i Cenniku dost&#281;pnych w
                Serwisie.</span></li>
    </ol>
    <p class="c22"><span class="c0"></span></p>
    <ol class="c5 lst-kix_z6r466v9qacb-0 start" start="7">
        <li class="c8 li-bullet-0"><span class="c4 c3">P&#322;atno&#347;ci</span></li>
    </ol>
    <ol class="c5 lst-kix_xr9khdw9fe6q-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugobiorca zobowi&#261;zany jest zap&#322;aci&#263; za
                Us&#322;ug&#281; odp&#322;atn&#261; z g&oacute;ry zgodnie z Cennikiem, p&#322;atno&#347;ci s&#261;
                realizowane za pomoc&#261; serwisu stripe.com</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Je&#380;eli dany wariant Us&#322;ug nie jest wyszczeg&oacute;lniony
                w obowi&#261;zuj&#261;cym Cenniku, to Us&#322;ugobiorc&#281; obowi&#261;zuje cennik zindywidualizowany
                (odpowiadaj&#261;cy tre&#347;ci odr&#281;bnej umowy z Us&#322;ugodawc&#261;).</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugodawca umo&#380;liwia dokonanie p&#322;atno&#347;ci w
                nast&#281;puj&#261;cych formach: </span><span>Przelew tradycyjny, Blik, przy pomocy karty</span><span
                class="c0">, p&#322;atno&#347;ci s&#261; realizowane za pomoc&#261; serwisu stripe.com</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Momentem zap&#322;aty jest data zaksi&#281;gowania wp&#322;aty na
                rachunku bankowym Us&#322;ugodawcy.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Koszty zwi&#261;zane z realizacj&#261; p&#322;atno&#347;ci ponosi
                Us&#322;ugobiorca.</span></li>
        <li class="c1 li-bullet-0"><span>Us&#322;ugodawca wystawi Us&#322;ugobiorcy faktur&#281; w oparciu o dane
                wskazane podczas rejestracji Konta Us&#322;ugobiorcy. Us&#322;ugodawca dostarczy faktur&#281;
                Us&#322;ugobiorcy na podany przez niego adres poczty elektronicznej w terminie </span><span
                class="c3">3</span><span class="c18">&nbsp;</span><span class="c0">dni od jej wystawienia.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugobiorca wyra&#380;a zgod&#281; na otrzymywanie faktur od
                Us&#322;ugodawcy drog&#261; elektroniczn&#261;, zgodnie z art. 106 n ust. 1 Ustawy z dnia 11 marca 2004
                r. o podatku od towar&oacute;w i us&#322;ug.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Wszelkie zmiany dotycz&#261;ce Op&#322;at abonamentowych
                b&#281;d&#261; og&#322;aszane w Serwisie poprzez publikacj&#281; aktualnego Cennika.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Zmiana Cennika nie wp&#322;ywa na zmian&#281; wysoko&#347;ci
                Op&#322;at abonamentowych uiszczonych przez Us&#322;ugobiorc&oacute;w na podstawie Umowy zawartej przed
                opublikowaniem zmienionego Cennika.</span></li>
    </ol>
    <ol class="c5 lst-kix_2amedsbs4wpe-0 start" start="8">
        <li class="c8 li-bullet-0"><span class="c4 c3">Rozwi&#261;zywanie um&oacute;w</span></li>
    </ol>
    <ol class="c5 lst-kix_shfel39lkr1e-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Rozwi&#261;zanie Umowy o &#347;wiadczenie Us&#322;ug
                nast&#261;pi:</span></li>
    </ol>
    <ol class="c5 lst-kix_1vy3yzwcks66-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">w odniesieniu do Us&#322;ugi prowadzenia Konta Us&#322;ugobiorcy
                &ndash; w chwili z&#322;o&#380;enia przez Us&#322;ugobiorc&#281; dyspozycji usuni&#281;cia Konta
                (poprzez wprowadzenie zmian w Koncie Us&#322;ugobiorcy lub przes&#322;anie deklaracji usuni&#281;cia
                konta na adres e-mail Us&#322;ugodawcy) lub wskutek usuni&#281;cia Konta Us&#322;ugobiorcy przez
                Us&#322;ugodawc&#281; w zwi&#261;zku z rozwi&#261;zaniem Umowy z przyczyn okre&#347;lonych w &sect; 4
                ust. 5 Regulaminu,</span></li>
        <li class="c2 li-bullet-0"><span>w odniesieniu do Us&#322;ugi &#347;wiadczonej bezp&#322;atnie </span><span
                class="c12">&ndash; </span><span class="c0">po up&#322;ywie terminu jej trwania i w zwi&#261;zku z
                brakiem wniesienia Op&#322;aty abonamentowej za Us&#322;ug&#281; odp&#322;atn&#261;;</span></li>
        <li class="c2 li-bullet-0"><span class="c0">w odniesieniu do Us&#322;ugi &#347;wiadczonej odp&#322;atnie - z
                up&#322;ywem okresu, na jaki Us&#322;uga zosta&#322;a wykupiona i braku kontynuacji Umowy na dalszy
                Okres rozliczeniowy, przez co nale&#380;y rozumie&#263; brak uiszczenia Op&#322;aty abonamentowej za
                kolejny Okres rozliczeniowy.</span></li>
    </ol>
    <ol class="c5 lst-kix_lhfs68ixug90-0 start" start="2">
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugobiorca mo&#380;e rozwi&#261;za&#263; Umow&#281; bez
                podania przyczyn poprzez usuni&#281;cie Konta Us&#322;ugobiorcy w Systemie. W&oacute;wczas Umowa wygasa
                z ko&#324;cem bie&#380;&#261;cego Okresu rozliczeniowego, a wcze&#347;niej dokonane op&#322;aty nie
                podlegaj&#261; zwrotowi.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawcy przys&#322;uguje prawo do rozwi&#261;zania Umowy
                bez zachowania okresu wypowiedzenia w przypadku:</span></li>
    </ol>
    <ol class="c5 lst-kix_bs8h945xvt8p-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">podania przez Us&#322;ugobiorc&#281; nieprawdziwych i niekompletnych
                danych, kt&oacute;re s&#261; niezb&#281;dne do realizacji Umowy,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">uzasadnionego podejrzenia lub stwierdzenia faktu korzystania przez
                Us&#322;ugobiorc&#281; z Us&#322;ug w spos&oacute;b niezgodny z Regulaminem lub w spos&oacute;b
                naruszaj&#261;cy powszechnie obowi&#261;zuj&#261;ce przepisy prawa, b&#261;d&#378; w spos&oacute;b
                naruszaj&#261;cy prawa os&oacute;b trzecich, w tym w szczeg&oacute;lno&#347;ci w sytuacji naruszania
                przez Us&#322;ugobiorc&#281; zobowi&#261;za&#324; okre&#347;lonych w &sect; 4 ust. 2 Regulaminu czy w
                sytuacji, o kt&oacute;rej mowa w &sect; 4 ust. 5 Regulaminu.</span></li>
    </ol>
    <ol class="c5 lst-kix_uqbv7hiqqgcv-0 start" start="9">
        <li class="c8 li-bullet-0"><span class="c4 c3">Tryb post&#281;powania reklamacyjnego</span></li>
    </ol>
    <ol class="c5 lst-kix_3elc2ktxgkmv-0 start" start="1">
        <li class="c1 li-bullet-0"><span>W przypadku wyst&#261;pienia zastrze&#380;e&#324; co do funkcjonowania Systemu
                lub nieprawid&#322;owo&#347;ci<br>w &#347;wiadczeniu Us&#322;ug przez Us&#322;ugodawc&#281;,
                Us&#322;ugobiorca mo&#380;e z&#322;o&#380;y&#263; reklamacj&#281; przesy&#322;aj&#261;c j&#261; na
                nast&#281;puj&#261;cy adres e-mail: kontakt@gaius-lex.pl</span></li>
        <li class="c1 li-bullet-0"><span class="c0">W zg&#322;oszeniu reklamacyjnym nale&#380;y wskaza&#263; :</span>
        </li>
    </ol>
    <ol class="c5 lst-kix_easree9c2dho-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">login/adres e-mail przypisany Us&#322;ugobiorcy oraz dane
                kontaktowe,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">przedmiot reklamacji &ndash; wskazanie nieprawid&#322;owo&#347;ci
                wraz ze wskazaniem terminu ich wyst&#261;pienia i czasu trwania,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">proponowany spos&oacute;b rozstrzygni&#281;cia reklamacji.</span>
        </li>
    </ol>
    <ol class="c5 lst-kix_wbr2z7x73lve-0 start" start="3">
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca przed rozpatrzeniem z&#322;o&#380;onej reklamacji,
                mo&#380;e zwr&oacute;ci&#263; si&#281; do Us&#322;ugobiorcy o jej uzupe&#322;nienie, je&#380;eli podane
                przez U&#380;ytkownika informacje, o kt&oacute;rych mowa w ust. 2 wymagaj&#261; doprecyzowania w celu
                prawid&#322;owego rozpatrzenia reklamacji.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Rozpatrzenie reklamacji nast&#261;pi niezw&#322;ocznie, przy czym
                nie p&oacute;&#378;niej ni&#380; w terminie 14 dni od dnia otrzymania zg&#322;oszenia reklamacyjnego
                przez Us&#322;ugodawc&#281;, a spos&oacute;b rozstrzygni&#281;cia Us&#322;ugodawca przeka&#380;e
                Us&#322;ugobiorcy drog&#261; elektroniczn&#261; na adres e-mail przypisany do Konta
                Us&#322;ugobiorcy.</span></li>
        <li class="c1 li-bullet-0"><span>Wszelkie inne uwagi dotycz&#261;ce funkcjonowania Serwisu oraz pytania
                dotycz&#261;ce Us&#322;ug nale&#380;y kierowa&#263; za po&#347;rednictwem poczty elektronicznej na
                nast&#281;puj&#261;cy adres mailowy: kontakt@gaius-lex.pl</span><span class="c4 c3">.</span></li>
    </ol>
    <ol class="c5 lst-kix_nas4vziddqer-0 start" start="10">
        <li class="c8 li-bullet-0"><span class="c3 c4">Wsparcie techniczne</span></li>
    </ol>
    <ol class="c5 lst-kix_a38rqt8hbxzi-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">W trakcie obowi&#261;zywania Umowy Us&#322;ugobiorca ma
                mo&#380;liwo&#347;&#263; skorzystania ze wsparcia technicznego Us&#322;ugodawcy w zakresie
                zwi&#261;zanym z korzystaniem z Us&#322;ug w ramach Op&#322;aty abonamentowej.</span></li>
        <li class="c1 li-bullet-0"><span>W celu skorzystania ze wsparcia technicznego nale&#380;y wys&#322;a&#263;
                zg&#322;oszenie problemu za pomoc&#261; czatu znajduj&#261;cego si&#281; w serwisie lub<br>na adres
                e-mail:</span><span class="c3">&nbsp;kontakt@gaius-lex.pl</span><span class="c3">&nbsp;</span><span
                class="c0">ze wskazaniem rodzaju nieprawid&#322;owo&#347;ci i b&#322;&#281;d&oacute;w w Systemie.</span>
        </li>
        <li class="c1 li-bullet-0"><span>Wsparcie i pomoc techniczna &#347;wiadczone s&#261; przez Us&#322;ugodawc&#281;
                od poniedzia&#322;ku do pi&#261;tku (z wyj&#261;tkiem dni ustawowo wolnych od pracy na terenie
                Rzeczypospolitej Polskiej) w godzinach od </span><span>9:00</span><span>&nbsp;do
            </span><span>21:00</span><span class="c0">&nbsp;.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Wsparcie techniczne Us&#322;ugodawcy nie ma zastosowania do:</span>
        </li>
    </ol>
    <ol class="c5 lst-kix_9otmb23h2e72-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c0">element&oacute;w, kt&oacute;re podlegaj&#261; samodzielnej
                modyfikacji przez Us&#322;ugobiorc&#281;,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">b&#322;&#281;d&oacute;w, kt&oacute;re powsta&#322;y w wyniku
                nieuprawnionej ingerencji Us&#322;ugobiorcy w ustawienia Us&#322;ugi,</span></li>
        <li class="c2 li-bullet-0"><span class="c0">problem&oacute;w wynikaj&#261;cych z niespe&#322;nienia przez
                Us&#322;ugobiorc&#281; wymog&oacute;w technicznych, kt&oacute;re s&#261; niezb&#281;dne do korzystania z
                Us&#322;ugi.</span></li>
    </ol>
    <ol class="c5 lst-kix_k9i9cgazomij-0 start" start="11">
        <li class="c8 li-bullet-0"><span class="c4 c3">Ochrona danych osobowych i poufno&#347;&#263;</span></li>
    </ol>
    <ol class="c5 lst-kix_5cyrqd6ve42g-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Administratorem danych Us&#322;ugobiorc&oacute;w jest
                Us&#322;ugodawca.</span></li>
        <li class="c1 li-bullet-0"><span>Administrator danych przetwarza dane osobowe
                Us&#322;ugobiorc&oacute;w</span><span>&nbsp;tj. imi&#281; i nazwisko, adres prowadzenia
                dzia&#322;alno&#347;ci gospodarczej, adres poczty elektronicznej, nr telefonu, NIP, nr konta
                bankowego</span><span class="c0">&nbsp;w celu umo&#380;liwienia Us&#322;ugobiorcom korzystania z
                Systemu, w tym w celu &#347;wiadczenia Us&#322;ug na rzecz Us&#322;ugobiorcy.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Podanie przez Us&#322;ugobiorc&oacute;w danych osobowych jest
                dobrowolne, jednak&#380;e bez ich podania korzystanie z poszczeg&oacute;lnych Us&#322;ug mo&#380;e
                okaza&#263; si&#281; niemo&#380;liwe.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca dok&#322;ada stara&#324;, aby zapewni&#263;
                bezpiecze&#324;stwo i poufno&#347;&#263; danych osobowych,<br>w szczeg&oacute;lno&#347;ci poprzez
                dokonywanie czynno&#347;ci przetwarzania zgodnie z obowi&#261;zuj&#261;cymi przepisami prawa (RODO i
                regulacje sektorowe) oraz zapewnienie odpowiednich &#347;rodk&oacute;w bezpiecze&#324;stwa.</span></li>
        <li class="c1 li-bullet-0"><span>Wi&#281;cej informacji na temat ochrony danych osobowych
                Us&#322;ugobiorc&oacute;w wraz z informacjami na temat polityki cookie&rsquo;s znajduje si&#281; w
                Polityce Prywatno&#347;ci dost&#281;pnej w Serwisie.</span></li>
    </ol>
    <ol class="c5 lst-kix_u6lsy85ayutq-0 start" start="12">
        <li class="c8 li-bullet-0"><span class="c4 c3">Prawa na dobrach niematerialnych</span></li>
    </ol>
    <ol class="c5 lst-kix_av44a7gw93kh-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca zastrzega, &#380;e wszelkie zar&oacute;wno Serwis
                jak i System zawieraj&#261; tre&#347;ci chronione prawem w&#322;asno&#347;ci intelektualnej (np.
                elementy graficzne, logotypy, programy komputerowe.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Korzystanie z Serwisu nie oznacza nabycia jakichkolwiek praw na
                dobrach niematerialnych<br>do utwor&oacute;w znajduj&#261;cych si&#281; w Serwisie. Bez pozyskania
                uprzedniej zgody Us&#322;ugodawcy, zabronione jest kopiowanie, rozpowszechnianie, wykorzystywanie oraz
                modyfikowanie jakichkolwiek element&oacute;w sk&#322;adowych Serwisu czy Systemu.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca udziela Us&#322;ugobiorcy jedynie licencji
                niewy&#322;&#261;cznej (dalej: &bdquo;Licencja&rdquo;) uprawniaj&#261;cej do korzystania z Serwisu i
                Systemu w spos&oacute;b zgodny z jego przeznaczeniem, w tym na zasadach okre&#347;lonych w Regulaminie,
                Cenniku oraz innych komunikatach zamieszczonych w Serwisie, a udzielona Us&#322;ugobiorcy licencja nie
                uprawnia do udzielania dalszych licencji (sublicencji).</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Licencja udzielana jest przez Us&#322;ugodawc&#281; bez
                ogranicze&#324; terytorialnych na czas okre&#347;lony odpowiadaj&#261;cy okresowi &#347;wiadczenia
                Us&#322;ugi dost&#281;pu do Systemu i wygasa wraz z usuni&#281;ciem Konta Us&#322;ugobiorcy.</span></li>
    </ol>
    <ol class="c5 lst-kix_1w7g9wynhivh-0 start" start="13">
        <li class="c8 li-bullet-0"><span class="c4 c3">Postanowienia ko&#324;cowe</span></li>
    </ol>
    <ol class="c5 lst-kix_w6rtoi8h18q4-0 start" start="1">
        <li class="c1 li-bullet-0"><span class="c0">Regulamin jest dost&#281;pny bezp&#322;atnie w Serwisie dla
                ka&#380;dego Us&#322;ugobiorcy.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca zastrzega sobie prawo wprowadzania zmian w
                tre&#347;ci Regulaminu z zastrze&#380;eniem ochrony praw nabytych.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca poinformuje Us&#322;ugobiorc&oacute;w o zmianach
                Regulaminu nie p&oacute;&#378;niej ni&#380; 14 dni przed ich planowanym wej&#347;ciem w &#380;ycie
                poprzez: wy&#347;wietlenie komunikatu i publikacj&#281; nowego Regulaminu w Serwisie oraz
                przes&#322;anie informacji o planowanej zmianie drog&#261; elektroniczn&#261; na adres e-mail przypisany
                do Konta Us&#322;ugobiorcy. Brak sprzeciwu Us&#322;ugobiorcy wobec proponowanych zmian w terminie 14 dni
                od powiadomienia jest r&oacute;wnoznaczny z akceptacj&#261; nowego Regulaminu. W przypadku sprzeciwu
                Umowa ulega rozwi&#261;zaniu z dniem poprzedzaj&#261;cym wej&#347;cie w &#380;ycie nowego Regulaminu, co
                skutkuje r&oacute;wnie&#380; rozwi&#261;zaniem umowy o prowadzenie Konta Us&#322;ugobiorcy.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">Us&#322;ugodawca mo&#380;e na mocy odr&#281;bnej umowy zawartej z
                Us&#322;ugobiorc&#261; odmiennie uregulowa&#263; zasady korzystania z Systemu. W takiej sytuacji w razie
                kolizji postanowienia ww. umowy maj&#261; pierwsze&#324;stwo przed postanowieniami Regulaminu.</span>
        </li>
        <li class="c1 li-bullet-0"><span class="c0">Niniejszy Regulamin podlega prawu polskiemu. Wszelkie spory na
                gruncie niniejszego regulaminu b&#281;d&#261; rozwi&#261;zywane w drodze polubownych negocjacji, a w
                przypadku gdy nie dojdzie do porozumienia - przed s&#261;dem powszechnym w&#322;a&#347;ciwym ze
                wzgl&#281;du na siedzib&#281; Us&#322;ugodawcy.</span></li>
        <li class="c1 li-bullet-0"><span class="c0">W sprawach nieuregulowanych w Regulaminie zastosowanie znajd&#261;
                przepisy powszechnie obowi&#261;zuj&#261;cego prawa polskiego.</span></li>
        <li class="c1 li-bullet-0"><span>Tre&#347;&#263; niniejszego Regulaminu dost&#281;pna jest w Serwisie.</span>
        </li>
        <li class="c1 li-bullet-0"><span>Regulamin obowi&#261;zuje od dnia </span><span
                class="c3">10.01.2024</span><span class="c4 c3">.</span></li>
    </ol>
    <p class="c17"><span class="c0"></span></p>
</body>

</html>`;

export default statute;
