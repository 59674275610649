import { StyleSheet } from "aphrodite";
import Colors from "./colors";

export const navbarLinkStyleSX = {
  fontWeight: 300,
  marginRight: "8pt",
  color: "gray",
  fontSize: "1.2rem",
  paddingBottom: 0,
  marginBottom: 0,
  ":hover": {
    color: Colors.FlatHubGreen,
    textDecoration: "none",
  },
};
export const navbarLinkStyle = StyleSheet.create({
  tmp: navbarLinkStyleSX,
}).tmp;

export const mainNavbarLinkStyleSX = {
  fontWeight: 400,
  marginRight: "8pt",
  color: Colors.LightGreen,
  fontSize: "1.2rem",
  paddingBottom: 0,
  marginBottom: 0,
  ":hover": {
    color: Colors.FlatHubGreen,
    textDecoration: "none",
  },
};
export const mainNavbarLinkStyle = StyleSheet.create({
  tmp: mainNavbarLinkStyleSX,
}).tmp;

export const buttonStyleSX = {
  height: "3rem",
  backgroundColor: Colors.FlatHubGreen,
  fontFamily: "sofia-pro, sans-serif",
  fontWeight: "semibold",
  fontSize: "1rem",
  color: "white",
  ":hover": {
    color: Colors.FlatHubGreen,
    textDecoration: "none",
  },
};

export const googleButtonStyleSX = {
  height: "3rem",
  margin: 1,
  backgroundColor: "white",
  fontFamily: "sofia-pro, sans-serif",
  fontWeight: "semibold",
  fontSize: "1.5rem",
  color: Colors.FlatHubGreen,
  ":hover": {
    color: "white",
    textDecoration: "none",
  },
};

export const buttonStyle = StyleSheet.create({ tmp: buttonStyleSX }).tmp;

export const scrollBarStyleSXX = {
  overflowX: "scroll",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: Colors.Gray,
    borderRadius: "0.5rem",
  },
};

export const scrollBarStyleSXY = {
  overflowY: "scroll",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: Colors.Gray,
    borderRadius: "0.5rem",
  },
};