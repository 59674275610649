import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Dialog, Divider, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

import Button from '../../components/generic/Button';
import LocalizedTextEditor from '../../components/LocalizedTextEditor';
import useGaiusWebSocket from '../../hooks/useGaiusWebSocket';
import { SelectDocumentButton } from '../chatWithGPT/ChatWithGPT';
import { Link, useBlocker, useLocation } from 'react-router-dom';
import LinearDeterminate from '../../components/LinearBuffer';
import { Helmet } from 'react-helmet';
import { Check, Delete } from '@mui/icons-material';
import { Colors } from 'legalgpt-react-shared';
import useUserData from '../../hooks/useUserData';
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit';
import ReactComponent from './Extension';
import { useNavigate } from "react-router";


const TextEditor: React.FC = () => {
  const location = useLocation();
  const initialText = location.state?.initialText;
  const title = location.state?.title;
  const isAnonymized = location.state?.isAnonymized;
  const [editOffset, setEditOffset] = useState(0);

  const [analysisResults, setAnalysisResults] = useState<any>({
    riskyTheses: [],
    repetitions: []
  });
  const [instructions, setInstructions] = useState('');
  const [documents, setDocuments] = useState([]);
  const [propositions, setPropositions] = useState([]);
  const [currentProposition, setCurrentProposition] = useState<number>(0);
  const [selectedDatabase, setSelectedDatabase] = useState('full');
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [modified, setModified] = useState(false);
  const [checkedOut, setCheckedOut] = useState<any>({
    risky: [],
    repetitions: []
  });
  const [hovered, setHovered] = useState<number | null>(null);
  const [drafting, setDrafting] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [previousText, setPreviousText] = useState('');

  const { sendMessage, lastMessage } = useGaiusWebSocket("/ws/v1/verify-text");
  const writerWS = useGaiusWebSocket("/ws/v1/writer");
  const editor = useEditor({
    extensions: [
      StarterKit,
      ReactComponent,
    ],
    content: initialText?.replaceAll("\n", "<br />") || '',
  })


  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return (modified && currentLocation.pathname !== nextLocation.pathname)
    }
  );

  React.useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const lastMessageItem = JSON.parse(lastMessage.data)
      if (lastMessageItem.message === 'partial-risky-theses') {
        const riskyTheses = analysisResults.riskyTheses || [];
        const uniqueTheses = Object.keys(lastMessageItem.data).filter(key => !riskyTheses.some((thesis: string[]) => thesis[0] === key));
        const uniqueItems = uniqueTheses.map(key => [key, lastMessageItem.data[key].proof]);
        setAnalysisResults({ ...analysisResults, riskyTheses: [...riskyTheses, ...uniqueItems] });
      }
      if (lastMessageItem.message === 'partial-repetitions') {
        const repetitions = analysisResults.repetitions || [];
        const uniqueItemKeys = Object.keys(lastMessageItem.data).filter(key => !repetitions.some((thesis: string[]) => thesis[0] === key));
        const uniqueItems = uniqueItemKeys.map(key => [key, lastMessageItem.data[key]]);
        setAnalysisResults({ ...analysisResults, repetitions: [...analysisResults.repetitions, ...uniqueItems] });
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    const msg = writerWS.lastMessage;
    if (msg && msg.data) {
      const msgItem = JSON.parse(msg.data);
      if (msgItem.message === 'propositions') {
        setPropositions(msgItem.propositions);
        setCurrentProposition(0);
        setGenerating(false);
      }
      if (msgItem.message === 'draftDocument') {
        if (msgItem.status === 'drafting') {
          editor?.commands.clearContent();
          editor?.commands.insertContent(msgItem.text);
        }
        else if (msgItem.status === 'finished') {
          setDrafting(false);
        }
      }
    }
  }, [writerWS.lastMessage])

  function insertTagBeforeString(text: string, idx: number, prefix: string, after?: number) {
    const currentHtml = editor?.getHTML() || '';
    const proofRegex = new RegExp(text.replace(/\s+/g, '(\\s|<br\\s*\\/?>)*'), 'i');
    const match = currentHtml.match(proofRegex);

    const location = match?.index;
    const matchedText = match?.[0];

    if (location !== undefined) {
      const newElement = `<insertable-tag id="${prefix}-${idx}" text="${prefix}.${idx}"></insertable-tag>`;

      if (!after && matchedText) {
        const html = editor?.getHTML();
        const replaced = html?.replace(matchedText, newElement + matchedText);
        editor?.commands.setContent(replaced || '');
      }
      else if (after && matchedText) {
        // skip first `after` characters in `currentText` while matching
        const skippedText = currentHtml.slice(after);
        const skippedMatch = skippedText.match(proofRegex);
        const matchedText = skippedMatch?.[0];

        if (skippedMatch) {
          const skippedLocation = skippedMatch.index;
          if (skippedLocation !== undefined && matchedText) {
            const html = editor?.getHTML();
            const replaced = html?.replace(matchedText, newElement + matchedText);
            editor?.commands.setContent(replaced || '');
          }
        }
      }
    }
  }

  React.useEffect(() => {
    let newText = editor?.getHTML()?.replace(/<insertable-tag.*?<\/insertable-tag>/g, '') || '';
    editor?.commands.setContent(newText);

    let idxs = { risky: 0, repetitions: 0 };

    analysisResults.riskyTheses.forEach(([key, proof]: [string, string]) => {
      idxs.risky += 1;
      insertTagBeforeString(proof, idxs.risky, 'R');
    })

    analysisResults.repetitions.forEach(([original, repeated]: [string, string]) => {
      const originalRegex = new RegExp(original.replace(/\s+/g, '\\s*'), 'gi');
      const currentText = editor?.getText() || '';

      const matches = [...currentText.replace(/\n/g, ' ').matchAll(originalRegex)];
      idxs.repetitions += 1;


      matches.forEach((match, index) => {
        let after = match.index;

        insertTagBeforeString(original, idxs.repetitions, 'P', after);
      });
      if (original === repeated) {
        return;
      }

      const repeatedRegex = new RegExp(repeated.replace(/\s+/g, '\\s*'), 'gi');
      const repeatedMatches = [...currentText.replace(/\n/g, ' ').matchAll(repeatedRegex)];

      repeatedMatches.forEach((match, index) => {
        idxs.repetitions += 1;

        let after = match.index
        if (after) after += original.length;

        insertTagBeforeString(original, idxs.repetitions, 'P', after);
      });
    })
    // editor?.commands.setContent(newText);
  }, [analysisResults, checkedOut])

  // after `text` changes, we need to wait for 1 second for additional input
  // if it doesn't change for 1 second, we send the message to the server
  const debounceTimer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      if (editor?.getText() !== '') {
        // get only last 1k characters
        let currentText = editor?.getText();
        if (currentText) {
          const textToSend = currentText.slice(0, editOffset).slice(-1000);
          const continuation = currentText.slice(editOffset, 1000);
          setGenerating(true);
          writerWS.sendMessage(JSON.stringify({
            command: "getPropositions",
            mode: selectedDatabase,
            text: textToSend,
            continuation: continuation,
            documents: documents,
            instructions: instructions
          }));
        }
      }
    }, 1000);
  }, [editor?.getText(), instructions, documents]);

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      if (window.confirm('Czy na pewno chcesz opuścić tę stronę? Wszystkie niezapisane zmiany zostaną utracone.')) {
        blocker.proceed();
      }
    }
  }, [blocker]);

  React.useEffect(() => {
    document.title = modified ? '* Edytor | Gaius-Lex' : 'Edytor | Gaius-Lex';
  }, [modified]);

  React.useEffect(() => {
    let editPosition = 0;
    let i = 0;
    const editorText = editor?.getText();
    if (editorText) {
      while (i < editorText.length && editorText[i] === previousText[i]) {
        editPosition++;
        i++;
      }
    }
    setEditOffset(editPosition);
    setPreviousText(editorText || '');
  }, [editor?.getText()]);



  return (
    <>
      <Helmet>
        <title>Edytor | Gaius-Lex</title>
      </Helmet>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <SaveDialog
          text={editor?.getText() || ''}
          saveDialogOpen={saveDialogOpen}
          documentName={title}
          onSave={(val: boolean) => {
            setSaveDialogOpen(val);
            setModified(false);
          }}
          extension='.md'
          isAnonymized={isAnonymized}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card className="flat-card" sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Edytor (wersja beta)</Typography>
              <EditorContent editor={editor} />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant='outlined-light'
              className='w-100 mb-3'
              onClick={() => setSaveDialogOpen(true)}>
              Zapisz we własnej bazie
            </Button>
            <CreateReportButton text={editor?.getText()} />
            {/* <DocumentCreationCard
              editor={editor}
              writerWS={writerWS}
            /> */}
            <Card className="flat-card" sx={{ p: 2, backgroundColor: 'white', borderTop: '1px solid #e0e0e0' }}>
              <Typography variant="h6" gutterBottom>
                Wyniki analizy
              </Typography>
              {analysisResults.riskyTheses.length > 0 && (
                <Accordion className='shadow-0'
                  defaultExpanded={false}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    marginBottom: '1rem',
                    padding: 0
                  }}>
                  <AccordionSummary>Ryzyka umowy</AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Typography variant="body1">
                      {analysisResults.riskyTheses.map((data: [string, string], idx: number) => {
                        if (checkedOut.risky.includes(idx + 1)) {
                          return null;
                        }

                        return (
                          <div id={`marker-result-${idx + 1}`} className='text-verify-result' key={idx}
                            onMouseEnter={() => setHovered(idx + 1)}
                            onMouseLeave={() => setHovered(null)}
                          >
                            <span className='text-verify-marker' onClick={() => {
                              document.getElementById(`marker-risk-${idx + 1}`)?.scrollIntoView({ behavior: 'smooth' });
                            }}>R.{idx + 1}</span>
                            <div className='d-flex flex-column'>
                              <Typography variant="body1">{data[0]}</Typography>
                              <Typography variant="body2" className='text-muted'>{data[1]}</Typography>
                              <IconButton sx={{ marginLeft: 'auto', marginRight: "16px" }} onClick={() => {
                                setCheckedOut({ ...checkedOut, risky: [...checkedOut.risky, idx + 1] });
                              }}>
                                <Check sx={{
                                  color: hovered === idx + 1 ? Colors.GaiusLexBlue : 'gray'
                                }} />
                              </IconButton>
                            </div>
                          </div>
                        )
                      })}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              {analysisResults.repetitions.length > 0 && (
                <Accordion className='shadow-0'
                  defaultExpanded={false}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    marginBottom: '1rem',
                    padding: 0
                  }}>
                  <AccordionSummary>Powtarzające się zapisy</AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Typography variant="body1">
                      {analysisResults.repetitions.map((data: [string, string], idx: number) => {
                        if (checkedOut.repetitions.includes(idx + 1)) {
                          return null;
                        }

                        return (
                          <div id={`marker-result-repetition-${idx + 1}`} className='text-verify-result' key={idx}
                            onMouseEnter={() => setHovered(idx + 1)}
                            onMouseLeave={() => setHovered(null)}
                          >
                            <span className='text-verify-marker' onClick={() => {
                              document.getElementById(`marker-repetition-${idx + 1}`)?.scrollIntoView({ behavior: 'smooth' });
                            }}>P.{idx + 1}</span>
                            <div className='d-flex flex-column'>
                              <Typography variant="body1">{data[0]}</Typography>
                              <Typography variant="body2" className='text-muted'>{data[1]}</Typography>
                              <IconButton sx={{ marginLeft: 'auto', marginRight: "16px" }} onClick={() => {
                                setCheckedOut({ ...checkedOut, repetitions: [...checkedOut.repetitions, idx + 1] });
                              }}>
                                <Check sx={{
                                  color: hovered === idx + 1 ? Colors.GaiusLexBlue : 'gray'
                                }} />
                              </IconButton>
                            </div>
                          </div>
                        )
                      })}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              <Box className="d-flex align-items-center flex-column">
                <Button disabled={!editor?.getText()} onClick={() => {
                  setAnalysisResults({ riskyTheses: [], repetitions: [] });
                  setCheckedOut({ risky: [], repetitions: [] });
                  sendMessage(JSON.stringify({ text: editor?.getText() || '', command: "verifyText" }))
                }}>
                  Przeprowadź analizę
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default TextEditor;

function DocumentCreationCard({
  editor,
  writerWS
}: {
  editor: any;
  writerWS: any;
}) {
  const [instructions, setInstructions] = useState('');
  const [selectedDatabase, setSelectedDatabase] = useState('full');
  const [documents, setDocuments] = useState([]);
  const [drafting, setDrafting] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [propositions, setPropositions] = useState([]);
  const [currentProposition, setCurrentProposition] = useState(0);

  useEffect(() => {
    if (writerWS.lastMessage && writerWS.lastMessage.data) {
      const msgItem = JSON.parse(writerWS.lastMessage.data);
      if (msgItem.message === 'propositions') {
        setPropositions(msgItem.propositions);
        setCurrentProposition(0);
        setGenerating(false);
      }
      if (msgItem.message === 'draftDocument') {
        if (msgItem.status === 'drafting') {
          editor?.commands.clearContent();
          editor?.commands.insertContent(msgItem.text);
        }
        else if (msgItem.status === 'finished') {
          setDrafting(false);
        }
      }
    }
  }, [writerWS.lastMessage, editor]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (editor?.getText() !== '') {
        let currentText = editor?.getText();
        if (currentText) {
          const textToSend = currentText.slice(-1000);
          setGenerating(true);
          writerWS.sendMessage(JSON.stringify({
            command: "getPropositions",
            mode: selectedDatabase,
            text: textToSend,
            documents: documents,
            instructions: instructions
          }));
        }
      }
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [editor?.getText(), instructions, documents, selectedDatabase, writerWS]);

  const handleDraftDocument = () => {
    setDrafting(true);
    writerWS.sendMessage(JSON.stringify({
      command: "draftDocument",
      mode: selectedDatabase,
      text: editor?.getText(),
      documents: documents,
      instructions: instructions
    }));
  };

  const handleStopDrafting = () => {
    setDrafting(false);
    writerWS.getWebSocket().close();
  };

  return (
    <Card className="flat-card mb-5" sx={{ p: 2, backgroundColor: 'white', borderTop: '1px solid #e0e0e0' }}>
      <Typography variant="h5" gutterBottom>
        Tworzenie dokumentu
      </Typography>
      <Typography variant="body1">
        <TextField
          label="O czym ma być dokument?"
          rows={3}
          multiline
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          className='w-100' />
      </Typography>
      <Typography variant="h6" className="my-2">Inspiracje</Typography>

      <FormControl component="fieldset" className="w-100">
        <RadioGroup
          row
          aria-label="database-selection"
          name="database-selection"
          value={selectedDatabase}
          className="d-flex justify-content-between"
          onChange={(e) => setSelectedDatabase(e.target.value)}
        >
          <FormControlLabel value="full" control={<Radio />} label="Pełna baza" />
          <FormControlLabel value="selected" control={<Radio />} label="Wybrane dokumenty" />
        </RadioGroup>
      </FormControl>

      {selectedDatabase === 'selected' && (
        <SelectDocumentButton callback={setDocuments} limit={30000} orientation="vertical" />
      )}
      {!drafting && (
        <Button variant='contained-dark' className='w-100 mb-3' onClick={handleDraftDocument}>
          Naszkicuj dokument
        </Button>
      )}
      {drafting && (
        <Button variant='contained-dark' sx={{ backgroundColor: Colors.Red }} className='w-100 mb-3' onClick={handleStopDrafting}>
          Wstrzymaj
        </Button>
      )}
      <Typography variant="h6" className={`my-2`}>
        Podpowiedzi:
        {generating && <LinearDeterminate />}
        {!generating && propositions.length === 0 && (
          <Typography variant="body2" className='text-muted mt-2 justify-content-center d-flex'>
            Zacznij pisać, a Gaius podpowie Ci kontynuację
          </Typography>
        )}
        <Typography variant="body2">
          {propositions.map((proposition, idx) => (
            <Card
              sx={{
                display: currentProposition === idx ? 'block' : 'none',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                "&:hover": {
                  backgroundColor: '#f0f0f0'
                },
                cursor: 'pointer'
              }}
              key={proposition + idx}
              className='p-2 w-100 shadow-0 border-1 border-solid border-gray-300'
              onClick={() => {
                setPropositions([]);
                editor?.commands.insertContent(proposition);
              }}
            >
              {proposition}
            </Card>
          ))}
          <Stack direction="row" spacing={1} className="w-100 d-flex justify-content-center mt-1">
            {propositions.map((_, idx) => (
              <Button
                key={idx}
                variant={currentProposition === idx ? 'contained-dark' : 'outlined-light'}
                color={currentProposition === idx ? 'primary' : 'default'}
                sx={{ padding: 1, margin: 0, width: "fit-content" }}
                onClick={() => setCurrentProposition(idx)}
              >
                {(idx + 1).toString()}
              </Button>
            ))}
          </Stack>
        </Typography>
      </Typography>
    </Card>
  );
}

function CreateReportButton(props: { text: any }) {
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [potentialTheses, setPotentialTheses] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const { userData } = useUserData();
  const { sendMessage, lastMessage } = useGaiusWebSocket("/ws/v1/verify-text");

  React.useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const msgItem = JSON.parse(lastMessage.data);
      if (msgItem.message === 'potential-theses') {
        setPotentialTheses(Object.keys(msgItem.data));
        setLoading(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (reportDialogOpen && !loading) {
      sendMessage(JSON.stringify({ text: props.text, command: "getPotentialTheses" }));
      setLoading(true);
    }
  }, [reportDialogOpen]);

  return (
    <>
      <Dialog open={reportDialogOpen} onClose={() => {
        setReportDialogOpen(false);
      }}>
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>Raport</Typography>
            <Typography variant="body1" gutterBottom sx={{ justifyItems: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              Wybierz tezy do analizy
              <br />
              {loading && <LinearDeterminate maxTime={3} />}
              {!loading && (<>
                <Stack mt={5} mb={2} direction="column" spacing={2} width="100%">
                  {potentialTheses.map((thesis, idx) => (
                    <Stack direction="row" spacing={2} width="100%">
                      <TextField
                        key={idx}
                        label={`Teza ${idx + 1}`}
                        value={thesis}
                        sx={{ flexGrow: 1 }}
                        onChange={(e) => {
                          const newTheses = [...potentialTheses];
                          newTheses[idx] = e.target.value;
                          setPotentialTheses(newTheses);
                        }}
                      />
                      <IconButton onClick={() => {
                        setPotentialTheses(potentialTheses.filter((_, i) => i !== idx));
                      }}>
                        <Delete />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
                <Button variant="contained-dark" disabled={potentialTheses.some(thesis => thesis.trim() === '')} onClick={() => {
                  setPotentialTheses([...potentialTheses, '']);
                }}>
                  Dodaj nową tezę
                </Button>
              </>
              )}
            </Typography>
            <Divider sx={{ width: '100%', marginTop: '16px', marginBottom: '16px' }} />
            <Typography variant="body1" gutterBottom>
              Raport z analizy dokumentu zostanie wysłany na Twój email:
              <br />
              <b>{userData.email}</b>
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="text" onClick={() => {
                setReportDialogOpen(false);
              }}>
                Zamknij
              </Button>
              <Button variant="contained-dark" disabled={loading || potentialTheses.some(thesis => thesis.trim() === '') || potentialTheses.length === 0} onClick={() => {
                setReportDialogOpen(false);
                sendMessage(JSON.stringify({ text: props.text, command: "prepareReport", questions: potentialTheses }));
              }}>
                Przygotuj raport
              </Button>
            </Stack>
          </CardContent>
        </Card >
      </Dialog >
      <Button
        variant='outlined-light'
        className='w-100 mb-3'
        onClick={() => setReportDialogOpen(true)}>
        Przygotuj raport
      </Button>
    </>
  );
}

function SaveDialog({ text, saveDialogOpen, onSave, extension, documentName, isAnonymized }:
  { text: string, saveDialogOpen: boolean, onSave: (val: boolean) => void, extension?: string, documentName?: string, isAnonymized?: boolean }) {
  const [saving, setSaving] = useState(false);
  const [documentSavingStatus, setDocumentSavingStatus] = useState('');
  const [currentDocumentName, setCurrentDocumentName] = useState(documentName ? documentName : '');
  const navigate = useNavigate();

  const writerWS = useGaiusWebSocket("/ws/v1/writer");

  React.useEffect(() => {
    if (writerWS.lastMessage && writerWS.lastMessage.data) {
      const msgItem = JSON.parse(writerWS.lastMessage.data);
      if (msgItem.status === "await") {
        navigate(`/anonymize`, { state: { initialText: text, path: msgItem.path, statusId: msgItem.status_id } });
      }
      if (msgItem.message === 'vectorization_status') {
        if (msgItem.status === 'removing_vectors') {
          setDocumentSavingStatus("Nadpisywanie...");
        }
        else if (msgItem.status.includes('vectorizing')) {
          const percentage = Math.round(msgItem.status.split('_')[1]);
          setDocumentSavingStatus(`${percentage}%`);
        }
        else {
          setDocumentSavingStatus("W kolejce...");
        }
      }
      if (msgItem.message === 'vectorization_finished') {
        setSaving(false);
        setDocumentSavingStatus('');
        onSave(false);
      }
    }
  }, [writerWS.lastMessage]);

  const saveDocument = () => {
    setSaving(true);
    let name = currentDocumentName;
    if (extension && !name.endsWith(extension)) {
      name += extension;
    }
    writerWS.sendMessage(JSON.stringify({
      command: "saveDocument",
      text: text.replace(/<span id="marker-\d+" class="text-verify-marker".*?<\/span>/g, ''),
      title: name,
      should_await: isAnonymized
    }));
  }

  return <Dialog open={saveDialogOpen || saving} onClose={() => onSave(false)}>
    <Card sx={{ p: 3 }}>
      {saving &&
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" gutterBottom>Trwa zapisywanie...</Typography>
          <Typography variant="body1" gutterBottom>{documentSavingStatus}</Typography>
          <LinearDeterminate />
        </Stack>}
      {!saving &&
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" gutterBottom>Zapisz dokument</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nazwa dokumentu"
              value={currentDocumentName}
              onChange={(e) => setCurrentDocumentName(e.target.value)} />
            {extension && <Typography variant="body1" className='align-content-center'>{extension}</Typography>}
          </Stack>
          <Button variant="contained-dark" onClick={() => {
            setSaving(true);
            saveDocument();
          }}>Zapisz</Button>
        </Stack>}
    </Card>
  </Dialog >;
}