import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Button from "../../components/generic/Button";
import { default as MuiButton } from "@mui/material/Button";
import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import { Form, Formik } from "formik";
import LinearBuffer from "../../components/LinearBuffer";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";
import DataUsageNoticeModal from "../../components/dataUsageNoticeModal";
import useUserData from "../../hooks/useUserData";

interface FullTextTheses {
  [key: string]: {
    proof: string;
    antithesis: string;
  };
}

interface Quote {
  [key: string]: string[];
}

const CaseLawDocumentAnalysis: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [documentChunks, setDocumentChunks] = React.useState<string[]>([]);
  const [fullTextTheses, setFullTextTheses] = React.useState<FullTextTheses>(
    {}
  );
  const [renderedDocument, setRenderedDocument] = React.useState<string>("");
  const [quotes, setQuotes] = React.useState<Quote>({});
  const [message, setMessage] = React.useState<string>("");

  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const filePath = searchParams.get("filePath") || "";

  const { sendMessage, lastMessage } = useGaiusWebSocket(
    "/ws/v1/case-law-analysis"
  );

  const [showDataUsageNotice, setShowDataUsageNotice] = React.useState<boolean>(false);
  const { userData } = useUserData();

  const handleSubmit = (values: any) => {
    if (!!!userData.dataUsageNotice) { 
      setShowDataUsageNotice(true);
      return;
    }
    
    setIsLoading(true);
    if (filePath === "") sendMessage(JSON.stringify(values));
    else
      sendMessage(
        JSON.stringify({
          filePath: filePath,
        })
      );
  };

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "analyzing") {
        setMessage("Analizowanie dokumentu...");
        setFullTextTheses(data.full_text_theses);
      } else if (data.message === "done") {
        setMessage("");
        setFullTextTheses(data.full_text_theses);
        setIsLoading(false);
      } else if (data.message === "error") {
        setIsLoading(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    let lastLabel: string | null = null;
    let finalText = "";

    documentChunks.map((chunk, index) => {
      const text = chunk[0];
      const label = chunk[1];
      if (label === lastLabel) finalText += text;
      else {
        if (lastLabel !== null) {
          finalText += ` <span style='background-color: #ffc300; color: black; border-radius: 2px'><b>${lastLabel}</b></span></span> `;
        }
        if (label !== null)
          finalText +=
            "<span style='background-color: #003566; color: white; padding: 3px; border-radius: 3px'>" +
            text;
        else finalText += text;
      }
      lastLabel = label;
    });
    if (lastLabel !== null) {
      finalText += ` <span style='background-color: #ffc300; color: black; border-radius: 2px'><b>${lastLabel}</b></span></span>`;
    }
    setRenderedDocument(finalText);
  }, [documentChunks]);

  const goToSearch = (thesis: string) => {
    window.open("/case-law-search?query=" + thesis, "_blank");
  };

  const validationSchema = null;
  if (filePath === "")
    Yup.object({
      text: Yup.string().required("Pole wymagane"),
    });

  return (
    <div id={"case-law-document-analysis"}>
      <Helmet>
        <title>Przeszukaj swoje akta | Gaius Lex - asystent prawnika</title>
        <meta
          name="description"
          content="Gaius Lex - asystent prawnika. Znajdź orzeczenia sądowe powiązane z Twoim pytaniem."
        />
      </Helmet>
      <Box id={"case-law-box"}>
        <h1 style={{ marginBottom: "20px" }}>
          Przeanalizuj tezy pojawiające się w dokumencie
        </h1>
        <Typography
          variant="h5"
          color="#8D9DA8"
          className="pb-2"
          mb={2}
          sx={{ fontWeight: "regular", fontSize: "1rem" }}
        >
          Gaius Lex znajdzie tezy zawarte w dokumencie które budzą jego
          wątpliwości. Następnie pomoże Ci odnaleźć orzeczenia sądowe, które
          wspierają lub przeczą tym tezom.
        </Typography>
        <Stack>
          <Formik
            initialValues={{
              text: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    gap: "4px",
                  }}
                >
                  {filePath === "" ? (
                    <TextareaAutosize
                      aria-label="Treść pisma"
                      minRows={10}
                      placeholder="Treść pisma ..."
                      style={{
                        width: "100%",
                        borderRadius: "0.75rem",
                        padding: "10px",
                        marginBottom: "20px",
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      }}
                      name="text"
                      onChange={formik.handleChange}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "left",
                        height: "100%",
                        fontStyle: "italic",
                        color: "#8D9DA8",
                      }}
                    >
                      {filePath}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained-dark"
                    color="primary"
                    disabled={
                      isLoading ||
                      (filePath === "" && formik.values.text === "")
                    }
                  >
                    Zbadaj dokument
                  </Button>
                </Form>
              );
            }}
          </Formik>
          { showDataUsageNotice && <DataUsageNoticeModal />}
          {isLoading && (
            <div
              style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
            >
              <LinearBuffer />
            </div>
          )}
          <Typography variant="body1" color="text.primary" className="mt-2">
            {message}
          </Typography>
          {Object.keys(fullTextTheses).length !== 0 && (
            <h1 style={{ margin: "20px 0" }}>Tezy zawarte w dokumencie:</h1>
          )}
          <Typography variant="body1">
            <Grid container spacing={2}>
              {fullTextTheses &&
                Object.keys(fullTextTheses).map((key, index) => {
                  const quote = fullTextTheses[key]["proof"];
                  const antithesis = fullTextTheses[key]["antithesis"];
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <Card key={index} className="my-3">
                        <CardContent className="pb-1">
                          <Typography variant="h6">{key}</Typography>
                          <ul>
                            <li
                              key={index}
                              style={{
                                fontWeight: "lighter",
                                color: "text.secondary",
                              }}
                            >
                              <em>(...){quote}(...)</em>
                            </li>
                          </ul>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="flex-end"
                            className="my-3"
                          >
                            <MuiButton
                              variant="text"
                              color="primary"
                              onClick={() => {
                                goToSearch(key);
                              }}
                            >
                              Orzeczenia wspierające tezę
                            </MuiButton>
                            <MuiButton
                              variant="text"
                              color="primary"
                              onClick={() => {
                                goToSearch(antithesis);
                              }}
                            >
                              Orzeczenia przeczące tezie
                            </MuiButton>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default CaseLawDocumentAnalysis;
