import LocalizedStrings from "react-localization";

let lang = "pl";
if (document.location.pathname.includes("/en/")) lang = "en";

const TextStrings = new LocalizedStrings({
  pl: {
    register: "Zarejestruj się",
    INVITE_CODE_EXPIRED: "Kod zaproszenia wygasł",
    INVITE_CODE_USED: "Kod zaproszenia został już wykorzystany",
    INVITE_CODE_INVALID: "Kod zaproszenia jest nieprawidłowy",
    EMPTY_INVITE_CODE: "Podaj kod zaproszenia",
    CONNECTION_ERROR: "Błąd połączenia z serwerem",
    REGISTER_SUCCESS: "Zarejestrowano pomyślnie",
    GOOGLE_AUTH_ERROR: "Błąd w trakcie autentykacji Google",
    FACEBOOK_AUTH_ERROR: "Błąd w trakcie autentykacji Facebook",
  },
  en: {
    register: "Register",
    INVITE_CODE_EXPIRED: "Invite code expired",
    INVITE_CODE_USED: "Invite code was already used",
    INVITE_CODE_INVALID: "Invite code is invalid",
    EMPTY_INVITE_CODE: "Enter invite code",
    CONNECTION_ERROR: "Server connection error",
    REGISTER_SUCCESS: "Registered successfully",
    GOOGLE_AUTH_ERROR: "Google Authentication Error",
    FACEBOOK_AUTH_ERROR: "Facebook Authentication Error",
  },
});

TextStrings.setLanguage(lang);

export default TextStrings;
