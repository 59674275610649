import CaseLawSearch from "./caseLawSearch/CaseLawSearch";
import React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ShowCachedResults = () => {
  const [results, setResults] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [metareview, setMetareview] = React.useState("");
  const [sources, setSources] = React.useState<"judiciary" | "Kodeksy">("judiciary");

  const axios = useAxiosPrivate();

  const params = new URLSearchParams(window.location.search);
  const key = params.get("key");

  React.useEffect(() => {
    if (key) {
      axios.get(`/api/v1/share-results?key=${key}`)
        .then((response) => {
          const data = response.data;
          setResults(data.results);
          setQuery(data.query);
          setMetareview(data.metaanalysis);
          setSources(data.sources);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  if (query === "" || metareview === "") {
    return (
      null
    )
  }

  return (
    <CaseLawSearch
      cachedResults={results}
      cachedQuery={query}
      cachedMetareview={metareview}
    />
  )
};

export default ShowCachedResults;