import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import "./index.scss";
import React from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "../generic/Button";
import * as Yup from "yup";
import TextStrings from "./Strings";

const questions = {
  questionsGroup1: [
    "Jaka była nazwa ulicy, na której mieszkałeś(-aś) w dzieciństwie?",
    "Jaki był Twój pseudonim z dzieciństwa?",
    "Jakie było Twoje ulubione jedzenie w dzieciństwie?",
  ],
  questionsGroup2: [
    "Jaka była nazwa Twojej pierwszej pracy?",
    "Jaki był tytuł pierwszego filmu, który widziałeś(-aś) w kinie?",
    "Jaki był tytuł pierwszej książki, którą przeczytałeś(-aś) samodzielnie?",
    "Jak nazywał się Twój pierwszy nauczyciel w szkole podstawowej?",
  ],
  questionsGroup3: [
    "Jakie było Twoje ulubione miejsce zabawy w dzieciństwie?",
    "Jaką nazwę miał pierwszy zespół muzyczny, który polubiłeś(-aś)?",
    "Jakie było imię Twojego pierwszego szefa?",
  ],
};

const getQuestions = (group: 1 | 2 | 3, formik: FormikProps<any>) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`questionsGroupLabel${group}`}>
        Wybierz pytanie
      </InputLabel>
      <Select
        name={`questionsGroup${group}`}
        labelId={`questionsGroupLabel${group}`}
        value={formik.values[`questionsGroup${group}`]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {questions[`questionsGroup${group}`].map(
          (question: string, index: number) => (
            <MenuItem value={index}>{question}</MenuItem>
          )
        )}
      </Select>
      <ErrorMessage
        name={`questionsGroup${group}`}
        component="div"
        className="cx-error-message"
      />
    </FormControl>
  );
};

export const SecretAnswersFormFields = ({
  formik,
}: {
  formik: FormikProps<any>;
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        {getQuestions(1, formik)}
        <TextField
          name="secretAnswer1"
          label="Odpowiedź"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.secretAnswer1}
        />
        <ErrorMessage
          name="secretAnswer1"
          component="div"
          className="cx-error-message"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        {getQuestions(2, formik)}
        <TextField
          name="secretAnswer2"
          label="Odpowiedź"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.secretAnswer2}
        />
        <ErrorMessage
          name="secretAnswer2"
          component="div"
          className="cx-error-message"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        {getQuestions(3, formik)}
        <TextField
          name="secretAnswer3"
          label="Odpowiedź"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.secretAnswer3}
        />
        <ErrorMessage
          name="secretAnswer3"
          component="div"
          className="cx-error-message"
        />
      </div>
    </>
  );
};

const SecretAnswersForm = ({
  onSubmit,
  withFooter = true,
}: {
  onSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<any>;
  withFooter?: boolean;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Formik
      initialValues={{
        secretAnswer1: "",
        secretAnswer2: "",
        secretAnswer3: "",
        questionsGroup1: "",
        questionsGroup2: "",
        questionsGroup3: "",
      }}
      onSubmit={async (values: any, formikHelpers: FormikHelpers<any>) => {
        setIsLoading(true);
        await onSubmit(values, formikHelpers);
        setIsLoading(false);
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        secretAnswer1: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_secret_answer
        ),
        secretAnswer2: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_secret_answer
        ),
        secretAnswer3: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_secret_answer
        ),
        questionsGroup1: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_question
        ),
        questionsGroup2: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_question
        ),
        questionsGroup3: Yup.string().required(
          TextStrings.secret_answer_form.mandatory_question
        ),
      })}
    >
      {(formik) => (
        <Form
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "inherit",
            }}
          >
            <SecretAnswersFormFields formik={formik} />
            {withFooter && (
              <p id="secret-answers-warning">
                Zapamiętaj odpowiedzi na podane pytania. W przypadku zapomnienia
                hasła odpowiedzi będą niezbędne przy resetowaniu hasła
              </p>
            )}
            <Button variant="contained-dark" type="submit">
              {isLoading ? <CircularProgress /> : "Wyślij"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SecretAnswersForm;
