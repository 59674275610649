import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer, Extension } from "@tiptap/react";

import InsertableTag from "./InsertableTag";
import { Plugin } from "prosemirror-state";

const InsertableTagExtension = Node.create({
  name: "reactComponent",

  group: "inline",
  inline: true,
  content: "text*",

  atom: true,

  addAttributes() {
    return {
      id: {
        default: 0,
      },
      text: {
        default: "",
      },
      label: {
        default: "",
      },
      className: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "insertable-tag",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["insertable-tag", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(InsertableTag);
  },
});

const OrdinalTagExtension = Node.create({
  name: "ordinalTag",

  group: "inline",
  inline: true,
  content: "inline*",

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", { ...HTMLAttributes }, 0];
  },

  parseHTML() {
    return [
      {
        tag: "span[id]",
      },
    ];
  },
});

const markBold = (text) => {
  const element = document.getElementById("anonymize-button");
  if (element) {
    element.innerHTML = text;
  }
}

const BoldOnlyExtension = Extension.create({
  name: "boldOnly",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        filterTransaction: (transaction) => {
          const isProgrammatic = transaction.getMeta("programmatic") === true;

          const isContentSetting = transaction.steps.some(
            (step) => step.toJSON().stepType === "replace"
          );

          const boldTransaction = transaction.steps.every((step) => {
            const stepJSON = step.toJSON();
            const makeBold = (stepJSON.stepType === "addMark" && stepJSON.mark.type === "bold")
            const removeBold = (stepJSON.stepType === "removeMark" && stepJSON.mark.type === "bold")
            
            if (makeBold) markBold("Deanonimizuj");
            if (removeBold) markBold("Zanonimizuj");

            return makeBold || removeBold;
          });
          
          return (isProgrammatic && isContentSetting) || boldTransaction;
        },
      }),
    ];
  },
});

export { InsertableTagExtension, OrdinalTagExtension, BoldOnlyExtension };
