// @ts-ignore
import Cookies from 'js-cookie';
import React from 'react';
import { Button, Box, Typography, Modal, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import Colors from './colors';

const MODAL_STYLE = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "calc(100% - 40px)",
    maxWidth: "640px",
  maxHeight: "calc(100vh - 40px)",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    padding: "20px",
  overflowY: "scroll"
  };

  const BUTTON_STYLE = {
    height: "30px",
    backgroundColor: Colors.DarkBlue,
    fontFamily: "sofia-pro, sans-serif",
    fontWeight: "semibold",
    fontSize: "1rem",
    color: "white",
    ":hover": {
      backgroundColor: Colors.LightBlue,
      textDecoration: "none",
    },
  };

  const BUTTON_CONTAINER = {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    marginTop: "24px"
  }

  const BUTTON_DENY_STYLE = {
    height: "30px",
    backgroundColor: Colors.DarkBlue,
    fontFamily: "sofia-pro, sans-serif",
    fontWeight: "semibold",
    fontSize: "1rem",
    color: "white",
    margin: "0 auto 0 0",
    ":hover": {
      backgroundColor: Colors.LightBlue,
      textDecoration: "none",
    },
  }


interface ConsentCheckboxProps {
  title: string;
  desc?: string;
  value: boolean;
  onchange?: () => void;
  disabled: boolean;
}

const ConsentCheckbox: React.FC<ConsentCheckboxProps> = ({ title, desc, value, onchange, disabled }) => {
  return (
    <div
      onClick={disabled ? undefined : onchange}
      style={{
        borderBottom: "1px solid #E0E0E0",
        padding: "12px 0",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <div style={{flex: 1}}>
        <p style={{
          margin: 0
        }}>{title}</p>
        {desc && <p style={{
          color: "#8D9DA8",
          fontSize: "12px",
          margin: 0
        }}>{desc}</p>}
      </div>
      <div>
        <Checkbox
          checked={value}
          onChange={onchange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const CookieConsentModal = () => {
    const storedConsent = Cookies.get("consent");
    const consent = storedConsent ? JSON.parse(storedConsent) : {};

    const [modalOpen, setModalOpen] = React.useState(consent.necessary !== true);
    const [consents, setConsents] = React.useState({
      necessary: consent.necessary !== undefined ? consent.necessary : true,
      preferences: consent.preferences || false,
      statistics: consent.statistics || false,
      marketing: consent.marketing || false
    });

    const saveConsent = () => {
      Cookies.set("consent", JSON.stringify(consents), { expires: 1095 });
      setModalOpen(false);
    }
  
    return (
      <>
      <Modal open={modalOpen} style={{padding: "24px"}}>
        <Box sx={MODAL_STYLE}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color={Colors.DarkBlue} >
            Pliki Cookie: Dbamy o Twoją prywatność
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} color={"black"} >
            Potrzebujemy twojej zgody na używanie ściśle niezbędnych plików cookie. Przetwarzamy dane użytkownika w celu dostarczenia podstawowych funkcjonalności. Te preferencje można zmienić później w profilu użytkownika.
          </Typography>
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <ConsentCheckbox
              title={"Niezbędne"}
              desc={"Pliki cookie niezbędne do działania strony"}
              value={consents.necessary}
              disabled={true}
            />
            <ConsentCheckbox
              title={"Marketingowe*"}
              desc={"czyli pliki cookie sesyjne; Administrator przechowuje te dane do czasu cofnięcia wyrażonej przez Państwa zgody. Dane te są przetwarzane do czasu usunięcia danych lub do czasu cofnięcia zgody, które nie ma wpływu na przetwarzanie, którego dokonano przed cofnięciem zgody."}
              value={consents.marketing}
              onchange={() => setConsents({ ...consents, marketing: !consents.marketing })}
              disabled={false}
            />
            <ConsentCheckbox
              title={"Funkcjonalne"}
              desc={"czyli pliki cookie, które umożliwiają „zapamiętanie” wybranych przez Państwa ustawień i personalizację interfejsu, np. w zakresie wybranego języka, rozmiaru czcionki, wyglądu strony Strony Internetowej"}
              value={consents.preferences}
              onchange={() => setConsents({ ...consents, preferences: !consents.preferences })}
              disabled={false}
            />
            <ConsentCheckbox
              title={"Statystyczne"}
              desc={"czyli pliki cookie, umożliwiające zbieranie informacji o sposobie korzystania przez Państwa z Serwisu Internetowego i badanie Państwa działań podejmowanych w ramach Serwisu Internetowego"}
              value={consents.statistics}
              onchange={() => setConsents({ ...consents, statistics: !consents.statistics })}
              disabled={false}
            />
          </div>
          <Box sx={BUTTON_CONTAINER}>
            <Button sx={BUTTON_DENY_STYLE} onClick={() => {
              setConsents({
                necessary: true,
                preferences: false,
                statistics: false,
                marketing: false
              })
              saveConsent();
            }} >Odrzuć</Button>
            <Button sx={BUTTON_STYLE} onClick={saveConsent} >Zapisz</Button>
            <Button sx={BUTTON_STYLE} onClick={() => {
                setConsents({
                  necessary: true,
                  preferences: true,
                  statistics: true,
                  marketing: true
                })
                saveConsent();
            }} >Akceptuję wszystko</Button>
          </Box>
        </Box>
      </Modal>
      </>
    );
  };
  
  export default CookieConsentModal;