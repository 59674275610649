import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { Outlet, useNavigate } from "react-router";
// @ts-ignore
import Cookies from "js-cookie";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Tooltip
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./menu";
import useLogout from "../hooks/useLogout";
import useUserData from "../hooks/useUserData";
import ReportImprovementButton from "./reportImprovementButton";
import SearchBar from "./searchBar";

export default function Container() {
  const { userData } = useUserData();
  const tillTrialEnds = userData?.subscriptionStatus?.tillTrialEnds;
  const menuType = Cookies.get("folded");
  const logout = useLogout();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(
    menuType === "true" ? 150 : 300
  );
  const logoutIcon = require("~/public/static/img/logout.svg");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
          {/* <TopBar /> */}
        <div style={{ backgroundColor: "#F7F8FA" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ margin: "16px", display: { sm: "none" }, position: "absolute" }}
          >
            <MenuIcon style={{ fontSize: 30 }} />
          </IconButton>

          <div style={{
            width: "100%",
            maxWidth: "1320px",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center"
          }}><SearchBar /></div>
          <div style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            position: "absolute",
            right: 24,
            top: "50%",
            transform: "translateY(-50%)"
          }}>
          <button
            style={{
              background: "none",
              border: "none"
            }}
            onClick={() => {
              navigate("/profile");
            }}
          >
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "20px"
              }}>
                <p style={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#8D9DA8",
                  margin: 0,
                }}
                   className="hide-on-mobile"
                >Cześć!</p>
                <div style={{
                  position: "relative",
                  width: 48,
                  height: 48,
                  borderRadius: 56,
                  backgroundColor: "#384455",
                }}>
                  <div style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "#27D79E",
                    border: "3px solid #f7f8fa",
                    width: 16,
                    height: 16,
                    borderRadius: "50%"
                  }}></div>
                </div>
              </div>
          </button>
          <IconButton
            id={"logout-icon"}
            color="inherit"
            aria-label="profile"
            edge="end"
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            <Tooltip title="Wyloguj" sx={{ height: 64 }}>
              <img src={logoutIcon} alt="" style={{
                height: 24
              }} />
            </Tooltip>
          </IconButton>
          </div>
        </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            overflowX: "hidden",
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowX: "hidden",
            },
          }}
        >
          <Menu setDrawerWidth={setDrawerWidth} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowX: "hidden",
            },
          }}
          open
        >
          <Menu setDrawerWidth={setDrawerWidth} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: "36px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { sm: `${drawerWidth}px` },
          marginTop: tillTrialEnds ? "114px": "64px",
        }}
      >
        <ReportImprovementButton />
        <div className={"container"}>
          <Outlet />
        </div>
      </Box>
    </>
  );
}
