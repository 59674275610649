import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, LinearProgress, List, ListItem, ListItemText, Pagination, Paper, Stack, TextField, Typography, debounce } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useGaiusWebSocket from '../../hooks/useGaiusWebSocket';
import LinearDeterminate from '../../components/LinearBuffer';
import DocumentParser from '../../components/DocumentParser';
import getCSRFToken from "../../stores/CSRFStore";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import "./index.scss";

type DocumentQuestionAnswererProps = {
  path?: string;
  documentId?: string;
}

const DocumentQuestionAnswerer = ({ path, documentId }: DocumentQuestionAnswererProps) => {
  const [sampleQuestions, setSampleQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [sources, setSources] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const theme = useTheme();
  const axios = useAxiosPrivate();
  const params = new URLSearchParams(window.location.search);

  const { sendMessage, lastMessage } = useGaiusWebSocket("/ws/v1/query-doc");

  React.useEffect(() => {
    let url = `api/v1/sample-questions`;
    if (documentId)
      url += `?id=${documentId}`;
    else if (path)
      url += `?path=${path}`;
    else
      url += `?source=${params.get('source')}&docId=${params.get('docId')}`;

    axios.get(url)
      .then(response => {
        setSampleQuestions(response.data.questions);
      });
  }, []);

  async function askQuestion(question: string) {
    const msg = {
      path: path?.replace("//", ""),
      id: documentId,
      text: question,
      isFile: true,
      source: params.get('source'),
      docId: params.get('docId'),
    };
    sendMessage(JSON.stringify(msg));
    setLoading(true);
    setAnswer("");
    setSources([]);
  }

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "searching") {
        setMessage("Wyszukiwanie...");
      }
      else if (data.message === "verifying") {
        setMessage("Analiza wyników...");
      }
      else if (data.message === "generating") {
        setLoading(false);
        setMessage("");
        setAnswer(data.answer);
        setSources(data.documents);
      }
    }

  }, [lastMessage]);

  return (
    <Box p={3}>
      <Stack direction="column" spacing={2}>
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Zadaj pytanie..."
            inputProps={{ "aria-label": "zadaj pytanie" }}
            onChange={(event: { target: { value: any; }; }) => setQuestion(event.target.value)}
            onKeyDown={(event: { keyCode: number; }) => {
              if (event.keyCode === 13) {
                askQuestion(question);
              }
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => askQuestion(question)}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Stack>
      {sampleQuestions.map(
        (sampleQuestion: {
          question: string;
          answer: string;
          source: string;
        }) => (
          <Box mt={1} mb={1}>
            <Button
              variant="outlined"
              onClick={() => {
                setQuestion(sampleQuestion.question);
                setAnswer(sampleQuestion.answer);
                setSources([sampleQuestion.source]);
              }}
            >
              {sampleQuestion.question}
            </Button>
          </Box>
        )
      )}
      {loading && (
        <>
          <Typography variant="body1" component="p">
            {message}
          </Typography>
          <LinearDeterminate maxTime={20} />
        </>
      )}
      {!loading && answer && (
        <Box
          p={2}
          className="shadow-sm"
          sx={{
            border: "2px solid " + theme.palette.primary.main,
            borderRadius: "8px",
          }}
        >
          <Typography variant="body1" component="p">
            {answer}
          </Typography>
        </Box>
      )}
      {!loading && answer && (
        <Box mt={2}>
          <Typography variant="h6" component="h6">
            Źródła:
          </Typography>
          {sources.map((source: any) => (
            <ExpandableBox text={source} />
          ))}
        </Box>
      )}
    </Box>
  );
}

const DocumentSearch = ({
  path,
  documentId,
  setAppliedSearchTerm,
  setSearchResults,
}: {
  path?: string;
  documentId?: string;
  setAppliedSearchTerm?: any;
  setSearchResults?: any;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const axios = useAxiosPrivate();

  const searchDocument = React.useCallback((value: string) => {
    if (value.length < 3) setSearchResults([]);

    let url;
    if (path) url = `api/v1/document-search?path=${path}&query=${value}`;
    else if (documentId)
      url = `api/v1/document-search?id=${documentId}&query=${value}`;
    else url = `api/v1/document-search${window.location.search}&query=${value}`;

    axios
      .get(url)
      .then((response) => {
        setSearchResults(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const debouncedSearchDocument = React.useMemo(
    () => debounce(searchDocument, 500),
    [searchDocument]
  );
  const debouncedSetSearchTerm = React.useMemo(
    () => debounce(setAppliedSearchTerm, 500),
    [setAppliedSearchTerm]
  );

  return (
    <Stack direction="column" spacing={2} padding={3}>
      <Paper
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Przeszukaj dokument..."
          inputProps={{ "aria-label": "przeszukaj dokument" }}
          onChange={(event: { target: { value: any } }) => {
            setSearchTerm(event.target.value);
            debouncedSetSearchTerm(event.target.value);
            debouncedSearchDocument(event.target.value);
          }}
          onKeyDown={(event: { keyCode: number }) => {
            if (event.keyCode === 13) {
              searchDocument(searchTerm);
            }
          }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => searchDocument(searchTerm)}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
};

const DocumentViewer = ({ path, documentId }: { path?: string, documentId?: string }) => {
  const [document, setDocument] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [appliedSearchTerm, setAppliedSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Array<[string, number]>>([]);
  const [url, setUrl] = useState('');
  const params = new URLSearchParams(window.location.search);
  const [expandedSection, setExpandedSection] = useState('questionAnswer');
  const [loadingMore, setLoadingMore] = useState(false);

  const axios = useAxiosPrivate();
  let scroll: ((this: Window, ev: Event) => any) | null = null;

  useEffect(() => {
    if (documentId) {
      axios
        .get(`api/v1/judgement-url?id=${documentId}`)
        .then((response) => {
          setUrl(response.data.url);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    let url;
    if (path) url = `api/v1/document?path=${path}&page=${page}`;
    else if (documentId) url = `api/v1/document?id=${documentId}&page=${page}`;
    else url = `api/v1/document${window.location.search}&page=${page}`;

    setLoadingMore(true);

    axios
      .get(url)
      .then((response) => {
        setDocument(document + response.data.content);
        setTotalPages(response.data.totalPages);
        setUrl(response.data.url);
        if (scroll === null) {
          scroll = () => handleScroll(page, response.data.totalPages);
          window.addEventListener('scroll', scroll);
        }
        setLoading(false);
        setLoadingMore(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingMore(false);
      });
  }, [path, documentId, page]);

  React.useEffect(() => {
    getCSRFToken();
    return () => {
      if (scroll) {
        scroll && window.removeEventListener('scroll', scroll);
      }
    }
  }, []);

  const downloadFile = () => {
    const win = window.open(`${process.env.BACKEND_ENDPOINT}/api/v1/download${window.location.search}`, '_blank');
    win?.focus();
  }

  const handleScroll = (currentPage: number, currentTotalPages: number) => {
    if (currentTotalPages === undefined) return;
    if (window.innerHeight + window.scrollY >= window.document.body.offsetHeight - 100 && currentPage < currentTotalPages) {
      setPage(currentPage + 1)
    }
  }

  return (
    <Grid container spacing={2} id="document-viewer">
      <Grid item sm={12} md={8} p={2}>
        <Box p={3} id="document-viewer-box" >
          {/* Search box */}
          {searchResults.length > 0
            ? searchResults.map((result: [string, number]) => (
              <SearchResult
                text={result[0]}
                onClick={async () => {
                  setPage(result[1] + 1);
                  setSearchResults([]);
                }}
                query={appliedSearchTerm}
                key={result[1].toString()}
              />
            ))
            : null}
          {searchResults.length === 0 ? (
            <Paper elevation={0}>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={200}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="body1"
                    component="pre"
                    sx={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      textAlign: "justify",
                    }}
                  >
                    <DocumentParser
                      document={document}
                      parse={true}
                      searchTerm={appliedSearchTerm}
                    />
                    {loadingMore && <LinearProgress />}
                  </Typography>
                </Box>
              )}
            </Paper>
          ) : null}
        </Box>
      </Grid>
      <Grid item sm={12} md={4} p={2}>
        <List id={"document-search-options-list"}>
          {url && (
            <ListItem
              button
              onClick={() => {
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
              }}
            >
              <OpenInNewIcon />
              <ListItemText primary="Przejdź do oryginalnego dokumentu" />
            </ListItem>
          )}
          <ListItem
            button
            onClick={downloadFile}
          >
            <DownloadIcon />
            <ListItemText primary="Pobierz jako DOCX" />
          </ListItem>
          <ListItem
            button
            onClick={() => setExpandedSection("search")}
            selected={expandedSection === "search"}
          >
            <SearchIcon />
            <ListItemText primary="Przeszukaj dokument" />
          </ListItem>
          <Collapse in={expandedSection === "search"}>
            <DocumentSearch
              path={path}
              documentId={documentId}
              setAppliedSearchTerm={setAppliedSearchTerm}
              setSearchResults={setSearchResults}
            />
          </Collapse>
          <ListItem
            button
            onClick={() => setExpandedSection("questionAnswer")}
            selected={expandedSection === "questionAnswer"}
          >
            <QuestionMarkIcon />
            <ListItemText primary="Zadaj pytanie" />
          </ListItem>
          <Collapse in={expandedSection === "questionAnswer"}>
            <DocumentQuestionAnswerer path={path} documentId={documentId} />
          </Collapse>
        </List>
        <CitingDocuments
          documentId={params.get("docId") || ""}
          source={params.get("source") || ""}
        />
      </Grid>
    </Grid>
  );
}

const ShowDocument = () => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path') || '';
  const documentId = searchParams.get('id') || '';

  return (
    <Box>
      <DocumentViewer path={path} documentId={documentId} />
    </Box>
  );
}

export default ShowDocument;

function ExpandableBox({ text }: any) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
      p={2}
      className="shadow-sm"
      sx={{
        borderRadius: "8px",
        maxHeight: expanded ? "none" : "200px",
        overflow: "hidden",
        cursor: "pointer",
        textFillColor: expanded ? "none" : "transparent",
        backgroundImage: expanded ? "none" : "linear-gradient(black, white)",
        backgroundClip: "text",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Typography variant="body1" component="p">
        {text}
      </Typography>
    </Box>
  );
}


const SearchResult = ({ query, text, onClick }: { query: string, text: string, onClick: () => {} }) => {
  return (
    <Paper elevation={0} className="hover-shadow mb-2 p-3" onClick={onClick}>
      <Typography variant="body1" component="pre" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", textAlign: "justify" }}>
        <DocumentParser document={text} parse={true} searchTerm={query} />
      </Typography>
    </Paper>
  );
}

const CitingDocuments = ({ documentId, source }: { documentId: string, source: string }) => {
  const [citingDocuments, setCitingDocuments] = useState([]);
  const axios = useAxiosPrivate();

  useEffect(() => {
    axios.get(`api/v1/citing-documents?docId=${documentId}&source=${source}`)
      .then(response => {
        setCitingDocuments(response.data.citations);
      });
  }, []);

  if (citingDocuments.length === 0) {
    return (
      <Box id="citing-documents-box" >
        <h1>
          Cytujące dokumenty
        </h1>
        <Box p={2}>
          <Typography variant="body1" component="p">
            Brak cytujących dokumentów
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant='h6' component="h6">
        Cytujące dokumenty
      </Typography>
      {citingDocuments.map((document: any) => {
        // highlight documentId
        const text = document.text.replace(documentId, `<strong>${documentId}</strong>`);
        const url = "?docId=" + document.doc_id + "&source=" + document.source;
        return (
          <CitationExpandableBox text={text} title={document.doc_id} url={url} />
        )
      })}
    </Box>
  );
}

const CitationExpandableBox = ({ text, title, url }: any) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Paper
      className="p-2 mb-2 shadow-sm"
      sx={{
        borderRadius: "8px",
        maxHeight: expanded ? "none" : "100px",
        overflow: "hidden",
        cursor: "pointer",
        textFillColor: expanded ? "none" : "transparent",
        backgroundImage: expanded ? "none" : "linear-gradient(black, white)",
        backgroundClip: "text",
        backgroundColor: expanded ? "white" : "transparent",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Typography variant="h6" component="h6">
        {title}
      </Typography>
      <Typography variant="body1" component="p" sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: text }} />
      <Button
        variant="text"
        href={url}
        target="_blank"
        rel="noreferrer"
        fullWidth
        className="p-3 mt-4"
      >
        Przejdź
      </Button>
    </Paper>
  );
}