import React, {useEffect, useState} from "react";
import "./PaymentsHistory.scss";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const PaymentsHistory = () => {
    const axios = useAxiosPrivate();

    const [payments, setPayments] = useState([]);

    useEffect(() => {
        axios.get("payment-api/payments-history").then((response) => {
            setPayments(response.data.payments);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    return (
      <div className="payments__header payments__box">
          <h2 className="payments__header-h2">Historia płatności</h2>
          {payments ?
            <div className={"payments__history"}>
                <table className={"payments__history-table"}>
                    <thead>
                    <tr>
                        <th>Data</th>
                        <th>Tytuł</th>
                        <th>Kwota</th>
                        <th>Status</th>
                        <th className={"payments__history__action"}>Akcja</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map((payment: any) => (
                      <tr key={payment.id}>
                          <td>{payment.created}</td>
                          <td className={"payments__history__name"}>Gaius Lex</td>
                          <td className={"payments__history__amount"}>

                              {payment.amount / 100} {payment.currency}
                          </td>
                          <td><span className={"payments__history__status payments__history__status-"+payment.status}>{payment.status_name}</span></td>
                          <td className={"payments__history__action"}><a href={payment.receipt_url} target={"_blank"}>Zobacz płatność</a></td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>: <>Brak płatności</>}
      </div>
    )
}

export default PaymentsHistory