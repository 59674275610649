import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./index.scss";

interface OnboardingModalProps {
  videos: string[];
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ videos }) => {
  const [currentVideo, setCurrentVideo] = useState<string>(videos[0]);
  const [open, setOpen] = useState<boolean>(false);
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchThumbnails = async () => {
      const thumbnailData: { [key: string]: string } = {};
      for (const videoId of videos) {
        const thumbnailUrl = await fetchVimeoThumbnail(videoId);
        thumbnailData[videoId] = thumbnailUrl;
      }
      setThumbnails(thumbnailData);
    };

    fetchThumbnails();
  }, [videos]);

  const fetchVimeoThumbnail = async (videoId: string): Promise<string> => {
    const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);
    const data = await response.json();
    return data.thumbnail_url;
  };

  return (
    <div>
      <Button id="onboarding-button" onClick={() => setOpen(true)}>Jak to działa?</Button>
      {open && (
        <div className={"onboardingModal"}>
          <div className="onboardingModal__container">
            <iframe
              src={`https://player.vimeo.com/video/${currentVideo}`}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="onboardingModal__videoList">
              {videos.map((videoId) => (
                <div
                  key={videoId}
                  onClick={() => setCurrentVideo(videoId)}
                  className={`onboardingModal__videoList__video ${
                    currentVideo === videoId ? "onboardingModal__videoList__video--selected" : ""
                  }`}
                >
                  {thumbnails[videoId] && (
                    <img
                      src={thumbnails[videoId]}
                      alt="video"
                    />
                  )}
                </div>
              ))}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => setOpen(false)}>Wróć</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingModal;