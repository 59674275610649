import axios from "../api/axios";
import useUserData from "./useUserData";
import getCSRFToken from "../stores/CSRFStore";

const useLogout = () => {
  const { setUserData } = useUserData();

  const logout = async () => {
    await getCSRFToken();
    setUserData({});
    try {
      await axios("/rest-auth/logout/", {
        method: "POST",
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
