import React, { createContext, useState } from 'react';

interface SnackbarContextProps {
  open: boolean;
  message: string;
  duration: number;
  severity: 'success' | 'error' | 'warning' | 'info';
  setOpen: (open: boolean) => void;
  setMessage: (message: string) => void;
  setDuration: (duration: number) => void;
  setSeverity: (severity: 'success' | 'error' | 'warning' | 'info') => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  open: false,
  message: '',
  duration: 6000,
  severity: 'success',
  setOpen: () => {},
  setMessage: () => {},
  setDuration: () => {},
  setSeverity: () => {},
});

export const SnackbarProvider: React.FC<{ children:  JSX.Element |  JSX.Element[] }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('Initial message');
  const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [duration, setDuration] = useState<number>(6000);

  const value = {
    open,
    message,
    duration,
    severity,
    setOpen,
    setMessage,
    setDuration,
    setSeverity,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};