import React, { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import Button from "../../components/generic/Button";

export const ShareResultsButton = ({ metareview, query, results }: { metareview: string; query: string; results: any; }) => {
  const [url, setUrl] = useState<string>("");
  const snackBarContext = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();

  const onClick = async () => {
    if (url !== "") {
      navigator.clipboard.writeText(url);
      snackBarContext.setMessage("Skopiowano link do schowka");
      snackBarContext.setSeverity("success");
      snackBarContext.setOpen(true);
    }

    await getCSRFToken();
    axios.post(`api/v1/share-results`, {
      "query": query,
      "metaanalysis": metareview,
      "results": results,
    })
      .then(response => {
        const url = `${window.location.origin}/show-results?key=${response.data.key}`;
        setUrl(url);
        navigator.clipboard.writeText(url);
      })
      .catch(error => {
        snackBarContext.setMessage("Wystąpił błąd, skontaktuj się z pomocą techniczną");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  };

  return (
    <Button variant="outlined-light" width={"100%"} onClick={onClick} className="my-3">Skopiuj link do wyników</Button>
  );
};
