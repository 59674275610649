import React from "react";
import TextField from "@mui/material/TextField";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MDBCheckbox, MDBRow } from 'mdb-react-ui-kit';
import { Link, Typography } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { SnackbarContext } from '../../contexts/SnackbarContext';
import CircularProgress from '@mui/material/CircularProgress';
import useLogout from "../../hooks/useLogout";
import Button from "../../components/generic/Button";
import "./index.scss";
import Background from "../../components/generic/Background";



enum ActivationStep {
    SubmitPhoneNumber = 0,
    SubmitVerificationCode = 1
}

const SubmitPhoneNumber = ({ setActiveStep }: any) => {
    const phoneNumberRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const axiosPrivate = useAxiosPrivate();
    const snackbar = React.useContext(SnackbarContext);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
      <Formik
        initialValues={{
          phoneNumber: "",
          phoneConsent: false,
          marketingConsent: false,
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          await getCSRFToken();

          axiosPrivate
            .post(
              `${process.env.BACKEND_ENDPOINT}/core_api/verify-phone-number-and-consent`,
              JSON.stringify({
                phoneNumber: values.phoneNumber,
                phoneConsent: values.phoneConsent,
                marketingConsent: values.marketingConsent,
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              setActiveStep(ActivationStep.SubmitVerificationCode);
              return response.data;
            })
            .catch((error) => {
              if (error.response.status == 400) {
                (snackbar as any).setMessage(error.response.data.error);
                (snackbar as any).setSeverity("error");
                (snackbar as any).setOpen(true);
              } else {
                (snackbar as any).setMessage(
                  "Wystąpił błąd podczas wysyłania smsa z kodem weryfikacyjnym, skontaktuj się z nami lub spróbuj ponownie później"
                );
                (snackbar as any).setSeverity("error");
                (snackbar as any).setOpen(true);
              }
              console.log(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .required("Numer telefonu jest wymagany")
            .matches(phoneNumberRegex, "Numer telefonu jest niepoprawny"),
          phoneConsent: Yup.boolean().oneOf(
            [true],
            "Zaakceptuj zgodę na kontakt telefoniczny"
          ),
          // marketingConsent: Yup.boolean().oneOf([true], "Musisz wyrazić zgodę na kontakt marketingowy")
        })}
      >
        {(formik) => (
          <Form style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "5px",
                  margin: "20px 0",
                }}
              >
                <span className="py-3">+48</span>
                <TextField
                  type="phoneNumber"
                  name="phoneNumber"
                  label="Numer telefonu"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MDBCheckbox
                  id="phone-consent-input"
                  name="phoneConsent"
                  checked={formik.values.phoneConsent}
                  onChange={formik.handleChange}
                  label={
                    "Wyrażam zgodę na kontakt telefoniczny przez przedstawicieli Administratora w celu prezentowania treści marketingowych oraz informacji handlowych o usługach świadczonych przez Administratora. Zostałem poinformowany o tym, że mogę wycofać zgodę w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem."
                  }
                />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="cx-error-message-password py-3 px-4"
                />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ErrorMessage
                  name="phoneConsent"
                  component="div"
                  className="cx-error-message-password py-3 px-4"
                />
              </div>
              <div
                style={{
                  maxWidth: "300px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <MDBCheckbox
                            name="marketingConsent"
                            label="Wyrażam zgodę na kontakt marketingowy w celu przedstawienia oferty produktu."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={String(formik.values.marketingConsent)}
                            onClick={() => {
                                formik.setFieldValue("marketingConsent", !formik.values.marketingConsent);
                            }}
                        /> */}
                {/* <FormControlLabel
                            required
                            control={<Checkbox />}
                            label="Wyrażam zgodę na kontakt marketingowy w celu przedstawienia oferty produktu."
                            name="marketingConsent"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.marketingConsent}
                            /> */}
              </div>
              <div style={{ color: "red" }}>
                <ErrorMessage
                  name="marketingConsent"
                  component="div"
                  className="cx-error-message-password py-3 px-4"
                />
              </div>
              <Button variant="contained-dark" type="submit">
                {isLoading ? <CircularProgress /> : "Wyślij kod"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
}

const SubmitVerificationCode = () => {
    const axiosPrivate = useAxiosPrivate();
    const snackbar = React.useContext(SnackbarContext);
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <>
            <Typography className="pb-4">Na podany numer został wysłany kod weryfikacyjny, wpisz go tutaj a następnie kliknij przycisk "zweryfikuj konto"</Typography>
            <Formik
                initialValues={{ verificationCode: "" }}
                onSubmit={(values) => {
                    setIsLoading(true);
                    getCSRFToken().then(() => {
                        axiosPrivate.post(`${process.env.BACKEND_ENDPOINT}/core_api/verify-sms-code`,
                            JSON.stringify({
                                verificationCode: values.verificationCode
                            }),
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        ).then(response => {
                            console.log(response.data);
                            (snackbar as any).setMessage('Konto zostało zweryfikowane');
                            (snackbar as any).setSeverity('success');
                            (snackbar as any).setOpen(true);
                            document.location.href = "/chat";
                        })
                            .catch(error => {
                                console.log(error)
                                if (error.response.status == 400) {
                                    (snackbar as any).setMessage('Wprowadzony kod jest niepoprawny, spróbuj ponownie');
                                    (snackbar as any).setSeverity('error');
                                    (snackbar as any).setOpen(true);
                                } else {
                                    console.log(error);
                                    (snackbar as any).setMessage('Wystąpił błąd podczas wysyłania kodu weryfikacyjnego, sprawdź czy masz połączenie z internetem lub skontaktuj się z nami');
                                    (snackbar as any).setSeverity('error');
                                    (snackbar as any).setOpen(true);
                                }
                            }).finally(() => {
                                setIsLoading(false);
                            });
                    })
                        .catch(error => {
                            console.log(error);
                            (snackbar as any).setMessage('Wystąpił błąd podczas wysyłania kodu weryfikacyjnego, sprawdź czy masz połączenie z internetem lub skontaktuj się z nami');
                            (snackbar as any).setSeverity('error');
                            (snackbar as any).setOpen(true);

                        })
                }}

                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    verificationCode: Yup.string().required("Kod weryfikacyjny jest wymagany")
                })}

            >
                {(formik) => (
                    <Form style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "5px" }}>
                                <TextField
                                    type="verificationCode"
                                    name="verificationCode"
                                    label="Kod weryfikacyjny"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.verificationCode}
                                />
                            </div>
                            <div style={{ color: "red", display: "flex", justifyContent: "center" }}>
                                <ErrorMessage
                                    name="verificationCode"
                                    component="div"
                                    className="cx-error-message-password py-3 px-4"
                                />
                            </div>
                            <Button variant="contained-dark" type="submit">{isLoading ? <CircularProgress /> : "Zweryfikuj konto"}</Button>

                        </div>
                    </Form>
                )}

            </Formik>
        </>);
}

const ActivateTrial = () => {
    const [activeStep, setActiveStep] = React.useState(ActivationStep.SubmitPhoneNumber);
    const logout = useLogout();

    // const handleSubmitPhoneNumer = () => {
    //     ;
    // }


    // possibly use stepper here
    return (
        <Background>
            <MDBRow style={{ justifyContent: "center", marginBottom: "50px", height: "70vh" }}>
            <div id={"at-container"} style={{ display: "flex", flexDirection: "column", width: "60%", height: "fit-content" }}>
                <h1>Cześć!</h1>
                <h3>Witamy w aplikacji Gaius-Lex!</h3>
                <p>
                    Przed Tobą ostatni krok, który pozwoli Ci na pełne korzystanie z aplikacji! <br />
                </p>
                <p>
                    Aby rozpocząć korzystanie z aplikacji bez konieczności podawania danych karty, w polu tekstowym poniżej podaj swój numer telefonu. <br />
                    Na podany numer wyślemy sms z kodem weryfikacjnym.
                </p>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    {/* <Stepper activeStep={1} alternativeLabel>
                        {steps.map(({ label, content }) => (
                            < Step key={label} >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper> */}
                    <div style={{ alignSelf: "center" }} className="px-5 pb-3">
                        {activeStep == ActivationStep.SubmitPhoneNumber && <SubmitPhoneNumber setActiveStep={setActiveStep} />}
                        {activeStep == ActivationStep.SubmitVerificationCode && <SubmitVerificationCode />}
                    </div>
                    <Typography id={"at-logout"} className="py-3" sx={{ alignSelf: "center" }}>
                        Złe konto? <Link style={{color: "#17181A", fontWeight: "bold" }} onClick={() => { logout() }}>Wyloguj się</Link>
                    </Typography>
                </div>

            </div>
            </MDBRow>
        </Background>
    )
}

export default ActivateTrial;