import React, { useState } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import Button from "@mui/material/Button";
import { IconButton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Message, MessageProps } from "./Message";

export const FollowUpDialog = (
  { question, initialMessage, open, handleClose, lastMessage, sendMessage }: { question: string; initialMessage: string; open: boolean; handleClose: () => void; lastMessage: any; sendMessage: (message: string) => void; }
): React.JSX.Element => {

  const [dialogSoFar, setDialogSoFar] = useState<MessageProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const lastItemRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setDialogSoFar([{
      "role": "user",
      "message": question,
      "type": "conversation"
    }, {
      "role": "gaius",
      "message": initialMessage,
      "type": "conversation"
    }]);
  }, [initialMessage, question]);

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "followup_done") {
        setDialogSoFar([...dialogSoFar, {
          "role": "gaius",
          "message": data.answer,
          "type": "conversation"
        }]);
        setLoading(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (lastItemRef.current) {
      lastItemRef.current.scrollIntoView(false);
    }
  }, [loading]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Dopytaj o wyniki</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText ref={lastItemRef} className="d-flex flex-column">
          {/* A beautifully formated dialog between role:bot and role:user */}
          {dialogSoFar.map((dialog: any) => {
            if (dialog.role === "gaius") {
              return (
                <Message message={dialog} />
              );
            }
            else {
              return (
                <Message message={dialog} />
              );
            }
          })}
          {loading && <Message message={{ "role": "gaius", "message": "...", "type": "conversation" }} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Formik initialValues={{ followUp: "" }}
          onSubmit={(values, { resetForm }) => {
            if (loading)
              return;
            if (values.followUp === "")
              return;
            setDialogSoFar([...dialogSoFar, {
              "role": "user",
              "message": values.followUp,
              "type": "conversation"
            }]);
            sendMessage(JSON.stringify({
              "factualState": values.followUp,
              "mode": "followup"
            }));
            setLoading(true);
            resetForm();
          }}
          validationSchema={Yup.object({
            followUp: Yup.string().required("Pole wymagane"),
          })}>
          {(formik) => {
            return (
              <Form style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                <TextField
                  label="Zadaj pytanie"
                  id="followUp"
                  name="followUp"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.followUp}
                  className="w-100" />
                <ErrorMessage name="followUp" component="div" />
                <Button style={{ width: "fit-content", display: "flex", alignContent: "center" }}
                  className="mx-1 my-3"
                  id="search-button"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >Wyślij</Button>
              </Form>
            );
          }}
        </Formik>
      </DialogActions>
      <Typography variant="body1" color="text.secondary" className="small pl-3 pr-3 pb-3 pt-0 mt-0 text-center">
        Gaius ma teraz dostęp tylko do wyszukanych wcześniej orzeczeń.
      </Typography>
    </Dialog>
  );
};
