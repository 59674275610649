import LocalizedStrings from "react-localization";

let lang = "pl";
if (document.location.pathname.includes("/en/")) lang = "en";

const TextStrings = new LocalizedStrings({
  pl: {
    document: {
        encrypted_tooltip: "Nikt poza Tobą nie ma dostępu do zawartości tego pliku"
    }
  },
  en: {
    document: {
        encrypted_tooltip: "No one but you can access the contents of this file"
    }
  },
});

TextStrings.setLanguage(lang);

export default TextStrings;