import { NodeViewWrapper } from "@tiptap/react";
import React from "react";

const InsertableTag = (props: any) => {
  const { id, text, label, className } = props.node.attrs;

  return (
    <NodeViewWrapper as="span" className={`${className}`}>
      {label && <span className="text-anonymizer-label">{label}</span>}
      <span contentEditable={false} id={`marker-${id}`} className="text-anonymizer-marker">
        {text}
      </span>
    </NodeViewWrapper>
  );
};

export default InsertableTag;
