import * as GaiusColors from "../../components/colors";

const Colors = {
  ButtonBackground: "#0e2451",
  ButtonHover: "#113C4A",
  ButtonText: "#ffffff",
}

export const LoginButtonStyleSX = {
  height: "50px",
  width: "100%",
  backgroundColor: Colors.ButtonBackground,
  fontFamily: "sofia-pro, sans-serif",
  fontWeight: "semibold",
  fontSize: "1rem",
  color: "white",
  ":hover": {
    color: Colors.ButtonText,
    backgroundColor: Colors.ButtonHover,
    textDecoration: "none",
  },
};