import React from "react";
import Button from "@mui/material/Button";
import { Popover } from "@mui/material";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import Colors from "../../components/colors";
import { ReviewBox } from "./ReviewBox";

export const ReviewAnswerButton = ({ type, answerKey, url }: { type: "up" | "down"; answerKey: string; url?: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <ReviewBox score={type === "up" ? 1 : 0} answerKey={answerKey} handleClose={handleClose} url={url} />
      </Popover>
      <Button style={{ width: "fit-content", display: "flex", alignContent: "center", justifySelf: "center", fontSize: "0.7rem" }}
        className="mx-1 my-3"
        id="search-button"
        variant="text"
        type="submit"
        onClick={handleClick}
      >{type === "up" ?
        <ThumbUp fontSize="medium" sx={{ color: Colors.getHue(Colors.FlatHubGreen, 20) }} /> :
        <ThumbDown fontSize="medium" sx={{ color: Colors.getHue(Colors.Red, 30) }} />}
      </Button>
    </>
  );
};
