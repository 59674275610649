{
    "quotes": [
      {
        "latin": "Fiat justitia, ruat caelum.",
        "translation": "Niech sprawiedliwość stanie się, niech nawet niebiosa runą."
      },
      {
        "latin": "Lex iniusta non est lex.",
        "translation": "Niesprawiedliwe prawo nie jest prawem."
      },
      {
        "latin": "Audi alteram partem.",
        "translation": "Słuchaj drugiej strony."
      },
      {
        "latin": "Ignorantia legis non excusat.",
        "translation": "Nieznajomość prawa nie usprawiedliwia."
      },
      {
        "latin": "Nemo dat quod non habet.",
        "translation": "Nikt nie daje tego, czego nie ma."
      },
      {
        "latin": "Falsus in uno, falsus in omnibus.",
        "translation": "Fałsz w jednej rzeczy, fałsz we wszystkim."
      },
      {
        "latin": "Actus non facit reum, nisi mens sit rea.",
        "translation": "Czyn nie czyni winnym, chyba że umysł jest zły."
      },
      {
        "latin": "In dubio pro reo.",
        "translation": "W razie wątpliwości na korzyść oskarżonego."
      },
      {
        "latin": "Res ipsa loquitur.",
        "translation": "Samo zdarzenie mówi samo za siebie."
      },
      {
        "latin": "De minimis non curat praetor.",
        "translation": "Praetor nie zajmuje się drobnostkami."
      },
      {
        "latin": "Alea iacta est.",
        "translation": "Kostka została rzucona."
      },
      {
        "latin": "Consensus facit legem.",
        "translation": "Zgoda tworzy prawo."
      },
      {
        "latin": "Ex turpi causa non oritur actio.",
        "translation": "Z niegodziwej przyczyny nie wyłania się żadne roszczenie."
      },
      {
        "latin": "Cuius est solum eius est usque ad coelum et ad inferos.",
        "translation": "Czyje jest ziemia, tego jest również niebo i piekło."
      },
      {
        "latin": "Dura lex, sed lex.",
        "translation": "Twarde prawo, ale prawo."
      },
      {
        "latin": "Pacta sunt servanda.",
        "translation": "Umów należy dotrzymywać."
      },
      {
        "latin": "Qui tacet consentire videtur.",
        "translation": "Kto milczy, zgadza się."
      },
      {
        "latin": "Salus populi suprema lex esto.",
        "translation": "Dobro ludu niech będzie najwyższym prawem."
      },
      {
        "latin": "Ubi jus ibi remedium.",
        "translation": "Gdzie jest prawo, tam jest remedium."
      },
      {
        "latin": "Veritas vos liberabit.",
        "translation": "Prawda was wyzwoli."
      },
      {
        "latin": "Fiat voluntas tua.",
        "translation": "Niech spełni się twoja wola."
      },
      {
        "latin": "Homo homini lupus est.",
        "translation": "Człowiek człowiekowi wilkiem."
      },
      {
        "latin": "Lex specialis derogat legi generali.",
        "translation": "Prawo szczególne uchyla prawo ogólne."
      },
      {
        "latin": "Nemo judex in causa sua.",
        "translation": "Nikt nie jest sędzią we własnej sprawie."
      },
      {
        "latin": "Qui facit per alium, facit per se.",
        "translation": "Kto działa przez innego, działa na własny rachunek."
      },
      {
        "latin": "Semper in dubio pro reo.",
        "translation": "Zawsze w razie wątpliwości na korzyść oskarżonego."
      },
      {
        "latin": "Vox populi, vox Dei.",
        "translation": "Głos ludu, głos Boga."
      },
      {
        "latin": "Fiat justitia et pereat mundus.",
        "translation": "Niech stanie się sprawiedliwość, choćby świat miał zginąć."
      },
      {
        "latin": "Nemo est supra leges.",
        "translation": "Nikt nie jest ponad prawem."
      },
      {
        "latin": "Acta est fabula, plaudite!",
        "translation": "Gra zakończona, brawa!"
      },
      {
        "latin": "In flagrante delicto.",
        "translation": "Na gorącym uczynku."
      },
      {
        "latin": "Non bis in idem.",
        "translation": "Nie dwa razy w tej samej sprawie."
      },
      {
        "latin": "Ex post facto.",
        "translation": "Po fakcie."
      },
      {
        "latin": "Summum ius, summa iniuria.",
        "translation": "Najwyższe prawo, najwyższa niesprawiedliwość."
      },
      {
        "latin": "De lege ferenda.",
        "translation": "O koniecznych zmianach prawa."
      },
      {
        "latin": "Caveat emptor.",
        "translation": "Niech kupujący będzie ostrożny."
      },
      {
        "latin": "Mater semper certa est.",
        "translation": "Matka zawsze jest pewna."
      },
      {
        "latin": "Jura novit curia.",
        "translation": "Sąd zna prawo."
      },
      {
        "latin": "Malum in se.",
        "translation": "Zło samo w sobie."
      },
      {
        "latin": "Audi et alteram partem.",
        "translation": "Słuchaj obu stron."
      }
    ]
}
