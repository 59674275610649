import React from "react";
import { Outlet } from "react-router";
import useUserData from "../hooks/useUserData";
import { useLocation, Navigate } from "react-router-dom";

const RequireAuth = ({ premiumRequired }) => {
  const { userData } = useUserData();

  const location = useLocation();
  const redirectUri = userData?.isLoggedIn
    ? "/payments"
    : "/login";
  const isPremiumUser = userData.subscriptionStatus?.enabled;

  if (
    (premiumRequired && isPremiumUser) ||
    (!premiumRequired && userData?.isLoggedIn)
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={redirectUri} state={{ from: location }} replace />;
  }
};

export default RequireAuth;
