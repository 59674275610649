import React from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import Button from "../../components/generic/Button";
import { default as MuiButton } from "@mui/material/Button";
import { Colors } from "../../components/generic/Colors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import getCSRFToken from "../../stores/CSRFStore";
import "./index.scss";

const dissatisfied = require("~/public/static/img/dissatisfied.png");
const moderate = require("~/public/static/img/moderate.png");
const satisfied = require("~/public/static/img/satisfied.png");

const Feedback = () => {
  const [score, setScore] = React.useState<number>(-1);
  const [comment, setComment] = React.useState<string>("");
  const [sent, setSent] = React.useState<boolean>(false);

  const snackBarContext = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await getCSRFToken();
    axios
      .post(`api/v1/feedback`, {
        score: score,
        feedback: comment,
      })
      .then((response) => {
        console.log(response);
        snackBarContext.setMessage("Opinia przesłana!");
        snackBarContext.setSeverity("success");
        snackBarContext.setOpen(true);

        setScore(-1);
        setComment("");
        setSent(true);
      })
      .catch((error) => {
        console.log(error);
        snackBarContext.setMessage("Wystąpił błąd przy przesyłaniu opinii");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  };

  return (
    <Container id="feedback-container">
      <Grid container spacing={2} justifyContent={"center"}>
        {sent ? (
          <Grid gap={2}>
            <Typography
              variant="h4"
              color="text.primary"
              className="mt-2"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Dziękujemy za przesłanie uwagi!
            </Typography>
            <Button
              variant="contained-dark"
              onClick={() => window.location.reload()}
              width={"100%"}
            >
              Dodaj kolejną uwagę
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                className="mt-3"
              >
                <Grid container spacing={2}>
                  <Grid id={"text-feedback"} item xs={12}>
                    <h4>Zgłoś uwagę</h4>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      className="pb-2"
                      sx={{ fontWeight: "regular", fontSize: "1rem" }}
                    >
                      Każda uwaga którą otrzymujemy zostaje przekazana do
                      naszego zespołu, aby Gaius Lex służył Ci jeszcze lepiej.{" "}
                      <br />
                    </Typography>
                    <TextField
                      id="feedback-textfield"
                      label="Prześlij swoją opinię lub uwagę"
                      multiline
                      rows={3}
                      value={comment}
                      onChange={(event) => setComment(event.target.value)}
                      fullWidth
                      sx={{minHeight: "50%"}}
                      inputProps={{ style: { resize: "vertical", height: "200px" } }}
                    />
                  </Grid>
                  <Grid id={"scale-feedback"} item xs={12}>
                    <h4>Jak generalnie oceniasz użyteczność Gaius Lex?</h4>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignSelf={"center"}
                    >
                      <MuiButton
                        style={{
                          borderRadius: "0.75rem",
                          border: "0.1rem solid #D9E1E7",
                          padding: "13px 0",
                          width: "fit-content",
                          display: "flex",
                          alignContent: "center",
                          justifySelf: "center",
                          fontSize: "0.7rem",
                          backgroundColor:
                            score === 0 ? Colors.NavyBlue : undefined,
                        }}
                        className="mx-1 mb-3"
                        id="search-button"
                        variant="text"
                        type="submit"
                        onClick={() => setScore(0)}
                        disabled={score === 0}
                      >
                        <img src={dissatisfied} alt="Nieusatysfakcjonowany" />
                      </MuiButton>
                      <MuiButton
                        style={{
                          borderRadius: "0.75rem",
                          border: "0.1rem solid #D9E1E7",
                          padding: "13px 0",
                          width: "fit-content",
                          display: "flex",
                          alignContent: "center",
                          justifySelf: "center",
                          fontSize: "0.7rem",
                          backgroundColor:
                            score === 1 ? Colors.NavyBlue : undefined,
                        }}
                        className="mx-1 mb-3"
                        id="search-button"
                        variant="text"
                        type="submit"
                        onClick={() => setScore(1)}
                        disabled={score === 1}
                      >
                        <img src={moderate} alt="Średnio usatysfakcjonowany" />
                      </MuiButton>
                      <MuiButton
                        style={{
                          borderRadius: "0.75rem",
                          border: "0.1rem solid #D9E1E7",
                          padding: "13px 0",
                          width: "fit-content",
                          display: "flex",
                          alignContent: "center",
                          justifySelf: "center",
                          fontSize: "0.7rem",
                          backgroundColor:
                            score === 2 ? Colors.NavyBlue : undefined,
                        }}
                        className="mx-1 mb-3"
                        id="search-button"
                        variant="text"
                        type="submit"
                        onClick={() => setScore(2)}
                        disabled={score === 2}
                      >
                        <img src={satisfied} alt="Usatysfakcjonowany" />
                      </MuiButton>
                    </Stack>
                  </Grid>
                  <Button
                    type="submit"
                    className="feedback-submit"
                    variant="contained-dark"
                    disabled={score === -1}
                    width={"100%"}
                  >
                    Wyślij
                  </Button>
                </Grid>
              </Box>
            </form>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Feedback;
