import React from "react";

const regexParser = (content: string, match: string) => {
  const remains = content.split(match);
  return {
    remains,
    match: match,
  };
};

const htmlParser = (content: string) => {
  const caseNumberTitleRegex = /Sygn\. akt[:.]? [a-zA-Z\s]+ \d+\/\d+/g;
  const caseNumberTitleMatch = content.match(caseNumberTitleRegex);
  const reducedCaseNumberTitleMatch = [...new Set(caseNumberTitleMatch)];
  reducedCaseNumberTitleMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(
      `<br/><h2 style="margin-top: 3%;">${parsed.match}</h2>`
    )}`;
  });

  const caseNumberBoldRegex = /sygn\. akt[:.]? [a-zA-Z\s]+ \d+\/\d+/g;
  const caseNumberBoldMatch = content.match(caseNumberBoldRegex);
  const reducedCaseNumberBoldMatch = [...new Set(caseNumberBoldMatch)];
  reducedCaseNumberBoldMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(`<strong>${parsed.match}</strong>`)}`;
  });

  const dateRegex = /Dnia (\d+ \w+ \d{4} [rR]\w*.)/g;
  const dateMatch = content.match(dateRegex);
  const reducedDateMatch = [...new Set(dateMatch)];
  reducedDateMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(
      `<br/><strong style="font-size: larger;">${parsed.match}</strong><br/>`
    )}`;
  });

  const numberingRegex = /\s+(?:\d+|[IVXivx]+)[\).]\s+/g;
  const numberingMatch = content.match(numberingRegex);
  const reducedNumberingMatch = [...new Set(numberingMatch)];
  reducedNumberingMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(`<br/><strong>${parsed.match}</strong>`)}`;
  });

  const keyWordsRegex = /\s*(?:Przewodniczą|Sędzi|Protokol)\w*\s*:\s*/g;
  const keyWordsMatch = content.match(keyWordsRegex);
  const reducedKeyWordsMatch = [...new Set(keyWordsMatch)];
  reducedKeyWordsMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(
      `<strong style="font-size: larger;"><u>  ${parsed.match}  </u></strong>`
    )}`;
  });

  const justificationRegex = /UZASADNIENIE|POSTANOWIENIE/g;
  const justificationMatch = content.match(justificationRegex);
  const reducedJustificationMatch = [...new Set(justificationMatch)];
  reducedJustificationMatch?.forEach((match: string) => {
    const parsed = regexParser(content, match);
    content = `${parsed.remains.join(
      `<strong><u>${parsed.match}</u></strong><br/><br/>`
    )}`;
  });

  let isNumbering: boolean = false;

  content = content.split(/\s{2,}/).reduce((str: string, a: string) => {
    const result = isNumbering ? str + " " + a : str + "<br/><br/>" + a;
    isNumbering = !!a.match(/(?:\d+|[IVXLivxl]+)[\).]/);
    return result;
  });

  return content;
};

const parseLegalDocument = (content: string) => {
  let formattedHTML = content;
  formattedHTML = htmlParser(content);

  return formattedHTML;
};

const highlightOccurences = (content: string, searchTerm: string) => {
  const regex = new RegExp(searchTerm, "gi");
  return content.replace(regex, `<mark>${searchTerm}</mark>`);
}

const DocumentParser = ({ document, parse, searchTerm }: { document: string, parse: boolean, searchTerm: string }) => {
  let parsed = document;
  try {
    parsed = parseLegalDocument(document);
    if (searchTerm) {
      parsed = highlightOccurences(parsed, searchTerm);
    }
  } catch (e) {
    console.error("Document parsing error");
  }
  return (
    <div
      style={{ fontSize: "13px", overflow: "hidden" }}
      dangerouslySetInnerHTML={{ __html: parsed }}
    />
  );
};

export default DocumentParser;
