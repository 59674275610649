import * as React from 'react';
import * as ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

import App from './App';
import "./styles.css";

const mountNode = document.getElementById("app");
if (!mountNode) {
    throw new Error("Could not find mount node with id 'app'");
}
const root = createRoot(mountNode);

root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="148075679652-p56d9mu5hg6f1e14gbe7t63538hbf5h7.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
    </React.StrictMode>
);
