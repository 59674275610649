import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getCSRFToken from "./CSRFStore";
import { SnackbarContext } from "../contexts/SnackbarContext";
import React from "react";

const PaymentStore = () => {
    const snackbar = React.useContext(SnackbarContext);
    const axiosPrivate = useAxiosPrivate();
    const buySubscription = async (origin: String, type: String) => {
        await getCSRFToken();

        return axiosPrivate.post(`${process.env.BACKEND_ENDPOINT}/payment-api/create-checkout-session`, { origin, type })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    throw new Error("Network error while fetching data.");
                }
            }).catch((error) => {
                console.log("Error during sending request", error);
                (snackbar as any).setMessage('Wystąpił błąd podczas tworzenia płatności, skontaktuj się z obsługą klienta');
                (snackbar as any).setSeverity('error');
                (snackbar as any).setOpen(true);
            });
    }


    const cancelSubscription = async () => {
        await getCSRFToken();

        return axiosPrivate.post(`${process.env.BACKEND_ENDPOINT}/payment-api/cancel-subscription`)
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    throw new Error("Network error while fetching data.");
                }
            }).catch((error) => {
                console.log("Error during sending request", error);
                (snackbar as any).setMessage('Wystąpił błąd podczas anulowania subskrypcji, skontaktuj się z obsługą klienta');
                (snackbar as any).setSeverity('error');
                (snackbar as any).setOpen(true);
            });
    }


    const makeOneOffPayment = async (origin: String, type: String) => {
        await getCSRFToken();

        return axiosPrivate.post(`${process.env.BACKEND_ENDPOINT}/payment-api/create-checkout-session-one-off`, { origin, type })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    throw new Error("Network error while fetching data.");
                }
            }).catch((error) => {
                console.log("Error during sending request", error);
                (snackbar as any).setMessage('Wystąpił błąd podczas tworzenia płatności, skontaktuj się z obsługą klienta');
                (snackbar as any).setSeverity('error');
                (snackbar as any).setOpen(true);
            });
    }

    return {
        buySubscription,
        cancelSubscription,
        makeOneOffPayment
    };

}

export default PaymentStore;