import * as React from 'react';

const statuteNew = `<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style>
        ol {
            counter-reset: item;
        }
        ol > li{
          font-size: 22px;
          font-weight: 600;
        }
        ol > li li{
          font-size: 16px;
          font-weight: 400;
        }
        li {
            display: block;
            line-height: 1.65;
            margin-bottom: 12px;
        }
        li:before {
            content: counters(item, ".") ". ";
            counter-increment: item;
        }
        ol ol {
            counter-reset: sub-item;
        }
        ol ol li:before {
            content: counters(item, ".") "." counters(sub-item, ".") ". ";
            counter-increment: sub-item;
        }
    </style>
</head>

<body class="c15 doc-content">
    <div class="container">
<h1 class="wp-block-heading has-text-align-center"><strong>REGULAMIN ŚWIADCZENIA USŁUG ZA&nbsp;POŚREDNICTWEM SERWISU INTERNETOWEGO<br>W&nbsp;OBROCIE PROFESJONALNYM (B2B)</strong></h1>



<style>
        .section ol {
            counter-reset: item;
        }
        .section ol > li{
          font-size: 22px;
          font-weight: 600;
        }
        .section ol > li li{
          font-size: 16px;
          font-weight: 400;
        }
        .section li {
            display: block;
            line-height: 1.65;
            margin-bottom: 12px;
        }
        .section li:before {
            content: counters(item, ".") ". ";
            counter-increment: item;
        }
        .section ol ol {
            counter-reset: sub-item;
        }
        .section ol ol li:before {
            content: counters(item, ".") "." counters(sub-item, ".") ". ";
            counter-increment: sub-item;
        }
    </style>



<ol>
<li><strong>DEFINICJE</strong><br>Ilekroć w&nbsp;niniejszym regulaminie jest mowa o:
<ol>
<li><strong>Aplikacji przeglądarkowej </strong>– należy przez&nbsp;to&nbsp;rozumieć oprogramowanie przeznaczone do&nbsp;użytku w&nbsp;przeglądarce internetowej na&nbsp;różnych urządzeniach, takich jak komputery osobiste, laptopy, tablety czy&nbsp;smartfony. Aplikacje te&nbsp;są&nbsp;dostępne i&nbsp;uruchamiane bezpośrednio z&nbsp;przeglądarki internetowej;</li>



<li><strong>Cenniku </strong>– należy przez&nbsp;to&nbsp;rozumieć zestawienie informacji o&nbsp;cenach Usług świadczonych przez&nbsp;Usługodawcę dostępne w&nbsp;Serwisie w&nbsp;zakładce „Płatności”, stanowiące integralną część Regulaminu;</li>



<li><strong>Konsument </strong>– należy przez&nbsp;to&nbsp;rozumieć osobę fizyczną nieprowadzącą działalności gospodarczej lub osobę fizyczną prowadzącą działalność gospodarczą, której&nbsp;działalność gospodarcza lub zawodowa nie&nbsp;pozostaje w&nbsp;bezpośrednim związku z&nbsp;zakresem Usług Usługodawcy oferowanych w&nbsp;Serwisie;</li>



<li><strong>Konto </strong>lub <strong>Konto w&nbsp;Serwisie </strong>– należy przez&nbsp;to&nbsp;rozumieć część Serwisu stanowiącą zbiór zasobów i&nbsp;uprawnień przypisanych konkretnemu Usługobiorcy, dostępna po&nbsp;zalogowaniu się przy użyciu loginu i&nbsp;hasła, za&nbsp;pośrednictwem której&nbsp;Usługobiorca ma&nbsp;dostęp do&nbsp;Systemu i&nbsp;może korzystać z&nbsp;Usług;</li>



<li><strong>Okres Testowy </strong>– należy przez&nbsp;to&nbsp;rozumieć okres 3 dni od&nbsp;dnia założenia Konta w&nbsp;Serwisie przez&nbsp;Użytkownika, w&nbsp;czasie którym&nbsp;Użytkownik otrzymuje dostęp do&nbsp;wszystkich, płatnych funkcjonalności Serwisu w&nbsp;celu sprawdzenia Usług oferowanych przez&nbsp;Usługodawcę w&nbsp;Serwisie;&nbsp;</li>



<li><strong>Opłata Abonamentowa </strong>– należy przez&nbsp;to&nbsp;rozumieć opłatę uiszczaną przez&nbsp;Usługobiorcę tytułem wynagrodzenia Usługodawcy za&nbsp;świadczone Usługi w&nbsp;wysokości określonej w&nbsp;Cenniku;</li>



<li><strong>Regulamin </strong>– należy przez&nbsp;to&nbsp;rozumieć niniejszy regulamin świadczenia usług drogą elektroniczną stanowiący wzorzec umowy i&nbsp;będący jednocześnie wypełnieniem obowiązku, o&nbsp;którym&nbsp;mowa w&nbsp;art. 8 ust. 1 pkt 1 Ustawy o&nbsp;świadczeniu usług drogą elektroniczną;</li>



<li><strong>RODO</strong> – należy przez&nbsp;to&nbsp;rozumieć rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016&nbsp;r. w&nbsp;sprawie ochrony osób fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych oraz&nbsp;uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o&nbsp;ochronie danych);</li>



<li><strong>Serwis</strong> – należy przez&nbsp;to&nbsp;rozumieć zorganizowaną platformę informatyczno-informacyjną, umożliwiającą Usługobiorcy korzystanie Usług oraz&nbsp;dostęp do&nbsp;treści cyfrowych opracowanych przez&nbsp;Usługodawcę dostępna pod&nbsp;adresem domeny gaius-lex.pl oraz&nbsp;adresami jej subdomen;</li>



<li><strong>Siła Wyższa </strong>– należy przez&nbsp;to&nbsp;rozumieć zdarzenie zewnętrzne o&nbsp;nadzwyczajnym charakterze, nieprzewidywalne, którego&nbsp;skutkom nie&nbsp;można zapobiec, w&nbsp;szczególności takie zdarzenia jak: pożar, huragan, powodzie, trzęsienia ziemi, działania wojenne, wybuch epidemii, przerwy w&nbsp;dostawach energii elektrycznej lub dostępu do&nbsp;sieci Internet przez&nbsp;urządzenia Usługodawcy (nie&nbsp;wynikające z&nbsp;zawinionych zaniechań lub zachowań Usługodawcy), które uniemożliwiają wykonanie zobowiązań umownych przez&nbsp;Usługodawcę zgodnie z&nbsp;Umową;</li>



<li><strong>System</strong> – należy przez&nbsp;to&nbsp;rozumieć rozwiązanie informatyczne będące oprogramowaniem wraz elementami niebędącymi programem komputerowym dostępne w&nbsp;modelu SaaS (<em>Software as&nbsp;a&nbsp;Service</em>) dostarczane przez&nbsp;Usługodawcę Usługobiorcy za&nbsp;pośrednictwem Serwisu na&nbsp;podstawie Umowy;</li>



<li><strong>Świadczenie usługi drogą elektroniczną</strong> – należy przez&nbsp;to&nbsp;rozumieć wykonanie Usługi świadczonej bez&nbsp;jednoczesnej obecności stron (na&nbsp;odległość) na&nbsp;rzecz Usługobiorcy, poprzez przekaz danych na&nbsp;indywidualne żądanie Usługobiorcy, przesyłanej i&nbsp;otrzymywanej za&nbsp;pomocą urządzeń do&nbsp;elektronicznego przetwarzania, włącznie z&nbsp;kompresją cyfrową, i&nbsp;przechowywania danych, która&nbsp;jest w&nbsp;całości nadawana, odbierana lub transmitowana za&nbsp;pomocą sieci telekomunikacyjnej w&nbsp;rozumieniu ustawy z&nbsp;dnia 16 lipca 2004&nbsp;r. Prawo telekomunikacyjne;</li>



<li><strong>Umowa</strong> – należy przez&nbsp;to&nbsp;rozumieć umowę o&nbsp;świadczenie Usług drogą elektroniczną zawieraną na&nbsp;odległość pomiędzy Usługodawcą a&nbsp;Usługobiorcą, której&nbsp;przedmiot i&nbsp;treść określają postanowienia niniejszego Regulaminu, Cennik oraz&nbsp;informacje o&nbsp;dostępnych Usługach dostępne w&nbsp;Serwisie;</li>



<li><strong>Usługi</strong> – należy przez&nbsp;to&nbsp;rozumieć nieodpłatne i&nbsp;odpłatne usługi Świadczone drogą elektroniczną za&nbsp;pośrednictwem Serwisu przez&nbsp;Usługodawcę na&nbsp;rzecz Usługobiorcy, polegające na&nbsp;zapewnieniu przez&nbsp;Usługodawcę Usługobiorcy dostępu do&nbsp;Systemu i&nbsp;jego funkcjonalności oraz&nbsp;jego prawidłowego działania;</li>



<li><strong>Usługobiorca </strong>lub <strong>Użytkownik</strong> – należy przez&nbsp;to&nbsp;rozumieć osobę, która&nbsp;korzysta z&nbsp;Usług na&nbsp;podstawie Umowy w&nbsp;celach związanych bezpośrednio z&nbsp;jego działalnością gospodarczą lub zawodową, posiadający Konto w&nbsp;Serwisie;</li>



<li><strong>Usługodawca</strong> – należy przez&nbsp;to&nbsp;rozumieć podmiot świadczący Usługi drogą elektroniczną na&nbsp;podstawie Regulaminu, Cennika i&nbsp;informacji o&nbsp;Usługach dostępnych w&nbsp;Serwisie, tj.&nbsp;<strong>Flathub Spółka z&nbsp;ograniczoną odpowiedzialnością</strong> z&nbsp;siedzibą w&nbsp;Krakowie pod&nbsp;adresem ul.&nbsp;Bronowicka 38/5, 30-091 Kraków, wpisaną do&nbsp;Rejestru Przedsiębiorców Krajowego Rejestru Sądowego pod&nbsp;numerem: 0001009006, dla której&nbsp;akta rejestrowe prowadzi Sąd Rejonowy dla Krakowa-Śródmieścia w&nbsp;Krakowie, XI Wydział Gospodarczy KRS, posiadającą NIP: 6772488192, REGON: 52396307300000, kapitał zakładowy: 20.000,00 zł.</li>



<li><strong>Abonament </strong>– należy przez&nbsp;to&nbsp;rozumieć wykupiony przez&nbsp;Użytkownika dostęp do&nbsp;odpłatnych funkcjonalności Serwisu, do&nbsp;których&nbsp;dostęp udzielany jest Użytkownikowi na&nbsp;wybrany przez&nbsp;Użytkownika okres abonamentowy trwający: 30 (<em>trzydzieści</em>) dni, 90 (<em>dziewięćdziesiąt</em>) dni&nbsp; lub 365 (<em>trzysta sześćdziesiąt pięć</em>) dni, pod&nbsp;warunkiem zapłaty Opłaty abonamentowej w&nbsp;odpowiedniej wysokości, określonej w&nbsp;Cenniku;&nbsp;</li>
</ol>
</li>



<li><strong>POSTANOWIENIA OGÓLNE&nbsp;</strong>
<ol>
<li>Niniejszy regulamin określa rodzaj, zakres i&nbsp;warunki świadczenia Usług drogą elektroniczną za&nbsp;pośrednictwem Serwisu, w&nbsp;tym zasady korzystania z&nbsp;Systemu oraz&nbsp;zasady zawierania Umów przez&nbsp;Usługodawcę.</li>



<li><strong>Celem świadczenia Usług jest zapewnienie wsparcia osobom profesjonalnie świadczącym pomoc prawną,&nbsp; dysponującym odpowiednią wiedzą&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; i&nbsp;doświadczeniem,&nbsp; dzięki którym&nbsp;rezultaty świadczonej Usługi mogą zostać właściwie wykorzystane. Świadczenie Usług w&nbsp;żaden sposób nie&nbsp;zastępuje pomocy prawnej świadczonej przez&nbsp;profesjonalistów,&nbsp; a&nbsp;Usługodawca stoi na&nbsp;stanowisku, że&nbsp;pełne zastąpienie człowieka w&nbsp;roli profesjonalisty świadczącego taką pomoc nigdy nie&nbsp;będzie możliwe.&nbsp;</strong></li>



<li>Właścicielem Serwisu i&nbsp;stroną zawieranej Umowy jest Usługodawca. Użytkownicy mogą kontaktować się z&nbsp;Usługodawcą pod&nbsp;następującym adresem e-mail: <a href="mailto:kontakt@gaius-lex.pl">kontakt@gaius-lex.pl</a></li>



<li>Korzystanie z&nbsp;Serwisu i&nbsp;świadczonych za&nbsp;jego pośrednictwem Usług wymaga uprzedniego zapoznania się z&nbsp;treścią Regulaminu i&nbsp;jego akceptacji. Brak akceptacji Regulaminu uniemożliwia złożenie zamówienia i&nbsp;korzystanie z&nbsp;Usług. Z&nbsp;zastrzeżeniem obowiązujących przepisów prawa, Usługobiorca zawierając Umowę oświadcza, że&nbsp;zapoznał się z&nbsp;treścią Regulaminu, a&nbsp;także, że&nbsp;dokumenty te&nbsp;w&nbsp;postaci elektronicznej zostały udostępnione Usługobiorcy przed&nbsp;zawarciem Umowy w&nbsp;taki sposób, aby mógł je&nbsp;przechowywać i&nbsp;odtwarzać w&nbsp;zwykłym toku czynności.</li>



<li>Usługi oferowane przez&nbsp;Usługodawcę są&nbsp;przeznaczone dla przedsiębiorców. Jeżeli jesteś Konsumentem lub osobą fizyczną zawierającą Umowę bezpośrednio związaną z&nbsp;jej działalnością gospodarczą, ale&nbsp;Usługi nie&nbsp;będą wykorzystywane przez&nbsp;Ciebie w&nbsp;związku z&nbsp;wykonywaniem zawodu lub prowadzoną działalnością gospodarczą świadczenie Usług na&nbsp;Twoją rzecz jest możliwe wyłącznie po&nbsp;indywidualnym ustaleniu warunków świadczenia Usług na&nbsp;Twoją rzecz. W&nbsp;tym celu uzyskania wstępnej oferty skontaktuj się z&nbsp;nami pod&nbsp;adresem: <a href="mailto:konsumenci@gaius-lex.pl">konsumenci@gaius-lex.pl</a>.</li>



<li>Usługodawca zastrzega sobie prawo do&nbsp;zamieszczania treści reklamowych osób trzecich w&nbsp;Serwisie i&nbsp;prezentowania ich Użytkownikom.&nbsp;</li>
</ol>
</li>



<li><strong>WYMAGANIA TECHNICZNE</strong>
<ol>
<li>Korzystanie z&nbsp;Serwisu, w&nbsp;tym w&nbsp;szczególności korzystanie z&nbsp;Usług, wymaga spełnienia minimalnych wymagań technicznych niezbędnych do&nbsp;współpracy z&nbsp;Systemem, tj.
<ul>
<li>dysponowanie sprawnym urządzeniem posiadającym połączenie z&nbsp;siecią Internet (np.&nbsp;komputer, tablet, smartphone);</li>



<li>stałe łącze internetowe o&nbsp;przepustowości minimum [2Mb/s];</li>



<li>aktualna i&nbsp;poprawnie skonfigurowana wersja przeglądarek internetowych: Google Chrome;</li>



<li>oprogramowanie umożliwiające prawidłowe wyświetlanie dokumentów PDF;</li>



<li>posiadanie aktywnego adresu poczty elektronicznej;</li>



<li>posiadanie aktywnego numeru telefonu w&nbsp;celu weryfikacji klienta;</li>



<li>procesor 2 rdzeniowy;</li>



<li>pamięć 8 GB;</li>



<li>włączona obsługa plików cookies i&nbsp;Javascript.</li>
</ul>
</li>
</ol>
</li>



<li><strong>ZAKRES OFEROWANYCH USŁUG</strong>
<ol>
<li>W&nbsp;ramach <strong>nieodpłatnych</strong> Usług świadczonych za&nbsp;pośrednictwem Serwisu, Usługodawca, po&nbsp;utworzeniu i&nbsp;aktywacji Konta, umożliwia Użytkownikowi:
<ol>
<li>założenie i&nbsp;prowadzenie Konta Usługobiorcy;</li>



<li>zapoznanie się z&nbsp;informacjami dotyczącymi zakresu i&nbsp;warunków świadczenia Usług, w&nbsp;tym Cennikiem;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>



<li>przeprowadzenie rozmowy z&nbsp;Usługodawcą na&nbsp;chacie online z&nbsp;przedstawicielem Usługodawcy w&nbsp;godzinach: 08:00- 16:00;</li>



<li>możliwość wykupienia przez&nbsp;Użytkownika wybranej formy Abonamentu;</li>



<li>możliwość nawiązania bezpośredniego kontaktu z&nbsp;Usługodawcą w&nbsp;celu umówienia rozmowy dotyczącej otrzymania indywidualnej oferty, bądź wdrożenia Serwisu w&nbsp;ramach działalności przedsiębiorstwa Użytkownika;</li>



<li>uzyskiwanie informacji dotyczących zmian w&nbsp;Serwisie i&nbsp;nowych funkcjonalności, a&nbsp;także treści marketingowych w&nbsp;formie newslettera (<em>opcjonalne</em>).</li>
</ol>
</li>



<li>W&nbsp;ramach <strong>odpłatnych</strong> Usług świadczonych za&nbsp;pośrednictwem Serwisu, Usługodawca, po&nbsp;utworzeniu i&nbsp;aktywacji oraz&nbsp;wykupieniu Abonamentu, umożliwia Użytkownikowi:
<ol>
<li>zadawanie pytań związanych z&nbsp;treścią orzeczeń wydawanych przez&nbsp;sądy powszechne, sądy administracyjne oraz&nbsp;inne organy orzecznicze, a&nbsp;także aktów polskiego prawa powszechnie obowiązującego oraz&nbsp;uzyskiwanie odpowiedzi wygenerowanych przez&nbsp;model językowy sztucznej inteligencji, za&nbsp;pośrednictwem której&nbsp;Usługodawca świadczy Usługi w&nbsp;ramach Systemu;</li>



<li>wgrywanie dokumentów i&nbsp;dokonanie ich analizy,, za&nbsp;pośrednictwem modelu językowego sztucznej inteligencji, za&nbsp;pośrednictwem której&nbsp;Usługodawca świadczy Usługi w&nbsp;ramach Systemu do&nbsp;własnych dokumentów we&nbsp;wspieranych formatach: txt, jpg, png, jpeg, gif, bmp, mp3, wav, m4a, flac, ac3, doc;</li>



<li>wydawanie poleceń modelowi językowemu sztucznej inteligencji, za&nbsp;pomocą której&nbsp;Usługodawca świadczy&nbsp; Usługi w&nbsp;ramach Serwisu w&nbsp;zakresie tworzenia projektów dokumentów;</li>
</ol>
</li>
</ol>
</li>



<li><strong>ZAKŁADANIE KONTA W&nbsp;SERWISIE</strong>
<ol>
<li>Rejestracja i&nbsp;prowadzenie Konta Usługobiorcy są&nbsp;nieodpłatne.</li>



<li>Utworzenie Konta Usługobiorcy jest możliwe poprzez wypełnienie dostępnego w&nbsp;Serwisie formularza rejestracji oraz&nbsp;podanie następujących danych zgodnych ze&nbsp;stanem faktycznym:
<ol>
<li>adresu e-mail;</li>



<li>numeru telefonu Użytkownika.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
</ol>
</li>



<li>Utworzenie Konta Usługobiorcy możliwe jest również poprzez uwierzytelnianie dostępne poprzez protokół autoryzacji dostępu gwarantowany przez&nbsp;OAuth2, za&nbsp;pośrednictwem serwisów trzecich np.&nbsp;Google, Facebook.</li>



<li>Użytkownikiem może być wyłącznie osoba, która:
<ul>
<li>posiada pełną zdolność do&nbsp;czynności prawnych;</li>



<li>jest przedsiębiorcą;</li>



<li><strong>zawiera Umowę w&nbsp;bezpośrednim związku z&nbsp;prowadzoną przez&nbsp;siebie działalnością gospodarczą lub zawodową.</strong></li>
</ul>
</li>



<li>W&nbsp;przypadku, gdy&nbsp;osoba zainteresowana założeniem Konta w&nbsp;Serwisie pozostaje Konsumentem, może skontaktować się z&nbsp;Usługodawcą pod&nbsp;adresem: <a href="mailto:konsumenci@gaius-lex.pl">konsumenci@gaius-lex.pl</a> w&nbsp;celu przygotowania indywidualnych warunków korzystania z&nbsp;Serwisu. W&nbsp;takim wypadku, osoba będąca Konsumentem, może otrzymać od&nbsp;Usługodawcy indywidualnie określone warunki zawarcia umowy na&nbsp;dostęp do&nbsp;Serwisu.</li>



<li>W&nbsp;celu założenia Konta w&nbsp;Serwisie, osoba zainteresowana:
<ul>
<li>kontaktuje się z&nbsp;Usługodawcą za&nbsp;pośrednictwem formularza kontaktowego w&nbsp;celu uzyskania dostępu do&nbsp;formularza założenia konta, bądź uzupełnia formularz założenia konta dostępny w&nbsp;Serwisie – w&nbsp;zależności od&nbsp;formuły przyjętej przez&nbsp;Usługodawcę w&nbsp;Serwisie;</li>



<li>wypełnia formularz zakładania konta, wskazując niezbędne dane osobowe oraz&nbsp;potwierdza Usługodawcy, że&nbsp;zakłada Konto w&nbsp;Serwisie w&nbsp;bezpośrednim związku z&nbsp;prowadzoną przez&nbsp;siebie działalnością gospodarczą lub zawodową;</li>



<li>zapoznaje się i&nbsp;akceptuje Politykę Prywatności;</li>



<li>zapoznaje się i&nbsp;akceptuje niniejszy Regulamin;</li>



<li>określa hasło stanowiące zabezpieczenie przed&nbsp;dostępem do&nbsp;Konta przez&nbsp;osoby nieuprawnione;</li>



<li>dokonuje weryfikacji tożsamości i&nbsp;aktywacji Konta poprzez: użycie autoryzacji dostępu za&nbsp;pośrednictwem platform trzecich lub dokonuje weryfikacji tożsamości za&nbsp;pośrednictwem e-maila lub numeru telefonu podane w&nbsp;formularzu zakładania konta, na&nbsp;które zostaje skierowany kod aktywacyjny.</li>
</ul>
</li>



<li>Wskutek akceptacji niniejszego Regulaminu i&nbsp;założenia Konta w&nbsp;Serwisie oraz&nbsp;dokonania aktywacji Konta przez&nbsp;Użytkownika, dochodzi do&nbsp;zawarcia Umowy pomiędzy Użytkownikiem i&nbsp;Usługodawcą na&nbsp;świadczenie Usług nieodpłatnych, wskazanych w&nbsp;ustępie 4.1. Regulaminu, na&nbsp;czas nieokreślony. Po&nbsp;dokonaniu aktywacji Konta Użytkownika, Użytkownik ma&nbsp;możliwość wykupienia dostępu do&nbsp;odpłatnych funkcjonalności Systemu (Abonament).&nbsp;</li>



<li>Użytkownik otrzymuje dostęp do&nbsp;Konta w&nbsp;Serwisie z&nbsp;chwilą aktywacji Konta po&nbsp;podaniu poprawnego kodu wysłanego na&nbsp;numer telefonu wskazany przez&nbsp;Użytkownika podczas zakładania Konta w&nbsp;Serwisie. Umowa ulega automatycznemu rozwiązaniu w&nbsp;przypadku braku aktywacji Konta przez&nbsp;Użytkownika w&nbsp;terminie 14 dni od&nbsp;dnia założenia Konta, a&nbsp;Konto w&nbsp;Serwisie w&nbsp;takim wypadku zostaje usunięte.&nbsp;</li>



<li>Konto zakładane jest na&nbsp;czas nieokreślony, przy czym Usługobiorca jest uprawniony do&nbsp;likwidacji Konta w&nbsp;każdym czasie bez&nbsp;podania przyczyny. W&nbsp;tym celu należy skontaktować się z&nbsp;obsługą klienta.</li>
</ol>
</li>



<li><strong>LOGOWANIE SIĘ DO&nbsp;KONTA W&nbsp;SERWISIE</strong>
<ol>
<li>Zalogowanie się do&nbsp;Serwisu wymaga podania loginu oraz&nbsp;hasła, bądź zastosowania protokołu autoryzacji dostępu za&nbsp;pośrednictwem platform trzecich (w&nbsp;tym: Google, Facebook). Loginem jest adres &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e-mail Użytkownika. Hasło ustalane jest przez&nbsp;Usługobiorcę w&nbsp;procesie rejestracji Konta.</li>



<li>Użytkownik zobowiązuje się do&nbsp;utrzymania w&nbsp;poufności informacji umożliwiających dostęp do&nbsp;Konta Usługobiorcy. W&nbsp;celu zachowania bezpieczeństwa logowania Usługodawca zaleca zmianę hasła logowania co&nbsp;30 dni. Usługodawca nie&nbsp;ponosi odpowiedzialności za&nbsp;skutki udostępnienia hasła przez&nbsp;Usługobiorcę osobom trzecim.</li>



<li>Zmiana, bądź odzyskiwanie hasła możliwa jest poprzez funkcje odzyskiwania, bądź zmianę hasła dostępną w&nbsp;serwisie lub po&nbsp;kontakcie z&nbsp;obsługą klienta.&nbsp;</li>
</ol>
</li>



<li><strong>OKRES TESTOWY</strong>
<ol>
<li>Z&nbsp;chwilą dokonania aktywacji Konta w&nbsp;Serwisie, Użytkownik otrzymuje na&nbsp;okres 3 dni testowy, bezpłatny dostęp do&nbsp;Serwisu w&nbsp;celu sprawdzenia odpłatnych Usług oferowanych przez&nbsp;Usługodawcę (Okres Testowy). Podczas Okresu Testowego Użytkownik ma&nbsp;możliwość korzystania ze&nbsp;wszystkich funkcjonalności oferowanych w&nbsp;Serwisie, w&nbsp;tym funkcjonalności odpłatnych, wyłączeniem historii przeszukiwania.</li>



<li>Podczas trwania Okresu Testowego Użytkownik jest zobowiązany do&nbsp;korzystania z&nbsp;Usług oferowanych w&nbsp;Serwisie w&nbsp;zgodzie z&nbsp;niniejszym Regulaminem.</li>



<li>Po&nbsp;zakończonym Okresie testowym, Użytkownik może wykupić Abonament na&nbsp;korzystanie z&nbsp;odpłatnych funkcjonalności Serwisu lub korzystać z&nbsp;Serwisu w&nbsp;wersji nieodpłatnej, w&nbsp;ramach której&nbsp;Usługodawca świadczy na&nbsp;rzecz Użytkownika nieodpłatne usługi wskazane w&nbsp;ustępie 7.2. poniżej.&nbsp;</li>



<li>Po&nbsp;zakończonym Okresie Testowym, Konto Użytkownika pozostaje aktywne, a&nbsp;zakres świadczonych Usług zależy od&nbsp;dostępu wykupionego przez&nbsp;Użytkownika w&nbsp;ramach Abonamentu wybranego przez&nbsp;Użytkownika. Użytkownik może korzystać z&nbsp;Serwisu w&nbsp;wersji nieodpłatnej.&nbsp;</li>



<li>Użytkownik może zakończyć Okres Testowy przed&nbsp;upływem okresu, o&nbsp;którym&nbsp;mowa w&nbsp;ustępie 5.1. poprzez wykupienie dostępu w&nbsp;ramach Abonamentu.&nbsp;</li>
</ol>
</li>



<li><strong>ABONAMENT</strong>
<ol>
<li>Użytkownik w&nbsp;celu uzyskania dostępu do&nbsp;płatnych Usług świadczonych w&nbsp;ramach Serwisu wykupuje Abonament, który&nbsp;zapewnia Użytkownikowi dostęp do&nbsp;określonych funkcjonalności Serwisu.&nbsp;</li>



<li>Wysokość Opłaty Abonamentowej jest wskazana w&nbsp;Cenniku.&nbsp;</li>



<li>Abonament może zostać wykupiony przez&nbsp;Użytkownika na&nbsp;okres: 30, 90 lub 365 dni.&nbsp;</li>



<li>Abonament rozpoczyna się z&nbsp;chwilą złożenia zamówienia przez&nbsp;Użytkownika za&nbsp;pośrednictwem Systemu i&nbsp;wykonania przez&nbsp;Użytkownika Opłaty Abonamentowej za&nbsp;pierwszy okres rozliczeniowy wskazany w&nbsp;ustępie 8.3. powyżej.&nbsp;</li>



<li>Z&nbsp;chwilą wykupienia Abonamentu, Usługodawca zobowiązuje się świadczyć na&nbsp;rzecz Użytkownika Usługi nieodpłatne oraz&nbsp;odpłatne Usługi, przez&nbsp;czas, na&nbsp;jaki Użytkownik wykupił Abonament (zgodnie z&nbsp;ustępem 8.3. Regulaminu).</li>



<li>Zamówienie świadczenia Usług odpłatnych zostaje złożone z&nbsp;chwilą zatwierdzenia przez&nbsp;Usługobiorcę złożonego zamówienia na&nbsp;Usługę poprzez wybranie przycisku „wybieram” oraz&nbsp;dokonanie opłaty pierwszej Opłaty Abonamentowej.&nbsp;</li>



<li>Usługodawca zobowiązuje się rozpocząć świadczenie usług odpłatnych na&nbsp;rzecz Użytkownika, który&nbsp;dokonał wykupienia Abonamentu, w&nbsp;terminie do&nbsp;24 godzin od&nbsp;momentu od&nbsp;zaksięgowania na&nbsp;koncie Usługodawcy Opłaty Abonamentowej, której&nbsp;wysokość uzależniona jest od&nbsp;wybranego wariantu Abonamentu.</li>



<li>Użytkownik zainteresowany wykupieniem Abonamentu, który&nbsp;nie&nbsp;jest oferowany w&nbsp;Serwisie i&nbsp;uzyskaniem indywidualnej oferty, może skontaktować się z&nbsp;Usługodawcą pod&nbsp;adresem e-mail: kontakt@gaius-lex.pl., wskazując do&nbsp;jakich funkcjonalności Użytkownik chciałby uzyskać dostęp i&nbsp;jaki okres rozliczeniowy przyjąć. Usługodawca udziela odpowiedzi Użytkownikowi w&nbsp;terminie 14 dni od&nbsp;dnia przesłania wiadomości e-mail. Zapytanie Użytkownika nie&nbsp;stanowi oferty, a&nbsp;jedynie zaproszenie do&nbsp;złożenia oferty przez&nbsp;Usługodawcę, zaś brak udzielenia odpowiedzi na&nbsp;złożone zapytanie w&nbsp;terminie, o&nbsp;którym&nbsp;mowa w&nbsp;zdaniu poprzedzającym nie&nbsp;będzie uważane za&nbsp;przyjęcie oferty Użytkownika.&nbsp;</li>



<li>Usługobiorca może oferować Użytkownikom dostępy do&nbsp;niektórych funkcjonalności Serwisu na&nbsp;czas określony w&nbsp;ramach specjalnych planów promocyjnych, bez&nbsp;konieczności uiszczania opłat.</li>
</ol>
</li>



<li><strong>ZASADY KORZYSTANIA Z&nbsp;SERWISU</strong>
<ol>
<li>Akceptując niniejszy Regulamin, Usługobiorca zobowiązuje się w&nbsp;szczególności do:
<ol>
<li>korzystania z&nbsp;Serwisu i&nbsp;Systemu zgodnie z&nbsp;niniejszym Regulaminem, Polityką Prywatności obowiązującą Usługobiorcę i&nbsp;dostępną pod&nbsp;adresem: https://gaius-lex.pl/privacy-policy oraz&nbsp;powszechnie obowiązującym prawem;</li>



<li>respektowania zakazu dostarczania treści o&nbsp;charakterze bezprawnym, przez&nbsp;co&nbsp;należy rozumieć m.in.: zakaz umieszczania treści obraźliwych, nieprawdziwych, niemoralnych, naruszających dobre obyczaje, propagujących przemoc i&nbsp;nienawiść,</li>



<li>niepodejmowania działań zmierzających do&nbsp;zakłócenia funkcjonowania Serwisu i&nbsp;wejścia w&nbsp;posiadanie informacji oraz&nbsp;danych nieprzeznaczonych dla Usługobiorcy,</li>



<li>podania prawdziwych danych niezbędnych do&nbsp;zawarcia Umowy, a&nbsp;w&nbsp;przypadku zamówienia Usług odpłatnych, również do&nbsp;podania danych niezbędnych do&nbsp;dokonania prawidłowych rozliczeń;</li>



<li>aktualizowania danych Użytkownika, przekazanych Usługodawcy w&nbsp;związku z&nbsp;realizacją Umowy, niezwłocznie po&nbsp;ich zmianie;</li>



<li>nieprzekazywania za&nbsp;pośrednictwem Systemu danych osobowych osób trzecich, do&nbsp;których&nbsp;udostępniania Usługobiorca nie&nbsp;jest uprawniony;</li>



<li>odpowiedniego anonimizowania treści zapytań i&nbsp;dokumentów udostępnianych w&nbsp;Systemie w&nbsp;celu ich weryfikacji przez&nbsp;sztuczną inteligencję w&nbsp;sposób niepozwalający na&nbsp;identyfikację osób trzecich, których&nbsp;dane osobowe dotyczą (z&nbsp;zastrzeżeniem sytuacji, w&nbsp;której&nbsp;Użytkownik zawrze z&nbsp;Usługodawcą umowy powierzenia przetwarzania danych osobowych);</li>



<li>nieprzekazywania za&nbsp;pośrednictwem Systemu plików cyfrowych lub innych dokumentów, do&nbsp;których&nbsp;Usługobiorca nie&nbsp;posiada autorskich praw majątkowych lub stosownego upoważnienia do&nbsp;korzystania z&nbsp;przekazywanych plików cyfrowych lub innych dokumentów w&nbsp;zakresie pozwalającym na&nbsp;ich udostępnienie w&nbsp;Systemie;</li>



<li>niepodejmowania innych działań, które byłyby niezgodne z&nbsp;obowiązującymi przepisami prawem, dobrymi obyczajami lub naruszałyby prawa i&nbsp;dobra osobiste Usługodawcy oraz&nbsp;osób trzecich.</li>
</ol>
</li>



<li>Usługobiorca może zapisać na&nbsp;wykorzystywanym przez&nbsp;siebie urządzeniu końcowym treść Polityki Prywatności, jak również Regulaminu, bez&nbsp;ponoszenia dodatkowych opłat. Możliwe jest również uzyskanie ich treści bezpośrednio od&nbsp;Usługodawcy – w&nbsp;tym celu prosimy o&nbsp;kontakt pod&nbsp;adresem e-mail wskazanym w&nbsp;niniejszym Regulaminie.</li>



<li>Treści generowane przez&nbsp;System nie&nbsp;mają nigdy charakteru samodzielnego i&nbsp;należy je&nbsp;traktować wyłącznie jako sugestię zastosowania określonych sformułowań językowych. Ostateczny przedmiot twórczy będzie wynikiem pracy Użytkownika, gdyż&nbsp;na&nbsp;etapie koncepcji, kreacji i&nbsp;recenzji jego działanie jest niezbędne.&nbsp;</li>



<li>Usługobiorca jest odpowiedzialny za&nbsp;treści, w&nbsp;szczególności dokumenty lub inne pliki cyfrowe, udostępniane i&nbsp;przechowywane w&nbsp;Systemie za&nbsp;pośrednictwem Serwisu i&nbsp;za&nbsp;ich zgodność z&nbsp;powszechnie obowiązującym prawem. Usługodawca nie&nbsp;dokonuje samodzielnie oceny treści udostępnianych przez&nbsp;Usługobiorcę, ani nie&nbsp;dokonuje ingerencji w&nbsp;udostępniane przez&nbsp;Usługobiorcę pliki cyfrowe. Pliki cyfrowe udostępniane przez&nbsp;Usługobiorcę nie&nbsp;są&nbsp;poddawane analizie innej, niż wyraźnie zleconej przez&nbsp;Użytkownika. Usługodawca nie&nbsp;ma&nbsp;możliwości oceny zgodności z&nbsp;prawem tak&nbsp;udostępnionych plików cyfrowych. Z&nbsp;uwagi na&nbsp;powyższe, wyłącza się odpowiedzialność Usługodawcy za&nbsp;zamieszczone przez&nbsp;Użytkownika w&nbsp;Serwisie.&nbsp;</li>



<li>Usługodawca jest uprawniony do&nbsp;wykorzystania historii zapytań składanych podczas interakcji ze&nbsp;sztuczną inteligencją przez&nbsp;Usługobiorcę, do&nbsp;rozwijania algorytmów sztucznej inteligencji, za&nbsp;pomocą której&nbsp;Usługodawca świadczy Usługi, z&nbsp;tym zastrzeżeniem, że&nbsp;historie zapytań nie&nbsp;będą zawierały danych osobowych osób trzecich ujawnionych przez&nbsp;Użytkownika.</li>



<li>W&nbsp;przypadku, gdy&nbsp;Usługobiorca korzystając z&nbsp;Serwisu dopuści się naruszeń Regulaminu lub obowiązujących przepisów prawa, a&nbsp;także gdy&nbsp;w&nbsp;inny sposób działa na&nbsp;szkodę Usługodawcy i/lub osób trzecich, Usługodawca może ograniczyć jego dostęp lub pozbawić go&nbsp;dostępu do&nbsp;Serwisu ze&nbsp;skutkiem natychmiastowym. O&nbsp;zastosowanych środkach Usługobiorca zostanie niezwłocznie poinformowany. W&nbsp;przypadku, gdy&nbsp;Usługodawca ograniczy lub pozbawi dostępu do&nbsp;Serwisu Usługobiorcę z&nbsp;przyczyn wskazanych w&nbsp;zdaniu poprzedzającym, Usługobiorca jest uprawniony do&nbsp;złożenia reklamacji. Do&nbsp;czasu rozpatrzenia reklamacji, Usługodawca ogranicza lub wstrzymuje dostęp Usługobiorcy do&nbsp;Systemu, a&nbsp;opłaty za&nbsp;kolejny okres rozliczeniowy nie&nbsp;są&nbsp;pobierane. W&nbsp;razie uznania reklamacji za&nbsp;niezasadną, Usługodawca przesyła taką informację wraz z&nbsp;uzasadnieniem do&nbsp;Usługobiorcy i&nbsp;jest uprawniony do&nbsp;rozwiązania Umowy ze&nbsp;skutkiem natychmiastowym. W&nbsp;razie skorzystania przez&nbsp;Usługodawcę z&nbsp;prawa do&nbsp;rozwiązania Umowy ze&nbsp;skutkiem natychmiastowym, o&nbsp;którym&nbsp;mowa w&nbsp;zdaniu poprzedzającym, Umowa ulega rozwiązaniu z&nbsp;chwilą przesłania do&nbsp;Usługobiorcy oświadczenia o&nbsp;rozwiązaniu&nbsp; Umowy drogą e-mailową na&nbsp;adres Usługobiorcy wskazany w&nbsp;Systemie, a&nbsp;Usługodawca nie&nbsp;jest zobowiązany do&nbsp;zwrotu Usługobiorcy opłaty uiszczonej za&nbsp;korzystanie z&nbsp;Usług za&nbsp;okres, w&nbsp;którym&nbsp;Usługobiorca nie&nbsp;miał możliwości korzystania z&nbsp;Systemu. Uzyskanie powtórnego dostępu do&nbsp;Serwisu wymaga uprzedniej zgody Usługodawcy.</li>



<li>Usługobiorca zobowiązuje się do&nbsp;nieudostępniania swojego Konta osobom trzecim. W&nbsp;przypadku udostępnienia danych Konta osobom trzecim, Usługobiorca odpowiada za&nbsp;działania tych osób jak za&nbsp;działania własne.</li>



<li>Usługodawca świadczy Usługi w&nbsp;sposób nieprzerwany 7 dni w&nbsp;tygodniu przez&nbsp;całą dobę, z&nbsp;zastrzeżeniem postanowień ustępu 9.9. i&nbsp;9.10. Regulaminu poniżej.</li>



<li>Usługodawca nieustannie dokłada starań, aby zapewnić, że&nbsp;Usługi świadczone przez&nbsp;niego będą dostarczały Użytkownikom coraz lepsze jakościowo treści i&nbsp;możliwości, a&nbsp;także z&nbsp;uwzględnieniem zapewnienia najwyższego bezpieczeństwa Użytkowników. W&nbsp;związku z&nbsp;tym, Usługodawca zastrzega sobie prawo do&nbsp;czasowego zawieszenia świadczenia Usług w&nbsp;ramach Systemu w&nbsp;związku z&nbsp;koniecznością przeprowadzenia prac serwisowych lub naprawczych, bądź związanych z&nbsp;modernizacją lub przebudową Systemu, jak również w&nbsp;przypadku działania Siły Wyższej rozumianej w&nbsp;szczególności jako przerwy świadczenia usług przez&nbsp;podmioty trzecie na&nbsp;rzecz Usługodawcy z&nbsp;zakresu udostępniania infrastruktury informatycznej niezbędnej do&nbsp;funkcjonowania Systemu. Usługodawca dołoży starań, aby przerwy w&nbsp;świadczeniu Usług nie&nbsp;były uciążliwe dla Usługobiorców. Informacja o&nbsp;planowanym czasowym zawieszeniu pracy Systemu będzie przekazywana Usługobiorcom drogą elektroniczną na&nbsp;podany przy rejestracji adres e-mail lub za&nbsp;pośrednictwem Konta Usługobiorcy, z&nbsp;odpowiednim wyprzedzeniem. Usługobiorca nie&nbsp;jest uprawniony do&nbsp;żądania obniżenia lub zwrotu opłaty za&nbsp;okres rozliczeniowy, w&nbsp;którym&nbsp;nastąpiła przerwa związana z&nbsp;przeprowadzeniem prac serwisowych lub naprawczych trwająca dłużej niż 24 godziny,&nbsp; bądź przerwa spowodowana działaniem siły wyższej.</li>



<li>Usługodawca nie&nbsp;ponosi odpowiedzialności za&nbsp;przerwy w&nbsp;świadczeniu Usług lub zakłócenia w&nbsp;dostępie do&nbsp;Serwisu wywołane Siłą Wyższą, jeśli skutki jej działania mają wpływ na&nbsp;świadczenie Usług przez&nbsp;Usługodawcę i&nbsp;nie&nbsp;wynikały z&nbsp;zawinionego działania lub zaniechania Usługodawcy. Za&nbsp;takie przypadki uważa się w&nbsp;szczególności: awarie sprzętu zapewniające Usługodawcy prawidłowe świadczenie Usług, przerwy w&nbsp;dostawie prądu czy&nbsp;łączności z&nbsp;Internetem, niewynikające z&nbsp;niedokonania przez&nbsp;Usługodawcę zapłaty za&nbsp;dostawy w.w. usług lub energii, a&nbsp;także nieuprawnione ingerencje Usługobiorców lub osób trzecich, którym&nbsp;Usługodawca nie&nbsp;mógł zapobiec,&nbsp; czy&nbsp;też błędnym działaniem systemów telekomunikacyjnych i&nbsp;oprogramowania zainstalowanego na&nbsp;sprzęcie komputerowym Usługobiorców.</li>



<li>Usługodawca świadczący Usługi za&nbsp;pomocą sztucznej inteligencji nie&nbsp;świadczy&nbsp;pomocy prawnej.&nbsp;</li>



<li>Usługobiorca nie&nbsp;odpowiada za&nbsp;sposób korzystania przez&nbsp;Usługobiorcę z&nbsp;funkcjonalności opartych na&nbsp;sztucznej inteligencji, ani za&nbsp; jakość dostarczanych przez&nbsp;Usługobiorcę na&nbsp;jego żądanie, danych wyjściowych. Usługodawca informuje, że&nbsp;sztuczna inteligencja dostarcza odpowiedź (dane wyjściowe) na&nbsp;zadane przez&nbsp;Usługobiorcę zapytanie z&nbsp;zakresu prawa powszechnie obowiązującego w&nbsp;Rzeczpospolitej Polskiej i&nbsp;wydawanego na&nbsp;ich podstawie orzecznictwa sądów powszechnych lub innych organów orzeczniczych lub polecenie stworzenia pisma bądź umowy, zastrzegając, że&nbsp;udzielona odpowiedź nie&nbsp;stanowi świadczenia pomocy prawnej. Usługodawca informuje, że&nbsp;uzyskana odpowiedź (dane wyjściowe) stanowi wyłącznie efekt przeprowadzonej przez&nbsp;sztuczną inteligencję analizy językowej prezentującej różne poglądy i&nbsp;kierunki orzecznicze oraz&nbsp;struktury wnioskowania przyjmowane przez&nbsp;organy wydające orzeczenia lub rozstrzygnięcia, które wymagają krytycznego podejścia ze&nbsp;strony Użytkownika, bądź stanowi projekt umowy lub pisma, niewiążący co&nbsp;do&nbsp;wymogów formalnych i&nbsp;założeń Użytkownika. Usługodawca nie&nbsp;gwarantuje, że&nbsp;udostępniane Usługobiorcy treści prezentują w&nbsp;sposób prawidłowy treść przepisów prawnych lub w&nbsp;sposób rzeczywisty odzwierciedlają treść publikowanych orzeczeń lub tendencji orzeczniczych, ani nie&nbsp;gwarantuje, że&nbsp;kierowanie się uzyskanymi danymi wyjściowymi w&nbsp;podejmowaniu kroków prawnych lub dokonywaniu czynności prawnych przez&nbsp;Usługobiorcę doprowadzi do&nbsp;osiągnięcia oczekiwanych skutków prawnych.&nbsp;</li>



<li>Usługobiorca, podejmując jakiekolwiek czynności prawne, powinien skonsultować się z&nbsp;osobą świadczącą profesjonalnie pomoc prawną i&nbsp;posiadającą odpowiedni tytuł zawodowy w&nbsp;tym zakresie.</li>



<li>Akceptując niniejszym Regulamin, Usługobiorca oświadcza, że&nbsp;został poinformowany, że&nbsp;Usługodawca nie&nbsp;ponosi odpowiedzialności za&nbsp;jakość danych wyjściowych dostarczanych przez&nbsp;sztuczną inteligencję, w&nbsp;szczególności z&nbsp;uwagi na&nbsp;fakt, że:
<ol>
<li>przedmiotem Umowy jest świadczenie na&nbsp;rzecz Usługobiorcy usług dostępu do&nbsp;Systemu poprzez Serwis, zaś sposób korzystania przez&nbsp;Usługobiorcę z&nbsp;funkcjonalności Systemu nie&nbsp;jest zależny od&nbsp;Usługodawcy;</li>



<li>System jest oparty na&nbsp;wysoce zaawansowanych rozwiązaniach technologicznych, w&nbsp;szczególności na&nbsp;technologii OCR, chmury obliczeniowej oraz&nbsp;stale rozwijanej sztucznej inteligencji;</li>



<li>na&nbsp;prawidłowe świadczenie Usług mają wpływ: urządzenie końcowe Usługobiorcy, dostawcy usług internetowych Usługobiorcy, dostawcy usług informatycznych, zapewniających prawidłowe działanie silnika sztucznej inteligencji, za&nbsp;pośrednictwem której&nbsp;Usługodawca świadczy Usługi oraz&nbsp;dostęp do&nbsp;publicznie publikowanych orzeczeń sądów powszechnych;</li>



<li>sztuczna inteligencja, za&nbsp;pomocą której&nbsp;Usługodawca świadczy usługi w&nbsp;ramach Serwisu, jest wciąż rozwijana w&nbsp;oparciu o&nbsp;ograniczone zbiory danych, za&nbsp;których&nbsp;treść nie&nbsp;odpowiada Usługodawca;</li>



<li>przedstawiane przez&nbsp;sztuczną inteligencję wyniki zapytań są&nbsp;uwarunkowane strukturą zapytania Usługobiorcy, jak również dostępnością danych zgromadzonych w&nbsp;publicznie dostępnych stronach internetowych publikujących orzeczenia i&nbsp;rozstrzygnięcia sądów powszechnych, sądów administracyjnych i&nbsp;innych organów orzeczniczych;</li>



<li>sztuczna inteligencja nie&nbsp;zawsze udziela odpowiedzi na&nbsp;pytania zgodnie z&nbsp;rzeczywistością, zjawisko to&nbsp;nazywa się mianem „<em>halucynowania</em>” i&nbsp;może mieć ono istotny wpływ na&nbsp;rezultat uzyskany za&nbsp;jej pomocą.</li>
</ol>
</li>
</ol>
</li>



<li><strong>PŁATNOŚCI</strong>
<ol>
<li>Usługobiorca, w&nbsp;celu wykupienia Abonamentu, uiszcza Opłatę Abonamentową, z&nbsp;góry, za&nbsp;dany okres rozliczeniowy i&nbsp;zgodnie z&nbsp;Cennikiem.&nbsp;</li>



<li>Użytkownik ma&nbsp;możliwość uiszczenia Opłaty Abonamentowej za&nbsp;wybrany okres jednorazowo (Abonament jednorazowy), bądź wybrać opcję automatycznego odnawiania się Abonamentu (Abonament w&nbsp;formule automatycznego odnawiania). W&nbsp;razie wybrania przez&nbsp;Użytkownika Abonamentu w&nbsp;formule automatycznego odnawiania, Abonament odnawia się automatycznie na&nbsp;czas wybrany przez&nbsp;Użytkownika przy wykupieniu Abonamentu, po&nbsp;pobraniu przez&nbsp;Usługodawcę Opłaty Abonamentowej zgodnej z&nbsp;Cennikiem.&nbsp;</li>



<li>Przez&nbsp;okres rozliczeniowy Abonamentu uznaje się okres, na&nbsp;jaki Abonament został wykupiony. Okres rozliczeniowy Abonamentu rozpoczyna się wraz z&nbsp;uiszczeniem przez&nbsp;Użytkownika Opłaty Abonamentowej za&nbsp;wybrany okres Abonamentu. W&nbsp;przypadku Użytkownika, który&nbsp;wykupił Abonament w&nbsp;formule automatycznego odnawiania, kolejne okresy rozliczeniowe rozpoczynają się z&nbsp;pierwszym dniem następującym po&nbsp;zakończeniu poprzedniego okresu rozliczeniowego, po&nbsp;pobraniu przez&nbsp;Usługodawcę Opłaty Abonamentowej za&nbsp;Abonament na&nbsp;kolejny okres.&nbsp;</li>



<li>Usługodawca umożliwia dokonanie płatności Opłaty Abonamentowej w&nbsp;następujących formach: przelew tradycyjny, BLIK oraz&nbsp;za&nbsp;pośrednictwem karty płatniczej, zaś&nbsp;płatności są&nbsp;realizowane za&nbsp;pomocą serwisu stripe.com.&nbsp;</li>



<li>Użytkownik, aby wykupić Abonament w&nbsp;formule automatycznego odnawiania, zapewni Usługodawcy możliwość automatycznego pobierania Opłaty Abonamentowej za&nbsp;pośrednictwem karty płatniczej, przy użyciu metody płatności oferowanej w&nbsp;Serwisie i&nbsp;upoważnia do&nbsp;tego Usługodawcę. Wykupując Abonament w&nbsp;formule automatycznego odnawiania, Użytkownik oświadcza, że&nbsp;przyjmuje do&nbsp;wiadomości, że&nbsp;Opłata Abonamentowa za&nbsp;nowy Abonament zostanie automatycznie pobrana przez&nbsp;Usługodawcę, o&nbsp;ile Użytkownik nie&nbsp;anuluje Abonamentu zgodnie z&nbsp;ustępem 12.4. poniżej przed&nbsp;rozpoczęciem kolejnego okresu rozliczeniowego</li>



<li>Momentem zapłaty Opłaty Abonamentowej jest data zaksięgowania wpłaty na&nbsp;rachunku bankowym Usługodawcy.</li>



<li>Koszty związane z&nbsp;realizacją płatności Opłaty Abonamentowej ponosi Usługobiorca.</li>



<li>Usługodawca wystawi Usługobiorcy fakturę na&nbsp;kwotę Opłaty Abonamentowej, niezwłocznie po&nbsp;jej otrzymaniu, w&nbsp;oparciu o&nbsp;dane wskazane podczas rejestracji Konta Usługobiorcy. Usługodawca dostarczy fakturę Usługobiorcy na&nbsp;podany przez&nbsp;niego adres poczty elektronicznej w&nbsp;terminie 3 dni roboczych&nbsp; od&nbsp;jej wystawienia.</li>



<li>Usługobiorca wyraża zgodę na&nbsp;otrzymywanie faktur od&nbsp;Usługodawcy drogą elektroniczną, zgodnie z&nbsp;art. 106 n&nbsp;ust. 1 Ustawy z&nbsp;dnia 11 marca 2004&nbsp;r. o&nbsp;podatku od&nbsp;towarów i&nbsp;usług, bez&nbsp;podpisu wystawcy.&nbsp;</li>



<li>W&nbsp;przypadku Użytkownika posiadającego wykupiony Abonament w&nbsp;formule automatycznego odnowienia, niemożliwość pobrania przez&nbsp;Usługodawcę Opłaty Abonamentowej za&nbsp;kolejny okres, po&nbsp;upływie okresu za, który&nbsp;uiszczono poprzednią Opłatę Abonamentową, uważane jest za&nbsp;zakończenie Abonamentu (wypowiedzenie Umowy w&nbsp;zakresie świadczenia Usług odpłatnych ze&nbsp;skutkiem natychmiastowym). W&nbsp;takim wypadku, wraz z&nbsp;upływem ostatniego dnia okresu rozliczeniowego, za&nbsp;który&nbsp;Użytkownik uiścił uprzednio Opłatę Abonamentową, Abonament wygasa, a&nbsp;Usługodawca zaprzestaje świadczenia Usług odpłatnych na&nbsp;rzecz Użytkownika.</li>
</ol>
</li>



<li><strong>ZMIANA CENNIKA&nbsp;</strong>
<ol>
<li>W&nbsp;celu zmiany Cennika, w&nbsp;szczególności podwyższenia Opłat Abonamentowych, Usługodawca poinformuje o&nbsp;tym fakcie Użytkowników z&nbsp;co&nbsp;najmniej 14-dniowym wyprzedzeniem drogą e-mailową, przesyłając zawiadomienie na&nbsp;adresy e-mail Użytkowników wskazane w&nbsp;Serwisie.</li>



<li>Zmiana Cennika wchodzi w&nbsp;życie wraz z&nbsp;upływem 14 dni od&nbsp;dnia zawiadomienia Użytkownika, nie&nbsp;wcześniej jednak niż z&nbsp;rozpoczęciem nowego okresu rozliczeniowego – w&nbsp;przypadku Użytkowników posiadających Abonament w&nbsp;formule automatycznego odnawiania, z&nbsp;zastrzeżeniem ustępu 11.3. poniżej. Użytkownik, który&nbsp;posiada wykupiony Abonament w&nbsp;formule automatycznego odnawiania, uiszcza Opłatę Abonamentową w&nbsp;zmienionej wysokości wraz z&nbsp;rozpoczęciem nowego okresu rozliczeniowego, rozpoczynającego się po&nbsp;wejściu w&nbsp;życie nowego Cennika. W&nbsp;przypadku Użytkowników posiadających Abonament jednorazowy, Opłata Abonamentowa nie&nbsp;ulega podwyższeniu w&nbsp;okresie trwania Abonamentu.</li>



<li>Po&nbsp;uzyskaniu informacji o&nbsp;planowanych zmianach Cennika, Użytkownik niewyrażający zgody na&nbsp;zmianę Cennika i&nbsp;posiadający Abonament w&nbsp;formule automatycznego odnawiania Abonamentu, jest uprawniony do&nbsp;anulowania Abonamentu wraz z&nbsp;końcem bieżącego okresu rozliczeniowego. W&nbsp;takim wypadku, po&nbsp;upływie okresu rozliczeniowego, za&nbsp;który&nbsp;pobrano Opłatę Abonamentową, Usługodawca nie&nbsp;będzie w&nbsp;dalszym ciągu świadczył na&nbsp;rzecz Użytkownika Usług odpłatnych, a&nbsp;nowa Opłata Abonamentowa nie&nbsp;zostanie pobrana. Użytkownik może anulować Abonament za&nbsp;pośrednictwem Serwisu, korzystając z&nbsp;opcji „anuluj abonament”.&nbsp;</li>
</ol>
</li>



<li><strong>ZAKOŃCZENIE UMOWY, ZAKOŃCZENIE ABONAMENTU</strong>
<ol>
<li>Każdemu Użytkownikowi przysługuje prawo do&nbsp;zakończenia współpracy z&nbsp;Usługodawcą (wypowiedzenia Umowy), z&nbsp;zastrzeżeniem ustępów kolejnych.</li>



<li>W&nbsp;przypadku, gdy&nbsp;Użytkownik korzysta z&nbsp;Serwisu wyłącznie w&nbsp;ramach Usług nieodpłatnych, może w&nbsp;każdym czasie zakończyć Umowę poprzez usunięcie Konta w&nbsp;Serwisie, bez&nbsp;podania przyczyny. Żądanie usunięcia Konta z&nbsp;Serwisu kieruje się do&nbsp;Usługodawcy za&nbsp;pośrednictwem Serwisu, poprzez złożenie oświadczenia o&nbsp;woli wypowiedzenia Umowy i&nbsp;usunięcia Konta drogą e-mailową na&nbsp;adres: kontakt@gaius-lex.pl Umowa ulega rozwiązaniu z&nbsp;chwilą usunięcia Konta Użytkownika w&nbsp;Serwisie. Usługodawca co&nbsp;do&nbsp;zasady usuwa Konto Użytkownika w&nbsp;ciągu 24 godzin od&nbsp; złożenia zgłoszenia, z&nbsp;tym zastrzeżeniem, że&nbsp;w&nbsp;przypadku przeszkód technicznych, okres ten może wydłużyć się do&nbsp;14 dni.</li>



<li>Użytkownik, który&nbsp;posiada wykupiony jednorazowy Abonament, nie&nbsp;jest uprawniony do&nbsp;wypowiedzenia Umowy. Odpłatne świadczenie Usług przez&nbsp;Usługodawcę skończy się wraz z&nbsp;upływem ostatniego dnia okresu, na&nbsp;jaki wykupiono Abonament. Po&nbsp;tym dniu, Użytkownik może wypowiedzieć Umowę i&nbsp;usunąć Konto zgodnie z&nbsp;ustępem 12.2. powyżej. W&nbsp;przypadku, gdy&nbsp;Użytkownik wyrazi wolę rozwiązania Umowy i&nbsp;usunięcia Konta, pomimo wykupionego i&nbsp;opłaconego z&nbsp;góry Abonamentu, ma&nbsp;możliwość skontaktowania się z&nbsp;Usługodawcą drogą e-mailową w&nbsp;tym celu na&nbsp;adres wskazany w&nbsp;Regulaminie, z&nbsp;tym zastrzeżeniem, że&nbsp;Opłata Abonamentowa zapłacona przez&nbsp;Użytkownika jednorazowo z&nbsp;góry nie&nbsp;zostanie zwrócona.</li>



<li>Użytkownik, który&nbsp;posiada wykupiony Abonament w&nbsp;formule automatycznego odnawiania Abonamentu, może w&nbsp;każdym czasie anulować Abonament (wypowiedzieć Umowę w&nbsp;części dotyczącej świadczenia Usług odpłatnych). W&nbsp;takim wypadku,&nbsp; żądanie anulowania Abonamentu Użytkownik kieruje do&nbsp;Usługodawcy za&nbsp;pośrednictwem Serwisu, w&nbsp;zakładce: płatności, korzystając z&nbsp;opcji „<em>anuluj abonament</em>” W&nbsp;takim wypadku, Usługodawca przestaje świadczyć na&nbsp;rzecz Użytkownika Usługi odpłatne objęte Abonamentem po&nbsp;upływie ostatniego dnia okresu rozliczeniowego, w&nbsp;którym&nbsp;anulowano Abonament (okres wypowiedzenia), a&nbsp; kolejna Opłata Abonamentowa nie&nbsp;jest pobierana.</li>



<li>Usługodawcy przysługuje prawo do&nbsp;wypowiedzenia Umowy ze&nbsp;skutkiem natychmiastowym:
<ol>
<li>w&nbsp;razie podania przez&nbsp;Usługobiorcę nieprawdziwych i&nbsp;niekompletnych danych, które są&nbsp;niezbędne do&nbsp;realizacji Umowy;</li>



<li>w&nbsp;razie podania przez&nbsp;Użytkownika, który&nbsp;posiada wykupiony Abonament, podania danych nieprawdziwych lub niekompletnych danych, które są&nbsp;niezbędne do&nbsp;prawidłowego i&nbsp;zgodnego z&nbsp;prawem rozliczenia Umowy, z&nbsp;tym zastrzeżeniem, że&nbsp;w&nbsp;takim wypadku Usługodawca wypowiada Umowę w&nbsp;części dotyczącej świadczenia Usług odpłatnych;&nbsp;</li>



<li>w&nbsp;razie stwierdzenia faktu korzystania przez&nbsp;Usługobiorcę z&nbsp;Usług w&nbsp;sposób niezgodny z&nbsp;Regulaminem bądź w&nbsp;sposób naruszający powszechnie obowiązujące przepisy prawa lub naruszający prawa osób trzecich zgodnie z&nbsp;ustępem 13 poniżej;</li>
</ol>
</li>



<li>Umowa wygasa:
<ol>
<li>w&nbsp;całości – z&nbsp;chwilą usunięcia Konta Użytkownika w&nbsp;Serwisie po&nbsp;złożeniu przez&nbsp;Użytkownika dyspozycji usunięcia Konta, bądź z&nbsp;chwilą usunięcia Konta Użytkownika przez&nbsp;Usługodawcę z&nbsp;przyczyn określonych w&nbsp;ustępie 13 Regulaminu;</li>



<li>w&nbsp;części, w&nbsp;odniesieniu do&nbsp;Usługi świadczonej odpłatnie – z&nbsp;upływem okresu, na&nbsp;jaki Usługa została wykupiona lub opóźnienia Użytkownika w&nbsp;zapłacie Opłaty abonamentowej za&nbsp;kolejny Okres rozliczeniowy dłuższego&nbsp;niż 7 dni.&nbsp;</li>
</ol>
</li>
</ol>
</li>



<li><strong>ZAWIESZENIE DOSTĘPU DO&nbsp;USŁUG, ZABLOKOWANIE KONTA I&nbsp;USUNIĘCIE KONTA&nbsp;</strong>
<ol>
<li>W&nbsp;przypadku:
<ol>
<li>powzięcia informacji o&nbsp;naruszaniu przez&nbsp;Użytkownika postanowień niniejszego Regulaminu lub Polityki Prywatności;</li>



<li>powzięcia informacji o&nbsp;naruszaniu przez&nbsp;Użytkownika przepisów powszechnie obowiązujących, w&nbsp;tym korzystania z&nbsp;Serwisu w&nbsp;sposób naruszający prawa osób trzecich lub w&nbsp;sposób, który&nbsp;powoduje dostarczanie treści o&nbsp;charakterze bezprawnym;</li>



<li>korzystanie przez&nbsp;Użytkownika z&nbsp;Usług może powodować szkodę na&nbsp;innych Użytkowników lub Usługodawcy;</li>



<li>Konto Użytkownika jest nieaktywne od&nbsp;ponad roku, a&nbsp;Użytkownik nie&nbsp;posiada wykupionego Abonamentu;<br><br>Usługodawca jest uprawniony do&nbsp;zawieszenia dostępu Użytkownika do&nbsp;Serwisu (zawieszenie Konta) na&nbsp;okres odpowiednio: 7, 14, 30 lub 60 dni lub usunięcia Konta Użytkownika w&nbsp;przypadkach większej wagi i&nbsp;w&nbsp;przypadku wskazanym w&nbsp;ustępie 13.1.4 powyżej. W&nbsp;tym okresie Usługodawca nie&nbsp;świadczy na&nbsp;rzecz Użytkownika Usług, a&nbsp;Użytkownik posiada wyłącznie ograniczony dostęp do&nbsp;Konta w&nbsp;Serwisie.&nbsp;</li>
</ol>
</li>



<li>Usunięcie Konta Użytkownika z&nbsp;przyczyn, o&nbsp;których&nbsp;mowa w&nbsp;ustępie 13.1. Regulaminu zostanie poprzedzone skierowaniem do&nbsp;Użytkownika informacji o&nbsp;zablokowaniu Konta na&nbsp;okres 30 dni, w&nbsp;czasie którym&nbsp;Użytkownik może złożyć reklamację do&nbsp;Usługodawcy.&nbsp;</li>



<li>W&nbsp;razie usunięcia Konta Użytkownika z&nbsp;przyczyn, o&nbsp;których&nbsp;mowa w&nbsp;ustępie 13.1. Regulaminu, Umowa ulega rozwiązaniu, a&nbsp;uiszczona za&nbsp;dany okres rozliczeniowy Opłata Abonamentowa nie&nbsp;jest zwracana.</li>



<li>W&nbsp;razie zablokowania Konta Użytkownika, Usługodawca podejmie rozsądne starania, żeby&nbsp;umożliwić Użytkownikowi przygotowanie się przez&nbsp;Użytkownika do&nbsp;usunięcia Konta oraz&nbsp;zapewnić, aby Użytkownik z&nbsp;wyprzedzeniem mógł eksportować swoje treści zachowane w&nbsp;ramach świadczonych Usług (historia zapytań), chyba że&nbsp;dalszy dostęp przez&nbsp;Użytkownika do&nbsp;Konta mógłby spowodować szkody dla innych Użytkowników Serwisu, Usługodawcy lub osób trzecich, bądź jeśli przyczyną zablokowania Konta jest korzystanie przez&nbsp;Użytkownika z&nbsp;Serwisu w&nbsp;sposób bezprawny.&nbsp;</li>



<li>Użytkownik jest informowany o&nbsp;zawieszeniu lub zablokowaniu. Po&nbsp;uzyskaniu informacji, Użytkownik jest uprawniony o&nbsp;złożeniu reklamacji (odwołania) od&nbsp;zawieszenia lub zablokowania Konta Użytkownika, którą Użytkownik kieruje na&nbsp;adres e-mail wskazany w&nbsp;ustępie 14 Regulaminu. W&nbsp;razie złożenia reklamacji przez&nbsp;Użytkownika, Usługodawca rozpatruje złożoną reklamację w&nbsp;terminie 14 dni, a&nbsp;w&nbsp;przypadku zablokowania Konta,&nbsp; wstrzymuje czynności usunięcia Konta do&nbsp;chwili rozpatrzenia reklamacji Użytkownika.</li>
</ol>
</li>



<li><strong>PROCEDURA REKLAMACYJNA</strong>
<ol>
<li>W&nbsp;przypadku wystąpienia zastrzeżeń co&nbsp;do&nbsp;funkcjonowania Systemu lub nieprawidłowości w&nbsp;świadczeniu Usług drogą elektroniczną przez&nbsp;Usługodawcę, Użytkownik może złożyć Usługodawcy reklamację przesyłając ją&nbsp;na&nbsp;następujący adres e-mail: <a href="mailto:kontakt@gaius-lex.pl">kontakt@gaius-lex.pl</a>.</li>



<li>W&nbsp;zgłoszeniu reklamacyjnym należy wskazać:
<ol>
<li>login/adres e-mail przypisany Usługobiorcy oraz&nbsp;dane kontaktowe,</li>



<li>przedmiot reklamacji – wskazanie nieprawidłowości wraz&nbsp;ze&nbsp;wskazaniem terminu ich wystąpienia i&nbsp;czasu trwania,</li>



<li>proponowany sposób rozstrzygnięcia reklamacji.</li>
</ol>
</li>



<li>Usługodawca przed&nbsp;rozpatrzeniem złożonej reklamacji, może zwrócić się do&nbsp;Usługobiorcy o&nbsp;jej uzupełnienie, jeżeli podane przez&nbsp;Użytkownika informacje, o&nbsp;których mowa w&nbsp;ust. 2 wymagają doprecyzowania w&nbsp;celu prawidłowego rozpatrzenia reklamacji.</li>



<li>Rozpatrzenie reklamacji nastąpi niezwłocznie, przy czym nie&nbsp;później niż w&nbsp;terminie 14 dni od&nbsp;dnia otrzymania zgłoszenia reklamacyjnego przez&nbsp;Usługodawcę, a&nbsp;sposób rozstrzygnięcia Usługodawca przekaże Usługobiorcy drogą elektroniczną na&nbsp;adres e-mail przypisany do&nbsp;Konta Usługobiorcy.</li>



<li>Wszelkie inne uwagi dotyczące funkcjonowania Serwisu oraz&nbsp;pytania dotyczące Usług należy kierować za&nbsp;pośrednictwem poczty elektronicznej na&nbsp;następujący adres mailowy: <a href="mailto:kontakt@gaius-lex.pl">kontakt@gaius-lex.pl</a>.</li>
</ol>
</li>



<li><strong>PRAWA WŁASNOŚCI INTELEKTUALNEJ</strong>
<ol>
<li>Usługodawca zastrzega, że&nbsp;zarówno Serwis, jak i&nbsp;System zawierają treści chronione prawem własności intelektualnej (np.&nbsp;elementy graficzne, logotypy, programy komputerowe), w&nbsp;szczególności prawami autorskimi, którymi dysponuje lub z&nbsp;których korzysta na&nbsp;mocy stosownych licencji, Usługodawca.</li>



<li>Korzystanie z&nbsp;Serwisu nie&nbsp;oznacza nabycia jakichkolwiek praw na&nbsp;dobrach niematerialnych do&nbsp;utworów znajdujących się w&nbsp;Serwisie. Bez&nbsp;pozyskania uprzedniej zgody Usługodawcy, zabronione jest kopiowanie, rozpowszechnianie, wykorzystywanie oraz&nbsp;modyfikowanie jakichkolwiek elementów składowych Serwisu czy&nbsp;Systemu.</li>



<li>Usługodawca oświadcza, że&nbsp;z&nbsp;chwilą uzyskania danych wyjściowych z&nbsp;Systemu, w&nbsp;wyniku&nbsp; interakcji ze&nbsp;sztuczną inteligencją, która&nbsp;stanowi część Systemu, Użytkownik nabywa autorskie prawa majątkowe do&nbsp;danych wyjściowych, które uzyskał wskutek interakcji ze&nbsp;sztuczną inteligencją i&nbsp;uważany jest za&nbsp;twórcę tak&nbsp;powstałego utworu w&nbsp;rozumieniu ustawy z&nbsp;dnia 4 lutego 1994&nbsp;r. o&nbsp;prawie autorskim i&nbsp;prawach pokrewnych, jeśli&nbsp; dane wyjściowe będą spełniały przesłanki utworu. Zdanie poprzedzające nie&nbsp;znajduje zastosowania w&nbsp;uznania, że&nbsp;związek Użytkownika jako twórcy z&nbsp;utworem jest znikomy. W&nbsp;takim wypadku, dane wyjściowe uzyskane wskutek interakcji Użytkownika z&nbsp;algorytmem sztucznej inteligencji stają się częścią domeny publicznej.&nbsp;&nbsp;&nbsp;</li>



<li>Usługodawca niniejszym oświadcza, że&nbsp;korzystanie przez&nbsp;Usługobiorcę z&nbsp;Systemu zgodnie z&nbsp;Regulaminem nie&nbsp;narusza praw autorskich osób trzecich, z&nbsp;tym zastrzeżeniem, że&nbsp;Usługodawca nie&nbsp;odpowiada za&nbsp;naruszenie praw autorskich osób trzecich wskutek udostępniania przez&nbsp;Usługobiorcę plików cyfrowych (dokumentów) w&nbsp;Systemie bez&nbsp;wymaganych prawem zgód lub licencji.&nbsp;</li>



<li>Każda osoba, która&nbsp;uznaje, że&nbsp;przysługujące jej prawa własności intelektualnej zostały naruszone wskutek oferowania Usług w&nbsp;Serwisie lub korzystania z&nbsp;Serwisu przez&nbsp;Użytkowników może poinformować Usługodawcę o&nbsp;tym fakcie pod&nbsp;adresem: kontakt@gaius-lex.pl Usługodawca podejmie działania w&nbsp;celu wyjaśnienia zgłoszenia i&nbsp;udzieli odpowiedzi w&nbsp;terminie 14 dni, a&nbsp;w&nbsp;przypadku gdyby&nbsp;napotkał techniczne utrudnienia w&nbsp;tym zakresie, poinformuje osobę zgłaszającą o&nbsp;terminie w&nbsp;jakim udzieli odpowiedzi.&nbsp;</li>
</ol>
</li>



<li><strong>OCHRONA DANYCH OSOBOWYCH</strong>
<ol>
<li>Administratorem danych Użytkowników jest Usługodawca.</li>



<li>Usługodawca przetwarza dane osobowe Użytkowników w&nbsp;celu umożliwienia Użytkownikom korzystania z&nbsp;Systemu, w&nbsp;tym w&nbsp;celu świadczenia Usług na&nbsp;rzecz Użytkowników.</li>



<li>Szczegółowe zasady przetwarzania danych osobowych przez&nbsp;Usługodawcę w&nbsp;ramach świadczenia Usług zgodnie z&nbsp;niniejszym Regulaminem znajdują się w&nbsp;Polityce Prywatności dostępnej pod&nbsp;adresem: https://gaius-lex.pl/privacy-policy</li>



<li>Usługodawca oświadcza, że&nbsp;dane osobowe udostępniane przez&nbsp;Użytkowników są&nbsp;chronione przez&nbsp;Usługodawcę w&nbsp;sposób opisany w&nbsp;Polityce Prywatności. <strong>Do&nbsp;chwili zawarcia z&nbsp;Usługodawcą umowy powierzenia przetwarzania danych osobowych Użytkownik nie&nbsp;jest uprawniony do&nbsp;udostępniania danych osobowych osób trzecich Usługodawcy i&nbsp;jest zobowiązany w&nbsp;stosowny sposób zanonimizować każdy dokument lub informacje przekazywane Usługodawcy przez&nbsp;Serwis</strong> w&nbsp;taki sposób, aby niemożliwe było zidentyfikowanie osoby fizycznej, której&nbsp;dane dotyczą.&nbsp;</li>



<li>Użytkownik może zgłosić wolę zawarcia z&nbsp;Usługobiorcą umowy o&nbsp;powierzeniu przetwarzania danych pod&nbsp;adresem: kontakt@gaius-lex.pl W&nbsp;przypadku zawarcia przez&nbsp;Użytkownika i&nbsp;Usługodawcę stosownej umowy powierzenia danych osobowych w&nbsp;imieniu Użytkownika, Użytkownik nie&nbsp;ma&nbsp;obowiązku anonimizacji danych osobowych przed&nbsp;ich udostępnieniem w&nbsp;Serwisie, a&nbsp;Usługodawca stosuje stosowne zabezpieczenia mające na&nbsp;celu zminimalizowanie przetwarzania danych i&nbsp;zapewnienie ich bezpieczeństwa i&nbsp;przetwarza dane osobowe zgodnie z&nbsp;umową o&nbsp;powierzeniu przetwarzania danych osobowych.&nbsp;</li>



<li>Mając na&nbsp;uwadze profesjonalny charakter Usługobiorcy oraz&nbsp;wyraźny zakaz udostępniania przez&nbsp;Usługobiorcę danych osobowych osób trzecich bez&nbsp;zawarcia umowy o&nbsp;powierzeniu przetwarzania danych osobowych z&nbsp;Usługodawcą, Usługodawca nie&nbsp;ponosi odpowiedzialności za&nbsp;przetwarzanie danych osobowych osób trzecich, które zostały udostępnione przez&nbsp;Usługobiorcę w&nbsp;Serwisie niezgodnie z&nbsp;postanowieniami niniejszego Regulaminu i&nbsp;Polityki Prywatności. W&nbsp;przypadku udostępnienia danych osobowych osób trzecich przez&nbsp;Usługobiorcę niezgodnie z&nbsp;postanowieniami Regulaminu i&nbsp;Polityki Prywatności:
<ol>
<li>w&nbsp;razie zgłoszenia żądań zgłoszonych przez&nbsp;osoby, których&nbsp;dane dotyczą, Usługodawca zadośćuczyni żądaniom zgłoszonym przez&nbsp;te&nbsp;osoby, w&nbsp;tym niezwłocznie usunie takie dane osobowe;</li>



<li>Usługodawca jest uprawniony do&nbsp;żądania naprawienia szkody przez&nbsp;Usługobiorcę w&nbsp;zakresie, w&nbsp;jakim był zobowiązany do&nbsp;zadośćuczynienia żądaniom osób, których&nbsp;dane dotyczą, zgłoszonym przez&nbsp;te&nbsp;osoby roszczeniom oraz&nbsp;poniesienia wszelkich kosztów, jakie Usługodawca poniósł w&nbsp;związku z&nbsp;prowadzonymi postępowaniami sądowymi lub administracyjnymi w&nbsp;tym zakresie.</li>
</ol>
</li>
</ol>
</li>



<li><strong>WSPARCIE TECHNICZNE</strong>
<ol>
<li>W&nbsp;trakcie obowiązywania Umowy Usługobiorca ma&nbsp;możliwość skorzystania ze&nbsp;wsparcia technicznego Usługodawcy w&nbsp;zakresie związanym z&nbsp;korzystaniem z&nbsp;Usług w&nbsp;ramach Opłaty abonamentowej.</li>



<li>W&nbsp;celu skorzystania ze&nbsp;wsparcia technicznego należy wysłać zgłoszenie problemu za&nbsp;pomocą czatu znajdującego się w&nbsp;Serwisie lub na&nbsp;adres e-mail: kontakt@gaius-lex.pl ze&nbsp;wskazaniem rodzaju nieprawidłowości i&nbsp;błędów w&nbsp;Systemie.</li>



<li>Wsparcie i&nbsp;pomoc techniczna świadczone są&nbsp;przez&nbsp;Usługodawcę od&nbsp;poniedziałku do&nbsp;piątku (z&nbsp;wyjątkiem dni ustawowo wolnych od&nbsp;pracy na&nbsp;terenie Rzeczypospolitej Polskiej) w&nbsp;godzinach od&nbsp;9:00 do&nbsp;21:00&nbsp;</li>



<li>Wsparcie techniczne Usługodawcy nie&nbsp;ma&nbsp;zastosowania do:
<ol>
<li>błędów, które powstały w&nbsp;wyniku nieuprawnionej ingerencji Usługobiorcy w&nbsp;ustawienia Usługi;</li>



<li>problemów wynikających z&nbsp;niespełnienia przez&nbsp;Usługobiorcę wymogów technicznych, które są&nbsp;niezbędne do&nbsp;korzystania z&nbsp;Usługi.</li>
</ol>
</li>
</ol>
</li>



<li><strong>POSTANOWIENIA KOŃCOWE</strong>
<ol>
<li>Usługodawca zastrzega sobie prawo wprowadzania zmian w&nbsp;treści Regulaminu. W&nbsp;razie wprowadzania zmian do&nbsp;Regulaminu, Usługodawca poinformuje Usługobiorców o&nbsp;zmianach Regulaminu nie&nbsp;później niż 14 dni przed&nbsp;ich planowanym wejściem w&nbsp;życie poprzez: wyświetlenie komunikatu i&nbsp;publikację nowego Regulaminu w&nbsp;Serwisie oraz&nbsp;przesłanie informacji o&nbsp;planowanej zmianie drogą elektroniczną na&nbsp;adres e-mail przypisany do&nbsp;Konta Usługobiorcy.&nbsp;</li>



<li>W&nbsp;terminie 7 dni od&nbsp;dnia przesłania do&nbsp;Użytkowników wiadomości e-mail o&nbsp;zmianach Regulaminu, Usługobiorca, który&nbsp;nie&nbsp;akceptuje nowej treści Regulaminu, może złożyć Usługodawcy oświadczenie o&nbsp;wypowiedzeniu Umowy drogą e-mailową lub za&nbsp;pośrednictwem Serwisu w&nbsp;drodze użycia opcji „<em>Usuń konto</em>”.&nbsp; W&nbsp;tym wypadku, Umowa ulega rozwiązaniu z&nbsp;upływem 14-tego dnia od&nbsp;dnia wysłania do&nbsp;Użytkowników informacji o&nbsp;planowanych zmianach Regulaminu, przy czym dla Użytkownika, który&nbsp;posiada wykupiony Abonament, okres wypowiedzenia nie&nbsp;może upłynąć przed&nbsp;dniem upływu danego okresu rozliczeniowego, w&nbsp;czasie którego&nbsp;Użytkownik korzysta z&nbsp;Serwisu zgodnie z&nbsp;Regulaminem w&nbsp;poprzednim brzmieniu, z&nbsp;zastrzeżeniem kolejnego ustępu.&nbsp;</li>



<li>W&nbsp;przypadku, gdy&nbsp;oświadczenie o&nbsp;wypowiedzeniu Umowy z&nbsp;przyczyn wskazanych w&nbsp;ustępie 18.2. powyżej składa Użytkownik posiadający wykupiony Abonament, a&nbsp;okres pozostały do&nbsp;końca trwającego okresu rozliczeniowego jest dłuższy niż 30 dni, w&nbsp;razie złożenia oświadczenia o&nbsp;wypowiedzeniu Umowy przez&nbsp;Użytkownika, Umowa ulega rozwiązaniu z&nbsp;upływem 30-tego dnia od&nbsp;dnia doręczenia do&nbsp;Usługodawcy oświadczenia o&nbsp;wypowiedzeniu Umowy, zaś Usługodawca usuwa Konto Użytkownika po&nbsp;upływie 30 dni od&nbsp;złożenia mu&nbsp;tego oświadczenia przez&nbsp;Użytkownika, a&nbsp;uiszczona przez&nbsp;Użytkownika Opłata Abonamentowa jest zwracana w&nbsp;wysokości proporcjonalnej do&nbsp;okresu, jaki pozostał do&nbsp;końca okresu rozliczeniowego.&nbsp;&nbsp;</li>



<li>Brak złożenia przez&nbsp;Usługobiorcę oświadczenia o&nbsp;wypowiedzeniu Umowy w&nbsp;terminie wskazanym w&nbsp;ustępie 18.1. powyżej z&nbsp;uwagi na&nbsp;zmiany Regulaminu, jest równoznaczny z&nbsp;akceptacją treści nowego Regulaminu. Usługodawca może również wprowadzić w&nbsp;Serwisie konieczność zatwierdzenia nowego Regulaminu przy pierwszym uruchomieniu Serwisu po&nbsp;wejściu w&nbsp;życie nowego Regulaminu; w&nbsp;takim wypadku ustęp 18.2. Regulaminu stosuje się odpowiednio.</li>



<li>Zmiana nazwy Serwisu logowania lub określonych Usług, zmiana adresów stron internetowych lub adresów e-mail oraz&nbsp;kontaktowych numerów telefonów, a&nbsp;także danych rejestrowych Usługodawcy wskazanych w&nbsp;Zasadach korzystania, nie&nbsp;stanowi zmiany Regulaminu ani Umowy i&nbsp;jest notyfikowana Użytkownikom na&nbsp;stronie Serwisu logowania lub e-mailowo.</li>



<li>Usługodawca może na&nbsp;mocy odrębnej umowy zawartej z&nbsp;Usługobiorcą odmiennie uregulować zasady korzystania z&nbsp;Systemu. W&nbsp;takiej sytuacji w&nbsp;razie kolizji postanowienia indywidualnie wynegocjowanej umowy mają pierwszeństwo przed&nbsp;postanowieniami Regulaminu.</li>



<li>Niniejszy Regulamin podlega prawu polskiemu.&nbsp;</li>



<li>Wszelkie spory powstałe na&nbsp;gruncie niniejszego Regulaminu będą rozpatrywane w&nbsp;drodze polubownych negocjacji, a&nbsp;w&nbsp;przypadku gdy&nbsp;nie&nbsp;dojdzie do&nbsp;porozumienia – przed&nbsp;sądem powszechnym właściwym ze&nbsp;względu na&nbsp;siedzibę Usługodawcy.</li>



<li>W&nbsp;sprawach nieuregulowanych w&nbsp;Regulaminie zastosowanie znajdą przepisy powszechnie obowiązującego prawa polskiego.</li>



<li>Treść niniejszego Regulaminu dostępna jest w&nbsp;Serwisie.</li>



<li>Regulamin<strong> obowiązuje od&nbsp;dnia 06.08.2024.</strong></li>
</ol>
</li>
</ol></div>
</body>

</html>`;

export default statuteNew;
