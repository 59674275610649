import React from "react";
import { MDBCheckbox, MDBInput } from "mdb-react-ui-kit";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, ErrorMessage } from "formik";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { buttonStyleSX } from "~/src/components/styles";
import TextStrings from "./Strings";
// import mautic from "mautic-tracking";
// import SnackBarContext, {
// SNACKBAR_LEVEL,
import { SnackbarContext } from "~/src/contexts/SnackbarContext";
import GoogleButton from "~/src/components/GoogleButton";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { RegisterButtonStyleSX } from "./style";
import { Link } from "react-router-dom";
import FacebookButton from "../../components/FacebookButton";
import Button from "../../components/generic/Button";
import { Colors } from "../../components/generic/Colors";
import { Collapse, Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ErrorStyle = {
  color: "red",
  backgroundColor: Colors.LightRed,
  height: 50,
  display: "flex",
  alignItems: "center",
  margin: "5px 0",
  padding: "0 10px",
};

export function RegisterForm({ accessCode }) {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const via = searchParams.get("via");

  const snackBar = React.useContext(SnackbarContext);

  const [fpHash, setFpHash] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const [referral, setReferral] = React.useState(via);

  const [moreBusiness, setMoreBusiness] = React.useState(false);
  const [more, setMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Set fingerprint
  // React.useEffect(() => {
  //   const setFp = async () => {
  //     const fp = await FingerprintJS.load();

  //     const { visitorId } = await fp.get();
  //     console.log("Setting fphash");
  //     setFpHash(visitorId);
  //   };

  //   setFp();
  // }, []);

  const handleSubmit = async (values, actions) => {
    const { email, password1, password2 } = values;
    setLoading(true);

    try {
      await axios.post(
        "/rest-auth/registration/",
        JSON.stringify({
          email,
          password1,
          password2,
          fpHash,
          referral,
        }),
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // mautic.pageView({
      //   url: "/register",
      //   email,
      // });

      // snackBarContext.openSnackBar(
      //   TextStrings.REGISTER_SUCCESS,
      //   SNACKBAR_LEVEL.SUCCESS
      // );
      snackBar.setMessage(TextStrings.REGISTER_SUCCESS);
      snackBar.setSeverity("success");
      snackBar.setOpen(true);
      setLoading(false);

      navigate("/", { replace: true });
    } catch (err) {
      handleError(err, actions);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    rewardful("ready", function () {
      if (Rewardful.referral) setReferral(Rewardful?.referral);
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password1: "",
          password2: "",
          statuteConsent: false,
          // statuteConsentNew: false,
          businessConsent: false,
          policyConsent: false,
          emailConsent: false,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Email musi być poprawny")
            .required("Email jest wymagany"),
          password1: Yup.string()
            .required("Hasło jest wymagane")
            .min(8, "Hasło musi mieć minimum 8 znaków"),
          password2: Yup.string()
            .required("Hasło jest wymagane")
            .min(8, "Hasło musi mieć minimum 8 znaków")
            .oneOf([Yup.ref("password1"), null], "Hasła muszą być takie same"),
          statuteConsent: Yup.boolean().oneOf(
            [true],
            "Zaakceptuj Regulamin Gaius Lex"
          ),
          // statuteConsentNew: Yup.boolean().oneOf(
          //   [true],
          //   "Zaakceptuj Regulamin Gaius Lex"
          // ),
          businessConsent: Yup.boolean().oneOf(
            [true],
            "Zaakceptuj Regulamin Gaius Lex"
          ),
          policyConsent: Yup.boolean().oneOf(
            [true],
            "Zaakceptuj Politykę prywatności Gaius Lex"
          ),
          // emailConsent: Yup.boolean().oneOf(
          //   [true],
          //   "Zaakceptuj zgodę na przesyłanie treści marketingowych Gaius Lex"
          // ),
        })}
      >
        {(formik) => {
          return (
            <Form id="register-form" data-rewardful="true">
              <div id="register-header">
                <h2 className="h2-header">Witaj w Gaius Lex</h2>
                <p className="grey-text pb-4">Rejestracja do serwisu</p>
              </div>
              {/* error message */}
              <div id="error-messages" className="text-center">
                <ErrorMessage
                  id="email-error-label"
                  name="email"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
                <ErrorMessage
                  name="password1"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
                <ErrorMessage
                  name="password2"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
                <ErrorMessage
                  name="statute-consent"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
                <ErrorMessage
                  name="policy-consent"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
                <ErrorMessage
                  name="email-consent"
                  component="div"
                  className="error-msg rounded-6"
                  style={ErrorStyle}
                />
              </div>
              <div id="register-input" className="grey-text">
                <MDBInput
                  className="mt-2"
                  id="email-input"
                  name="email"
                  label="Podaj adres email"
                  icon="envelope"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <MDBInput
                  className="mt-2"
                  id="password-1-input"
                  name="password1"
                  label="Wpisz hasło"
                  icon="lock"
                  type="password"
                  value={formik.values.password1}
                  onChange={formik.handleChange}
                />
                <MDBInput
                  className="mt-2 mb-4"
                  id="password-2-input"
                  name="password2"
                  label="Wpisz hasło ponownie"
                  icon="lock"
                  type="password"
                  value={formik.values.password2}
                  onChange={formik.handleChange}
                />
                <MDBCheckbox
                  id="statute-consent-input"
                  name="statuteConsent"
                  checked={formik.values.statuteConsent}
                  onChange={formik.handleChange}
                />
                <p className="checkbox-content">
                  Oświadczam, że zapoznałem się z{" "}
                  <Link to="/statute-new">Regulaminem świadczenia usługi</Link> i
                  akceptuję jego postanowienia.*
                </p>
                {/* <MDBCheckbox
                  id="statute-consent-input"
                  name="statuteConsentNew"
                  checked={formik.values.statuteConsentNew}
                  onChange={formik.handleChange}
                />
                <p className="checkbox-content">
                  Oświadczam, że zapoznałem się z{" "}
                  <Link to="/statute-new">Regulaminem świadczenia usługi</Link>, który obowiązuje od 06.08.2024 i
                  akceptuję jego postanowienia.*
                </p> */}
                <div style={{clear: "both"}}>


                  <div style={{float: "left"}}>
                <MDBCheckbox
                  id="business-consent-input"
                  name="businessConsent"
                  checked={formik.values.businessConsent}
                  onChange={formik.handleChange}

                />
                  </div><p className="checkbox-content" style={{float: "left"}}>Oświadczam, że prowadzę działalność gospodarczą we własnym imieniu, a
                  założenie konta w Serwisie i korzystanie z usług w ramach Serwisu, będzie pozostawało w bezpośrednim
                  związku z prowadzoną przeze mnie działalnością gospodarczą lub zawodową.*{" "}<Collapse in={moreBusiness}>Informujemy, że nasze usługi skierowane są do przedsiębiorców, którzy korzystać będą
                  z oferowanych przez nas usług w ramach prowadzonej działalności. Jeśli chciałbyś założyć konto w
                  Serwisie, a nie prowadzisz działalności gospodarczej lub korzystanie przez Ciebie z Serwisu nie będzie
                  miało bezpośredniego związku z Twoją działalnością - napisz do nas. Poinformujemy Cię, czy możliwe
                  jest skorzystanie przez Ciebie z naszych usług oraz przedstawimy indywidualną ofertę.</Collapse><a onClick={() => setMoreBusiness(!moreBusiness)}>
                  {moreBusiness ? "mniej" : "więcej"}</a>
                </p>
                </div>
                <MDBCheckbox
                  id="policy-consent-input"
                  name="policyConsent"
                  checked={formik.values.policyConsent}
                  onChange={formik.handleChange}
                />
                <p className="checkbox-content">
                  Oświadczam, że zapoznałem się z zasadami przetwarzania danych
                  osobowych zawartych w{" "}
                  <Link to="/privacy-policy">Polityce prywatności</Link> i je
                  akceptuję.*
                </p>
                <MDBCheckbox
                  id="email-consent-input"
                  name="emailConsent"
                  checked={formik.values.emailConsent}
                  onChange={formik.handleChange}
                />
                <p className="checkbox-content">
                  Wyrażam zgodę na przesyłanie mi przez Administratora na
                  wskazany przeze mnie adres e-mail treści marketingowych i
                  informacji handlowych dotyczących działalności Administratora
                  ...{" "}
                  <Collapse in={more}>
                    Zostałem poinformowany o tym, że mogę wycofać zgodę w
                    dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z
                    prawem przetwarzania dokonanego przed jej wycofaniem.
                  </Collapse>
                  <a onClick={() => setMore(!more)}>
                    {more ? "mniej" : "więcej"}
                  </a>
                </p>
              </div>
              <a style={{ fontSize: 14 }} onClick={handleOpen}>
                Podstawowe informacje o zasadach przetwarzania danych osobowych
              </a><br/>
              <p  style={{ fontSize: 14 }}>* - zgody niezbędne do założenia konta w Serwisie i rozpoczęcia świadczenia usług</p>
              <div id="register-social-buttons">
                <GoogleButton referral={referral} />
                <FacebookButton referral={referral} />
              </div>
              <div
                style={{ display: "block", float: "left", width: "100%" }}
                className="text-center pt-2 pb-2"
              >
                <Button
                  variant="contained-dark"
                  width={"100%"}
                  type="submit"
                  disabled={fpHash === "" || loading === true}
                >
                  {fpHash === "" || loading === true ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    TextStrings.register
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Link
        to={`/login`}
        className="text-center"
        style={{ marginTop: 15, color: Colors.DarkGrey, textDecoration: "none", textAlign: "left" }}
      >
        Masz już konto? Zaloguj się
      </Link>
      <Modal open={open} onClose={handleClose} id="basic-info-modal">
        <Box>
          <Typography id="basic-info-modal-description" sx={{ mt: 2 }}>
            <IconButton sx={{ float: "right" }} onClick={handleClose}>
              <CloseIcon color="black" />
            </IconButton>
            <h4>
              <strong>
                Podstawowe informacje o zasadach przetwarzania danych osobowych
              </strong>
            </h4>
            <br />
            <p>
              Administratorem danych osobowych jest FlatHub Spółka z ograniczoną
              odpowiedzialnością z siedzibą w Krakowie pod adresem ul.
              Bronowicka 38/5; 30-091, Kraków, wpisaną do Rejestru
              Przedsiębiorców Krajowego Rejestru Sądowego pod numerem:
              0001009006, posiadająca numer NIP: 6772488192 (zwana dalej
              „Administratorem”). Wszelkie zapytania lub żądania mogą Państwo
              zgłosić pod adresem: kontakt@flathub.pl
              <br />
              Dane osobowe będą przetwarzane w celu świadczenia przez
              Administratora usług i realizacji umowy zawartej po zaakceptowaniu{" "}
              <Link to="/statute">Regulaminu świadczenia usług</Link>, a po
              wykupieniu przez Państwa abonamentu w serwisie internetowym -
              również do dokonywania rozliczeń, wystawiania faktur i
              dokumentowania transakcji. Administrator przetwarza dane osobowe
              podane przez Państwa podczas rejestracji w serwisie internetowym,
              dane osobowe przekazywane Administratorowi podczas korzystania z
              usług w ramach serwisu internetowego, jak również dane zebrane w
              ramach plików cookies, o ile konfiguracja Państwa urządzeń
              końcowych i ich oprogramowania na to pozwala. Dane mogą być
              poddawane profilowaniu, aby umożliwić lepsze dopasowanie
              przekazywanych treści do Państwa indywidualnych preferencji i
              zainteresowań, ale profilowanie dokonywane przez Administratora
              nie będzie prowadziło do automatycznego podejmowania decyzji w
              Państwa sprawie, ani nie będzie wywoływać skutków prawnych. Jeżeli
              wyrazili Państwo zgodę na otrzymywanie informacji handlowych drogą
              elektroniczną lub telefoniczną, Administrator może przetwarzać
              Państwa dane osobowe w celu wysyłania na wskazany przez Państwa
              adres e-mail informacji handlowych dotyczących swojej działalności
              i kontaktować się z Państwem w tym celu drogą telefoniczną.
              Ponadto, dane mogą być przetwarzane przez Administratora w celu
              ustalenia, dochodzenia lub obrony roszczeń. Państwa dane mogą być
              przekazywane do odbiorców danych, którzy świadczą usługi na rzecz
              Administratora, w tym usługi z zakresu usług księgowych,
              doradczych, prawnych oraz usługi z zakresu zapewniania
              infrastruktury informatycznej. Państwa dane mogą być przekazywane
              poza EOG, na zasadach określonych w Polityce Prywatności.
            </p>
            <p>
              Mają Państwo prawo dostępu do danych, a także do ich sprostowania,
              usunięcia, przeniesienia, ograniczenia przetwarzania, otrzymania
              kopii danych, wniesienia sprzeciwu wobec przetwarzania danych,
              prawo do przenoszenia danych oraz prawo do wniesienia skargi do
              Prezesa Urzędu Ochrony Danych Osobowych. W przypadku przetwarzania
              danych na podstawie zgody, mają Państwo prawo do cofnięcia zgody
              na przetwarzanie danych w dowolnym czasie, przy czym cofnięcie
              zgody nie będzie miało wpływu na zgodność z prawem przetwarzania
              odbywającego się do czasu cofnięcia zgody.
              <br />
              <strong>
                Kompletne informacje o zasadach przetwarzania danych osobowych
              </strong>
              , w tym m. in. o podstawach prawnych przetwarzania danych,
              pozostałych celach przetwarzania danych osobowych i
              przysługujących Państwu uprawnieniach, znajdą Państwo w{" "}
              <Link to="/statute">Polityce Prywatności</Link>.
            </p>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

function handleError(err, actions) {
  if (!err?.response) {
    actions.setErrors({
      email: "Upewnij się, że masz połączenie z internetem.",
    });
  } else if (err?.response?.status === 400) {
    let errMsg = "";
    if (err.response.data == "The two password fields didn't match.") {
      errMsg = "Hasła muszą być takie same";
    } else if (
      err.response.data ==
      "A user is already registered with this e-mail address."
    ) {
      errMsg = "Istnieje już konto zarejestrowane na podany email";
    } else if (err.response.data.non_field_errors?.length > 0) {
      errMsg = err.response.data.non_field_errors[0];
      errMsg = TextStrings[errMsg];
    } else if (
      err.response.data?.password1[0] == "This password is too common."
    ) {
      errMsg = "Hasło jest zbyt popularne";
    } else if (
      err.response?.data?.password1[0] == "This password is entirely numeric."
    ) {
      errMsg = "Hasło nie może zawierać samych cyfr";
    } else if (
      err.response?.data?.password1[0].find(
        "The password is too similar to the"
      ) != -1
    ) {
      errMsg = "Hasło jest zbyt podobne do emaila";
    }

    actions.setErrors({
      email: errMsg,
    });
  } else if (err?.response?.status === 401) {
    actions.setErrors({
      email: "Upewnij się, że wprowadziłeś poprawny adres email i hasło",
    });
  } else {
    actions.setErrors({ email: "Błąd, proszę spróbować później" });
  }
}
