import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import quoutesFile from "./quotes.json";
const GaiusLexLogo = require("~/public/static/img/gaius-lex-logo.svg");

export default function LinearDeterminate({
  maxTime,
  quotes,
}: {
  maxTime?: number;
  quotes?: boolean;
}) {
  const initQuouteIndex = Math.floor(
    Math.random() * (quoutesFile.quotes.length - 1 + 1)
  );
  const [progress, setProgress] = React.useState(0);
  const [quote, setQuote] = React.useState<{
    latin: string;
    translation: string;
  }>(quoutesFile.quotes[initQuouteIndex]);

  const useTimer = () => {
    let timer: string | number | NodeJS.Timer | undefined;

    if (!maxTime) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    }

    if (maxTime) {
      const startTime = Date.now();
      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const newProgress = (elapsedTime / 1000 / maxTime) * 100;
        setProgress(newProgress);
      }, 100);
    }

    return () => {
      clearInterval(timer as number);
    };
  };

  const useQuotes = () => {
    let timer: string | number | NodeJS.Timer | undefined;
    let quotesTime: string | number | NodeJS.Timer | undefined;
    const startTime = Date.now();

    timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const newProgress = (elapsedTime / 1000 / 45) * 100;
      setProgress(newProgress);
    }, 100);

    quotesTime = setInterval(() => {
      const index = Math.floor(
        Math.random() * (quoutesFile.quotes.length - 1 + 1)
      );
      setQuote(quoutesFile.quotes[index]);
    }, 7000);

    return () => {
      clearInterval(timer as number);
      clearInterval(quotesTime as number);
    };
  };

  React.useEffect(() => {
    if (quotes) useQuotes();
    else useTimer();
  }, []);

  const getLoader = () => {
    if (quotes) {
      return (
        <div style={{ display: "grid", justifyContent: "center" }}>
          <img
            src={GaiusLexLogo}
            alt="Gaius Lex Logo"
            id={"menu-logo"}
            style={{ justifySelf: "center" }}
          />
          <p
            style={{
              justifySelf: "center",
              color: "black",
              fontSize: 18,
            }}
          >
            {quote?.latin}
          </p>
          <p style={{ justifySelf: "center" }}>{quote?.translation}</p>
        </div>
      );
    } else {
      let timeLeft = maxTime?.toString()
        ? parseInt((maxTime - (progress / 100) * maxTime).toString()).toString()
        : "0";
      if (parseInt(timeLeft) <= 0) timeLeft = "Jeszcze chwila...";
      else timeLeft = `Pozostało: ~${timeLeft}s`;
      return <>{maxTime && <p>{timeLeft}</p>}</>;
    }
  };

  return (
    <Box sx={{ width: "100%", color: "#8D9DA8" }}>
      {getLoader()}
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
