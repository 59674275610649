class Colors {
  static FlatHubGreen = "#0d4946";
  static FlatHubGreenLight = "#cce3e1";
  static LightGreen = "#226c51";
  static LightBlue = "#2A7C96";
  static DarkBlue = "#19437d";
  static Orange = "#ff914d";
  static Red = "#aa3232";
  static Gray = "#ccd1d3";
  static DarkGray = "#757575";
  static Neutral = "#e9eef1";
  static GreenNeutral = "#9DBFB3";
  static PremiumBlack = "#262626";
  static White = "#ffffff";
  

  static getHue(color, lightness) {
    const hsl = hexToHSL(color);
    color = hslToRgb(hsl[0], hsl[1], lightness / 100);
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
}

export default Colors;

function hexToHSL(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h;
  let s;
  const l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [h, s, l];
}

function hslToRgb(h, s, l) {
  let r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}
