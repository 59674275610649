const TextStrings = {
  errors: {
    internet: "Brak połączenia z internetem",
    wrongCredentials: "Niepoprawne dane logowania",
    unknown: "Nieznany błąd",
    emailInvalid: "Niepoprawny adres email",
    emailRequired: "Adres email jest wymagany",
    passwordRequired: "Hasło jest wymagane",
    passwordTooShort: "Hasło musi mieć co najmniej 6 znaków",
  },
  socialLogin: {
    title: "Zaloguj się z użyciem emaila i hasła",
    description:
      "Login przy użyciu konta społecznościowego został zablokowany, ponieważ twoje konto posiada możliwość szyfrowania plików",
  },
  loginPageTitle: "Logowanie",
  meta: "Główna strona logowania",
  welcome: "Witaj w systemie Gaius-Lex",
  emailInput: "Adres email",
  passwordInput: "Hasło",
  login: "Zaloguj się",
  noAccount: "Nie masz jeszcze konta?",
  forgotPassword: "Nie pamiętasz hasła?",
};

export default TextStrings;