import * as React from "react";
import { useContext, useRef, useState } from "react";
import axios from "../api/axios";
import TextStrings from "../pages/register/Strings";
import FacebookLogin, { ReactFacebookLoginInfo } from "react-facebook-login";
import { SnackbarContext } from "../contexts/SnackbarContext";
import Button from "./generic/Button";

const handleSocialLogin = async (
  authData: ReactFacebookLoginInfo,
  setError: (arg: string) => void,
  referral: string
) => {
  const url = referral ? `/rest-auth/facebook/login/?referral=${referral}`: "/rest-auth/facebook/login/";

  try {
    const response = await axios.post(
      url,
      JSON.stringify({
        access_token: authData?.accessToken,
        id_token: authData?.accessToken,
        provider: "facebook",
      }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.key) {
      console.log("Facebook Auth: Login successful:", response.data);
      window.location.href = "/";
    } else {
      console.error("Facebook Auth: Login failed:", response.data);
      setError(TextStrings.FACEBOOK_AUTH_ERROR);
    }
  } catch (error) {
    console.error("Error during facebook login:", error);
    setError(TextStrings.FACEBOOK_AUTH_ERROR);
  }
};

const checkEmailExists = async (email: string, snackbar: any) => {
  try {
    const response = await axios.post(
      "core_api/user/exist",
      JSON.stringify({ "email": email }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.data.exists) {
      snackbar.setMessage("Nie istnieje konto z podanym adresem email połączone z kontem Facebook. Chcesz założyć konto? Zarejestruj się");
      snackbar.setSeverity("error");
      snackbar.setOpen(true);
      return false;
    }
    return true;
  } catch (error) {
    console.error("Error during email check:", error);
    snackbar.setMessage("Wystąpił błąd podczas próby sprawdzenia adresu email. Spróbuj ponownie później");
    snackbar.setSeverity("error");
    snackbar.setOpen(true);
    return false;
  }
};

const FacebookButton = ({ referral, email }: { referral: string, email: string }) => {
  const [error, setError] = useState("");
  const [canProceed, setCanProceed] = useState(false);
  const snackbar = useContext(SnackbarContext);
  const facebookLoginButtonRef = useRef<HTMLDivElement>(null);

  const handleFacebookLogin = async (authData: ReactFacebookLoginInfo) => {
    if (canProceed) {
      handleSocialLogin(authData, setError, referral);
    }
  };

  const initiateFacebookLogin = async () => {
    if (email) {
      const emailExists = await checkEmailExists(email, snackbar);
      if (emailExists) {
        setCanProceed(true);
        facebookLoginButtonRef.current?.querySelector('button')?.click();
      } else {
        setCanProceed(false);
      }
    }
  };

  return (
    <>
      <div
        className="cx-error-message-email"
        style={{ color: "red", display: error ? "block" : "none" }}
      >
        {error}
      </div>
      <div style={{ display: "inline-block", width: "50%" }}>
        <div ref={facebookLoginButtonRef} style={{display: "none"}}>
        <FacebookLogin
          appId="1962595430867221"
          autoLoad={false}
          fields="email"
          callback={handleFacebookLogin}
          scope="public_profile,email"
          cssClass="facebook-login-button"
          size="small"
          icon="fa-facebook"
          textButton={"Zaloguj się z Facebook"}
        />
        </div>
        <Button variant={"outlined-light"} className={"facebook-login-button"} width={"100%"} onClick={initiateFacebookLogin} >
          <i className="fa-brands fa-facebook" style={{ marginRight: 10 }} />
          Zaloguj się z Facebook
        </Button>
      </div>
    </>
  );
};

export default FacebookButton;
