import { useParams, useNavigate } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";

import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "../../../../api/axios";
import Button from "../../../../components/generic/Button";
import Background from "../../../../components/generic/Background";
import { SnackbarContext } from "../../../../contexts/SnackbarContext";
import { Colors } from "../../../../components/generic/Colors";
import CircularProgress from "@mui/material/CircularProgress";
import SecretAnswersForm, { SecretAnswersFormFields } from "../../../../components/secretAnswersForm";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import getCSRFToken from "../../../../stores/CSRFStore";
import Loading from "../../../../components/loading";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

const ErrorStyle = {
  color: "red",
  backgroundColor: Colors.LightRed,
  height: 50,
  display: "inline-block",
  margin: "5px 0",
  padding: "10px",
  borderRadius: "0.75rem",
  width: "100%",
};

const ResetPasswordStore = async (
  uid: String,
  token: String,
  new_password1: String,
  new_password2: String,
  old_secret_answers: String[],
  new_secret_answers: String[]
) => {
  return axios
    .post(
      "rest-auth/password/reset/confirm/",
      JSON.stringify({
        uid,
        token,
        new_password1,
        new_password2,
        new_secret_answers: new_secret_answers,
        old_secret_answers: old_secret_answers,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log("Error during sending request", error);
    });
};

const ResetPasswordForm = ({ secretAnswers }: { secretAnswers: string[] }) => {
  const params = useParams();
  const navigate = useNavigate();
  const snackbarContext = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showSecretAnswers, setShowSecretAnswers] = React.useState<boolean>(false);

  const uid: string = params.id !== undefined ? params.id : "";
  const token: string = params.token !== undefined ? params.token : "";

  const handleSubmit = async (
    values: {
      new_password1: string;
      new_password2: string;
      secretAnswer1: string;
      secretAnswer2: string;
      secretAnswer3: string;
    },
    _actions: {}
  ) => {
    const {
      new_password1,
      new_password2,
      secretAnswer1,
      secretAnswer2,
      secretAnswer3,
    } = values;
    setLoading(true);
    if (
      (await ResetPasswordStore(
        uid!,
        token!,
        new_password1,
        new_password2,
        secretAnswers,
        [secretAnswer1, secretAnswer2, secretAnswer3]
      )) === true
    ) {
      snackbarContext.setSeverity("success");
      snackbarContext.setDuration(15000);
      snackbarContext.setMessage(
        "Zmiana hasła przebiegła pomyślnie!\nMożesz zalogować się za pomocą nowego hasła."
      );
      snackbarContext.setOpen(true);
      setLoading(false);
      navigate("/login");
    } else {
      snackbarContext.setSeverity("error");
      snackbarContext.setMessage(
        "Błąd zmiany hasła, skontaktuj się z administratorem lub spróbuj później."
      );
      snackbarContext.setOpen(true);
      setLoading(false);
    }
  };

  return (
    <Background>
      <MDBRow className="flex-grow-1 w-100 m-0">
        <MDBCol className="d-flex flex-column px-0">
          <MDBCard
            className="mx-auto rounded-6"
            style={{
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <MDBCardBody>
              <Formik
                initialValues={{
                  new_password1: "",
                  new_password2: "",
                  secretAnswer1: "",
                  secretAnswer2: "",
                  secretAnswer3: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  new_password1: Yup.string()
                    .required("Hasło jest wymagane")
                    .min(8, "Hasło musi mieć minimum 8 znaków"),
                  new_password2: Yup.string()
                    .required("Hasło jest wymagane")
                    .min(8, "Hasło musi mieć minimum 8 znaków")
                    .oneOf(
                      [Yup.ref("new_password1"), ""],
                      "Hasła muszą być takie same"
                    ),
                  secretAnswer1: Yup.lazy((_value: any) => {
                    if (showSecretAnswers) return Yup.string().required(
                      "Odpowiedź jest wymagana"
                    )
                    else return Yup.string()
                  }),
                  secretAnswer2: Yup.lazy((_value: any) => {
                    if (showSecretAnswers) return Yup.string().required(
                      "Odpowiedź jest wymagana"
                    )
                    else return Yup.string()
                  }),
                  secretAnswer3: Yup.lazy((_value: any) => {
                    if (showSecretAnswers) return Yup.string().required(
                      "Odpowiedź jest wymagana"
                    )
                    else return Yup.string()
                  }),
                })}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div>
                        <MDBInput
                          className="mt-2"
                          id="new-password-input1"
                          name="new_password1"
                          label="Nowe hasło"
                          type="password"
                          value={formik.values.new_password1}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          name="new_password1"
                          component={(props: any) => (
                            <div style={ErrorStyle}>
                              <div style={{ float: "left" }}>
                                {props?.children}
                              </div>
                              <div style={{ float: "right" }}>X</div>
                            </div>
                          )}
                        />
                      </div>
                      <div>
                        <MDBInput
                          className="mt-2"
                          id="new-password-input2"
                          name="new_password2"
                          label="Powtórz nowe hasło"
                          type="password"
                          value={formik.values.new_password2}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          name="new_password2"
                          component={(props: any) => (
                            <div style={ErrorStyle}>
                              <div style={{ float: "left" }}>
                                {props?.children}
                              </div>
                              <div style={{ float: "right" }}>X</div>
                            </div>
                          )}
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showSecretAnswers}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setShowSecretAnswers(event.target.checked)}
                            name="showSecretAnswers"
                          />
                        }
                        label="Ustaw nowe odpowiedzi"
                      />
                      {showSecretAnswers && (
                        <>
                          <br />
                          <Divider sx={{ borderBottom: "1px solid black" }} />
                          <div style={{ padding: "10px 0", color: "#8d9da8" }}>
                            Podaj nowe odpowiedzi, jeśli równieź chcesz je
                            ustawić ponownie lub ustawiasz je po raz pierwszy.
                          </div>
                          <div style={{ padding: "10px 0", color: "red" }}>
                            Jeśli poprzednio logowałeś się kontem google lub
                            facebook ustawienie pomocniczych pytań umożliwy
                            szyfrowanie plików, jednak uniemożliwi logowanie za
                            pomocą google lub facebook
                          </div>
                          <SecretAnswersFormFields formik={formik} />
                          <br />
                        </>
                      )}
                      <p style={{ padding: "10px 0", color: "#8d9da8" }} >
                        Zapamiętaj odpowiedzi na podane pytania. W przypadku
                        zapomnienia hasła odpowiedzi będą niezbędne przy
                        resetowaniu hasła
                      </p>
                      <Button
                        id="reset-password-button"
                        variant="contained-dark"
                        type="submit"
                        width={"100%"}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress /> : "Resetuj hasło"}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Background>
  );
};

const SecretAnswers = ({
  uid,
  setSecretAnswers,
}: {
  uid: string;
  setSecretAnswers: (val: string[] | undefined) => void;
}) => {
  const axiosPrivate = useAxiosPrivate();
  const snackbar = React.useContext(SnackbarContext);

  const onSubmit = async (values: {
    secretAnswer1: string;
    secretAnswer2: string;
    secretAnswer3: string;
  }) => {
    await getCSRFToken();

    axiosPrivate
      .post(
        `${process.env.BACKEND_ENDPOINT}/api/v1/validate-secret-answers`,
        JSON.stringify({
          secretAnswer1: values.secretAnswer1,
          secretAnswer2: values.secretAnswer2,
          secretAnswer3: values.secretAnswer3,
          uid: uid,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setSecretAnswers([
          values.secretAnswer1,
          values.secretAnswer2,
          values.secretAnswer3,
        ]);
        return response.data;
      })
      .catch((error) => {
        if (error.response.status == 400) {
          (snackbar as any).setMessage("Odpowiedzi nie są poprawne");
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        } else {
          (snackbar as any).setMessage(
            "Wystąpił błąd podczas wysyłania odpowiedzi, skontaktuj się z nami lub spróbuj ponownie później"
          );
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        }
        console.log(error);
      });
  };

  return (
    <Background>
      <MDBRow
        style={{
          justifyContent: "center",
          marginBottom: "50px",
          height: "70vh",
        }}
      >
        <div id="secret-answers-container">
        <h3>Zresetuj hasło</h3>
          <p>
            Wybierz pytania oraz udziel dokładnie tych samych odpowiedzi podanych wcześniej, po rejestracji.
          </p>
          <SecretAnswersForm onSubmit={onSubmit} withFooter={false} />
        </div>
      </MDBRow>
    </Background>
  );
};

const ResetPassword = () => {
  const params = useParams();
  const uid: string = params.id !== undefined ? params.id : "";
  const axiosPrivate = useAxiosPrivate();
  const snackbar = React.useContext(SnackbarContext);
  const [secretAnswers, setSecretAnswers] = React.useState<
    string[] | undefined
  >();
  const [hasEncryptionKeys, setHasEncryptionKeys] = React.useState<
    boolean | undefined
  >();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    await getCSRFToken();

    axiosPrivate
      .get(
        `${process.env.BACKEND_ENDPOINT}/api/v1/validate-secret-answers?uid=${uid}`
      )
      .then((response) => {
        setHasEncryptionKeys(response.data.has_encryption_keys);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status == 400) {
          (snackbar as any).setMessage(error.response.data.error);
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        } else {
          (snackbar as any).setMessage(
            "Wystąpił błąd podczas reswtu hasła, skontaktuj się z nami lub spróbuj ponownie później"
          );
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        }
        console.log(error);
      });
  };

  if (loading) return <Loading />;
  else
    return (
      <>
        {hasEncryptionKeys && !secretAnswers ? (
          <SecretAnswers uid={uid} setSecretAnswers={setSecretAnswers} />
        ) : (
          <ResetPasswordForm secretAnswers={secretAnswers ?? []} />
        )}
      </>
    );
};

export default ResetPassword;
