import React from "react";
import { Button, Box, Typography, Modal } from "@mui/material";
import Colors from "./colors";
import useUserData from "../hooks/useUserData";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import getCSRFToken from "../stores/CSRFStore";

const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BUTTON_STYLE = {
  height: "30px",
  backgroundColor: Colors.DarkBlue,
  fontFamily: "sofia-pro, sans-serif",
  fontWeight: "semibold",
  fontSize: "1rem",
  color: "white",
  ":hover": {
    backgroundColor: Colors.LightBlue,
    textDecoration: "none",
  },
};

const PrivacyConsentModal = () => {
  const axios = useAxiosPrivate();
  const { userData, setUserData } = useUserData();
  const privacyConsentDate: Date = new Date(userData.privacyConsent as string);
  const privacyUpdateDate: Date = new Date(process.env.PRIVACY_UPDATE as string);
  const [modalOpen, setModalOpen] = React.useState(privacyConsentDate < privacyUpdateDate);

  const setPrivacyConsent = async () => {
    try {
      await getCSRFToken();
      await axios
        .post(
          `${process.env.BACKEND_ENDPOINT}/core_api/privacy-consent`,
          JSON.stringify({ privacy_consent: true })
        )
        .then((response: { status: number; data: { error: string, privacy_consent: string } }) => {
          if (response.status == 200) {
            setUserData({
              ...userData,
              privacyConsent: response.data.privacy_consent,
            });
            setModalOpen(false);
          } else {
            console.error(response.data.error);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const PrivacyConsent = () => (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        color={Colors.DarkBlue}
      >
        Polityka Prywatności
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }} color={"black"}>
        Potrzebujemy twojej zgody na przetwarzanie niezbędnych danych.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }} color={"black"}>
        Oświadczam, że znam i akceptuję postanowienia{" "}<Link to="/privacy-policy">Polityki prywatności</Link> Gaius Lex
      </Typography>
      <Typography margin={2}>
        <Button
          sx={{ ...BUTTON_STYLE, float: "right" }}
          onClick={() => {
            setPrivacyConsent();
          }}
        >
          Akceptuję
        </Button>
      </Typography>
    </>
  );

  return (
    <>
      <Modal open={modalOpen}>
        <Box sx={MODAL_STYLE}>
          <PrivacyConsent />
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyConsentModal;
