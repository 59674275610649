import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useContext, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { InteractiveSearchContext } from "./interactiveSearch";

type TRow = { query: string; added: string; key: string };

const columns = [
  { field: "query", headerName: "Pytanie", align: "left", flex: 1 },
  { field: "added", headerName: "Data zapytania", width: 170 },
  {
    field: "link",
    headerName: "",
    sortable: false,
    renderCell: (params: { row: TRow }) => {
      return (
        <OpenInNewIcon
          className={"openInNewIcon"}
          onClick={() => {
            window.open(`/interactive-search?key=${params.row.key}`, "_blank");
          }}
        />
      );
    },
  },
];

export default function InteractiveSearchHistory() {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const interactiveSearchState = useContext(InteractiveSearchContext);

  React.useEffect(() => {
    getHistoryResult();
  }, [interactiveSearchState]);

  const getHistoryResult = () => {
    setLoading(true);
    axios
      .get("/api/v1/interactive-search-history?key=ALL")
      .then((response) => {
        const resp = response?.data?.data;

        if (resp) {
          const data = resp.map((row: TRow, index: number) => ({
            id: index,
            query: row.query,
            added: new Date(row.added).toLocaleString(),
            key: row.key,
          }));

          setHistory(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <DataGrid
      loading={loading}
      columns={columns as GridColDef[]}
      localeText={{ noRowsLabel: "Brak historii zapytań" }}
      rows={history}
      pageSizeOptions={[5, 10, 15, 30]}
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
    />
  );
}
