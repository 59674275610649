import { useState } from 'react';
import * as React from 'react';
import { Box, Typography, CircularProgress, Button, Divider, Stack, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

interface DocAbusivityProps {
  filePath: string;
}

const DocAbusivity = ({ filePath }: DocAbusivityProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{ text: string,  explanation: string }[] | undefined>(undefined);
  const axios = useAxiosPrivate();

  const handleClick = async () => {
    setLoading(true);
    axios.post(`api/v1/doc-abusivity`,
      JSON.stringify({
        path: filePath
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setResult(response.data.abusivity);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
      <Typography variant="h6">Analiza klauzul abuzywnych</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={"space-between"}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', textAlign: 'center' }}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>Ścieżka:</Typography>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', height: '100%', fontStyle: "italic" }}>{filePath}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Button variant="contained" onClick={handleClick}>Analizuj</Button>
        </Box>
      </Stack>
      <Divider />
      {result && (
        result.map(({ text, explanation }) => (
          <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', whiteSpace: 'pre-wrap' }}>
            <Typography variant="body1" sx={{ fontStyle: "italic", paddingLeft: "32px", paddingBottom: "16px" }}>(...){text}(...)</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }} >{explanation}</Typography>
          </Box>
        ))
      )}
      {result && result.length === 0 && (
        <Typography variant="body1" sx={{ fontStyle: "italic" }}>Nie znaleziono klauzul abuzywnych</Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center' }}>
        {loading && <CircularProgress size={24} sx={{ margin: '0 auto' }} />}
      </Box>
    </Box>
  );
};

const TextAbusivity = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState<{ text: string,  explanation: string }[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  function handleClick() {
    setLoading(true);
    axios.post(`api/v1/text-abusivity`,
      JSON.stringify({
        text: text
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        setResult(response.data.abusivity);
        setLoading(false);
      });
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
      <Typography variant="h6">Analiza klauzul abuzywnych</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={"space-between"}>
        <TextField
          id="outlined-multiline-static"
          label="Wprowadź tekst"
          multiline
          rows={8}
          value={text}
          onChange={(event) => { setText(event.target.value); }}
          sx={{
            width: '100%'
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', height: 'fit-content', alignSelf: "end" }}>
          <Button variant="contained" onClick={handleClick}>Analizuj</Button>
        </Box>
      </Stack>
      <Divider />
      {result && (
        result.map(({ text, explanation }) => (
          <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', whiteSpace: 'pre-wrap' }}>
            <Typography variant="body1" sx={{ fontStyle: "italic", paddingLeft: "32px", paddingBottom: "16px" }}>(...){text}(...)</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }} >{explanation}</Typography>
          </Box>
        ))
      )}
      {result && result.length === 0 && (
        <Typography variant="body1" sx={{ fontStyle: "italic" }}>Nie znaleziono klauzul abuzywnych</Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'center' }}>
        {loading && <CircularProgress size={24} sx={{ margin: '0 auto' }} />}
      </Box>

    </Box>
  );
}

const AnalyzeDocument = () => {
  const [searchParams, _] = useSearchParams();
  const filePath = searchParams.get('filePath') || '';

  if (filePath === '') {
    return <TextAbusivity />
  }

  return (
    <DocAbusivity filePath={filePath} />
  );
};

export default AnalyzeDocument;