import * as React from "react";
import { Button as MuiButton } from "@mui/material";
import { Colors } from "legalgpt-react-shared";

interface IButton {
  id?: string;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "contained-dark"|"outlined-light"|"text";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: string | JSX.Element | (string|JSX.Element)[];
  disabled?: boolean;
  width?: number | string; 
  className?: string;
  target?: string;
  href?: string;
  rel?: string;
  component?: React.ElementType;
  color?: string;
  sx?: object;
}

export const ButtonSX = {
  height: "50px",
  fontFamily: "sofia-pro, sans-serif",
  fontSize: "1rem",
  color: Colors.Black,
  borderColor: Colors.Black,
  textTransform: "none",
  ":hover": {
    color: Colors.Black,
    backgroundColor: Colors.Peach,
    borderColor: Colors.Peach,
    textDecoration: "none",
  },
}

const DarkButtonSX = {
  backgroundColor: Colors.NavyBlue,
  borderColor: Colors.NavyBlue,
  color: Colors.White,
  ":hover": {
    backgroundColor: Colors.Peach,
    borderColor: Colors.Peach,
    textDecoration: "none",
  },
};

const Button = ({ id, type, variant, onClick, children, disabled, width, className, target, href, rel, component, color, sx }: IButton) => {
  let muiVariant: 'text' | 'outlined' | 'contained' = "outlined";
  let muiSX = {}
  let url = {
    target,
    href,
    component
  }

  if (variant === "contained-dark") {
    muiVariant = "contained";
    muiSX = {...ButtonSX, ...DarkButtonSX, width: width || "unset"};
  }
  if (variant === "outlined-light") {
    muiVariant = "outlined";
    muiSX = {...ButtonSX, width: width || "unset"};
  }
  if (variant === "text") {
    muiVariant = "text";
    muiSX = {...ButtonSX, width: width || "unset"};
  }

  if (color) {
    muiSX = {...muiSX, backgroundColor: color};
  }

  return (
    <MuiButton
      id={id}
      type={type}
      onClick={onClick}
      className={`rounded-6 ${className || ""}`}
      variant={muiVariant}
      disabled={disabled}
      sx={{...muiSX, ...sx}}
      rel={rel}
      {...url}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
