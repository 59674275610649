import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Colors } from "./Colors";

const BackgroundImageLeft = require("~/public/static/img/background-left.png");
const BackgroundImageRight = require("~/public/static/img/background-right.png");
const GaiusLexVertical = require("~/public/static/img/gaius-lex-vertical.png");

const Background = ({
  children,
}: {
  children: string | JSX.Element | (string | JSX.Element)[],
}) => {
  return (
    <div style={{ backgroundColor: Colors.LightBlue, minHeight: "100vh", display: "contents" }}>
      <MDBRow
        id={"img-row"}
        className="flex-grow-1 w-100 m-0"
      >
        <MDBCol
          className="d-flex col-xl-3 col-lg-3 mx-0 px-0"
          style={{ position: "fixed", left: 0, top: 0 }}
        >
          <div className="my-auto">
            <img
              src={BackgroundImageLeft}
              alt="Background Left"
              style={{ width: "100%" }}
            />
          </div>
        </MDBCol>
        <MDBCol
          className="d-flex col-xl-4 col-lg-4 mx-0 px-0"
          style={{ position: "fixed", right: 0, bottom: 0 }}
        >
          <div className="my-auto">
            <img
              src={BackgroundImageRight}
              alt="Background Right"
              style={{ width: "100%" }}
            />
          </div>
        </MDBCol>
        <MDBCol className="d-flex col-xl-2 col-lg-2 mx-0 px-0 my-auto mx-auto">
          <img
            src={GaiusLexVertical}
            alt="Gaius Lex Logo"
            style={{ width: "100%" }}
          />
        </MDBCol>
      </MDBRow>
      {children}
    </div>
  );
};

export default Background;
