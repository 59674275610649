import useWebSocket, { ReadyState } from "react-use-websocket"

const useGaiusWebSocket = (path) => {
    const url = process.env.BACKEND_ENDPOINT.replace("https://", "wss://").replace("http://", "ws://") + path;
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(url, {
        share: false,
        shouldReconnect: () => true,
      });
    return { sendMessage, getWebSocket, lastMessage, readyState };
}

export default useGaiusWebSocket;