import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../api/axios";
// import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useUserData from "../hooks/useUserData";
import ActivateTrial from "../pages/activateTrial";
import PrivacyConsentModal from "./PrivacyConsentModal";
import SecretAnswers from "../pages/secretAnswers";
import Loading from "./loading";
import useLogout from "../hooks/useLogout";
import StatueConsentModal from "./StatueConsentModal";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { userData, setUserData } = useUserData();
  const logout = useLogout();
  const navigate = useNavigate();
  
  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await axios
          .get("/core_api/user-data", { withCredentials: true })
          .then((response) => {
            if (response.status == 200) {
              setUserData({
                ...response.data,
                phone: response.data.phone_number,
                isVerified: response.data.verified,
                isLoggedIn: true,
                privacyConsent: response.data.privacy_consent,
                statueConsent: response.data.statue_consent,
                isSocialLogin: response.data.is_social_login,
                hasSecretAnswers: response.data.has_encryption_keys,
                encryptionDisabled: response.data.encryption_disabled,
                subscriptionStatus: {
                  enabled: response.data.user_plan.name == "subscription",
                  isTrial: response.data.user_plan.trial,
                  tillTrialEnds: response.data.user_plan.till_trial_ends,
                  // endDate: response.data.user_plan.subscription_renewal_date,
                  // isCanceled: response.data.user_plan.subscription_cancelled,
                },
                dataUsageNotice: response.data.data_usage_notice,
                featureFlags: response.data.feature_flags,
                defaultAnonymization: response.data.default_anonymization,
                disableAnonymization: response.data.disable_anonymization,
              });
            }
          });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    !userData?.isLoggedIn ? verifyRefreshToken() : setIsLoading(false);
  }, []);

  const getView = (
    isLoggedIn: boolean,
    isVerified: boolean,
    hasSecretAnswers: boolean,
    encryptionDisabled: boolean,
    isSocialLogin: boolean
  ) => {
    let render = <Outlet />;
    if (isLoggedIn && !isVerified) render = <ActivateTrial />;
    if (isLoggedIn && isSocialLogin && hasSecretAnswers) {
      logout();
      navigate("/login?socialLogin=true");
    }
    if (
      isLoggedIn &&
      !isSocialLogin &&
      !hasSecretAnswers &&
      !encryptionDisabled
    )
      render = <SecretAnswers />;

    return (
      <>
        <PrivacyConsentModal />
        <StatueConsentModal />
        {render}
      </>
    );
  };

  let retVal = isLoading ? (
    <Loading />
  ) : (
    getView(
      userData?.isLoggedIn,
      userData?.isVerified,
      userData?.hasSecretAnswers,
      userData?.encryptionDisabled,
      userData?.isSocialLogin
    )
  );

  return retVal;
};

export default PersistLogin;
