import { LinearProgress } from "@mui/material";
import "./index.scss";
import React from "react";

const GaiusLexVertical = require("~/public/static/img/gaius-lex-vertical.png");

const Loading = () => {
  return (
    <div id="loading-component">
      <div id="loading-bar">
        <img
          src={GaiusLexVertical}
          alt="Gaius Lex Logo"
          style={{ width: "20%", marginBottom: 20 }}
        />
        <LinearProgress />
        Ładowanie strony ...
      </div>
    </div>
  );
};

export default Loading;
