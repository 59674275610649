import React, { useEffect } from "react";
// @ts-ignore
import Cookies from "js-cookie";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import WindowIcon from "@mui/icons-material/Window";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchEngineIcon from '@mui/icons-material/ManageSearch';
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PaymentIcon from "@mui/icons-material/Payment";
import Chat from "@mui/icons-material/Chat";
import { useNavigate } from "react-router";
import useUserData from "../../hooks/useUserData";
import { AdfScanner, QuestionAnswer } from "@mui/icons-material";
import "./index.scss";

const GaiusLexHorizontal = require("~/public/static/img/gaius-lex-horizontal.svg");
const GaiusLexLogo = require("~/public/static/img/gaius-lex-logo.svg");

interface IMenuListItem {
  text: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  selected?: boolean;
  onClick?: () => void;
  badge?: string;
}

const MenuListItem = ({
  text,
  disabled,
  icon,
  selected,
  onClick,
  badge,
}: IMenuListItem) => {
  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton selected={selected} disabled={disabled}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} style={{marginLeft: "-18px"}} />
        {badge && <div className="gaius-badge">{badge}</div>}
      </ListItemButton>
    </ListItem>
  );
};

const Menu = ({
  setDrawerWidth,
}: {
  setDrawerWidth: (arg: number) => void;
}) => {
  const pages = [
    "/",
    "/interactive-search",
    "/chat",
    "/history",
    "/browse",
    "/case-law-analysis",
    "/contract-analysis",
    "/payments",
    "/feedback",
    "/knowledge-base",
    "/case-law-search",
  ];
  const menuType = Cookies.get("folded");
  const navigate = useNavigate();
  const { userData } = useUserData();
  const isSubscribed = userData?.subscriptionStatus?.enabled;
  const isTrial = userData?.subscriptionStatus?.isTrial;
  const [selected, setSelected] = React.useState<number | undefined>(isSubscribed ? 0 : 8);
  const [menuClassName, setMenuClassName] = React.useState(
    menuType === "true" ? "folded-menu" : "unfolded-menu"
  );

  useEffect(() => {
    const url = pages.includes(window.location.pathname)
      ? window.location.pathname
      : undefined;
    if (url) {
      const page = pages.findIndex((x) => x === url);
      if(page) {
        setSelected(page);
      } else {
        setSelected(undefined);
      }
    }
    else {
      setSelected(undefined);
    }
  }, []);

  return (
    <div id="menu" className={`${menuClassName}`}>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 1,
          background: "white",
          padding: "15px 0",
        }}
      >
        <img
          src={
            menuClassName === "folded-menu" ? GaiusLexLogo : GaiusLexHorizontal
          }
          alt="Gaius Lex Logo"
          id={"menu-logo"}
        />
      </div>
      <List>
        <div style={{ padding: 20 }}>
          <div className="menu-section-header">GŁÓWNE</div>
          <MenuListItem
            onClick={() => {
              setSelected(0);
              navigate("/");
            }}
            text={"Home"}
            icon={<WindowIcon />}
            selected={selected === 0}
          />
          <div className="menu-section-header">MODUŁY</div>
          <MenuListItem
            onClick={() => {
              setSelected(1);
              navigate(isSubscribed ? "/interactive-search" : "/payments");
            }}
            text={"Wyszukiwanie interaktywne"}
            badge={"Nowość"}
            disabled={!isSubscribed}
            icon={<SearchEngineIcon />}
            selected={selected === 1}
          />
          <MenuListItem
            onClick={() => {
              setSelected(2);
              navigate(isSubscribed ? "/chat" : "/payments");
            }}
            text={"Porozmawiaj z Gaiusem"}
            disabled={!isSubscribed}
            icon={<Chat />}
            selected={selected === 2}
          />
          <MenuListItem
            onClick={() => {
              setSelected(3);
              navigate(isSubscribed && !isTrial ? "/history" : "/payments");
            }}
            text={"Historia zapytań"}
            disabled={!isSubscribed || isTrial}
            icon={<HelpOutlineIcon />}
            selected={selected === 3}
          />
          <div className="menu-section-header">TWOJA KANCELARIA</div>
          <MenuListItem
            onClick={() => {
              setSelected(4);
              navigate(isSubscribed ? "/browse" : "/payments");
            }}
            text={"Własna baza"}
            disabled={!isSubscribed}
            icon={<FolderCopyIcon />}
            selected={selected === 4}
          />
          <MenuListItem
            onClick={() => {
              setSelected(5);
              navigate(isSubscribed ? "/case-law-analysis" : "/payments");
            }}
            text={"Przeanalizuj pismo"}
            disabled={!isSubscribed}
            icon={<MonitorHeartIcon />}
            selected={selected === 5}
          />
          <MenuListItem
            onClick={() => {
              setSelected(6);
              navigate(isSubscribed ? "/contract-analysis" : "/payments");
            }}
            text={"Przeanalizuj umowę"}
            disabled={!isSubscribed}
            icon={<AdfScanner />}
            selected={selected === 6}
          />
          <hr />
          <MenuListItem
            onClick={() => {
              setSelected(7);
              navigate("/payments");
            }}
            text={"Płatności"}
            icon={<PaymentIcon />}
            selected={selected === 7}
          />
          <MenuListItem
            onClick={() => {
              setSelected(8);
              navigate("/feedback");
            }}
            text={"Zgłoś uwagę"}
            icon={<QuestionAnswer />}
            selected={selected === 8}
          />
          <MenuListItem
            onClick={() => {
              setSelected(9);
              navigate("/knowledge-base");
            }}
            text={"Baza wiedzy"}
            icon={<SchoolIcon />}
            selected={selected === 9}
          />
        </div>
      </List>
      <div
        className="menu-button"
        onClick={() => {
          Cookies.set("folded", menuClassName === "unfolded-menu");
          setDrawerWidth(menuClassName === "unfolded-menu" ? 150 : 300);
          setMenuClassName(
            menuClassName === "unfolded-menu" ? "folded-menu" : "unfolded-menu"
          );
        }}
      />
    </div>
  );
};

export default Menu;
