import React, { useState } from "react";
import { Form, Formik, ErrorMessage, yupToFormErrors } from "formik";
import { MDBInput, MDBRow } from "mdb-react-ui-kit";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "../../components/generic/Button";
import { default as MuiButton } from "@mui/material/Button";
import { Autocomplete, Box, CardActionArea, Checkbox, Grid, Radio, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import LinearBuffer from "../../components/LinearBuffer";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Divider from '@mui/material/Divider';
import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import { useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { FollowUpDialog } from "./FollowUpDialog";
import { ShareResultsButton } from "./ShareResultsButton";
import { ReviewAnswerButton } from "./ReviewAnswerButton";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import Colors from "../../components/colors";

const SourcesOptions = [{
  value: "judiciary",
  label: "Orzecznictwo"
},
{
  value: "Kodeksy",
  label: "Kodeksy"
}];

const CaseLawSearch = ({ cachedMetareview, cachedResults, cachedQuery }:
  { cachedMetareview?: string, cachedResults?: any, cachedQuery?: string }
) => {
  //I need formik form with two textboxes and one submit button
  //I need to send data from form to backend
  //I need to get data from backend and display it
  const [backendResponse, setBackendResponse] = useState<any>(null);
  const [metareview, setMetareview] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any>(null);
  const [answerKey, setAnswerKey] = useState<string>("");
  const [disambiguations, setDisambiguations] = useState<Array<string>>([]);

  const snackBar = React.useContext(SnackbarContext);
  const [params, setParams] = useSearchParams();
  const query = params.get("query") || cachedQuery || "";

  const { sendMessage, lastMessage, readyState } = useGaiusWebSocket("/ws/v1/query-judiciary");
  const axios = useAxiosPrivate();

  const [message, setMessage] = useState<string>("");

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "searching") {
        setMessage("Wyszukiwanie...");
      }
      if (data.message === "transforming_question") {
        setMessage("Analiza pytania...");
      }
      if (data.message === "finding_context") {
        setMessage("Wyszukiwanie kontekstu...");
      }
      else if (data.message === "filtering_docs") {
        setMessage("Filtrowanie dokumentów...");
      }
      else if (data.message === "summarizing") {
        setMessage("Streszczanie dokumentów...");
        setBackendResponse(data.documents);
      }
      else if (data.message === "metareviewing") {
        setMessage("Odpowiadanie na pytanie...");
        setBackendResponse(data.documents);
      }
      else if (data.message === "generating") {
        setMessage("");
        let metareview = data.metareview;
        metareview = formatHTML(metareview);
        setMetareview(metareview);
      }
      else if (data.message === "done") {
        let metareview = data.metareview;
        metareview = formatHTML(metareview);

        setMessage("");
        setBackendResponse(data.documents);
        setMetareview(metareview);
        setIsLoading(false);
        setAnswerKey(data.key);
      }
      else if (data.message === "suggesting_questions") {
        setSuggestions(data.suggestions);
      }
      else if (data.message === "error") {
        snackBar.setMessage("Wystąpił błąd, skontaktuj się z pomocą techniczną");
        snackBar.setSeverity("error");
        snackBar.setOpen(true);
        setIsLoading(false);
      }
    }
  }, [lastMessage]);

  async function disambiguateQuestion(question: string) {
    await getCSRFToken();
    await axios.post("api/v1/disambiguate-question", { factual_state: question })
      .then((response) => {
        if (response.data.question) {
          setDisambiguations(response.data.question);
        }
      })
      .catch((error) => {
        snackBar.setMessage("Wystąpił błąd, skontaktuj się z pomocą techniczną");
        snackBar.setSeverity("error");
        snackBar.setOpen(true);
      })
  }

  const handleSubmit = async (values: any) => {
    console.log("Sending message");
    setIsLoading(true);
    setBackendResponse(null);
    setMetareview(null);
    setSuggestions(null);
    setAnswerKey("");
    setMessage("Analiza pytania...")
    await disambiguateQuestion(values.factualState);
    setIsLoading(false);
    setMessage("")
  }

  const handleFinalSubmit = (values: any) => {
    // We pre-initialize this with original formik values,
    // but the question is overwritten with the disambiguated one
    const inner = async (question: string) => {
      console.log("Sending message");
      values.factualState = question;

      setIsLoading(true);
      sendMessage(JSON.stringify(values));
      // @ts-ignore
      setParams({ sources: values.sources, query: values.factualState, ...params });
      setBackendResponse(null);
      setMetareview(null);
      setSuggestions(null);
      setAnswerKey("");
    };
    return inner;
  }

  React.useEffect(() => {
    if (cachedMetareview && cachedResults && cachedQuery) {
      setBackendResponse(cachedResults);
      setMetareview(cachedMetareview);
      setMessage("");
    }
  }, [cachedMetareview, cachedResults, cachedQuery])

  return (
    <div id={"case-law-search"} >
      <Helmet>
        <title>Znajdź odpowiedź | Gaius Lex - asystent prawnika</title>
        <meta name="description" content="Gaius Lex - asystent prawnika. Znajdź dokumenty powiązane z Twoim pytaniem." />
      </Helmet>
      <div id={"cls-form"} >
        <h1>
          Znajdź orzeczenia sądowe powiązane z Twoim pytaniem
        </h1>
        <Typography variant="h5" color="text.secondary" className="pb-2" sx={{ fontWeight: "regular", fontSize: "1rem" }}>
          Wpisz tutaj swoje pytanie albo opis sytuacji. Gaius Lex znajdzie dla Ciebie adekwatne dokumenty i podsumuje na ich podstawie linie orzecznicze.
        </Typography>
        <MDBRow className="pt-4">
          <Formik
            initialValues={{
              factualState: query,
              question: 'Jakie argumenty były najważniejsze dla sędziego?',
              courtCode: "ALL",
              judge: "",
              sources: [],
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              factualState: Yup.string().required("Pole wymagane"),
            })
            }
          >
            {(formik) => {
              return (
                <>
                  <MDBRow style={{ width: "100%" }}>
                    <FollowUpDialog
                      question={formik.values.factualState}
                      initialMessage={metareview}
                      open={dialogOpen}
                      handleClose={() => setDialogOpen(false)}
                      lastMessage={lastMessage}
                      sendMessage={sendMessage}
                    />
                    <Form style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                      <TextField
                        label="Twoje pytanie"
                        id="factualState"
                        name="factualState"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.factualState}
                        className="w-100"
                        placeholder="po kim dziedziczy dziecko które zostało przysposobione?"
                        autoComplete="off"
                      />
                      <ErrorMessage name="factualState" component="div" />
                      <Autocomplete
                        id="sources"
                        options={[{
                          value: "judiciary",
                          label: "Orzecznictwo"
                        }, {
                          value: "Kodeksy",
                          label: "Kodeksy"
                        }, {
                          value: "Eureka",
                          label: "Interpretacje podatkowe"
                        }]}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        className="w-100 mt-2"
                        onChange={(event, value) => formik.setFieldValue("sources", [...formik.values.sources, value[0].value])}
                        renderInput={(params) => <TextField {...params} label="Źródło" />}
                        multiple
                      />
                      <ErrorMessage name="question" component="div" />
                      <Button
                        className="mx-1 my-3"
                        id="search-button"
                        variant="contained-dark"
                        type="submit"
                        width="100%"
                        disabled={isLoading}
                      >Znajdź</Button>
                      <Stack direction="row" spacing={2} style={{ justifyContent: "center" }} className="my-2 w-100">
                        {suggestions && suggestions.map((suggestion: any) => {
                          return (
                            <MuiButton
                              style={{
                                width: "fit-content",
                                display: "flex",
                                alignContent: "center",
                                justifySelf: "center",
                                fontSize: "0.7rem",
                                borderRadius: "0.75rem",
                                borderColor: "#02253F",
                                color: "#02253F",
                              }}
                              className="mx-1 my-3"
                              id="search-button"
                              variant="outlined"
                              type="submit"
                              onClick={() => {
                                formik.setFieldValue("factualState", suggestion);
                              }}
                            >
                              {suggestion}
                            </MuiButton>
                          );
                        })
                        }
                      </Stack>
                    </Form>
                  </MDBRow>
                  <MDBRow>
                    <DisambiguateQuestionBox disambiguations={disambiguations} original={formik.values.factualState} onSubmit={handleFinalSubmit(formik.values)} />
                  </MDBRow>
                </>
              )
            }
            }
          </Formik>
        </MDBRow>
      </div>
      <MDBRow style={{ textAlign: "center", justifyContent: "center", padding: "10px" }}>
        {metareview && <div id={"cls-result"} style={{ width: "100%", justifyContent: "center" }}>
          <h1 style={{ marginBottom: "20px" }} >Odpowiedź</h1>
          <Typography variant="body1" color="text.primary" style={{ textAlign: "left" }} className="mt-2 mb-5" dangerouslySetInnerHTML={{ __html: metareview }} />
          {
            answerKey && (
              <Box>
                <h6>Oceń odpowiedź</h6>
                <Stack direction={"row"} spacing={2} style={{ justifyContent: "center" }}>
                  <ReviewAnswerButton type="down" answerKey={answerKey} />
                  <ReviewAnswerButton type="up" answerKey={answerKey} />
                </Stack>
              </Box>
            )
          }
          <Stack direction="row" spacing={2} style={{ justifyContent: "center" }} className="my-2 w-100">
            {/* <Button style={{ width: "fit-content", display: "flex", alignContent: "center", justifySelf: "center" }}
              className="mx-1 my-3"
              id="search-button"
              variant="contained"
              type="submit"
              onClick={() => setDialogOpen(true)}
            >Dopytaj o wyniki</Button> */}
            <ShareResultsButton metareview={metareview} query={query} results={backendResponse} />
          </Stack>
        </div>}

        {(backendResponse || isLoading) && <h1>Wyniki wyszukiwania</h1>}
        {backendResponse && (
          <Typography variant="body1" color="text.secondary" className="mt-2" style={{ textAlign: "left" }} >
            Upewnij się, że tezy orzeczeń nie zostały podważone przez wyższe instancje!
          </Typography>
        )}
        {isLoading && <div style={{ width: "100%", padding: "20px" }}><LinearBuffer maxTime={45} /></div>}
        <Typography variant="body1" color="text.primary" className="mt-2">
          {message}
        </Typography>
        {backendResponse && backendResponse.map((document: any, index: any) => {
          let url = "";
          if (document.id)
            url = `/show-document?id=${document.id}`;
          const originalUrl = document.url;

          return (
            <SearchResult index={index} document={document} sources={document.source} url={url} originalUrl={originalUrl} />
          )
        })}
      </MDBRow>
    </div>
  )
}

export default CaseLawSearch;

function SearchResult({ index, document, sources, url, originalUrl }: { index: number, document: any, sources: string, url: string, originalUrl: string }) {
  const [details, setDetails] = useState<any>({});
  const axios = useAxiosPrivate();

  React.useEffect(() => {
    const doc_id = document.id || document.saos_id;
    if (doc_id) {
      axios.get(`api/v1/judgement-details?id=${doc_id}`)
        .then((response) => {
          setDetails(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [document.id, document.saos_id]);

  let citation = "";
  if (details.type === "SENTENCE")
    citation += "Wyrok";
  else if (details.type === "DECISION")
    citation += "Postanowienie";
  else if (details.type === "RESOLUTION")
    citation += "Uchwała";
  else if (details.type === "REASONS")
    citation += "Uzasadnienie";

  if (details.court_type === "SUPREME")
    citation += ` Sądu Najwyższego (${details.division})`;
  else if (details.court_type === "COMMON_COURT" || details.court_type === "COMMON") {
    let name = details.court.replace("Sąd Okręgowy", "Sądu Okręgowego");
    name = name.replace("Sąd Rejonowy", "Sądu Rejonowego");
    name = name.replace("Sąd Apelacyjny", "Sądu Apelacyjnego");

    citation += ` ${name} (${details.division})`;
  }
  else if (details.court_type === "CONSTITUTIONAL_TRIBUNAL")
    citation += " Trybunału Konstytucyjnego";
  else if (details.court_type === "NATIONAL_APPEAL_CHAMBER")
    citation += ` Krajowej Izby Odwoławczej`;
  else if (details.court_type === "NSA")
    citation += ` Naczelnego Sądu Administracyjnego`;

  citation += ` z dnia ${details.date}`;
  citation += `, ${details.signature}`

  console.log(Object.keys(document));

  return <MDBRow className="py-2 px-0" key={index}>
    <Card className="px-0 mx-2" style={{ borderRadius: "0.75rem", boxShadow: "none" }} >
      <CardContent className="d-flex flex-column">
        <Button
          className="mx-1 my-3"
          id="search-button"
          variant="outlined-light"
          type="submit"
          href={originalUrl}
          target="_blank"
          rel="noreferrer"
        >Przejdź do oryginalnego dokumentu</Button>
        <Typography variant="h6" color="text.primary" className="mt-2">
          {document.case_number || document.full_name || document.judgement_id}
        </Typography>
        {(document.source === "judiciary" || document.source === "judiciary_200") && (
          <>
            <Typography variant="h6" color="text.secondary" className="mb-2" fontSize={"1rem"}>
              {Object.keys(details).length !== 0 && citation}
            </Typography>
            <Typography variant="body2" color="text.primary" style={{ textAlign: "justify" }}>
              {document.summary}
            </Typography>
          </>
        )}
        {
          document.source === "Eureka" && (
            <Typography variant="body2" color="text.primary" style={{ textAlign: "justify" }}>
              {document.summary}
            </Typography>
          )
        }
        <Divider className="my-3" />
        <CardActionArea href={url} target="_blank" rel="noreferrer">
          {document.text.map((text: string) => <Typography variant="body2" color="text.secondary" className="my-2" style={{ fontStyle: "italic" }}>
            <FormatQuoteIcon />(...){text}(...)
          </Typography>
          )}
          <Stack direction="row" spacing={2} style={{ justifyContent: "space-between" }} className="my-2 w-100">
            <Typography variant="body2" color="text.primary" style={{ textAlign: "left" }} className="py-1">
              {document.judgement_date ? "Data:" : null} {document.judgement_date}
            </Typography>
            <Typography
              // align right
              style={{ textAlign: "right" }}
              className="py-1"
              variant="body2"
              color="text.primary">
              {document.judges ? "Sędzia:" : null} {document.judges}
            </Typography>
          </Stack>
        </CardActionArea>
      </CardContent>
    </Card>
  </MDBRow>;
}

function formatHTML(metareview: any) {
  metareview = metareview.replace(/(?:\t)/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  // prevent XSS
  metareview = metareview.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  metareview = metareview.replace(/(?:\r\n|\r|\n)/g, '<br>');
  return metareview;
}

const DisambiguateQuestionBox = ({ disambiguations, original, onSubmit }: { disambiguations: Array<string>; original: string; onSubmit: (question: string) => {} }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  React.useEffect(() => {
    console.log(disambiguations);
    if (disambiguations.length === 1) {
      setSubmitted(true);
      onSubmit(disambiguations[0]);
    }
    else
      setSubmitted(false);
  }, [disambiguations]);

  if (disambiguations.length === 0)
    return null;

  if (submitted)
    return null;

  return (
    <Grid container spacing={2} style={{ justifyContent: "center" }}>
      <Grid item xs={12} sm={8}>
        <Card className="px-0 mx-2" sx={{ backgroundColor: Colors.DarkBlue }}>
          <CardContent>
            <Typography variant="h6" color={Colors.White} className="my-2">
              Twoje pytanie jest niejednoznaczne. Czy chodziło Ci o:
            </Typography>
            {disambiguations.map((disambiguation: string) => {
              return (
                <MuiButton style={{ width: "fit-content", display: "flex", alignContent: "center", justifySelf: "center", borderColor: Colors.White, color: Colors.White }}
                  className="mx-1 my-3 w-100"
                  id="search-button"
                  variant="outlined"
                  type="submit"
                  onClick={() => {
                    setSubmitted(true);
                    onSubmit(disambiguation);
                  }}
                >{disambiguation}</MuiButton>
              )
            })}
            <hr style={{ borderColor: Colors.White }} />
            <MuiButton style={{ width: "fit-content", display: "flex", alignContent: "center", justifySelf: "center", borderColor: Colors.White, color: Colors.White }}
              className="mx-1 my-3 w-100"
              id="search-button"
              variant="outlined"
              type="submit"
              onClick={() => {
                setSubmitted(true);
                onSubmit(original);
              }}
            >Zostaw oryginalne pytanie</MuiButton>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}