import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";

export const ReviewBox = ({ score, answerKey, handleClose, url }: { score: number; answerKey: string; handleClose: () => void; url?: string }) => {
  const [value, setValue] = React.useState<string>("");
  const axios = useAxiosPrivate();
  const snackBarContext = React.useContext(SnackbarContext);

  const handleClick = async () => {
    await getCSRFToken();

    let requestURL = `api/v1/review`;

    if (url) {
      requestURL = url;
    }

    axios.post(requestURL, {
      "score": score,
      "review": value,
      "key": answerKey
    })
      .then(response => {
        console.log(response);
        snackBarContext.setMessage("Opinia przesłana!");
        snackBarContext.setSeverity("success");
        snackBarContext.setOpen(true);
        handleClose();
      })
      .catch(error => {
        console.log(error);
        snackBarContext.setMessage("Wystąpił błąd przy przesyłaniu opinii");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
        handleClose();
      });
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader title="Oceń odpowiedź" />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {score === 1 ? "Dlaczego odpowiedź jest dobra?" : "Dlaczego odpowiedź jest zła?"}
        </Typography>
        <TextField
          multiline
          rows={4}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          fullWidth />
        <Box sx={{ display: "flex", justifyContent: "center" }} className="mt-3">
          <Button
            variant="contained"
            sx={{ alignSelf: "center" }}
            onClick={handleClick}
          >Wyślij</Button>
        </Box>
      </CardContent>
    </Card>
  );
};
