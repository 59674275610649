import { Step } from 'react-joyride';

const JoyRideSteps: Step[] = [
    {
        "target": "#onboarding-button",
        "content": 'Kliknij, aby oglądnąć krótkie wideo instruktażowe.',
    },
    {
        "target": '#factual-state',
        "content": 'Opisz stan faktyczny który chcesz zbadać.',
    },
    {
        "target": '#factual-state-submit',
        "content": 'Kliknij Wyszukaj lub naciśnij klawisz Enter.',
    },
]

const JoyRideStepsPhaseOne: Step[] = [
    {
        "target": "#search-results-tabs",
        "content": "Wybieraj z pośród zakładek te źródła danych, które Cię interesują.",
    },
    {
        "target": ".factual-state-results-checkbox",
        "content": "Kliknij, aby wybrać interesujące Cię dokumenty do dalszej analizy.",
    },
    {
        "target": ".documentViewLink",
        "content": "Kliknij, aby zobaczyć dokument źródłowy w nowej zakładce przeglądarki.",
    },
    {
        "target": "#summarize-documents-button",
        "content": "Kliknij, aby podsumować dokumenty pod kątem pytania i przejść do następnego kroku. Aby wybranie tej opcji było możliwe, zaznacz przynajmniej jeden dokument.",
    },
    {
        "target": "#ask-question-to-documents-button",
        "content": "Kliknij, aby móc zadawać pytania do zaznaczonych dokumentów.",
    },
    {
        "target": "#export-signatures-button",
        "content": "Kliknij, aby zobaczyć sygantury wszystkich zaznaczonych dokumentów.",
    },
]

const JoyRideLocale = {
    'back': 'Wstecz',
    'close': 'Zamknij',
    'last': 'Koniec',
    'next': 'Następny krok',
    'open': 'otwórz',
    'skip': 'Pomiń'
}

export { JoyRideSteps, JoyRideStepsPhaseOne, JoyRideLocale };