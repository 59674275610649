import React, { useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Mission from "../aboutUs/Mission";
import Team from "../aboutUs/Team";
import DataSources from "../aboutUs/DataSources";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const KnowledgeBase = () => {
  const pages = [
    "/knowledge-base/mission",
    "/knowledge-base/team",
    "/knowledge-base/data",
  ];
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    const url = pages.includes(window.location.pathname)
      ? window.location.pathname
      : undefined;
    if (url) {
      const page = pages.findIndex((x) => x === url);
      setTabValue(page);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Tabs onChange={handleChange} value={tabValue}>
        <Tab label="Misja" component={Link} to={"/knowledge-base/mission"} />
        <Tab label="Zespół" component={Link} to={"/knowledge-base/team"} />
        <Tab label="Dane" component={Link} to={"/knowledge-base/data"} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <Mission />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Team />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <DataSources />
      </CustomTabPanel>
    </Box>
  );
};

export default KnowledgeBase;
