const Colors = {
    ButtonBackground: "#171717",
    ButtonHover: "#0e2451",
    ButtonText: "#ffffff",
}

export const RegisterButtonStyleSX = {
    height: "40px",
    width: "300px",
    margin: "10px",
    backgroundColor: Colors.ButtonBackground,
    fontFamily: "sofia-pro, sans-serif",
    fontWeight: "semibold",
    fontSize: "1rem",
    color: "white",
    ":hover": {
        color: Colors.ButtonText,
        backgroundColor: Colors.ButtonHover,
        textDecoration: "none",
    },
};