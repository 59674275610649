import { NodeViewWrapper } from '@tiptap/react'
import React from 'react';

const InsertableTag = (props: any) => {
  const { id, text } = props.node.attrs;
  return (
    <NodeViewWrapper as="span">
      <span id={`marker-${id}`} className="text-verify-marker">
        {text}
      </span>
    </NodeViewWrapper>
  )
}

export default InsertableTag;