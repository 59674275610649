import axios, { axiosPrivate } from "../api/axios";

const getCSRFToken = async () => {
  const response = await axios
    .get("core_api/csrf-token", {
      withCredentials: true,
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  if (response.status !== 200) {
    return;
  }

  if (!response.data.csrf) {
    console.error("No CSRF token received")
    return;
  }
  axios.defaults.headers.post["X-CSRFTOKEN"] = response.data.csrf;
  axiosPrivate.defaults.headers.post["X-CSRFTOKEN"] = response.data.csrf;
  axios.defaults.headers.delete["X-CSRFTOKEN"] = response.data.csrf;
  axiosPrivate.defaults.headers.delete["X-CSRFTOKEN"] = response.data.csrf;
};

export default getCSRFToken;
