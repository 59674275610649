import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  TextareaAutosize,
  Typography,
  Dialog,
  CardActionArea
} from "@mui/material";
import Button from "../../components/generic/Button";
import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import { Form, Formik } from "formik";
import LinearBuffer from "../../components/LinearBuffer";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import BrowseDocuments from "../browseDocuments/BrowseDocuments";
import "./index.scss";
import DataUsageNoticeModal from "../../components/dataUsageNoticeModal";
import useUserData from "../../hooks/useUserData";


interface FullTextRisks {
  [key: string]: {
    proof: string;
    antithesis: string;
  };
}

interface TextAbusivity {
  [key: string]: {
    part: string;
    original: string;
    arguments: string;
  };
}

const ContractAnalysis: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fullTextRisks, setFullTextRisks] = React.useState<FullTextRisks>({});
  const [message, setMessage] = React.useState<string>("");

  const [abusivityIsLoading, setAbusivityIsLoading] = React.useState<boolean>(false);
  const [textAbusivity, setTextAbusivity] = React.useState<TextAbusivity>({});
  const [abusivityMessage, setAbusivityMessage] = React.useState<string>("");

  const [searchParams, _] = useSearchParams();
  // const filePath = searchParams.get("filePath") || "";
  const [filePath, setFilePath] = React.useState<string>(searchParams.get("filePath") || "");
  const [showDataUsageNotice, setShowDataUsageNotice] = React.useState<boolean>(false);
  const { userData } = useUserData();

  const { sendMessage, lastMessage } = useGaiusWebSocket(
    "/ws/v1/contract-analysis"
  );

  const abusivityWS = useGaiusWebSocket("/ws/v1/abusivity-analysis");

  const handleSubmit = (values: any) => {
    if (!!!userData.dataUsageNotice) {
      setShowDataUsageNotice(true);
      return;
    }

    setIsLoading(true);
    setAbusivityIsLoading(true);
    if (filePath === "") {
      sendMessage(JSON.stringify(values));
      abusivityWS.sendMessage(JSON.stringify(values));
    }
    else {
      sendMessage(
        JSON.stringify({
          filePath: filePath,
        })
      );
      abusivityWS.sendMessage(
        JSON.stringify({
          filePath: filePath,
        })
      );
    }
  };

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.message === "analyzing") {
        setMessage("Analizowanie dokumentu...");
        setFullTextRisks(data.full_text_risks);
      } else if (data.message === "done") {
        setMessage("");
        setFullTextRisks(data.full_text_risks);
        setIsLoading(false);
      } else if (data.message === "error") {
        setIsLoading(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (abusivityWS.lastMessage) {
      const data = JSON.parse(abusivityWS.lastMessage.data);
      if (data.message === "analyzing") {
        setAbusivityMessage("Analizowanie dokumentu...");
        setTextAbusivity(data.text_abusivity);
        setAbusivityMessage("Postęp analizy: " + data.progress + "%")
      }
      if (data.message === "done") {
        if (Object.keys(data.text_abusivity).length === 0) {
          setAbusivityMessage("Nie znaleziono klauzul abuzywnych w dokumencie.");
        }
        else {
          setAbusivityMessage("");
        }
        setTextAbusivity(data.text_abusivity);
        setAbusivityIsLoading(false);
      }
      if (data.message === "error") {
        setAbusivityIsLoading(false);
      }
    }
  }, [abusivityWS.lastMessage]);

  const validationSchema = null;
  if (filePath === "")
    Yup.object({
      text: Yup.string().required("Pole wymagane"),
    });

  return (
    <div id={"contract-analysis"}>
      <Helmet>
        <title>Przeanalizuj ryzyka i abuzywność umowy | Gaius Lex - asystent prawnika</title>
        <meta
          name="description"
          content="Gaius Lex - asystent prawnika. Znajdź orzeczenia sądowe powiązane z Twoim pytaniem."
        />
      </Helmet>
      <Box id={"contract-analysis-box"}>
        <h1 style={{ marginBottom: "20px" }}>Przeanalizuj ryzyka i abuzywność umowy</h1>
        <Typography
          variant="h5"
          color="text.secondary"
          mb={2}
          className="pb-2"
          sx={{ fontWeight: "regular", fontSize: "1rem" }}
        >
          Gaius Lex przeanalizuje Twoją umowę i wskaże ryzyka z nią związane. Do tego przeanalizuje, czy nie zawiera klauzul niedozwolonych.
        </Typography>
        <DocumentSelector callback={(filePath: string) => {
          setFilePath(filePath);
        }} />
        <Stack>
          <Formik
            initialValues={{
              text: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {filePath === "" ? (
                    <TextareaAutosize
                      aria-label="Treść umowy"
                      minRows={10}
                      placeholder="Treść umowy ..."
                      style={{
                        width: "100%",
                        borderRadius: "0.75rem",
                        padding: "10px",
                        marginBottom: "20px",
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      }}
                      name="text"
                      onChange={formik.handleChange}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        fontStyle: "italic",
                      }}
                    >
                      {filePath}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained-dark"
                    color="primary"
                    width={"100%"}
                    disabled={
                      (isLoading || abusivityIsLoading) ||
                      (filePath === "" && formik.values.text === "")
                    }
                  >
                    Zbadaj dokument
                  </Button>
                </Form>
              );
            }}
          </Formik>
          {showDataUsageNotice && <DataUsageNoticeModal />}
          {( isLoading || abusivityIsLoading ) && (
            <div
              style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
            >
              <LinearBuffer />
            </div>
          )}
          <Typography variant="body1" color="text.primary" className="mt-2">
            {message}
          </Typography>
          <Typography variant="body1">
            <h1 style={{ margin: "20px 0" }}>Klauzule abuzywne zawarte w dokumencie:</h1>
            <Typography variant="body1" color="text.secondary" className="mt-2 p-3">
              {abusivityMessage}
            </Typography>
            <Grid container spacing={2}>
              {textAbusivity &&
                Object.keys(textAbusivity).map((key, index) => {
                  const quote = textAbusivity[key]["part"];
                  const explanation = textAbusivity[key]["original"];
                  const args = textAbusivity[key]["arguments"];

                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <Card key={index} className="my-3">
                        <CardContent className="pb-1">
                          <Typography variant="h6">Znaleziono podobieństwo do klauzuli z wyroku {key}</Typography>
                          <ul>
                            <li
                              key={index}
                              style={{
                                fontWeight: "lighter",
                                color: "text.secondary",
                              }}
                            >
                              Fragment wyroku: <em>(...){quote}(...)</em>
                              <br />
                              <br />
                              Podobne do: <em>(...){explanation}(...)</em>
                              <br />
                              <br />
                              <b>Wyjaśnienie:</b> {args}
                            </li>
                          </ul>
                        </CardContent>
                        <CardActionArea>
                          <Button variant="contained-dark" color="primary" width={"100%"} href={`/show-document?docId=${key}&source=judiciary`} target="_blank">
                            Zobacz wyrok
                          </Button>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
            <Divider />
            {Object.keys(fullTextRisks).length !== 0 && (
              <h1 style={{ margin: "20px 0" }}>Ryzyka zawarte w dokumencie:</h1>
            )}
            <Grid container spacing={2}>
              {fullTextRisks &&
                Object.keys(fullTextRisks).map((key, index) => {
                  const quote = fullTextRisks[key]["proof"];
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <Card key={index} className="my-3">
                        <CardContent className="pb-1">
                          <Typography variant="h6">{key}</Typography>
                          <ul>
                            <li
                              key={index}
                              style={{
                                fontWeight: "lighter",
                                color: "text.secondary",
                              }}
                            >
                              <em>(...){quote}(...)</em>
                            </li>
                          </ul>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default ContractAnalysis;

const DocumentSelector = ({ callback }: { callback: any }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box display={"flex"} flexDirection={"row"} alignContent={"center"} justifyContent={"center"}>
      <Button
        variant="outlined-light"
        color="primary"
        width={"50%"}
        className="mb-3"
        onClick={() => setOpen(true)}
      >
        Wybierz dokument
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Box p={2}>
          <Typography variant="h5" color="text.primary" className="mb-2">
            Wybierz dokument
          </Typography>
          <BrowseDocuments minimal={true} callback={(path: string) => {
            callback(path);
            setOpen(false);
          }} />
        </Box>
      </Dialog>
    </Box>
  )
}