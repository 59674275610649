import React, { useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

const GhostText = (props: any) => {
  const [isHovered, setIsHovered] = useState(false);
  const { text } = props.node.attrs;

  const handleAccept = () => {
    // TODO: Implement accept functionality
    console.log('Accepted:', text);
  };

  return (
    <NodeViewWrapper>
      <span
        style={{
          color: 'gray',
          fontStyle: 'italic',
          position: 'relative',
          display: 'inline-block',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {text}
        {isHovered && (
          <button
            style={{
              position: 'absolute',
              top: '-20px',
              right: '0',
              background: '#4CAF50',
              color: 'white',
              border: 'none',
              padding: '2px 5px',
              borderRadius: '3px',
              cursor: 'pointer',
              fontSize: '12px',
            }}
            onClick={handleAccept}
          >
            Accept
          </button>
        )}
      </span>
    </NodeViewWrapper>
  );
};

export default GhostText;
